import React, { PureComponent, Fragment } from 'react';

import {
	StyledModalAlertContent,
	StyledModalAlertFooter,
	StyledModalAlertBtn
} from './styles';

import { Modal } from '../Modal/index'

class ModalAlert extends PureComponent<any> {
	render () {
		const { 
			message,
			messageHtml,
			type,
			show,
			onClose
		} = this.props;

		return (
			<Modal 
				show={show}
				onClose={onClose}
			>
				<StyledModalAlertContent type={type}>
					<Fragment>
						{message && message}
						{messageHtml &&
							<div dangerouslySetInnerHTML={{__html: messageHtml}} />
						}
					</Fragment>
				</StyledModalAlertContent>
				<StyledModalAlertFooter>
					<StyledModalAlertBtn
						onClick={onClose}
					>
						Ok
					</StyledModalAlertBtn>
				</StyledModalAlertFooter>
			</Modal>
		)
	}
}

export { ModalAlert };

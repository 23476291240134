import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form'

import {
	StyledFormTitle,
	StyledForm
} from '../styles';

import { 
	Input, 
	Submit 
} from '@/components/FormUI/index';

const validate = values => {
	const { username } = values;
	const errors = {}
	
  if (!username) {
		// @ts-ignore
    errors.username = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(username)) {
		// @ts-ignore
		errors.username = 'Invalid email address';
	}
	
  return errors;
};
				
let FormResendActivation = (props) => {
	const { handleSubmit, loading } = props;

	return (
		<Fragment>
			<StyledFormTitle>
				Activate Your account
			</StyledFormTitle>
			<StyledForm onSubmit={handleSubmit}>
				<Input 
					name="username"
					type="email"
					label="Email"/>
				<Submit 
					primary
					center 
					xl={!loading} 
					maxWidth="340"
          disabled={loading}
          loading={loading}
				>
					Send
				</Submit>
			</StyledForm>
		</Fragment>
	)
};

FormResendActivation = reduxForm({
	form: 'resendActivation',
	validate
})(FormResendActivation)

export { FormResendActivation };

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  titleSection: {
    padding: '0 20px'
  },
  panel: {
    margin: '0 20px 20px'
  },
  layoutContainer: {
    display: 'inline-block',
    textAlign: 'center'
  }
});

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { StyledAppSign } from '@/components/Styled/App/index';
import { StyledFormWrapper } from '@/components/Forms/styles';

import HeaderAuth from '@/components/Headers/HeaderAuth/index';

export default function wrappedAuth(WrappedComponent, mapStateToProps, mapDispatchToProps) {
  class decoreWithStore extends Component {
    render() {
      return (
        <StyledAppSign>
          <StyledFormWrapper>
            <HeaderAuth />
            <WrappedComponent {...this.props} />
          </StyledFormWrapper>
				</StyledAppSign>
      )
    }
  }
	
	return connect(mapStateToProps, mapDispatchToProps)(decoreWithStore);
}

import React from 'react';
import { reduxForm, FieldArray } from 'redux-form';

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import { HSelect } from '@/components/FormUI/index';
import PanelForm from '@/components/PanelForm/index';
import { StyledFormList, StyledFormListItem } from '@/components/FormUI/styles';
import Title from '@/components/Title';

const validate = (values) => {
	const errors = {};

	if (!values.agentId) {
		// @ts-ignore
    errors.agentId = 'Required';
	}
	
  return errors;
};

const renderMultiAgents = ({fields, items, disabled}) => (
	<StyledFormList>
		{fields.map((agent, index) => (
			<StyledFormListItem  key={index}>
				<HSelect
					name={`${agent}.agent`}
					label="Select Agent Name"
					options={items}
					isClearable={true}
					btnAdd={fields.length === (index + 1)}
					btnRemove={fields.length > 1}
					onAdd={() => fields.push({})}
					onRemove={() => fields.remove(index)}
					disabled={disabled}
				/>
			</StyledFormListItem>
		))}
	</StyledFormList>
);
				
let FormAgent = (props) => {
	const { 
		handleSubmit,
		onSaveAndNext,
		skipUrl,
		loading,
		items,
		disabled
	} = props;

	return (
		<form onSubmit={handleSubmit}>
			<HSelect
				name="agentId"
				label="Agent Name"
				disabled={disabled}
				options={items} 
			/>
			<GridRow>
				<GridCol style={{ marginTop: 25 }}>
					<Title
						name="Add another Agent:" 
						subtitle="true" 
					/>
					<FieldArray name="multiAgents" component={renderMultiAgents} props={{items, disabled}} />
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol>
					<PanelForm 
						loading={loading}
						onSave={handleSubmit}
						onSaveAndNext={onSaveAndNext}
						skipUrl={skipUrl}
						formProps={props}
					/>
				</GridCol>
			</GridRow>
		</form>
	)
};

FormAgent = reduxForm({
	form: 'agent',
	validate,
	enableReinitialize: true
})(FormAgent)

export { FormAgent };

import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import config from '@/config';

import { ApiPath } from '@/v2/shared/enums';
import { saveTourRequest } from '@/actions/tour';
import { saveTour } from '@/helpers/forms';
import { redirectTo } from '@/helpers/redirect';
import Title from '@/components/Title';
import PanelForm from '@/components/PanelForm';
import FileManager from '@/v2/shared/components/FileManager/FileManager';
import { File } from '@/v2/shared/components/FileManager/types';

const Photos: React.FC = (props: any): ReactElement => {
	const FORM_NAME = 'photos';
	const { id, currentTour, loadingTour, token, initialPhotos } = props;
	// @ts-ignore
  const skipUrl = `/${redirectTo({currentPage: FORM_NAME, activePages: currentTour.settings})}/${id}`;

	const [ fileList, setFileList ] = useState<File[]>([]);
	const [ photos, setPhotos ] = useState([]);
	const [ uploading, setUploading ] = useState(false);

	useEffect(() => {
		setPhotos(initialPhotos || []);
		setFileList(initialPhotos?.map(mapFilesToFileGrid));
	}, [initialPhotos]);

	const mapFilesToFileGrid = (file, index): File => {
		const url = file?.urls[2]?.url;
		const type = url?.split('.').pop();

		return {
			id: photos[index]?.id || (index + 1).toString(),
			name: url,
			url,
			uploaded: true,
			saved: true,
			status: 'success',
			type,
			size: 0,
			fileData: file
		}
	};

	const handleChangeFiles = (files: File[]) => {
		setPhotos(files.map(file => file.fileData));
	};

	const handleSave = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, photos);
  };

  const handleSaveAndNext = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, photos, true);
  };

  return (
    <>
			<Title name="Photos" />
			<FileManager
				prefix="photos"
				url={config.API_URL + ApiPath.uploadPhotos + id}
				headers={{ 'Authorization': `Bearer ${token}` }}
				info={
					<>
						Accepted extensions: jpg, jpeg, png
						<p style={{ marginTop: 5 }}>
							Tip: Files are uploaded in alphabetical order. Name your files 1.jpg, 2.jpg, 3.jpg, 
							... to avoid manual reordering.
						</p>
					</>
				}
				files={fileList}
				enableRemovingFile={true}
				onChangeFiles={handleChangeFiles}
				onStart={() => setUploading(true)}
				onEnd={() => setUploading(false)}
				onUploadDone={handleSave}
			/>
			<PanelForm
				loading={loadingTour}
				disabled={uploading}
				onSave={handleSave}
				onSaveAndNext={handleSaveAndNext}
				skipUrl={skipUrl}
				formProps={{valid: true}}
			/>
		</>
	);
};

const mapStateToProps = (state: any, ownProps: any): any => {
  const id = ownProps.match.params.id;
  const currentTour = state.tour[id];
  const initialPhotos = currentTour ? currentTour.photos : null;

  return {
		token: state.auth.token,
    id,
    currentTour,
    initialPhotos,
    loadingTour: state.tour.loading
  };
};

const mapDispatchToProps = {
  saveTourRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Photos);

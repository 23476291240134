import React from 'react';
import { reduxForm } from 'redux-form'

import {
	Textarea, 
	Input
} from '@/components/FormUI/index';

import PanelForm from '@/components/PanelForm/index';

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';
				
let FormDetails = (props) => {
	const { 
		handleSubmit,
		onSaveAndNext,
		skipUrl,
		loading 
	} = props;

	return (
		<form onSubmit={handleSubmit}>
			<GridRow>
				<GridCol>
					<Textarea 
						name="description"
						label="Description"
					/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input 
						name="bedrooms"
						type="text"
						label="Bedrooms"/>
				</GridCol>
        <GridCol col="50">
					<Input 
						name="propertyType"
						type="text"
						label="Property type"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input 
						name="bathrooms"
						type="number"
						min="0"
						label="Bathrooms"/>
				</GridCol>
        <GridCol col="50">
					<Input 
						name="buildingType"
						type="text"
						label="Building type"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input 
						name="basement"
						type="checkbox"
						label="Basement"/>
				</GridCol>
        <GridCol col="50">
					<Input 
						name="price"
						type="text"
						label="Price"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input 
						name="stories"
						type="number"
						min="0"
						label="Stories"/>
				</GridCol>
        <GridCol col="50">
					<Input 
						name="parking"
            type="number"
            min="0"
						label="Parking"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input 
						name="garage"
						type="checkbox"
						label="Garage"/>
				</GridCol>
        <GridCol col="50">
					<Input 
						name="heatingType"
						type="text"
						label="Heating type"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input 
						name="appliances"
						type="text"
						label="Appliances"/>
				</GridCol>
				<GridCol col="50">
					<Input
						name="lot"
						type="text"
						label="Lot Size"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input
						name="totalSquareFootage"
						type="number"
						min="0"
						label="Total Square Footage (sqft)"/>
				</GridCol>
				<GridCol col="50">
					<Input
						name="yearBuilt"
						type="number"
						min="0"
						label="Year Built"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input
						name="taxes"
						type="number"
						min="0"
						label="Taxes"/>
				</GridCol>
				<GridCol col="50">
					<Input
						name="maintenanceFee"
						type="number"
						min="0"
						label="Maintenance fee"/>
				</GridCol>
			</GridRow>

			<div style={{marginBottom: "2rem"}}></div>

			<GridRow>
				<GridCol col="50">
					<Input
						name="guestHome"
						type="checkbox"
						label="Guest Home"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input
						name="bedroomsGH"
						type="number"
						min="0"
						label="Bedrooms"/>
				</GridCol>
				<GridCol col="50">
					<Input
						name="bathroomsGH"
						type="number"
						min="0"
						label="Bathrooms"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input
						name="totalSquareFootageGH"
						type="number"
						min="0"
						label="Total Square Footage (sqft)"/>
				</GridCol>
			</GridRow>

			<div style={{marginBottom: "2rem"}}></div>

			<GridRow>
				<GridCol col="50">
					<Input
						name="shop"
						type="checkbox"
						label="Shop"/>
				</GridCol>
			</GridRow>
			<GridRow>
				<GridCol col="50">
					<Input
						name="totalSquareFootageShop"
						type="number"
						min="0"
						label="Total Square Footage (sqft)"/>
				</GridCol>
			</GridRow>

			<GridRow>
				<GridCol>
					<PanelForm 
						loading={loading}
						onSave={handleSubmit}
						onSaveAndNext={onSaveAndNext}
						skipUrl={skipUrl}
						formProps={props}
					/>
				</GridCol>
			</GridRow>	
		</form>
	)
};

FormDetails = reduxForm({
	form: 'details'
})(FormDetails)

export { FormDetails };

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  row: {
    display: 'grid',
    'grid-template-columns': 'repeat(auto-fill, minmax(280px, 1fr))',
    'margin-bottom': 50,
    gap: '16px'
  },
  col: {
    height: '100%'
  },
  card: {
    height: '100%',
    overflow: 'visible',

    '& > .rs-panel-body': {
      height: '100%'
    }
  },
  cardThumbnail: {
    position: 'relative',
    height: 160,
    padding: 4,
    borderBottom: '1px solid #f2f2f2',
    backgroundColor: '#f8f8f8',

    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      transition: 'opacity .1s ease-out',
      '&:hover': {
        opacity: '.88'
      }
    },
    '& .rs-tag': {
      position: 'absolute',
      left: 9,
      top: 9,

      '& + .rs-tag': {
        left: 72
      }
    }
  },
  removeButton: {
    position: 'absolute !important',
    right: -10,
    top: -10
  },
  cardBody: {
  },
  editLink: {
    position: 'relative',
    display: 'block',
    height: 41,
    marginTop: -5,
    marginBottom: 20,
    paddingLeft: 20,
    fontSize: 14,
    color: '#333',
    overflow: 'hidden',

    '& .rs-icon': {
      position: 'absolute',
      left: 0,
      top: 4
    }
  },
  viewButtonCopyIcon: {
    position: 'absolute',
    right: 8,
    cursor: 'copy'
  },
  badge: {
    position: 'absolute',
    left: 5,
    top: 5,
    fontSize: 11,
    fontWeight: 500,
    padding: '1px 5px'
  },
  dropdown: {
    width: '100%',
    margin: {
      top: 5,
      left: '0 !important'
    },
    '& .rs-btn': {
      paddingRight: '10px !important'
    },
    '& .rs-dropdown-toggle-caret': {
      top: '6px !important'
    },
    '& .rs-dropdown-menu': {
      width: '100%',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.16), 0 4px 4px rgba(0, 0, 0, 0.18)'
    }
  },
  footer: {
    marginTop: 5,
    borderTop: '1px solid #f2f2f2',
    borderRadius: 0,
    backgroundColor: '#f8f8f8',

    '& .rs-panel-body': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '10px 20px'
    }
  },
  agentAvatar: {
    minWidth: 40,

    '& .rs-avatar-image:before': {
      fontSize: 14
    }
  },
  agentContent: {
    width: '100%',
    marginLeft: 10,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& div': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& strong': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingRight: 3
    },
    '& small': {
      maxWidth: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  date: {
    fontSize: 11
  },
  agentInfoStatus: {
    marginTop: 1,

    '& .rs-icon': {
      marginLeft: 6,
      fontSize: 12
    }
  },
});

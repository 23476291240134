import * as yup from 'yup';

import { REQUIRED } from '@/v2/shared/components/Form/validationMessages';

export const SCHEMA = yup.object().shape({
	enabled: yup.boolean(),
	days: yup.array().notRequired().of(
		yup.object().shape({
			day: yup.string().required(REQUIRED),
			start: yup.string().required(REQUIRED),
			end: yup.string().required(REQUIRED),
		})
	)
});

export const DAY_ITEM = {
	day: '',
	start: '',
	end: ''
};

import {
	ACCOUNT_REQUEST_GET_INFO,
	ACCOUNT_SUCCESS_GET_INFO,
	ACCOUNT_REQUEST_SAVE_INFO,
	ACCOUNT_SUCCESS_SAVE_INFO,
  ACCOUNT_REQUEST_CHANGE_PASSWORD,
  ACCOUNT_SUCCESS,
	ACCOUNT_FAILURE
} from '@/actions/types';

export const getAccountInfoRequest = () => {
  return {
    type: ACCOUNT_REQUEST_GET_INFO
  }
};

export const getAccountInfoSuccess = data => {
  return {
		type: ACCOUNT_SUCCESS_GET_INFO,
		data
	}
};

export const saveAccountInfoRequest = data => {
  return {
		type: ACCOUNT_REQUEST_SAVE_INFO,
		payload: {
			data
		}
	}
};

export const saveAccountInfoSuccess = () => {
  return {
		type: ACCOUNT_SUCCESS_SAVE_INFO
  }
};

export const accountChangePasswordRequest = (username, password) => {
  return {
		type: ACCOUNT_REQUEST_CHANGE_PASSWORD,
		payload: {
			username,
			password
		}
	}
};

export const accountSuccess = data => {
  return {
		type: ACCOUNT_SUCCESS,
		data
  }
};

export const accountFailure = () => {
  return {
		type: ACCOUNT_FAILURE
  }
};

import React from 'react';

import { 
	BlockNotFoundContainer,
	BlockNotFound,
	BlockNotFoundTitle,
	BlockNotFoundText
} from '@/components/Styled/Blocks/index';
import { ButtonRedirect } from '@/components/Styled/Buttons/index';

const NotFound = () => (
	<BlockNotFoundContainer>
		<BlockNotFound>
			<BlockNotFoundTitle>404</BlockNotFoundTitle>
			<BlockNotFoundText>Not found</BlockNotFoundText>
			<ButtonRedirect to="/tours">Back to home</ButtonRedirect>
		</BlockNotFound>
	</BlockNotFoundContainer>
)

export default NotFound;

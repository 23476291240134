import React from 'react';

import {
	StyledFormGroup,
	StyledFormError
} from './styles';

export default WrappedComponent => {
	return (props) => {
		const { touched, error } = props.meta;
		
		return (
			<StyledFormGroup>
				<WrappedComponent {...props} />
				{touched &&
					error && <StyledFormError>{error}</StyledFormError>
				}
			</StyledFormGroup>
		)
	};
}

import { call, put, takeLatest, select } from 'redux-saga/effects';

import Api from '@/services/api';

import { getToken } from '@/selectors/index';

import { catchError } from './catchError';

import { SUBSCRIPTION_REQUEST_CHECK } from '@/actions/types';

import {
	checkSubscriptionSuccess,
	checkSubscriptionFailure
} from '@/actions/subscriptions';

export function* checkSubscriptionRequest() {
  try {
		const token = yield select(getToken);

		yield call(Api.checkSubscription, token);
    yield put(checkSubscriptionSuccess());
  } catch (error) {
		const { data: { message } } = error;

		if (message !== 'SUBS_CHECK_FAILURE') {
			yield catchError(error);
		}

    yield put(checkSubscriptionFailure());
  }
}

export function* checkSubscriptionRequestSaga() {
  yield takeLatest(SUBSCRIPTION_REQUEST_CHECK, checkSubscriptionRequest);
}

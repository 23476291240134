import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form';

import Title from '@/components/Title/index';
import PanelForm from '@/components/PanelForm/index';
import { Input } from '@/components/FormUI/index';

const validate = values => {
  const { companyName, companyURL, copyrightInfo } = values;
  const errors = {};

  if (!companyName) {
    // @ts-ignore
    errors.companyName = 'Required.';
  }

  if (!companyURL) {
    // @ts-ignore
    errors.companyURL = 'Required.';
  } else if (!/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i.test(companyURL)) { /* eslint-disable-line */
    // @ts-ignore
    errors.companyURL = 'Invalid URL. Your URL should start with http:// or https://';
  }

  if (!copyrightInfo) {
    // @ts-ignore
    errors.copyrightInfo = 'Required.';
  }

  return errors;
};

let FormFooterCustomize = (props) => {
  const { handleSubmit, submitSucceeded, loading } = props;

  return (
    <Fragment>
      <Title
        subtitle="true"
        name="3) Edit tour footer:"
      />
      <form onSubmit={handleSubmit}>
        <Input
          name="companyName"
          type="text"
          label="Company Name:"
        />
        <Input
          name="companyURL"
          type="text"
          label="Company URL: (e.g. https://example.com)"
          placeholder={"https://example.com"}/>
        <Input
          name="copyrightInfo"
          type="text"
          label="Copyright Info: (e.g. Company Name, All Rights Reserved)" />
        <PanelForm loading={loading && submitSucceeded} />
      </form>
    </Fragment>
  )
};

FormFooterCustomize = reduxForm({
  form: 'footerCustomize',
  validate,
  enableReinitialize: true
})(FormFooterCustomize);

export { FormFooterCustomize };

import React from 'react';
import { connect } from 'react-redux';
import {
  CSSTransition,
	TransitionGroup,
} from 'react-transition-group';

import {
		StyledToastsContainer,
		StyledToastsItem,
		transitionOptionsCSS
} from './styles';

const Toasts = props => {
	const { 
		timeout, 
		transitionName
	} = transitionOptionsCSS;

	return (
		<StyledToastsContainer>
			<TransitionGroup>
				{props.items.map((item, key) =>
					<CSSTransition
						key={key}
						timeout={timeout}
						classNames={transitionName}
					>
						<StyledToastsItem
							key={key} 
							type={item.type}
							dangerouslySetInnerHTML={{__html: item.message}}
						/>
					</CSSTransition>
				)}
			</TransitionGroup>
		</StyledToastsContainer>
	)
};

const mapStateToProps = (state, ownProps) => ({
    items: state.toasts.items
});

export default connect(mapStateToProps, null)(Toasts);

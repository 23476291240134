import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Badge, Button, Icon } from 'rsuite';

import { 
  StyledHeader,
	StyledHeaderLogo,
	StyledHeaderLogoImage,
  StyledHeaderTour,
  StyledHeaderTourLabel,
  StyledHeaderTourName
} from './styles';

// @ts-ignore
import logoImg from '@/assets/logo-rwd.svg';

class Header extends PureComponent<any> {
	render() {
		const { address, customDomain } = this.props;

    return (
			<StyledHeader>
				{!customDomain &&
					<StyledHeaderLogo 
						href="http://homeontour.com"
						target="_blank">
						<StyledHeaderLogoImage 
							src={logoImg} 
							alt="Home On Tour"
							title="Home On Tour" />
					</StyledHeaderLogo>
				}
				{/* <Badge content="NEW" style={{ marginLeft: 30 }}> */}
					<Button
						style={{ marginLeft: customDomain ? 0 : 30 }}
						appearance="default"
						href="https://support.homeontour.com"
						target="_blank"
					>
						Help
					</Button>
				{/* </Badge> */}
				<StyledHeaderTour>
					<StyledHeaderTourLabel>Editing tour:</StyledHeaderTourLabel>
					<StyledHeaderTourName>
						{address ? (
							address
						) : (
							`None`
						)}
					</StyledHeaderTourName>
				</StyledHeaderTour>
			</StyledHeader>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	const pathname = ownProps.location.pathname;
	const id = pathname.split('/')[2];
	const currentTour = state.tour[id];
	const customDomain = state.customDomain.customDomain;

  return {
    address: currentTour?.fullAddress,
		customDomain
	};
};

export default withRouter(connect(mapStateToProps)(Header));

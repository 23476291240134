import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form';
import { take, takeRight, isEmpty } from 'lodash';
import ReactAudioPlayer from 'react-audio-player';
import { ControlLabel, FormControl, FormGroup, Toggle } from 'rsuite';

import { getLayouts } from '@/helpers/layouts'

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import PanelForm from '@/components/PanelForm/index';
import {
	HSelect,
	Input,
	SwitchMedia
} from '@/components/FormUI/index';
import Title from '@/components/Title/index';
				
let FormSettings = (props) => {
	const {
		handleSubmit,
		onSaveAndNext,
		onValues,
		topSectionItems,
		musicItems,
		loading,
		musicValue
	} = props;

	const getMusicUrl = () => {
		const foundTrack = musicItems.find(({ value }) => value === musicValue.value);

		return foundTrack ? foundTrack.url: '';
	};

	const handleChange = (event, value) => {
		const { name } = event.target;

		onValues({name, value});
	}

	return (
		<form onSubmit={handleSubmit}>
			<GridRow>
				<GridCol>
					<Title 
						name="Active pages:"
						subtitle={true} />
				</GridCol>
				<GridCol wrap="true">
					<Input
						label="360 tour"
            name="tour360"
						type="checkbox" />
					<Input
						label="Video"
            name="video"
						type="checkbox" />
					<Input
						label="Slideshow"
            name="slideshow"
						type="checkbox" />
					<Input
						label="Gallery"
            name="gallery"
						type="checkbox" />
					<Input
						label="Details"
            name="details"
						type="checkbox" />
					<Input
						label="Floorplan"
            name="floorplan"
						type="checkbox" />
					<Input
						label="Documents"
            name="documents"
						type="checkbox" />
					<Input
						label="Walk Score"
						name="walkScore"
						type="checkbox"
						onChange={handleChange}
					/>
					<Input
						label="Map"
            name="map"
						type="checkbox" />
        </GridCol>
				<GridCol>
					<Title 
						name="Layout:"
						subtitle={true} 
					/>
				</GridCol>
				<GridCol>
				<SwitchMedia
					title="Light Mode:"
					name="layout"
					options={take(getLayouts(), 3)}
				/>
				<SwitchMedia
					title="Dark Mode:"
					name="layout"
					options={takeRight(getLayouts(), 3)}
				/>
				</GridCol>
				{!isEmpty(topSectionItems) &&
					<Fragment>
						<GridCol>
							<Title
								name="Top Section:"
								subtitle={true} />
						</GridCol>
						<GridCol col="50">
							<HSelect
								name="topSection"
								options={topSectionItems}
							/>
						</GridCol>
					</Fragment>
				}
				<GridCol>
					<Title
						name="Slideshow configuration:"
						subtitle={true} />
				</GridCol>
				<GridCol wrap="true">
					<Input
						label="Animations"
            name="slideshowConfig.animations"
						type="checkbox" />
					<Input
						label="Thumbnails"
            name="slideshowConfig.thumbnails"
						type="checkbox" />
					<Input
						label="Autoplay"
            name="slideshowConfig.autoplay"
						type="checkbox" />
				</GridCol>
				<GridCol>
					<Title
						name="Music:"
						subtitle={true} />
				</GridCol>
				<GridCol col="50">
					<HSelect
						name="music"
						options={musicItems}
						isClearable
					/>
					{musicValue &&
						<div style={{ marginBottom: 20 }}>
							<ReactAudioPlayer
								src={getMusicUrl()}
								controls
								controlsList={'nodownload'}
							/>
						</div>
					}
				</GridCol>
				<GridCol>
					<PanelForm 
						loading={loading}
						onSave={handleSubmit}
						onSaveAndNext={onSaveAndNext}
						formProps={props}
					/>
				</GridCol>
      </GridRow>
		</form>
	)
};

FormSettings = reduxForm({
	form: 'settings'
})(FormSettings);

const selector = formValueSelector('settings');

FormSettings = connect(state => {
	const musicValue = selector(state, 'music');

	return { musicValue };
})(FormSettings);

export { FormSettings };

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { first, get } from 'lodash';

import { 
	saveAccountInfoRequest,
	accountChangePasswordRequest 
} from '@/actions/account';

import { 
	FormAccountChangeInfo,
	FormAccountChangePassword
} from '@/components/Forms/index';

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import { statesUSA } from '@/helpers/statesUSA';
import { provincesCanada } from '@/helpers/provincesCanada';
import { countries } from '@/helpers/countries';

class AccountData extends Component<any> {
	handleChangeInfoSubmit = values => {
		const { subscription, saveAccountInfoRequest } = this.props;
		const { name, phone, country, state } = values;

    saveAccountInfoRequest({
			name,
			phone,
			country: country?.value,
			state: state?.value,
			package: subscription ? subscription.currentPackage : ''
		});
  }

	handleChangePasswordSubmit = values => {
		const { username, password } = values;

    this.props.accountChangePasswordRequest(username, password);
  }

  render() {
		const { initialValues, selectedCountry, loading } = this.props;

    return (
			<GridRow>
				<GridCol>
					<FormAccountChangeInfo
						onSubmit={this.handleChangeInfoSubmit}
						initialValues={initialValues}
						selectedCountry={selectedCountry}
						loading={loading}
					/>
					<div style={{'height': '2.5rem'}}></div>
					<FormAccountChangePassword
						onSubmit={this.handleChangePasswordSubmit}
						loading={loading}
					/>
				</GridCol>
			</GridRow>
    )
  }
}

const getCountry = (code: string): any => first(countries.filter(item => item.value === code));

const getState = (selectedCountry: string, state: string): any => {
	const list = selectedCountry === 'usa'
		? statesUSA
		: provincesCanada;

	return first(list.filter(({code}) => code === state)
		.map(({ code, name }) => ({ value: code, label: name })));
}

const mapStateToProps = (mainState, ownProps) => {
	const { email, name, phone, country, state, subscription } = mainState.account.info;
	const selectedCountry = get(mainState.form, 'accountChangeInfo.values.country.value') || country;

  const initialValues = {
		email,
		name,
		phone,
		country: getCountry(country),
		state: getState(selectedCountry, state)
	};

  return {
		subscription,
		initialValues,
		selectedCountry,
    loading: mainState.account.loading
  };
};

const mapDispatchToProps = {
	saveAccountInfoRequest,
  accountChangePasswordRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountData);

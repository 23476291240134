import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	item: {
		position: 'relative',
		padding: '5px',

		'& .rs-panel-bordered': {
			borderWidth: 3,
			backgroundColor: '#fff'
		},

		'&.itemSize2 img, &.itemSize2 .image-card-file-wrapper': {
			height: '350px', // 2 cols
		},

		'&.itemSize4 img, &.itemSize4 .image-card-file-wrapper': {
			height: '250px', // 4 cols
		},

		'&.itemSize6 img, &.itemSize6 .image-card-file-wrapper': {
			height: '220px', // 6 cols
		},
	
		'& img': {
			display: 'block',
			height: '100%',
			width: '100%',
			objectFit: 'cover',
			borderRadius: 4
		},

		'& .image-card-file-wrapper': {
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			alignItems: 'center'
		},
	
		'& .rs-panel': {
			overflow: 'visible'
		}
	},
	itemSelected: {
		'& .rs-panel': {
			borderColor: '#7d52a8',
		},
		'& img': {
			opacity: '0.5 !important'
		}
	},
	itemBody: {
		position: 'relative',
		margin: '5px',
		outline: 'none',
		cursor: 'grab',
		userSelect: 'none',

		'&:active': {
			cursor: 'grabbing'
		}
	},
	image: {
		position: 'relative'
	},
	imageOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 1
	},
	removeButton: {
		position: 'absolute !important',
		top: 0,
		right: 0,
		zIndex: 5
	},
	editButton: {
		position: 'absolute !important',
		top: 0,
		right: 35,
		zIndex: 5
	},
	progressBar: {
		position: 'absolute',
		bottom: '0',
		left: '0',
		width: '100%',
		backgroundColor: 'rgba(0,0,0,.6)',
		color: '#fff'
	},
	uploadedStatus: {
		position: 'absolute',
		top: '4px',
		left: '3px',
		zIndex: 2,
		color: '#ffc107',
		cursor: 'auto'
	}
});

import { AccessAndPasswordForm } from "@/v2/views/Tour/Settings/components/AccessAndPassword/types";
import { selectAccess } from "@/v2/views/Tour/Settings/selectors";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import timezones from "timezones-list";
import { SCHEMA } from "@/v2/views/Tour/Settings/components/AccessAndPassword/constants";
import {
  DatePicker,
  Form,
  FormGroup,
  Input,
  Message,
  Panel,
  Radio,
  RadioGroup,
  SelectPicker,
  Toggle,
} from "rsuite";
import { isBefore, startOfToday } from "date-fns";
import { formatDateToString } from "@/v2/shared/utils/dateHelper";
import { StyledFormError } from "@/components/FormUI/styles";

export const AccessAndPassword = ({
  id,
  onChange,
}: {
  id: string;
  onChange: (value: AccessAndPasswordForm) => void;
}) => {
  const accessValue = useSelector(selectAccess(id));

  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<AccessAndPasswordForm>({
    mode: "onChange",
    shouldFocusError: false,
    resolver: yupResolver(SCHEMA),
    defaultValues: accessValue,
  });

  const watchEnabled = watch("enabled");
  const watchIsPublishDate = watch("isPublishDate");
  const watchIsTourPassword = watch("isTourPassword");
  const watchIsMediaPassword = watch("isMediaPassword");

  useEffect(() => {
    if (watchIsPublishDate) {
      setValue("isTourPassword", false);
      setValue("isMediaPassword", false);
    }
  }, [watchIsPublishDate]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value.publishDate) {
        value.publishDate = formatDateToString(
          new Date(value.publishDate),
          "yyyy-MM-dd HH:mm"
        );
      }

      onChange({ ...accessValue, ...value });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const renderPublishDate = () => (
    <div>
      <FormGroup>
        <label style={{ marginRight: 10 }}>Publish date</label>
        <Controller
          name={"publishDate"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <DatePicker
              placement="auto"
              format="YYYY-MM-DD HH:mm"
              disabledDate={(date) => isBefore(date, startOfToday())}
              ranges={[]}
              {...(field.value && { value: new Date(field.value) })}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
        {errors.publishDate && (
          <StyledFormError>{errors.publishDate?.message}</StyledFormError>
        )}
      </FormGroup>
      <FormGroup>
        <label style={{ marginRight: 10 }}>Time Zone</label>
        <Controller
          name={"timeZone"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <SelectPicker
              data={getTimeZoneList()}
              value={field.value}
              cleanable={false}
              placement="auto"
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
        {errors.timeZone && (
          <StyledFormError>{errors.timeZone?.message}</StyledFormError>
        )}
      </FormGroup>
    </div>
  );

  const renderPasswords = () => (
    <>
      {renderTourPassword()}
      {renderMediaPassword()}
    </>
  );

  const renderTourPassword = () => (
    <Panel bordered>
      <FormGroup style={{ marginTop: 10 }}>
        <label style={{ marginRight: 10 }}>Tour Password</label>
        <Controller
          name={"isTourPassword"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Toggle
              size="lg"
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={field.value}
              style={{ width: 80 }}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </FormGroup>
      {watchIsTourPassword && (
        <>
          <FormGroup>
            <label style={{ display: "block", marginBottom: 5 }}>
              Password
            </label>
            <Controller
              name={"tourPassword"}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Input
                  style={{ width: 400 }}
                  value={field.value || ""}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            {errors.tourPassword && (
              <StyledFormError>{errors.tourPassword?.message}</StyledFormError>
            )}
          </FormGroup>
          <FormGroup>
            <label style={{ marginRight: 10 }}>
              Unlock after first correct entry
            </label>
            <Controller
              name={"tourPasswordOneTime"}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Toggle
                  size="lg"
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  checked={field.value}
                  style={{ width: 80 }}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
        </>
      )}
    </Panel>
  );

  const renderMediaPassword = () => (
    <Panel bordered style={{ marginTop: 30 }}>
      <FormGroup style={{ marginTop: 10 }}>
        <label style={{ marginRight: 10 }}>Media Password</label>
        <Controller
          name={"isMediaPassword"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Toggle
              size="lg"
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={field.value}
              style={{ width: 80 }}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </FormGroup>
      {watchIsMediaPassword && (
        <>
          <FormGroup>
            <label style={{ display: "block", marginBottom: 5 }}>
              Password
            </label>
            <Controller
              name={"mediaPassword"}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Input
                  style={{ width: 400 }}
                  value={field.value || ""}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            {errors.mediaPassword && (
              <StyledFormError>{errors.mediaPassword?.message}</StyledFormError>
            )}
          </FormGroup>
          <FormGroup>
            <label style={{ marginRight: 10 }}>
              Unlock after first correct entry
            </label>
            <Controller
              name={"mediaPasswordOneTime"}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Toggle
                  size="lg"
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  checked={field.value}
                  style={{ width: 80 }}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
        </>
      )}
    </Panel>
  );

  return (
    <div>
      <Message
        type="info"
        showIcon
        description={
          <p>
            Manage access to tour and media assets with password protection or
            scheduled publishing dates.
          </p>
        }
      />

      <Form style={{ marginTop: 30 }}>
        <FormGroup>
          <label style={{ marginRight: 10 }}>Enabled</label>
          <Controller
            name={"enabled"}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Toggle
                size="lg"
                checkedChildren="On"
                unCheckedChildren="Off"
                checked={field.value}
                style={{ width: 80 }}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </FormGroup>
        {watchEnabled && (
          <>
            <FormGroup>
              <Controller
                name={"isPublishDate"}
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <RadioGroup
                    name="isPublishDate"
                    inline
                    defaultValue={accessValue?.isPublishDate}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  >
                    <Radio value={true}>Publish date</Radio>
                    <Radio value={false}>Password</Radio>
                  </RadioGroup>
                )}
              />
            </FormGroup>

            {watchIsPublishDate ? renderPublishDate() : renderPasswords()}
          </>
        )}
      </Form>
    </div>
  );
};

const getTimeZoneList = (): any[] =>
  timezones
    .filter((item) => item.tzCode.includes("America"))
    .map((item) => ({
      label: item.label,
      value: item.tzCode,
    }));

import { 
  FEEDBACK_REQUEST_SEND,
  FEEDBACK_SUCCESS_SEND,
  FEEDBACK_FAILURE_SEND
} from '@/actions/types';

export const sendFeedbackRequest = (requestData, callback) => {
  return {
    type: FEEDBACK_REQUEST_SEND,
    payload: {
			requestData,
			callback
		}
  }
};

export const sendFeedbackSuccess = () => {
  return {
    type: FEEDBACK_SUCCESS_SEND
  }
};

export const sendFeedbackFailure = () => {
  return {
    type: FEEDBACK_FAILURE_SEND
  }
};

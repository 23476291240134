import React, { Fragment, useState } from 'react';
import { reduxForm, change } from 'redux-form';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import { Panel } from 'rsuite';

import 'react-datepicker/dist/react-datepicker.css';

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import PanelForm from '@/components/PanelForm/index';
import {
	Input,
	Textarea
} from '@/components/FormUI/index';
import Title from '@/components/Title/index';
import {
	StyledFormGroup,
	StyledFormInputWrapper,
	StyledFormLabel
} from '@/components/FormUI/styles';

const validate = values => {
	const { notifications, sendLater, addCC, notifyAtValidation, payments } = values;
	const errors = { notifications: {}, payments: {} };

	if (!notifications || !notifications.additionalNotes) {
		// @ts-ignore
		errors.notifications.additionalNotes = 'Required';
	}

	if (addCC && !notifications?.cc) {
		// @ts-ignore
		errors.notifications.cc  = 'Required';
	}

	if (notifications?.cc) {
		const emails = notifications?.cc?.split(',');

		emails.forEach(email => {
			if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email.trim())) {
				// @ts-ignore
				errors.notifications.cc = 'Invalid email address';
			}
		});
	}

	if (sendLater && !notifyAtValidation) {
		// @ts-ignore
		errors.notifyAtValidation = 'Required';
	}

	if (payments?.paymentLink && !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i.test(payments?.paymentLink)) { /* eslint-disable-line */
		// @ts-ignore	
		errors.payments.paymentLink = 'Invalid URL. Your URL should start with http:// or https://';
	}

	return errors;
};

let FormPublish = (props) => {
	const {
		handleSubmit,
		onSaveAndNext,
		onSend,
		onSendAndNext,
		loading,
		clickedSubmitName,
		initialValues,
		paymentsEnabled
	} = props;

	const additionalNotesState = initialValues.additionalNotesState;
	const [additionalNotes, setAdditionalNotes] = useState(additionalNotesState);

  const published = initialValues.enabled;
  const [notify, setNotify] = useState(published);

	const handleStatusChange = (event, value) => {
		const { name } = event.target;

		if (name === 'enabled' && value) {
      props.dispatch(change('publish', 'sold', false));
      setNotify(true);
		} else {
      props.dispatch(change('publish', 'enabled', false));
      setNotify(false);
		}
	};

  const notifyAt = initialValues.notifications ? initialValues.notifications.notifyAt : null;
  const notifyAgent = initialValues.notifications ? initialValues.notifications.agent : false;

	const [startDate, setStartDate] = useState(notifyAt ? new Date(notifyAt) : null);

  const [isNotifyAt, setIsNotifyAt] = useState(Boolean(notifyAt));
  const [isNotifyAgent, setIsNotifyAgent] = useState(Boolean(notifyAgent));
	const [paid, setPaid] = useState(!!initialValues?.payments?.paid);
	const [cc, setCC] = useState(!!initialValues?.notifications?.cc);

	const handleUnpaidChange = (event, value) => {
		setPaid(value);

		if (value) {
			props.dispatch(change('publish', 'payments.paymentLink', null));
		}
	};

	const handleCCChange = (event, value) => {
		setCC(value);

		if (!value) {
			props.dispatch(change('publish', 'notifications.cc', null));
		}
	};

  const handleNotifyChange = (event, value) => {
    const { name } = event.target;

    if (name === 'sendLater' && value) {
      setIsNotifyAt(true);
      props.dispatch(change('publish', 'sendNow', false));
    } else {
      setIsNotifyAt(false);
      handleChangeDatePicker(null);
      props.dispatch(change('publish', 'sendLater', false));
    }

    if ((name === 'sendNow' && value) || (name === 'sendLater' && value)) {
      props.dispatch(change('publish', 'notifications.agent', true));
      setIsNotifyAgent(true);
    } else {
      props.dispatch(change('publish', 'notifications.agent', false));
      setIsNotifyAgent(false);
    }
  };

	const handleChangeDatePicker = date => {
		// @ts-ignore
		const formattedDate = date ? moment(date).format() : null;

		setStartDate(date);
		props.dispatch(change('publish', 'notifications.notifyAt', formattedDate));
		props.dispatch(change('publish', 'notifyAtValidation', formattedDate));
	};

	return (
		<form onSubmit={handleSubmit}>
			<GridRow>
				<GridCol>
					<Title 
						name="Tour status:"
						subtitle={true} />
				</GridCol>
        <GridCol wrap="true">
          <Input
						label="Publish"
            name="enabled"
						type="checkbox"
						onChange={handleStatusChange} />
					<Input
						label="Sold"
            name="sold"
						type="checkbox" 
						onChange={handleStatusChange} />
					{paymentsEnabled &&
						<Input
							label="Paid"
							name="payments.paid"
							type="checkbox"
							onChange={handleUnpaidChange}
						/>
					}
        </GridCol>
				{paymentsEnabled &&
					<GridCol>
						{!paid && 
							<Input
								label="Payment link:"
								name="payments.paymentLink"
								type="text"
							/>
						}
					</GridCol>
				}
				<GridCol>
					<PanelForm
						formProps={props}
						loading={loading && clickedSubmitName === 'save'}
						onSave={handleSubmit}
						onSaveAndNext={onSaveAndNext}
					/>
				</GridCol>
			</GridRow>

			{notify &&
				<GridRow>
					<GridCol style={{marginTop: '20px'}}>
						<Panel header="Agent Notifications:" collapsible bordered expanded={true}>
							<Fragment>
								<GridCol wrap="true">
									<Input
										label="Send Now"
										name="sendNow"
										type="checkbox"
										onChange={handleNotifyChange}
									/>
									{isNotifyAgent &&
										<Input
											label="Send me a Copy"
											name="notifications.sendCopy"
											type="checkbox"
										/>
									}
									{/* <Input
										label="Send Later"
										name="sendLater"
										type="checkbox"
										onChange={handleNotifyChange}
									/> */}
								</GridCol>
								{isNotifyAt &&
									<GridCol>
										<StyledFormGroup>
											<StyledFormLabel>Notify at</StyledFormLabel>
											<StyledFormInputWrapper>
												<DatePicker
													selected={startDate}
													onChange={handleChangeDatePicker}
													minDate={new Date()}
													showTimeSelect
													timeFormat="HH:mm"
													timeIntervals={15}
													timeCaption="Time"
													dateFormat="dd/MM/yyyy HH:mm"
													isClearable
													className="notify-at-react-datepicker-input"
													calendarClassName="notify-at-react-datepicker-calendar"
												/>
											</StyledFormInputWrapper>
											<div className="notify-at-react-datepicker-input-validation">
												<Input
													name="notifyAtValidation"
													type="text"
												/>
											</div>
										</StyledFormGroup>
									</GridCol>
								}
								{isNotifyAgent &&
									<Fragment>
										<GridCol>
											<Input
												label="Add CC"
												name="addCC"
												type="checkbox"
												onChange={handleCCChange}
											/>
											{cc &&
												<Input
													type="text"
													label="CC:"
													name="notifications.cc"
													placeholder="email1@example.com, email2@example.com"
												/>
											}
										</GridCol>
										<GridCol>
											<Input
												label="Additional Notes"
												name="additionalNotesState"
												type="checkbox"
												onChange={() => setAdditionalNotes(!additionalNotes)}
											/>
											{additionalNotes &&
												<Textarea
													name="notifications.additionalNotes"
													placeholder="This notes will be attached to the email send to the agent..."
												/>
											}
										</GridCol>
										{(isNotifyAt || isNotifyAgent) &&
											<GridCol>
												<PanelForm
													formProps={props}
													loading={loading && clickedSubmitName === 'send'}
													onSave={onSend}
													onSaveAndNext={onSendAndNext}
													primaryButtonText={'Send'}
													secondaryButtonText={'Send and Next'}
												/>
											</GridCol>
										}
									</Fragment>
								}
							</Fragment>
						</Panel>
					</GridCol>
				</GridRow>
			}
		</form>
	)
};

FormPublish = reduxForm({
	form: 'publish',
	initialValues: {
		notifications: {
			agent: true,
			notifyAt: null,
			sendCopy: false,
			cc: ''
		}
	},
	validate
})(FormPublish)

export { FormPublish };

import styled, { css } from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledEmptyList = styled.div`
  font-size: 18px;
	font-weight: 500;
`;

export const TextTop = styled.p<any>`
	margin-bottom: 25px;
	font-size: 18px;
	// @ts-ignore
	color: ${props => props.primary ? colors.catalinaBlue : colors.amaranth};
`;

export const Paragraph = styled.p`
	margin-bottom: 1.25rem;
`;

export const TextStrong = styled.strong`
	font-weight: 500;
`;

export const Asterisk = styled.p<any>`
	margin-bottom: 0.25rem;
	font-size: 0.6875rem;
	color: ${colors.dustyGray};

	// @ts-ignore
	${props => props.light && css `
		color: ${colors.white};
	`}
`;

import React from "react";
import classnames from "classnames";
import { TiLockClosedOutline } from "react-icons/ti";

import {
  StyledMenuItem,
  StyledMenuItemWrapper,
  StyledMenuItemName,
  StyledMenuItemLock,
  StyledMenuItemIcon,
  StyledNavLink,
  StyledUnreadOrders,
} from "./styles";

type Props = {
  url: string;
  name: string;
  active: boolean;
  icon: any;
  pull: boolean;
};

const MenuItem = (props: Props & any) => {
  const { url, name, active, icon, pull, unreadOrders } = props;
  const IconComponent = icon;

  return (
    <StyledMenuItem
      pull={pull}
      className={classnames({
        active: active,
        [`shepherd-step-${name.toLowerCase()}`]: true,
      })}
    >
      <StyledNavLink
        to={url}
        className={classnames({
          active: active,
        })}
        activeClassName="is-active"
      >
        <StyledMenuItemWrapper>
          <StyledMenuItemIcon>
            <IconComponent />
          </StyledMenuItemIcon>
          <StyledMenuItemName>
            {!pull && name}
            {!active && (
              <StyledMenuItemLock>
                <TiLockClosedOutline />
              </StyledMenuItemLock>
            )}
            {unreadOrders && (
              <StyledUnreadOrders>{unreadOrders}</StyledUnreadOrders>
            )}
            {!unreadOrders && name === "Orders" && (
              <StyledUnreadOrders new={true}>NEW</StyledUnreadOrders>
            )}
            {name === "Virtual Staging" && (
              <StyledUnreadOrders new={true}>NEW</StyledUnreadOrders>
            )}
          </StyledMenuItemName>
        </StyledMenuItemWrapper>
      </StyledNavLink>
    </StyledMenuItem>
  );
};

export default MenuItem;

import uuid from 'uuid';

import {
  TOAST_ADD,
  TOAST_REMOVE
} from './types';

const duration = 3000;

export const fireToast = toast => {
  return dispatch => {
    const modifiedToast = { ...toast, id: uuid() };

    dispatch(addToast(modifiedToast));

    setTimeout(() => {
      dispatch(removeToast(modifiedToast.id));
    }, toast.duration || duration);
  };
};

export const addToast = toast => {
  return {
    type: TOAST_ADD,
    toast
  }
};

export const removeToast = id => {
  return {
    type: TOAST_REMOVE,
    id
  }
};

import { RSFormState, initialRSFormState } from '@/v2/shared/components/RSForm';
import { CustomDomainFormValue } from './types';

export const isRequiredMsg = 'This field is required.';

export const initialFormValue: CustomDomainFormValue = {
  tourId: '',
  domain: '',
  branded: false
};

export const initialFormState: RSFormState = {
  ...initialRSFormState,
  formValue: {
    tourId: '',
    domain: '',
    branded: false
  }
};

import { RSFormState, initialRSFormState } from '@/v2/shared/components/RSForm';

export const isRequiredMsg = 'This field is required.';

export const initialFormState: RSFormState = {
  ...initialRSFormState,
  formValue: {
    address: '',
    city: '',
    zipcode: '',
    country: '',
    state: '',
    idType: 'short'
  }
};

import React, { ReactElement } from 'react';
import { Button, Icon, Modal } from 'rsuite';

import { useStyles } from './styles';
import detailsData from './data.json';
import { TemplateDetailsModalProps } from './types';

const TemplateDetailsModal = ({ show, detailsKey, onClose }: TemplateDetailsModalProps): ReactElement => {
  const classes = useStyles();

  const data = detailsData[detailsKey];

  return (
    <Modal 
      backdrop={true} 
      show={show} 
      onHide={onClose} 
      size="xs"
    >
      <Modal.Header>
        <Modal.Title>{data?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={classes.list}>
          <ul>
            {data?.details.map(text => (
              <li key={text.replace(/ /g, '')}>
                <Icon icon="check" /> {text}
              </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="primary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateDetailsModal;

import Storage from '@/services/storage';

import {
	AUTH_REQUEST_SIGNIN,
	AUTH_REQUEST_SIGNUP,
	AUTH_REQUEST_RESEND_ACTIVATION,
	AUTH_REQUEST_USER_ACTIVATE,
	AUTH_REQUEST_RESET_PASSWORD,
	AUTH_REQUEST_CHANGE_PASSWORD,
  AUTH_SUCCESS,
  AUTH_FAILURE
} from '@/actions/types';

const defaultState = {
	token: Storage.getToken(),
	loading: false,
	isActivation: false,
	domain: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
		case AUTH_REQUEST_SIGNIN:
		case AUTH_REQUEST_SIGNUP:
		case AUTH_REQUEST_RESEND_ACTIVATION:
		case AUTH_REQUEST_USER_ACTIVATE:
		case AUTH_REQUEST_RESET_PASSWORD:
		case AUTH_REQUEST_CHANGE_PASSWORD:
			return {
				...state,
				loading: true
			};
		case AUTH_SUCCESS:
      return {
				...state,
				token: action.token,
				loading: false,
				isActivation: false
			};
		case AUTH_FAILURE:
			const { message } = action;
			const isActivation = message && message === 'AUTH_FAILURE_ACCOUNT_NOT_ACTIVE';

      return {
				...state,
				loading: false,
				isActivation
			};
    default:
      return state;
  }
};

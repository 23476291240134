import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form'
import classnames from 'classnames';

import {
	StyledFormTitle,
	StyledFormSubTitle,
	StyledForm,
	StyledFormRedirect
} from '../styles';

import { 
	Input, 
	InputPassword,
	Submit 
} from '@/components/FormUI/index';

const validate = values => {
	const { username, password, code } = values;
	const errors = {}
	
  if (!username) {
		// @ts-ignore
    errors.username = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(username)) {
		// @ts-ignore
		errors.username = 'Invalid email address';
	}

	if (!password) {
		// @ts-ignore
    errors.password = 'Required';
	} else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z_\W\d]{8,}$/.test(password)) {
		// @ts-ignore
		errors.password = 'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number';
	}

	if (!code) {
		// @ts-ignore
		errors.code = 'Required';
	}
	
  return errors;
};
				
let FormChangePassword = (props) => {
	const { handleSubmit, loading } = props;

	return (
		<Fragment>
			<StyledFormTitle>
				Change password
			</StyledFormTitle>
			<StyledFormSubTitle>
				Copy reset code which we sent to your email address, 
				fill all fields and click the button below.
			</StyledFormSubTitle>
			<StyledForm onSubmit={handleSubmit}>
				<Input 
					name="username"
					type="email"
					label="Email"/>
				<InputPassword 
					name="password"
					type="password"
					label="Password"/>
				<Input 
					name="code"
					type="text"
					label="Reset code"/>
				<Submit 
					success 
					center 
					xl={!loading} 
					maxWidth="340"
          disabled={loading}
          loading={loading}
				>
					Change password
				</Submit>
			</StyledForm>
			<StyledFormSubTitle>
				Just remembered?&nbsp;
				<StyledFormRedirect 
					to="/sign-in"
					className={
						classnames({
							'disabled': loading
						})
					}
				>
					Sign in
				</StyledFormRedirect>
				&nbsp;instead.
			</StyledFormSubTitle>
		</Fragment>
	)
};

FormChangePassword = reduxForm({
	form: 'changePassword',
	validate
})(FormChangePassword)

export { FormChangePassword };

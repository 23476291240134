import React, { memo, ReactElement, useRef } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { GoogleMapsProps } from './types';
import useGoogleMaps from '@/v2/shared/hooks/useGoogleMaps';

const mapStyles = require('./mapStyles.json');
const markerIcon = require('./marker.svg');

function GoogleMaps ({
	lat,
	lng,
	zoom, 
	width = '100%', 
	height = '100%',
	onPositionChange,
	onZoomChange
}: GoogleMapsProps): ReactElement {
	const mapRef = useRef(null);
	const { isLoaded } = useGoogleMaps();

  return (
		<>
			{isLoaded &&
				<GoogleMap
					mapContainerStyle={{ width, height }}
					center={{ lat, lng }}
					zoom={zoom}
					options={{
						disableDefaultUI: true,
						fullscreenControl: true,
						zoomControl: true,
						styles: mapStyles
					}}
					ref={mapRef}
					onZoomChanged={() => onZoomChange(mapRef.current?.state.map.zoom)}
				>
					<Marker
						position={{ lat, lng }}
						icon={markerIcon}
						draggable={true}
						onDragEnd={evt => onPositionChange(evt.latLng.lat(), evt.latLng.lng())}
					/>
				</GoogleMap>
			}
		</>
  );
};

export default memo(GoogleMaps);

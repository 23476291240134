import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Button, Col, ControlLabel, Form, FormControl, FormGroup, Grid, Message,
  Modal, Radio, RadioGroup, Row, SelectPicker
} from 'rsuite';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import GoogleAutocomplete from '@/v2/shared/components/GoogleAutocomplete/GoogleAutocomplete';
import { RSFormState, useStyles } from '@/v2/shared/components/RSForm';
import { getStateFieldData, isStateField } from './utils';
import { ModifyTourFormProps } from './types';
import { initialFormState } from './constants';
import { model } from './schema';

const countriesData = require('./data/countries.json');
const idTypesData = require('./data/idTypes.json');

const ModifyTourForm = (
  { tourId, value, onSubmit, loading, footer, fullAddress }: ModifyTourFormProps
): ReactElement => {
  const classes = useStyles();
  const form = useRef<HTMLFormElement>(null);
  const [ formState, setFormState ] = useState<RSFormState>(initialFormState);
  const { formValue } = formState;
  const stateFieldData = getStateFieldData(formValue.country);
  const displayStateField = isStateField(formValue.country);

  useEffect((): void => {
    setFormState((state) => ({
      ...state,
      formValue: {
        ...state.formValue,
        ...value
      }
    }));
  }, []);

  const handleSubmit = async (): Promise<any> => {
    if (!form.current.check()) {
      return;
    }
    onSubmit(formState.formValue);
  };

  const handleCountryChange = (): void => {
    setFormState((state) => ({
      ...state,
      formValue: {
        ...state.formValue,
        state: ''
      }
    }));
  }

  const handleAutocompleteChange = (response) => {
    geocodeByPlaceId(response?.value?.place_id)
      .then(results => {
        const addressComponets = results?.find(item => item?.address_components)?.address_components;
        const newFormValue = {
          address: '',
          zipcode: ''
        };

        addressComponets.forEach(component => {
          const componentType = component.types[0];

          switch (componentType) {
            case 'street_number': {
              newFormValue['address'] = `${component.long_name} `;
              break;
            }
            case 'route': {
              newFormValue['address'] = newFormValue['address'] + component.long_name;
              break;
            }
            case 'postal_code': {
              newFormValue['zipcode'] = component.long_name;
              break;
            }
            case 'postal_code_suffix': {
              newFormValue['zipcode'] = `${newFormValue['zipcode']}-${component.long_name}`;
              break;
            }
            case 'locality':
              newFormValue['city'] = component.long_name;
              break;
            case 'administrative_area_level_1': {
              newFormValue['state'] = component.short_name;
              break;
            }
            case 'country':
              const matchedCountry = countriesData.find(c => c.shortName === component.short_name);

              if (matchedCountry) {
                newFormValue['country'] = matchedCountry.value;
              } else {
                newFormValue['country'] = null;
              }
              break;
          }
        });

        setFormState((state) => ({
          ...state,
          formValue: {
            ...state.formValue,
            ...newFormValue
          }
        }));
      });
  };

  return (
    <Modal.Body>
      <Form
        autoComplete="off"
        className={classes.root}
        fluid
        ref={form}
        onChange={formValue => {
          setFormState((state) => ({
            ...state,
            ...{formValue}
          }));
        }}
        onCheck={formError => {
          setFormState(state => ({
            ...state,
            ...{formError}
          }));
        }}
        formValue={formValue}
        model={model}
      >
        <Grid fluid style={{ overflow: 'hidden' }}>
          <Row gutter={16}>
            <Col xs={24}>
              <FormGroup>
                <ControlLabel>Address Search</ControlLabel>
                <GoogleAutocomplete
                  geocodeByAddressIsOff={!!tourId}
                  defaultValue={fullAddress}
                  onChange={handleAutocompleteChange}
                />
              </FormGroup>
            </Col>
            <Col xs={24} md={8}>
              <FormGroup>
                <ControlLabel>Address</ControlLabel>
                <FormControl name="address" />
              </FormGroup>
            </Col>
            <Col xs={24} md={8}>
              <FormGroup>
                <ControlLabel>City</ControlLabel>
                <FormControl name="city" />
              </FormGroup>
            </Col>
            <Col xs={24} md={8}>
              <FormGroup>
                <ControlLabel>ZIP Code</ControlLabel>
                <FormControl name="zipcode" />
              </FormGroup>
            </Col>
            <Col xs={24} md={12}>
              <FormGroup>
                <ControlLabel>Country</ControlLabel>
                <FormControl
                  name="country"
                  accepter={SelectPicker}
                  data={countriesData}
                  maxHeight={220}
                  placement="autoVerticalEnd"
                  block
                  onChange={handleCountryChange}
                />
              </FormGroup>
            </Col>
            <Col xs={24} md={12}>
              <FormGroup>
                <ControlLabel>State</ControlLabel>
                <FormControl
                  name="state"
                  accepter={SelectPicker}
                  data={stateFieldData}
                  maxHeight={220}
                  placement="autoVerticalEnd"
                  disabled={!displayStateField}
                  block
                />
              </FormGroup>
            </Col>
            <Col xs={24}>
              <FormGroup>
                <ControlLabel>Tour URL{tourId && <sup>*</sup>}</ControlLabel>
                <FormControl
                  name="idType"
                  accepter={RadioGroup}
                  inline
                >
                  {idTypesData.map(({ label, value }) =>
                    <Radio key={value} value={value}>{label}</Radio>
                  )}
                </FormControl>
              </FormGroup>
            </Col>
            {tourId &&
              <Col xs={24}>
                <FormGroup>
                  <Message
                    type="warning"
                    showIcon
                    description={
                      <p>
                      <sup>*</sup>Changing the address of the tour can change the web address that
                      you shared with your agent, please resend the new web address
                      </p>
                    }
                  />
                </FormGroup>
              </Col>
            }
          </Row>
        </Grid>
      </Form>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleSubmit} loading={loading}>
          Save
        </Button>
        {footer}
      </Modal.Footer>
    </Modal.Body>
  )
};

export default ModifyTourForm;

import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form'
import classnames from 'classnames';

import { colors } from '@/defaults/index';

import {
	StyledFormTitle,
	StyledFormSubTitle,
	StyledFormRedirect,
	StyledFormLink,
	StyledForm
} from '../styles';

import { 
	Input, 
	InputPassword,
	Submit 
} from '@/components/FormUI/index';

const validate = values => {
	const { username, password } = values;
	const errors = {}
	
  if (!username) {
		// @ts-ignore
    errors.username = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(username)) {
		// @ts-ignore
		errors.username = 'Invalid email address';
	}

	if (!password) {
		// @ts-ignore
    errors.password = 'Required';
	} else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z_\W\d]{8,}$/.test(password)) {
		// @ts-ignore
		errors.password = 'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number';
	}
	
  return errors;
};
				
let FormSignIn = (props) => {
	const { handleSubmit, loading, isActivation } = props;

	return (
		<Fragment>
			<StyledFormTitle>
				Sign in to Your Account
			</StyledFormTitle>
			<StyledFormSubTitle>
				Don't have an account?&nbsp;
				<StyledFormRedirect 
					to="/sign-up"
					className={
						classnames({
							'disabled': loading
						})
					}
				>
					Create one.
				</StyledFormRedirect>
			</StyledFormSubTitle>
			<StyledForm onSubmit={handleSubmit}>
				<Input 
					name="username"
					type="email"
					label="Email"/>
				<InputPassword 
					name="password"
					type="password"
					label="Password" />
				<Submit 
					primary
					center 
					xl={!loading} 
					maxWidth="340"
          disabled={loading}
          loading={loading}
				>
					Sign In
				</Submit>
			</StyledForm>
			{isActivation &&
				<StyledFormSubTitle>
					<StyledFormRedirect 
						to="/resend-activation"
					>
						Activate Your account
					</StyledFormRedirect>
				</StyledFormSubTitle>
			}
			<StyledFormSubTitle>
				<StyledFormRedirect 
					to="/reset-password"
					className={
						classnames({
							'disabled': loading
						})
					}
				>
					Forgotten your password?
				</StyledFormRedirect>
			</StyledFormSubTitle>
			<StyledFormSubTitle>
				<StyledFormLink 
					href="https://homeontour.com/privacy/index.html" 
					target="_blank"
				>
					Privacy Policy
				</StyledFormLink>
				<br />
				<StyledFormLink 
					href="https://homeontour.com/terms/index.html" 
					target="_blank"
				>
					Terms and Conditions
				</StyledFormLink>
			</StyledFormSubTitle>
		</Fragment>
	)
};

FormSignIn = reduxForm({
	form: 'signIn',
	validate
})(FormSignIn)

export { FormSignIn };

import React from 'react';
import { Field } from 'redux-form'

import { renderField } from '../renderField'

const Textarea = (props) => {
	const { name, label, placeholder } = props;

	return (
		<Field
			name={name}
			placeholder={placeholder}
			component={renderField}
			label={label} />
	)
};

export { Textarea };

import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledTitle = styled.div`
	margin-bottom: 0.9375rem;
	padding: 0 0 0.9375rem;
	border-bottom: 0.0325rem solid ${colors.wildSand};
`;

export const StyledTitleH2 = styled.h2`
	font-size: 1.25rem;
	font-weight: 400;
`;

export const StyledTitleH3 = styled.h3<any>`
	font-size: 1rem;
	// @ts-ignore
	font-weight: ${props => props.bold ? 500 : 400};
`;

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    padding: 15,
		textAlign: 'center',
    backgroundColor: '#000',
    color: '#fff',
    fontSize: 15
  }
});

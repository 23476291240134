import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { 
	IoMdEye,
	IoMdEyeOff
} from "react-icons/io";

import { renderField } from '../renderField'

import  { StyledFormPreviewPassword } from '../styles';

class InputPassword extends PureComponent<any> {
	static defaultProps = {
    typePassword: 'password',
    typeText: 'text'
	}
	
	state = {
		type: this.props.typePassword
	}

	handleSwitchType = () => {
		const { typePassword, typeText } = this.props;

		let type = this.state.type === typePassword
			? typeText 
			: typePassword;

		this.setState({ type });
	}

	render() {
		const { type } = this.state;
		const { name, label, placeholder, disabled, typePassword } = this.props;

		return (
			<Field
				disabled={disabled}
				name={name}
				type={type}
				placeholder={placeholder}
				component={renderField}
				label={label}
				controls={true}
			>
				<StyledFormPreviewPassword
					onClick={this.handleSwitchType}
				>
					{type === typePassword ? (
						<IoMdEye />
					) : (
						<IoMdEyeOff />
					)}
				</StyledFormPreviewPassword>
			</Field>
		)
	}
};

export { InputPassword };

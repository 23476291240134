import React from 'react';
import { reduxForm } from 'redux-form'

import {
	Textarea,
	Submit 
} from '@/components/FormUI/index';

import { 
  ModalHeader, 
  ModalFooter 
} from '@/components/Modal/index';

const validate = values => {
  const { message } = values;
	const errors = {}
	
  if (!message) {
		// @ts-ignore
    errors.message = 'Required';
  }
	
  return errors;
};
				
let FormFeedback = (props) => {
	const { loading, handleSubmit, onClose } = props;

	return (
		<form onSubmit={handleSubmit}>
      <ModalHeader>
				Contact Us
      </ModalHeader>
			<Textarea 
				name="message"
				placeholder="If you have any questions, suggestions, or requests, please drop us a line!"
				autoFocus={true}
			/>
			<ModalFooter>
				<Submit 
					type="button"
					boulder
					onClick={onClose}
				>
					Cancel
				</Submit>
				<Submit 
          primary
          loading={loading}
				>
					Send
				</Submit>
			</ModalFooter>
		</form>
	)
};

FormFeedback = reduxForm({
	form: 'feedback',
	validate
})(FormFeedback)

export { FormFeedback };

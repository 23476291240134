import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Button, ButtonToolbar, ControlLabel, Message, Panel } from 'rsuite';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import { redirectTo } from '@/helpers/redirect';
import { history } from '@/routes/index';
import Title from '@/components/Title';
import PanelForm from '@/components/PanelForm';
import ConfirmModal from '@/v2/shared/components/ConfirmModal/ConfirmModal';

import { useStyles } from './styles';
import { generate, getAgentList, sendContact } from './http';
import { selectAgent, selectGenerating, selectUrlBranded, selectUrlUnbranded, selectTemplate } from './selectors';
import { getUserId } from '@/selectors';

const REQUEST_TEMPLATE = {
  id: 'request',
  exampleUrl: '',
  disabled: true
};

const VideoSlideshow = ({ id }: { id: string; }) => {
  
  const currentTour = useSelector((state: any) => state?.tour[id]);
  const { videoSlideshow } = useSelector((state: any) => state?.account?.info);
  
  const skipUrl = `/${redirectTo({
    currentPage: 'videoSlideshow', activePages: currentTour?.settings, isVideoSlideshow: videoSlideshow
  })}/${id}`;

  const classes = useStyles();

  const token = useSelector((state: any) => state.auth.token);
  const { email, name, phone, videoTemplates = [] } = useSelector((state: any) => state.account.info);

  const pricing = useSelector((state: any) => state?.account?.info?.pricing?.videoSlideshow);
  const generating = useSelector(selectGenerating(id));
  const urlBranded = useSelector(selectUrlBranded(id));
  const urlUnbranded = useSelector(selectUrlUnbranded(id));
  const activeTemplate = useSelector(selectTemplate(id));
  const agent = useSelector(selectAgent(id));
  const creatorId = useSelector((state: any) => state.tour[id]?.creatorId);
  const tourOwner = getUserId() === creatorId;

  const [ generateClicked, setGenerateClicked ] = useState(false);
  const [ requestingCustomTemplate, setRequestingCustomTemplate ] = useState(false);
  const [ confirmModal, setConfirmModal ] = useState(false);
  const [ isAgent, setIsAgent ] = useState<boolean>(null);
  const [ activeTemplateId, setActiveTemplateId ] = useState<string>(activeTemplate);

  useEffect((): void => {
    getAgentList(token).then(res => {
      setIsAgent(!isEmpty(res?.data?.agents?.find(item => item?.id === agent?.id)));
    });
  }, []);

  // Request that adds generating video to the queue
  const generateVideo = async () => {
    try {
      await generate(id, activeTemplateId, true, token);
      await generate(id, activeTemplateId, false, token);
    }
    catch (e) {
      console.log(e);

      Alert.error('Something went wrong.');
      setGenerateClicked(false);
    }
  };

  const handleGenerate = () => {
    if (pricing?.price > 0) {
      setConfirmModal(true);
    } else {
      setGenerateClicked(true);
      generateVideo();
    }
  };

  const handleConfirmGenerate = () => {
    setConfirmModal(false);
    setGenerateClicked(true);
    generateVideo();
  };

  const handleRequestCustomTemplate = () => {
    setRequestingCustomTemplate(true);

    sendContact(email, name, phone, 'Request custom Video Slideshow template')
      .then(() => Alert.success('Our customer service representative will be in touch with you shortly.'))
      .finally(() => setRequestingCustomTemplate(false));
  };

  const nextPage = () => {
    history.push(skipUrl);
  };

  if (videoSlideshow !== true) {
    return null;
  }

  const redirectToAgent = () => {
    history.push(`/agent/${id}`);
  };

  const extendVideoTemplates = (templates: any[]): any[] => [...templates, REQUEST_TEMPLATE];

  return (
    <Panel>
      <Title name="Video Slideshow" />

      {pricing?.price > 0 &&
        <Message
          style={{ marginTop: 30 }}
          type="warning"
          showIcon
          description={
            <p>
              There is {pricing?.priceAsString} charge for each tour with a video slideshow.<br />
              Charge will happen at the end of month as a part of the subscription renewal invoice.
            </p>
          }
        />
      }

      <Panel header="Layout:" bordered className={classes.panel}>
        <div className={classes.templates}>
          {extendVideoTemplates(videoTemplates).map(item =>
            <div 
              key={item.id}
              className={classnames(
                classes.templateCard, 
                {[classes.templateActiveCard]: item.id === activeTemplateId},
                {[classes.templateDisabledCard] : item.disabled && item.id !== REQUEST_TEMPLATE.id},
                {[classes.templateRequestCard] : item.id === REQUEST_TEMPLATE.id}
              )}
              onClick={() => !item.disabled && setActiveTemplateId(item.id)}
            >
              {item.id !== REQUEST_TEMPLATE.id &&
                <>
                  <img src={item.iconUrl} alt="" />
                  {!item.disabled &&
                    <a href={item.exampleUrl} target="_blank" rel="noopener noreferrer">View Example</a>
                  }
                </>
              }
              {item.id === REQUEST_TEMPLATE.id &&
                <>
                  <img src={require('./images/free-branded-template.jpg')} alt="" />
                  <p>Free branded template for your business</p>
                  <Button 
                    appearance="primary" 
                    size="lg" 
                    onClick={handleRequestCustomTemplate}
                    loading={requestingCustomTemplate}
                  >
                    Request
                  </Button>
                </>
              }
            </div>
          )}
        </div>

        {/* {isAgent === false &&
          <Message
            style={{ marginBottom: 20 }}
            type="error"
            description={
              <>
                <div style={{ marginBottom: 10 }}>Before generating video slideshow you need to assign the agent.</div>
                <Button appearance="ghost" onClick={redirectToAgent}>Assign Agent</Button>
              </>
            }
          />
        } */}

        {tourOwner &&
          <ButtonToolbar>
            <Button
              disabled={isAgent === false}
              appearance="primary"
              loading={generating || generateClicked}
              onClick={handleGenerate}
            >
              Generate
            </Button>
            {generating && <> &nbsp;Generating...</>}
          </ButtonToolbar>
        }

        {(generating || generateClicked) &&
          <Message 
            type="warning" 
            description="Video generation may take a few minutes. You can continue with publishing the tour and the video will get automatically published when ready." 
          />
        }
      </Panel>

      {urlBranded && urlUnbranded &&
        <Panel header="Preview:" bordered className={classes.panel}>
          <div className={classes.preview}>
            <div>
              <ControlLabel>Branded</ControlLabel>
              <video controls controlsList="noremoteplayback noplaybackrate" disablePictureInPicture>
                <source src={urlBranded} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <Button appearance="ghost" size="sm" href={urlBranded} download target="_blank">Download</Button>
            </div>
            <div>
              <ControlLabel>Unbranded</ControlLabel>
              <video controls controlsList="noremoteplayback noplaybackrate" disablePictureInPicture>
                <source src={urlUnbranded} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <Button appearance="ghost" size="sm" href={urlUnbranded} download target="_blank">Download</Button>
            </div>
          </div>
        </Panel>
      }

      <PanelForm
        onSave={nextPage}
        onSaveAndNext={nextPage}
        skipUrl={skipUrl}
        formProps={{valid: true}}
      />

      <ConfirmModal 
				show={confirmModal}
				message={'Please confirm the ' + pricing?.priceAsString + ' charge on your next invoice'}
				onCancel={() => setConfirmModal(false)}
				onConfirm={handleConfirmGenerate}
			/>
		</Panel>
	);
};

export default VideoSlideshow;

import {
	FEEDBACK_REQUEST_SEND,
	FEEDBACK_SUCCESS_SEND,
	FEEDBACK_FAILURE_SEND
} from '@/actions/types';

const defaultState = {
	loading: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
		case FEEDBACK_REQUEST_SEND:
			return {
				...state,
				loading: true
			};
		case FEEDBACK_SUCCESS_SEND:
		case FEEDBACK_FAILURE_SEND:
			return {
				...state,
				loading: false
			};
    default:
      return state;
  }
};

import { get } from 'lodash';

export const redirectTo = ({currentPage, activePages, redirectPath = null, isVideoSlideshow = false}) => {
	const defaultPages = [
		'settings',
		'tour360',
		'video',
		'slideshow',
		'gallery',
		'virtualStaging',
		'details',
		'floorplan',
		'documents',
		'walkScore',
		'map',
		'agent',
		'publish'
	];

	activePages = { ...activePages, ...{ virtualStaging: true, agent: true, publish: true } };

	if (isVideoSlideshow) {
		defaultPages.splice(12, 0, 'videoSlideshow');
	}

	if (redirectPath) {
		return redirectPath;
	}

	let currentDefaultPageIndex = defaultPages.indexOf(currentPage);

	if (currentPage === 'photos') {
		currentDefaultPageIndex = 4;
	}

	if (currentDefaultPageIndex > -1) {
		let redirectPage = null;
		let stoppedLoop = false;

		defaultPages.forEach((value, index) => {
			const isActivePage = get(activePages, [value]);

			if (index > currentDefaultPageIndex && isActivePage && !stoppedLoop) {
				if (value === 'tour360') {
					redirectPage = '360-tour';
				} else if (value === 'walkScore') {
						redirectPage = 'walk-score';
				} else if (value === 'slideshow' || value === 'gallery') {
					redirectPage = 'photos';
				} else if (value === 'videoSlideshow') {
					redirectPage = 'video-slideshow';
				} else if (value === 'virtualStaging') {
					redirectPage = 'virtual-staging';
				} else {
					redirectPage = value;
				}

				stoppedLoop = true;
			}
		});

		if (redirectPage) {
			return redirectPage;
		}
	}

	return currentPage;
};

import styled, { css } from 'styled-components';

export const GridRow = styled.div<any>`
	display: flex;
	flex-wrap: wrap;
	// @ts-ignore
	margin-left: ${props => props.margin ? `-${props.margin}px` : '-0.9375rem'};
	// @ts-ignore
	margin-right: ${props => props.margin ? `-${props.margin}px` : '-0.9375rem'};
`;

export const GridCol = styled.div<any>`
	// @ts-ignore
	width: ${props => props.col ? `${props.col}%` : '100%'};
	// @ts-ignore
	padding-left: ${props => props.padding ? `${props.padding}px` : '0.9375rem'};
	// @ts-ignore
	padding-right: ${props => props.padding ? `${props.padding}px` : '0.9375rem'};

	// @ts-ignore
	${props => props.wrap && css`
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		
		& > * {
			margin-right: 0.625rem;

			&:last-child {
				margin-right: 0;
			}
		}`
	}
`;

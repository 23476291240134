export interface IntegrationItem {
  id: string;
  name: string;
  image: string;
  style: any;
  integrated: boolean;
}

export enum IntegrationType {
  GoogleCalendar = 'googleCalendar',
  QuickBooks = 'quickbooks',
  Stripe = 'stripe'
}

export enum StatusType {
  Success = 'success',
  Error = 'error'
}
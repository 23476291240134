export const tourUrls = [
  {
    value: 'short',
    label: 'Short (e.g. /1234567)',
  },
  {
    value: 'long',
    label: 'Long (e.g. /address-state-zip-code-city)',
  }
];

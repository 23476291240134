import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Avatar, Icon } from 'rsuite';
import { FaSignOutAlt } from 'react-icons/fa';

import { authRequestSignOut } from '@/actions/auth';

import { 
  StyledUser,
  StyledUserName,
  StyledUserSignOut
} from './styles';

class User extends Component<any> {
  render() {
    const { userName, authRequestSignOut, pull, favIcon } = this.props;

    return (
			<StyledUser pull={pull}>
        <NavLink to="/branding">
          <Avatar circle>
            {favIcon ? (
              <img src={favIcon} alt="" />
            ) : (
              <Icon 
                icon="avatar"
                style={{ fontSize: 18 }}
              />
            )}
          </Avatar>
        </NavLink>
        {!pull &&
          <Fragment>
            <StyledUserName>
              {userName}
            </StyledUserName>
            <StyledUserSignOut
              title="Sign Out"
              onClick={authRequestSignOut}
            >
              <FaSignOutAlt />
            </StyledUserSignOut>
          </Fragment>
        }
			</StyledUser>
    )
  }
}

const mapStateToProps = state => {
  const email = state?.account?.info?.email;
  const favIcon = state?.account?.info?.branding?.favIcon;
  const userName = email ?`@${email.split('@')[0]}` : null;
  const pull = state.layout.pull;

	return {
    userName,
    pull,
    favIcon
	}
};

const mapDispatchToProps = {
  authRequestSignOut
};

export default connect(mapStateToProps, mapDispatchToProps)(User);

import { startOfYear, subDays, subMonths, subWeeks, startOfDay, endOfDay } from 'date-fns';

export const datePickerRanges = [
  {
    label: 'Today',
    value: [startOfDay(new Date()), endOfDay(new Date())]
  },
  {
    label: 'Yesterday',
    value: [subDays(new Date(), 1), subDays(new Date(), 1)]
  },
  {
    label: 'Last Week',
    value: [subWeeks(new Date(), 1), new Date()]
  },
  {
    label: 'Last Month',
    value: [subMonths(new Date(), 1), new Date()]
  },
  {
    label: 'Year',
    value: [startOfYear(new Date()), new Date()]
  },
];

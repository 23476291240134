import React, { PureComponent } from 'react';

import { subscriptions } from '@/helpers/subscriptions';
import { dateFormat } from '@/helpers/format/date';

import {
	HistoryContainer,
	HistoryTable,
	HistoryRow,
	HistoryCol,
} from './styles';

class History extends PureComponent<any> {
  render() {
		const { items } = this.props;

    return (
			<HistoryContainer>
				<HistoryTable>
				<HistoryRow>
					<HistoryCol><strong>Date</strong></HistoryCol>
					<HistoryCol><strong>Package Name</strong></HistoryCol>
				</HistoryRow>
					{items.map(item => {
						const packageName = item.package && subscriptions[item.package]
							? subscriptions[item.package].title
							: 'Cancel';

						return (
							item.package && item.updateTime && item.package !== '' &&
								<HistoryRow key={item.updateTime}>
									<HistoryCol>
										{dateFormat(item.updateTime, 'YYYY-MM-DD HH:mm')}
									</HistoryCol>
									<HistoryCol>
										{packageName}
									</HistoryCol>
								</HistoryRow>
						)
					})}
				</HistoryTable>
			</HistoryContainer>
    )
  }
}

export default History;

import React from 'react';
import { Field } from 'redux-form';

import withFormGroup from '../withFormGroup';

import {
	StyledRadioButtonsGroup,
	StyledRadioButtonsTitle,
	StyledRadioButtonsLabel,
	StyledRadioButtonsInput,
	StyledRadioButtonsCheckmark
} from './styles';

const renderField = ({
	input,
	options,
	title
}) => (
	<StyledRadioButtonsGroup>
		<StyledRadioButtonsTitle>{title}</StyledRadioButtonsTitle>
		{options.map(({value, label}) => 
			<StyledRadioButtonsLabel key={value}>
				{label}
				<StyledRadioButtonsInput 
					{...input} 
					type="radio" 
					value={value}
					checked={value === input.value}
				/>
				<StyledRadioButtonsCheckmark />
			</StyledRadioButtonsLabel>
		)}
	</StyledRadioButtonsGroup>
);

const FieldComponent = withFormGroup(renderField);

const RadioButtons = ({...rest}) => {
		return (
			<Field
				component={FieldComponent}
				{...rest}
			/>
		)
};

export { RadioButtons };

import { SearchValueState, ToursDataState } from './types';

export const limit = 15;

export const initialToursDataState: ToursDataState = {
  tourList: [],
  totalPages: 1,
  activePage: 1
};

export const initialSearchValuesState: SearchValueState = {
  address: '',
  agentId: '',
  from: '',
  to: '',
  sold: false
};

import React, { ReactElement } from 'react';
import { Button, DateRangePicker, FlexboxGrid, Icon, Input, SelectPicker, Toggle } from 'rsuite';

import { formatDateToString } from '@/v2/shared/utils/dateHelper';

import { SearchFormProps } from '../../types';
import { datePickerRanges } from './constants';
import { useStyles } from './styles';

const { afterToday } = DateRangePicker;

const SearchForm = (props: SearchFormProps): ReactElement => {
  const {
    searchValues,
    debouncedSearchValue,
    selectPickerData,
    selectPickerLoading,
    clearDisabled,
    onChangeDebouncedSearchValue,
    onChange,
    onClear
  } = props;

  const classes = useStyles();

  return (
    <FlexboxGrid
      align="middle"
      className={`shepherd-step-search ${classes.row}`}
    >
      <FlexboxGrid.Item>
        <Input
          value={debouncedSearchValue}
          style={{ width: 200 }}
          placeholder="Address"
          onChange={value => onChangeDebouncedSearchValue(value)}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item>
        <SelectPicker
          value={searchValues.agentId}
          placeholder="Select agent"
          data={selectPickerData}
          disabled={selectPickerLoading}
          style={{ width: 200 }}
          onSelect={agentId => onChange({ agentId })}
          onClean={() => onChange({ agentId: '' })}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item>
        <DateRangePicker
          ranges={(datePickerRanges as any)}
          disabledDate={afterToday()}
          placeholder="Select date range"
          onChange={([from, to]) =>
            from && onChange({ from: formatDateToString(from), to: formatDateToString(to) })
          }
          onClean={() => onChange({ from: '', to: '' })}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item>
        <Toggle
          checked={searchValues.sold}
          size="lg"
          checkedChildren="Sold"
          unCheckedChildren="Sold"
          defaultChecked={false}
          style={{ width: 80 }}
          onChange={sold => onChange({ sold })}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item>
        <Button
          color="cyan"
          size="sm"
          disabled={clearDisabled}
          onClick={onClear}
        >
          <Icon icon="eraser" /> Clear
        </Button>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}

export default SearchForm;

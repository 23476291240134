import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetch, { CachePolicies } from 'use-http';
import { Alert, Button, Modal } from 'rsuite';
import { merge } from 'immutable';
import { omit } from 'lodash';

import Api from '@/services/api';
import config from '@/config';
import { history } from '@/routes/index';

import { useStyles } from './styles';
import ModifyTourForm from './ModifyTourForm';
import {
  mapStringToSelectPickerItem, mapSelectPickerItemToString
} from '../../utils/mapperHelper';
import { getStateFieldData } from './utils';
import { ModifyTourFormValue, ModifyTourProps } from './types';
import { ApiPath } from '../../enums';

const countriesData = require('./data/countries.json');

const ModifyTour = ({ tourId, smallButton, addressSearch }: ModifyTourProps): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tour = useSelector((state: any) => state.tour[tourId]);
  const token = useSelector((state: any) => state.auth.token);
  const options = {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: { 'Authorization': `Bearer ${token}` }
  };

  const [ showModal, setShowModal ] = useState<boolean>(false);
  const { post, response, loading } = useFetch(config.API_URL + ApiPath.TourSave, options);

  const getValue = (): ModifyTourFormValue => {
    const { address: { address: rawAddress, city, zipcode, country, state }, idType } = tour;
    const rawCountry = mapSelectPickerItemToString(country);
    const statesData = getStateFieldData(rawCountry);

    return {
      address: rawAddress,
      city,
      zipcode,
      country: rawCountry,
      state: mapSelectPickerItemToString(state, statesData),
      ...(idType && { idType })
    };
  };

  const save = async (json: any, successMsg: string, errorMsg: string): Promise<any> => {
    const res = await post({ json });

    if (response.ok) {
      Alert.success(successMsg);
      // Temporary solution (dispatch)
      dispatch({ type: 'TOUR_SUCCESS_SAVE', json: res.data.tour })
      setShowModal(false);
      history.push(`/settings/${res.data.tour.id}`);
    } else {
      Alert.error(`${errorMsg} Please try again.`);
    }
  }

  const handleSubmit = (formValue: ModifyTourFormValue): void => {
    const { address, city, zipcode, country, state, idType } = formValue;
    const statesData = getStateFieldData(country);
    const transformedState = mapStringToSelectPickerItem(state, statesData);
    const transformedCountry = mapStringToSelectPickerItem(country, countriesData);

    const fullAddress = `${address}, ${city}, ` +
      `${transformedState ? transformedState['value'] + ' ' : ''}${zipcode}, ` +
      `${transformedCountry['label']}`;

    const walkScore = { address: fullAddress };

    const reqData = {
      address: {
        address,
        city,
        zipcode,
        country: transformedCountry,
        state: transformedState ? transformedState : ''
      },
      fullAddress,
      walkScore,
      idType
    };

    if (tourId) {
      let updatedData = merge(tour, reqData);
      const currentIdType = tour.idType;

      if (currentIdType === 'long' || (currentIdType === 'short' && idType === 'long')) {
        updatedData = omit(updatedData, 'id')
      }
      save(updatedData, 'Tour has been updated.', 'Can not update tour.');
    } else {
      save(reqData, 'Tour has been created.', 'Can not create a new tour.');
    }
  }

  const handleShowModal = () => {
    setShowModal(true);

    Api.getAccountInfo(token).then(res => {
      if(res?.data?.info?.suspended === true) {
        location.reload();
      }
    });
  };

  const handleCancelClick = (): void => {
    if (loading) {
      return;
    }
    setShowModal(false);
  };

  const renderCancelButton = (): ReactElement =>
    <Button appearance="subtle" onClick={handleCancelClick} disabled={loading}>Cancel</Button>;

  const renderTitle = (): string =>
    tourId
      ? ('Change Tour Address')
      : ('Create a New Tour');

  return (
    <>
      {smallButton ? (
        <Button size="xs" appearance="primary" onClick={handleShowModal}>{renderTitle()}</Button>
      ) : (
        <Button
          className={!tourId && `${classes.button} shepherd-step-create-tour`}
          color="cyan"
          size="sm"
          onClick={handleShowModal}
        >
          {renderTitle()}
        </Button>
      )}
      <Modal size="md" show={showModal} onHide={handleCancelClick}>
        <Modal.Header>
          <Modal.Title>{renderTitle()}</Modal.Title>
        </Modal.Header>
        <ModifyTourForm
          tourId={tourId}
          fullAddress={addressSearch ? addressSearch : tour?.fullAddress}
          value={tourId ? getValue() : undefined}
          onSubmit={handleSubmit}
          loading={loading}
          footer={renderCancelButton()}
        />
      </Modal>
    </>
  )
};

export default ModifyTour;

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    position: 'relative'
  },
  clearButton: {
    position: 'absolute !important', 
    top: 0, 
    right: -30
  }
});

export const imageButton = {
  width: 150,
  height: 150
}

import { httpPost } from '@/services/api/http';
import { ApiPath } from '@/v2/shared/enums';
import store from '@/store/index';
import { BrandingData } from './types';

export const save = (data: BrandingData): Promise<any> =>
	httpPost(ApiPath.BrandingSave, { branding: data }, getHeaders());

export const connectDomain = (): Promise<any> =>
	httpPost(ApiPath.BrandingDomain, null, getHeaders());

export const connectEmail = (): Promise<any> =>
	httpPost(ApiPath.BrandingEmail, null, getHeaders());

export const getBrandingExample = (): Promise<any> =>
	httpPost(ApiPath.BrandingExample, null, getHeaders());

export const sendTestEmail = (): Promise<any> =>
	httpPost(ApiPath.NotifyTest, null, getHeaders());

const getHeaders = (): any => ({
	headers: {
		'Authorization': `Bearer ${getToken()}`
	}
});

function getToken() {
  return store().getState().auth.token;
}

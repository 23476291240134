import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetch, { CachePolicies } from 'use-http';
import { Alert, Button, Modal } from 'rsuite';

import config from '@/config';

import { ApiPath } from '@/v2/shared/enums';
import CustomDomainForm from './CustomDomainForm';
import { CustomDomainFormValue, CustomDomainProps } from './types';
import { initialFormValue } from './constants';

const CustomDomain = ({ tourId }: CustomDomainProps): ReactElement => {
  const token = useSelector((state: any) => state.auth.token);
  const domainPricing = useSelector((state: any) => state?.account?.info?.pricing?.domain);
  const options = {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: { 'Authorization': `Bearer ${token}` }
  };

  const [ showModal, setShowModal ] = useState<boolean>(false);
  const [ formValue, setFormValue ] = useState<CustomDomainFormValue>({ ...initialFormValue, tourId });
  const { post: get, response: getResponse, loading: getLoading } = useFetch(config.API_URL + ApiPath.DomainByTourId, options);
  const { post, response: postResponse, loading: postLoading } = useFetch(config.API_URL + ApiPath.DomainLink, options);

  useEffect((): void => {
    if (showModal) {
      getDomain();
    }
  }, [showModal]);

  const getDomain = async (): Promise<any> => {
    const res = await get({tourId});

    if (getResponse.ok) {
      const { domainName, branded } = res.data;
      setFormValue({ tourId, domain: domainName, branded });
    }
  }

  const handleSubmit = async (formValue: CustomDomainFormValue): Promise<any> => {
    await post(formValue);

    if (postResponse.ok) {
      Alert.success('Domain data have been saved.');
      setShowModal(false);
    } else {
      Alert.error(
        'Unexcepted error occurred. Please contact us at contact@homeontour.com ' +
        'with your domain details to complete the domain linkage.',
        10000
      );
    }
  };

  const handleCancelClick = (): void => {
    if (postLoading) {
      return;
    }
    setShowModal(false);
  };

  const renderCancelButton = (): ReactElement =>
    <Button appearance="subtle" onClick={handleCancelClick} disabled={postLoading}>Cancel</Button>;

  return (
    <>
      <Button
        color="cyan"
        size="sm"
        onClick={() => setShowModal(true)}
      >
        Link Custom Domain
      </Button>
      <Modal size="sm" show={showModal} onHide={handleCancelClick}>
        <Modal.Header>
          <Modal.Title>Link Custom Domain</Modal.Title>
        </Modal.Header>
        <CustomDomainForm
          value={formValue}
          onSubmit={handleSubmit}
          getLoading={getLoading}
          postLoading={postLoading}
          pricing={domainPricing}
          footer={renderCancelButton()}
        />
      </Modal>
    </>
  )
};

export default CustomDomain;

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  row: {
    margin: '0 -5px 30px',
    maxWidth: 800,

    '& > .rs-flex-box-grid-item': {
      marginBottom: 10,
      padding: '0 5px'
    }
  }
});

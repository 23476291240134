import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { sendFeedbackRequest } from '@/actions/feedback';

import { colors } from '@/defaults/index';

import { Modal } from '@/components/Modal/index';
import { FormFeedback } from '@/components/Forms/index';

import { 
	StyledFeedback,
	StyledFeedbackText
} from './styles';

class Feedback extends Component<any> {
	state = {
		showModal: false
	}

	toggleShowModal = () => {
		this.setState({
			showModal: !this.state.showModal
		});
	}

	handleSubmit = values => {
		const { message } = values;
		const { account: {email, name, phone}, sendFeedbackRequest } = this.props;

		sendFeedbackRequest({
			email,
			name,
			phone,
			message
    }, () => {
      this.toggleShowModal();
    });
	}

	render() {
    return (
			<Fragment>
				<StyledFeedback onClick={this.toggleShowModal} className="shepherd-step-contact-us">
					<StyledFeedbackText>Contact Us</StyledFeedbackText>
				</StyledFeedback>
				<Modal
						color={colors.whiteApprox}
						show={this.state.showModal} 
						onClose={this.toggleShowModal}
					>
						<FormFeedback
							onSubmit={this.handleSubmit}
							onClose={this.toggleShowModal}
              loading={this.props.loading}
						/>
					</Modal>
			</Fragment>
    )
  }
}

const mapStateToProps = state => ({
	account: state.account.info,
	loading: state.feedback.loading
});

const mapDispatchToProps = {
	sendFeedbackRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);

import styled from 'styled-components';
import { colors } from '@/defaults/index';
import { darken } from 'polished';

export const StyledImageCard = styled.div`
	position: relative;
	display: inline-block;
	background-color: ${colors.white};
`;

export const StyledImageCardImg = styled.img<any>`
	display: block;
	padding: 0.3125rem;
	width: 150px;
	// @ts-ignore
	height: ${props => props.auto ? 'auto' : '150px'};
	border: 0.0525rem solid ${colors.wildSand};
	object-fit: cover;
`;

export const StyledImageCardBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -15px;
	right: -15px;
	z-index: 5;
	width: 1.825rem;
  height: 1.825rem;
	border: none;
	border-radius: 50%;
	background-color: ${colors.carnation};
	font-size: 1.25rem;
	overflow: hidden;
	transition: background-color .2s ease-out;
	outline: none;

	&:hover {
		background-color: ${darken(0.1, colors.carnation)};
	}
`;

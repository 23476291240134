import styled, { css } from 'styled-components';
import { darken } from 'polished'
import { colors } from '@/defaults/index';

export const StyledFormGroup = styled.div`
	margin-bottom: 1.25rem;
`;

export const StyledFormLabel = styled.label`
	display: block;
	margin-bottom: 0.3125rem;
`;

export const StyledFormInputWrapper = styled.div`
	position: relative;
`;

export const StyledFormInput = styled.input<any>`
	display: block;
	height: 2.5rem;
	width: 100%;
	padding: 0 0.9375rem;
	border: 0.0625rem solid ${colors.gallery};
	background-color: ${colors.white};
	font-size: 0.875rem;
	font-weight: 400;
	outline: none;
	transition: border-color .2s ease-out;

	// @ts-ignore
  ${props => props.controls && css`
		padding-right: 3.125rem;
	`}

	&:focus {
		border-color: ${colors.cobalt};
	}
	&:disabled {
		cursor: not-allowed;
	}
`;

export const StyledFormTextarea = styled.textarea`
	display: block;
	height: 10rem;
	width: 100%;
	padding: 0.9375rem;
	border: 0.0625rem solid ${colors.gallery};
	background-color: ${colors.white};
	font-size: 0.875rem;
	font-weight: 400;
	outline: none;
	resize: none;
	transition: border-color .2s ease-out;

	&:focus {
		border-color: ${colors.cobalt};
	}
`;

export const StyledFormSwitch = styled.label`
	position: relative;
  display: block;
  width: 5rem;
  height: 2.125rem;
`;

export const StyledFormSwitchInput = styled.input`
	display: none;

	&:checked + span {
		background-color: ${colors.mountainMeadow};

		&:before {
  		transform: translateX(2.875rem);
		}
	}

	&:disabled + span {
		opacity: .6;
		cursor: not-allowed;
	}
`;

export const StyledFormSwitchSlider = styled.span`
	position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	border-radius: 2.125rem;
  background-color: ${colors.silver};
	cursor: pointer;
  transition: .4s;

	&:before {
		content: "";
		position: absolute;
		height: 1.625rem;
		width: 1.625rem;
		left: 0.25rem;
		bottom: 0.25rem;
		border-radius: 50%;
		background-color: ${colors.white};
		transition: .4s;
	}
`;

export const StyledFormCheckboxRadioInput = styled.input<any>`
	position: absolute;
  height: 0;
	width: 0;
	opacity: 0;
  cursor: pointer;
`;

export const StyledFormCheckboxRadioCheckmark = styled.span<any>`
	position: absolute;
	// @ts-ignore
  top: ${props => props.kind === 'circle' ? '-2px' : '0'};
  left: 0;
  height: 20px;
	width: 20px;
	// @ts-ignore
	border-radius: ${props => props.kind === 'circle' ? '50%' : '2px'};
	background-color: #eee;
	
	&:after {
		content: "";
  	position: absolute;
  	display: none;
	}
`;

export const StyledFormCheckboxRadioLabel = styled.label`
	display: block;
  position: relative;
  padding-left: 30px;
	font-size: 12px;
	color: ${props => props.color ? props.color : colors.mineShaft};
	cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
	user-select: none;
	
	&:hover {
		${StyledFormCheckboxRadioInput} {
			& ~ ${StyledFormCheckboxRadioCheckmark} {
				background-color: #ccc;
			}
		}
	}

	${StyledFormCheckboxRadioInput} {
		&:checked {
			& ~ ${StyledFormCheckboxRadioCheckmark} {
				background-color: #2196F3;

				&:after {
					display: block;
				}
			}	
		}
	}

	${StyledFormCheckboxRadioCheckmark} {
		&:after {
			left: 8px;
			top: 0.22rem;
			width: 5px;
			height: 10px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
`;

export const StyledFormSubmitButton = styled.button<any>`
	border: none;
	// @ts-ignore
	margin: ${props => props.margin ? props.margin : 0};
	padding: 0.5rem 1.125rem;
	border-radius: 0.125rem;
	background-color: ${colors.black};
	color: ${colors.white};
	outline: none;
	transition: background-color .2s ease-out;

	&:disabled {
		opacity: .7;
		cursor: not-allowed;
	}
	
	// @ts-ignore
	${props => props.small && css`
		padding: 0.5rem;
		font-size: 0.75rem;
	`}

	// @ts-ignore
	${props => props.xl && css`
		padding-top: 0.875rem;
		padding-bottom: 0.875rem;
		font-weight: 500;
	`}
	
	// @ts-ignore
	${props => props.maxWidth && css`
		width: 100%;
		// @ts-ignore
		max-width: ${props.maxWidth}px;
	`}

	// @ts-ignore
	${props => props.center && css`
		display: block;
		margin: 0 auto;
	`}

	// @ts-ignore
	${props => props.primary && css`
		background-color: ${colors.ceruleanBlue};

		&:hover:not(:disabled) {
			background-color: ${darken(0.1, colors.ceruleanBlue)};
		}
  `}

	// @ts-ignore
	${props => props.success && css`
		background-color: ${colors.mountainMeadow};

		&:hover:not(:disabled) {
			background-color: ${darken(0.1, colors.mountainMeadow)};
		}
  `}

	// @ts-ignore
	${props => props.dark && css`
		background-color: ${colors.mineShaft};

		&:hover:not(:disabled) {
			background-color: ${darken(0.1, colors.mineShaft)};
		}
  `}

	// @ts-ignore
	${props => props.boulder && css`
		background-color: ${colors.boulder};

		&:hover:not(:disabled) {
			background-color: ${darken(0.1, colors.boulder)};
		}
  `}
`;

export const StyledFormError = styled.div`
	margin-top: 0.3125rem;
	padding: 0 0.0625rem;
	font-size: 0.8125rem;
	font-weight: 400;
	color: ${colors.carnation};
`;

export const StyledFormPreviewPassword = styled.div`
	display: flex;
	position: absolute;
	right: 0.9375rem;
	top: 50%;
	font-size: 1.5rem;
	color: ${colors.black};
	transform: translateY(-50%);
  cursor: pointer;
`;

export const StyledFormInputBtn = styled.button<any>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	margin-left: 4px;
	border: 1px solid #000;
	border-radius: 0.1875rem;
	outline: none;
	color: ${colors.white};
	transition: background-color .2s ease-out, border-color .2s ease-out;

	// @ts-ignore
	${props => props.add && `
		border-color: ${darken(0.1, colors.wedgewood)};
		background-color: ${colors.wedgewood};

		&:hover {
			border-color: ${darken(0.2, colors.wedgewood)};
			background-color: ${darken(0.1, colors.wedgewood)};
		}
	`};

	// @ts-ignore
	${props => props.remove && `
		border-color: ${darken(0.1, colors.carnation)};
		background-color: ${colors.carnation};

		&:hover {
			border-color: ${darken(0.2, colors.carnation)};
			background-color: ${darken(0.1, colors.carnation)};
		}
	`};
`;

export const StyledFormList = styled.ul`
`;

export const StyledFormListItem = styled.li`
	${StyledFormInputWrapper} {
		display: flex;
		width: 100%;
	}

	${StyledFormInput} {
		width: calc(100% - 88px);
	}

	.react-select {
		width: calc(100% - 88px);

		& + button, & + button + button {
			height: 38px;
			width: 38px;
		}
	}
`;

export const colors = {
	white: '#fff',
	whiteApprox: '#fdfdfd',
	concrete: '#f2f2f2',
	black: '#000',
	wildSand: '#f5f5f5',
	blueMarguerite: '#7379c7',
	mineShaft: '#333',
	lima: '#4be42f',
	gallery: '#eaeaea',
	alabaster: '#f9f9f9',
	carnation: '#f16161',
	boulder: '#777',
	dustyGray: '#9c9c9c',
	ceruleanBlue: '#2857ab',
	silver: '#ccc',
	cobalt: '#0747a6',
	catalinaBlue: '#053275',
	athensGray: '#F4F5F7',
	amaranth: '#E94256',
	ebonyClay: '#2e3642',
	dodgerBlue: '#3F8CFF',
	crimson: '#dd1616',
	mountainMeadow: '#21ba45',
	melrose: '#b3b9fe',
	alizarinCrimson: '#db2828',
	steelBlue: '#507bbc',
	blueRibbon: '#004eff',
	jacksonsPurple: '#2b23a7',
	capePalliser: '#a5673f',
	punch: '#dd4b39',
	doveGray: '#686868',
	wedgewood: '#49769c',
	corn: '#eaae00',
	scorpion: '#5d5d5d',
	mercury: '#e5e5e5',
	studio: '#7D52A8'
}

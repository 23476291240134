import { DeprecatedResponseData, OptionName, VideoItem } from './types';

export const getMappedValueFromObject = (data: DeprecatedResponseData): VideoItem[] => {
  const name = (data.isYoutube && OptionName.Youtube) || (data.isVimeo && OptionName.Vimeo) || (data.isIframe && OptionName.Iframe);

  return [{
    name,
    youtube: {
      branded: name === OptionName.Youtube ? data.youtubeUrlBranded : '',
      unbranded: name === OptionName.Youtube ? data.youtubeUrlUnbranded : ''
    },
    vimeo: {
      branded: name === OptionName.Vimeo ? data.vimeoUrlBranded : '',
      unbranded: name === OptionName.Vimeo ? data.vimeoUrlUnbranded : ''
    },
    iframe: {
      branded: name === OptionName.Iframe ? data.iframeUrlBranded : '',
      unbranded: name === OptionName.Iframe ? data.iframeUrlUnbranded : ''
    }
  }];
};

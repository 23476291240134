import * as yup from "yup";

import { REQUIRED } from "@/v2/shared/components/Form/validationMessages";

function isValidPassword(message) {
  return this.test("isValidPassword", message, function (value) {
    const { path, createError } = this;

    if (value.length <= 2) {
      return createError({
        path,
        message: "Password must be at least 3 characters",
      });
    }

    if (!/^\S*$/.test(value)) {
      return createError({
        path,
        message: "Please don't use any white spaces in your password",
      });
    }

    return true;
  });
}

yup.addMethod(yup.string, "isValidPassword", isValidPassword);

export const SCHEMA = yup.object({
  enabled: yup.boolean(),
  isPublishDate: yup.boolean(),
  publishDate: yup.string().when("isPublishDate", {
    is: true,
    then: yup.string().required(REQUIRED).nullable(),
    otherwise: yup.string(),
  }),
  timeZone: yup.string().when("isPublishDate", {
    is: true,
    then: yup.string().required(REQUIRED).nullable(),
    otherwise: yup.string(),
  }),
  isTourPassword: yup.boolean(),
  tourPassword: yup.string().when("isTourPassword", {
    is: true,
    // @ts-ignore
    then: yup.string().isValidPassword().required(REQUIRED),
    otherwise: yup.string(),
  }),
  tourPasswordOneTime: yup.boolean(),
  isMediaPassword: yup.boolean(),
  mediaPassword: yup.string().when("isMediaPassword", {
    is: true,
    // @ts-ignore
    then: yup.string().isValidPassword().required(REQUIRED),
    otherwise: yup.string(),
  }),
  mediaPasswordOneTime: yup.boolean(),
});

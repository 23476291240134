import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form'

import config from '@/config';

import { WalkScore } from '@/components/WalkScore/WalkScore';
import { Input } from '@/components/FormUI/index';
import PanelForm from '@/components/PanelForm/index';
import  {
  GridRow,
  GridCol
} from '@/components/Styled/Grid/index';

const validate = values => {
  const { address } = values;
  const errors = {};

  if (!address) {
    // @ts-ignore
    errors.address = 'Required.';
  }

  return errors;
};

let FormWalkScore = (props) => {
  const {
    handleSubmit,
    onSaveAndNext,
    skipUrl,
    loading,
    values
  } = props;

  const walkScoreId = config.WALK_SCORE_ID;

  return (
    <form onSubmit={handleSubmit}>
      <GridRow>
        <GridCol>
          <Input
            name="enableWalkScore"
            type="checkbox"
            label="Enable Walk Score"/>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Input
            name="address"
            type="text"
            label="Address"/>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <WalkScore
            wsId={walkScoreId}
            address={values.address}
          />
        </GridCol>
      </GridRow>

      <GridRow style={{ marginTop: 20 }}>
        <GridCol>
          <Input
            name="enableHoodq"
            type="checkbox"
            label="Enable HoodQ"/>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Input
            name="hoodqUrl"
            type="text"
            label="HoodQ URL (e.g. https://app.hoodq.com/package/abc...)"
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <PanelForm
            loading={loading}
            onSave={handleSubmit}
            onSaveAndNext={onSaveAndNext}
            skipUrl={skipUrl}
            formProps={props}
          />
        </GridCol>
      </GridRow>
    </form>
  )
};

FormWalkScore = connect(state => ({
  values: getFormValues('walkScore')(state)
}))(FormWalkScore);

FormWalkScore = reduxForm({
  form: 'walkScore',
  validate
})(FormWalkScore);

export { FormWalkScore };

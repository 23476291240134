import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form'
import classnames from 'classnames';

import {
	StyledFormTitle,
	StyledFormSubTitle,
	StyledForm,
	StyledFormRedirect
} from '../styles';

import { 
	Input,
	Submit 
} from '@/components/FormUI/index';

const validate = values => {
	const { username } = values;
	const errors = {}
	
  if (!username) {
		// @ts-ignore
    errors.username = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(username)) {
		// @ts-ignore
		errors.username = 'Invalid email address';
	}
	
  return errors;
};
				
let FormResetPassword = (props) => {
	const { handleSubmit, loading } = props;

	return (
		<Fragment>
			<StyledFormTitle>
				Forgotten Your Password?
			</StyledFormTitle>
			<StyledFormSubTitle>
				We'll email you a code to reset it.
			</StyledFormSubTitle>
			<StyledForm onSubmit={handleSubmit}>
				<Input 
					name="username"
					type="email"
					label="Email"/>
				<Submit 
					primary 
					center 
					xl={!loading} 
					maxWidth="340"
          disabled={loading}
          loading={loading}
				>
					Send Email
				</Submit>
			</StyledForm>
			<StyledFormSubTitle>
				Just remembered?&nbsp;
				<StyledFormRedirect 
					to="/sign-in"
					className={
						classnames({
							'disabled': loading
						})
					}
				>
					Sign in
				</StyledFormRedirect>
				&nbsp;instead.
			</StyledFormSubTitle>
		</Fragment>
	)
};

FormResetPassword = reduxForm({
	form: 'resetPassword',
	validate
})(FormResetPassword)

export { FormResetPassword };

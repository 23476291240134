import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { colors } from '@/defaults/index';

import { getUserId } from '@/selectors/index';

import {
	addPermissionsRequest,
	removePermissionsRequest
} from '@/actions/permissions';
import { fetchAgentsRequest } from '@/actions/agents';

import Title from '@/components/Title/index';
import PermissionList from '@/components/PermissionList/index';
import { FormPermissions } from '@/components/Forms/index';
import { FormPermissionList } from '@/components/Forms/index';
import { ModalConfirm } from '@/components/Modal/index';

import {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import { SegmentWrapper } from '@/components/Styled/Segments/index';

class AgentAccess extends Component<any> {
	state = {
		username: null,
		showModal: false
	}

	componentDidMount() {
    this.props.fetchAgentsRequest();
  }

	toggleShowModal = () => {
		this.setState({
			showModal: !this.state.showModal
		});
	};

	handleRemoveItem = username => {
		this.setState({ username });

		this.toggleShowModal();
	}

	handleConfirmRemoveItem = () => {
		const { id, removePermissionsRequest } = this.props;

		removePermissionsRequest({
			tourId: id,
			username: this.state.username
		}, () => {
			this.toggleShowModal();
		});
	}

	saveData = usernames => {
		const { id, addPermissionsRequest } = this.props;

		addPermissionsRequest({
			tourId: id,
			usernames
		});
	}

	handleSubmitList = ({email}) => {
    this.saveData([email.value]);
	}

	handleSubmit = ({userNames}) => {
		const usernames = userNames.map(({userName}) => {
			return userName;
		});

		this.saveData(usernames);
	}

  render() {
		const { creatorId, userId, userName, userNames, agentEmails, loading } = this.props;

    return (
			<GridRow>
				<GridCol>
					<Title name="Agent Access" />
					<PermissionList
						items={userNames}
						creatorId={creatorId}
						userId={userId}
						userName={userName}
						onRemoveItem={this.handleRemoveItem}
						loading={loading}
					/>
				</GridCol>
				{creatorId === userId &&
					<GridCol>
						{!isEmpty(agentEmails) &&
							<SegmentWrapper maxWidth="450px">
								<Title
									name="Select an email from the list of Agents:" 
									subtitle="true"
								/>
								<FormPermissionList
									onSubmit={this.handleSubmitList}
									loading={loading}
									options={agentEmails}
									items={userNames}
								/>
								<div style={{
										marginTop: '25px', 
										marginBottom: '25px',
										textAlign: 'center',
										fontWeight: 500
									}}>
									OR
								</div>
							</SegmentWrapper>
						}
						<SegmentWrapper maxWidth="450px">
							<Title
								name="Add new Agent email:" 
								subtitle="true" 
							/>
							<FormPermissions 
								onSubmit={this.handleSubmit}
								loading={loading}
								items={userNames}
							/>
						</SegmentWrapper>
					</GridCol>
				}
				<ModalConfirm 
					message="Are you sure you want to delete this email?"
					cancelBtnText="Cancel"
					confirmBtnText="Delete"
					loading={this.props.loading}
					confirmBtnColor={colors.carnation}
					show={this.state.showModal}
					onConfirm={this.handleConfirmRemoveItem}
					onClose={this.toggleShowModal}
				/>
			</GridRow>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	const id = ownProps.match.params.id;
	const currentTour = state.tour[id];
	const agents = state.agents.items;

	const agentEmails = agents.map(({id, email}) => {
		return {
			value: email,
			label: email
		};
	});
	
	return {
		id,
		userNames: state.permissions.userNames,
		creatorId: currentTour ? currentTour.creatorId : null,
		agentEmails,
		userId: getUserId(),
		userName: state.account.info.email,
		loading: state.permissions.loading
	}
};

const mapDispatchToProps = {
  addPermissionsRequest,
	removePermissionsRequest,
	fetchAgentsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentAccess);

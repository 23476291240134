import React, { PureComponent } from 'react';

class LoaderInfinity extends PureComponent<any> {
	render() {
		let { size, color } = this.props;

		size = size || 30;
		color = color || '#c5523f';

		return (
			<svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" 
				preserveAspectRatio="xMidYMid meet"
			>
				<path fill="none"  
					d="M24.3,30C11.4,30,5,43.3,5,50s6.4,20,19.3,20c19.3,0,32.1-40,51.4-40 C88.6,30,95,43.3,95,50s-6.4,20-19.3,20C56.4,70,43.6,30,24.3,30z" 
					stroke={color} 
					strokeWidth="6" 
					strokeDasharray="230.93003540039064 25.658892822265614"
				>
					<animate attributeName="stroke-dashoffset" 
						calcMode="linear" 
						values="0;256.58892822265625" 
						keyTimes="0;1" 
						dur="1.4" 
						begin="0s" 
						repeatCount="indefinite">
					</animate>
				</path>
			</svg>
		)
	}
}

export { LoaderInfinity };

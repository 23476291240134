import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import config from '@/config';

import { ApiPath } from '@/v2/shared/enums';
import { saveTourRequest } from '@/actions/tour';
import { saveTour } from '@/helpers/forms';
import { redirectTo } from '@/helpers/redirect';
import Title from '@/components/Title';
import PanelForm from '@/components/PanelForm';
import FileManager from '@/v2/shared/components/FileManager/FileManager';
import { File } from '@/v2/shared/components/FileManager/types';

const Documents: React.FC = (props: any): ReactElement => {
	const FORM_NAME = 'documents';
	const { id, currentTour, loadingTour, token, initialDocuments } = props;
	// @ts-ignore
  const skipUrl = `/${redirectTo({currentPage: FORM_NAME, activePages: currentTour.settings})}/${id}`;

	const [ documentList, setDocumentList ] = useState<File[]>([]);
	const [ documents, setDocuments ] = useState([]);
	const [ uploading, setUploading ] = useState(false);

	useEffect(() => {
		setDocuments(initialDocuments || []);
		setDocumentList(initialDocuments?.map(mapDocumentsToFileGrid));
	}, [initialDocuments]);

	// TODO: move to helper (is used by Fllorplan, Photos, Documents)
	const mapDocumentsToFileGrid = (file, index): File => {
		return {
			id: (index + 1).toString(),
			name: file.name,
			url: file.url,
			uploaded: true,
			saved: true,
			status: 'success',
			type: file.url.split('.').pop(),
			size: 0,
			fileData: file
		};
	};

	const handleChangeDocuments = (files: File[]) => {
		setDocuments(files.map(file => ({
			name: file.name,
			url: file.url
		})));
	};

	const handleSave = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, documents);
  };

  const handleSaveAndNext = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, documents, true);
  };

  return (
    <>
			<Title name="Documents" />
			<FileManager
				prefix="documents"
				url={config.API_URL + ApiPath.uploadFiles + id}
				headers={{ 'Authorization': `Bearer ${token}` }}
				info="Accepted extensions: pdf"
				acceptExtensions={['application/pdf']}
				files={documentList}
				enableFileName={true}
				enableEditingFile={true}
				enableRemovingFile={true}
				onChangeFiles={handleChangeDocuments}
				onStart={() => setUploading(true)}
				onEnd={() => setUploading(false)}
			/>
			<PanelForm
				loading={loadingTour}
				disabled={uploading}
				onSave={handleSave}
				onSaveAndNext={handleSaveAndNext}
				skipUrl={skipUrl}
				formProps={{valid: true}}
			/>
		</>
	);
};

const mapStateToProps = (state: any, ownProps: any): any => {
  const id = ownProps.match.params.id;
  const currentTour = state.tour[id];
  const initialDocuments = currentTour?.documents;

  return {
		token: state.auth.token,
    id,
    currentTour,
		initialDocuments,
    loadingTour: state.tour.loading
  };
};

const mapDispatchToProps = {
  saveTourRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);

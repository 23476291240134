import {
	CUSTOM_DOMAIN_INIT,
	CUSTOM_DOMAIN_REQUEST_GET,
	CUSTOM_DOMAIN_SUCCESS_GET
} from '@/actions/types';

export const initCustomDomain = () => {
  return {
		type: CUSTOM_DOMAIN_INIT
  }
};

export const getCustomDomainRequest = () => {
  return {
		type: CUSTOM_DOMAIN_REQUEST_GET
  }
};

export const getCustomDomainSuccess = data => {
  return {
		type: CUSTOM_DOMAIN_SUCCESS_GET,
		payload: { ...data }
  }
};

import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { 
	StyledFooter,
	StyledFooterContainer,
	StyledFooterMenuLink
} from './styles';

const Footer = (): ReactElement => {
	const { customDomain } = useSelector((state: any) => state.customDomain);
	
	return (
		<StyledFooter>
			<StyledFooterContainer>
				<StyledFooterMenuLink 
					href="https://homeontour.com/privacy/index.html" 
					target="_blank"
				>
					Privacy Policy
				</StyledFooterMenuLink>
				<StyledFooterMenuLink 
					href="https://homeontour.com/terms/index.html" 
					target="_blank"
				>
					Terms and Conditions
				</StyledFooterMenuLink>
				{customDomain &&
					<StyledFooterMenuLink 
						href="https://homeontour.com" 
						target="_blank"
						extra
					>
						Powered by HomeOnTour
					</StyledFooterMenuLink>
				}
			</StyledFooterContainer>
		</StyledFooter>
	);
};

export default Footer;

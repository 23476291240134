import { ValueLabel } from "./types";

export const mapStagingOptionToValueLabel = (type: string): ValueLabel => {
  const label = type.replace("_", " ");

  return {
    value: type,
    label: label[0].toUpperCase() + label.substring(1),
  };
};

import { Schema } from 'rsuite';

import { Countries } from '../../enums';
import { isRequiredMsg } from './constants';

const { StringType } = Schema.Types;

export const model = Schema.Model({
  address: StringType()
    .isRequired(isRequiredMsg),
  city: StringType()
    .isRequired(isRequiredMsg),
  zipcode: StringType()
    .isRequired(isRequiredMsg),
  country: StringType()
    .isRequired(isRequiredMsg),
  state: StringType()
    .addRule((value, data) => {
      if (
        !value
        && (data.country === Countries.USA
        || data.country === Countries.Canada)
      ) {
        return false;
      }
      return true;
    }, isRequiredMsg, true),
  idType: StringType()
    .isRequired(isRequiredMsg)
});

export const getAgentAvatar = (avatar: any): string => {
	if (!avatar) {
		return;
	}

	return avatar[0]?.urls[3]?.url || avatar[0]?.urls[2]?.url;
};

export const sortAgentListASC = (a, b): number => a.name.localeCompare(b.name);

export const sortAgentListDESC = (a, b): number => b.name.localeCompare(a.name);

export const sortAgentListByLastAdded = (a, b): number =>
	(a.createTime < b.createTime) ? 1 : ((b.createTime < a.createTime) ? -1 : 0);

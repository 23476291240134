import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  panel: {
    marginTop: 20,

    '& >.rs-panel-heading': {
      paddingRight: 0,
      paddingLeft: 0
    },
    '& >.rs-panel-body': {
      paddingRight: 2,
      paddingLeft: 2
    }
  },
  wrapIconAndRadioGroup: {
    display: 'flex',
    alignItems: 'center',

    '& .rs-icon': {
      marginRight: 15
    }
  },
  inputGroup: {
    display: 'flex',

    '& .rs-input': {
      width: 'calc(100% - 10px)',
      marginRight: 10
    }
  }
});

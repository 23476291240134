import styled, { css } from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledSidebar = styled.div<any>`
	position: sticky;
	top: 0;
	flex-grow: 1;
	flex-basis: 0;
	max-width: 17.5rem;
  height: 100vh;
  border-right: 0.0325rem solid ${colors.gallery};
	background-color: ${colors.cobalt};

	// @ts-ignore
	${props => props.pull && css`
		max-width: 4.0625rem;

		${StyledSidebarBottom} {
			justify-content: center;
			padding-left: 0;
			padding-right: 0;
		}
	`}
`;

export const StyledSidebarContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-content: space-between;
	width: 100%;
	height: 100%;
`;

export const StyledSidebarTop = styled.div`
	width: 100%;
	padding-bottom: 2.5rem;
`;

export const StyledSidebarBottom = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 0.625rem 0.9375rem;
`;

export const StyledSidebarMinimize = styled.div`
	display: flex;
	align-items: center;
	padding: 0.3125rem;
	border-radius: 0.1875rem;
	font-size: 1.5rem;
	color: ${colors.white};
	cursor: pointer;

	&:hover {
		background-color: ${colors.catalinaBlue};
	}
`;

import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Icon, Panel } from 'rsuite';

import CustomizeIframe from './CustomizeIframe';
import { Urls } from './enums';
import { useStyles } from './styles';

const MyPortfolio = (): ReactElement => {
  const classes = useStyles();
  const account = useSelector((state: any) => state.account);
  const url = `${Urls.Portfolio}?username=${account?.info?.email}`;

  return (
    <div className={classes.tab}>
      <Panel bodyFill header="Direct link">
        <Button appearance="link" href={url} target="_blank">
          <Icon icon='link' /> View My Portfolio
        </Button>
      </Panel>

      <Divider>OR</Divider>

      <Panel bodyFill header="Embed">
        <CustomizeIframe url={url} />
      </Panel>
    </div>
  )
};

export default MyPortfolio;

import { put } from 'redux-saga/effects';

import Messages from '@/services/messages';

import { fireToast } from '@/actions/toasts';

export function* catchSuccess(message) {
  yield put(
    // @ts-ignore
    fireToast({
      type: 'success',
      message: Messages[message]
    })
  );
}

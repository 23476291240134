import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledPanelForm = styled.div`
	display: flex;
	width: 100%;
	padding: 0.9375rem;
	border: 0.0625rem solid ${colors.gallery};
	border-radius: 0.125rem;
	background-color: ${colors.white};

	& > * {
		margin-bottom: 0 !important;
		margin-right: 0.375rem;
	}
`;

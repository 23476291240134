import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import themeModeData from './data.json';
import { ThemeModeName, ThemeModeProps } from './types';
import { selectMode } from '../../selectors';

const ThemeMode = ({ id, availableThemeModes, onChange }: ThemeModeProps): ReactElement => {
  const classes = useStyles();

  const mode = useSelector(selectMode(id));

  const [ activeMode, setActiveMode ] = useState(mode);

  useEffect(() => {
    onChange(activeMode);
  }, []);

  useEffect(() => {
    if (!availableThemeModes.includes(activeMode)) {
      setActiveMode(availableThemeModes[0]);
      onChange(availableThemeModes[0] as ThemeModeName);
    }
  }, [ availableThemeModes ]);

  const handleClick = (value: ThemeModeName) => {
    setActiveMode(value);
    onChange(value);
  };

  return (
    <ul className={classes.list}>
      {themeModeData.map(({ value, label, color }) => {

        if (!availableThemeModes.includes(value)) {
          return;
        }

        return (
          <li
            key={value} 
            className={classes.item}
          >
            <div className={classes.name}>{label}</div>
            <div 
              className={classes.circle} 
              style={{ 
                backgroundColor: color,
                borderColor: value == activeMode ? '#3498ff' : '#e3e3e3'
              }}
              onClick={() => handleClick(value as ThemeModeName)}
            >
            </div>
          </li>
        );
      })}
    </ul>
	);
};

export default ThemeMode;

export const countries = [
  { value: 'canada', label: 'Canada' },
  { value: 'usa', label: 'USA' },
  { value: 'australia', label: 'Australia' },
  { value: 'new-zealand', label: 'New Zealand' },
  { value: 'usvi', label: 'USVI' }
];

export const isCountryState = value => {
  return value === 'usa' || value === 'canada';
};

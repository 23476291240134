import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { mainMenu, tourContent, tourSettings } from './menus'

import { 
  StyledMenu,
  StyledMenuHeader
} from './styles';

import MenuItem from './MenuItem/index';

const mainMenuRender = (pull, customDomain, unreadOrders): ReactElement[] => {
  return mainMenu.map(route => {
    const { url, name, icon } = route;

    if (customDomain && !['Account', 'Tours'].includes(name)) {
      return null;
    }

    return (
      <MenuItem 
        key={name}
        name={name}
        url={url}
        active={true}
        icon={icon}
        pull={pull}
        unreadOrders={name === 'Orders' && unreadOrders > 0 && unreadOrders}
      />
    );
  })
};

const tourMenuRender = (menu, id, pull, videoSlideshow): ReactElement[] => {
  return menu.map(route => {
    const { url, name, icon } = route;
    const path = `${url}${id}`;

    if (videoSlideshow !== true && name === 'Video Slideshow') {
      return;
    }

    return (
      <MenuItem 
        key={name}
        name={name}
        url={path}
        active={id}
        icon={icon}
        pull={pull}
      />
    );
  })
};

const Menu: any = ({id, pull}): ReactElement => {
  const { customDomain } = useSelector((state: any) => state.customDomain);
  const { videoSlideshow } = useSelector((state: any) => state?.account?.info);
  const { unreadOrders } = useSelector((state: any) => state?.account?.info);

  return (
    <StyledMenu pull={pull}>
      <StyledMenuHeader>
        Main {!pull && `Menu`}
      </StyledMenuHeader>
      {mainMenuRender(pull, customDomain, unreadOrders)}
      <StyledMenuHeader>
        {!pull && `Tour`} Content
      </StyledMenuHeader>
      {tourMenuRender(tourContent, id, pull, videoSlideshow)}
      <StyledMenuHeader>
        {!pull && `Tour`} Settings
      </StyledMenuHeader>
      {tourMenuRender(tourSettings, id, pull, videoSlideshow)}
    </StyledMenu>
  );
};

export default Menu;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withRouter } from 'react-router-dom'
import { 
	TiArrowBackOutline,
	TiArrowForwardOutline
} from "react-icons/ti";

import { pullSidebar } from '@/actions/layout';

import { 
	StyledSidebar,
	StyledSidebarContainer,
	StyledSidebarTop,
	StyledSidebarBottom,
	StyledSidebarMinimize
} from './styles';

import HeaderSidebar from '@/components/Headers/HeaderSidebar/index';
import Menu from '@/components/Menu/index';

class Sidebar extends Component<any> {
	handlePullSidebar = () => {
		const { pull, pullSidebar } = this.props;

		pullSidebar(!pull);
	}

	render() {
		const { id, settings, pull } = this.props;

    return (
			<StyledSidebar pull={pull}>
				<StyledSidebarContainer>
					<Scrollbars 
						style={{ width: '100%', height: '100%' }}
						autoHide
						autoHideTimeout={200}
						autoHideDuration={200}
					>
						<StyledSidebarTop>
							<HeaderSidebar />
							<Menu
								id={id}
								settings={settings}
								pull={pull}
							/>
						</StyledSidebarTop>
						{/* <StyledSidebarBottom>
							<StyledSidebarMinimize onClick={this.handlePullSidebar}>
								{pull ? (
									<TiArrowForwardOutline />
								) : (
									<TiArrowBackOutline />
								)}
							</StyledSidebarMinimize>
						</StyledSidebarBottom> */}
					</Scrollbars>
				</StyledSidebarContainer>
			</StyledSidebar>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const pathname = ownProps.location.pathname;
	const id = pathname.split('/')[2];
	const pull = false; //state.layout.pull;

  return {
		id,
		pull
	};
};

const mapDispatchToProps = {
  pullSidebar
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

import React, { ReactElement } from 'react';
import { Avatar, FlexboxGrid, Panel } from 'rsuite';
import classnames from 'classnames';

import { useStyles } from './drag-overlay-card.styles';
import { DragOverlayCardProps } from '../types';

const DragOverlayCard = ({ colSize, quantity, imageComp }: DragOverlayCardProps): ReactElement => {
	const classes = useStyles();

	return (
		<FlexboxGrid.Item colspan={24}>
			<div className={
					classnames(
						classes.item,
						{ [`itemSize${colSize}`]: true },
						{ [classes.itemSelected]: quantity > 0 }
					)
				}
			>
				<Panel bordered bodyFill>
					<div className={classes.itemBody}>
						<div className={classes.image}>
							<div className={classes.imageOverlay}></div>
							{quantity > 1 &&
								<Avatar circle className={classes.quantity}>{quantity}</Avatar>
							}
							{imageComp}
						</div>
					</div>
				</Panel>
			</div>
		</FlexboxGrid.Item>
	);
};

export default DragOverlayCard;

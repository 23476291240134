import React, { ReactElement } from 'react';
import { FlexboxGrid, Icon, IconButton, Message, Radio, RadioGroup, Toggle } from 'rsuite';

import { useStyles } from './file-grid.styles';
import { FileGridProps } from '../types';
import { FILE_GRID_INFO } from '../constants';

const FileGrid = ({
	children,
	defaultColsNumber = 4,
	disabled,
	disabledRemove,
	numberOfSelectedFiles = 0,
	totalFiles = 0,
	hideToolbar,
	selectedAll,
	onChangeCols,
	onChangeSelectAll,
	onRemove
}: FileGridProps): ReactElement => {
	const classes = useStyles();

	const showPlural = () => {
		return numberOfSelectedFiles === 0
			? 's'
			: numberOfSelectedFiles > 1 ? 's' : ''; 
	};

	return (
		<>
			{totalFiles > 0 && !hideToolbar &&
				<>
					<div className={classes.toolbar}>
						{disabled && 
							<div className={classes.backdrop}></div>
						}
						<RadioGroup 
							name="radioList" 
							inline 
							appearance="picker"
							defaultValue={defaultColsNumber.toString()}
							onChange={onChangeCols}
						>
							<span className={classes.label}>Columns in a row: </span>
							<Radio value="2">2</Radio>
							<Radio value="4">4</Radio>
							<Radio value="6">6</Radio>
						</RadioGroup>
						<div className={classes.selected}>
							Selected <strong>{numberOfSelectedFiles}</strong> file{showPlural()} of <strong>{totalFiles}</strong>.
						</div>
						<Toggle 
							size="lg"
							checked={selectedAll}
							checkedChildren="Select All" 
							unCheckedChildren="Select All"
							onChange={onChangeSelectAll}
						/>
						<IconButton 
							icon={<Icon icon="trash" />}
							size="sm"
							color="red"
							disabled={disabledRemove}
							onClick={onRemove}
						/>
					</div>

					<Message
						showIcon 
						type="info"
						className={classes.message}
						description={
							<>Use one of these (<kbd>Shift</kbd>, <kbd>Ctrl</kbd> or <kbd>Cmd</kbd>) keys to multi-select</>
						}
					/>
				</>
			}

			<div className={classes.grid}>
				{disabled && 
					<div className={classes.backdrop}></div>
				}
				{children}
			</div>
		</>
	);
};

export default FileGrid;

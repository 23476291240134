import React, { memo, ReactElement } from 'react';
import { 
	Avatar,
	ButtonToolbar,
	FlexboxGrid, 
	Icon, 
	IconButton,
	List,
	Tooltip,
	Whisper,
} from 'rsuite';

import { useStyles } from './styles';
import { AgentListProps } from './types';

const AgentList = ({ items, onEdit, onRemove }: AgentListProps): ReactElement => {
	const classes = useStyles();

  return (
		<List hover className={classes.agentList}>
			{items.map(item => (
				<List.Item key={item.id}>
					<FlexboxGrid>
						<FlexboxGrid.Item colspan={3} className={classes.agentListItem}>
							{item.avatar ? (
								<Avatar src={item.avatar} className={classes.agentListAvatar} />
							) : (
								<Avatar><Icon icon="avatar" style={{ fontSize: 20 }} /></Avatar>
							)}
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={14} className={classes.agentListItem}>
							<div className={classes.agentListDetails}>
								<div>{item.name}</div>
								<div>{item.subname}</div>
							</div>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={7} className={classes.agentListItem}>
							{item.edit &&
								<ButtonToolbar style={{ marginLeft: 'auto' }}>
									<Whisper 
										placement="top" 
										trigger="hover" 
										speaker={<Tooltip>Edit</Tooltip>}
									>
										<IconButton 
											icon={<Icon icon="edit" />} 
											appearance="primary"
											onClick={() => onEdit(item.id)}
										/>
									</Whisper>
									<Whisper 
										placement="top" 
										trigger="hover" 
										speaker={<Tooltip>Remove</Tooltip>}
									>
										<IconButton 
											icon={<Icon icon="trash2" />}
											color="red"
											onClick={() => onRemove(item.id)}
										/>
									</Whisper>
								</ButtonToolbar>
							}
						</FlexboxGrid.Item>
					</FlexboxGrid>
				</List.Item>
			))}
		</List>
  );
};

export default memo(AgentList);

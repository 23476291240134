import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Loader } from 'rsuite';

import { getAgentsRequest } from '@/actions/agents';
import { getToursRequest } from '@/actions/tours';
import { getTourRequest } from '@/actions/tour';
import { getPermissionsRequest } from '@/actions/permissions';
import { checkSubscriptionRequest } from '@/actions/subscriptions';
import { fireFakeRequest } from '@/actions/layout';

import { StyledAppContainer, transitionOptionsCSS } from '@/components/Styled/App/index';

export default function wrappedAdmin(WrappedComponent, request) {
  class decoreWithStore extends Component {
    state = {
      loading: true
    }

    componentDidMount() {
      const { 
        id,
        tours: {page, limit},
        getAgentsRequest,
        getTourRequest,
        getToursRequest,
        getPermissionsRequest,
        checkSubscriptionRequest,
        fireFakeRequest
      }: any = this.props;

      this.setState({ loading: true });

      switch(request) {
        case 'agents':
          getAgentsRequest();
          break;
        case 'tours':
          getToursRequest(page, limit);
          checkSubscriptionRequest();
          break;
        case 'tour':
          getTourRequest(id);
          break;
        case 'permissions':
          getPermissionsRequest(id);
          break;
        case 'stats':
          getTourRequest(id);
          break;
        default:
          fireFakeRequest();
      }      
    }

    handleLoadingOn = () => {
      this.setState({ loading: true });
    }

    handleLoadingOff = () => {
      this.setState({ loading: false });
    }

    render() {
      // @ts-ignore
      const { loadingContent } = this.props;
      const { loading } = this.state;

      const { 
        timeout,
        transitionName
      } = transitionOptionsCSS;

      return (
        <StyledAppContainer loading={loading}>
          <TransitionGroup>    
            {loadingContent && loading &&
              <CSSTransition
                key={1}
                timeout={timeout}
                classNames={transitionName}
                onEnter={this.handleLoadingOn}
                onExited={this.handleLoadingOff}
              >
                <Loader size="lg" center vertical content="Loading..."/>
              </CSSTransition>
            }
            {!loadingContent && !loading &&
              <CSSTransition
                key={2}
                enter={false}
                exit={false}
                timeout={timeout}
                classNames={transitionName}
              >
                <WrappedComponent {...this.props} />
              </CSSTransition>
            }
          </TransitionGroup>
        </StyledAppContainer>
      )
    }
  }

  const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const path = ownProps.match.path;
    const currentTour = state.tour[id];
    const settings = currentTour ? currentTour.settings : null;
    const loadingContent = state.layout.loadingContent;
  
    return {
      id,
      path,
      settings,
      loadingContent,
      tours: {
        page: state.tours.currentPage,
        limit: state.tours.perPage
      }
    };
  };
  
  const mapDispatchToProps = {
    getAgentsRequest,
    getToursRequest,
    getTourRequest,
    getPermissionsRequest,
    checkSubscriptionRequest,
    fireFakeRequest
  };
  
  return connect(mapStateToProps, mapDispatchToProps)(decoreWithStore);
}

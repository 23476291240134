import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { darken } from "polished";
import { colors } from "@/defaults/index";

export const StyledMenuItem: any = styled.li<any>`
  display: block;
  cursor: not-allowed;

  &.active {
    cursor: pointer;
  }

  // @ts-ignore
  ${(props) =>
    props.pull &&
    css`
      ${StyledNavLink} {
        padding: 0 0.625rem;
      }

      ${StyledMenuItemWrapper} {
        justify-content: center;
      }

      ${StyledMenuItemIcon} {
        width: "auto";
        margin-right: 0;
      }

      ${StyledMenuItemName} {
        flex-grow: initial;
        padding-right: 0;
        overflow: visible;
      }

      ${StyledMenuItemLock} {
        top: -0.125rem;
        right: -0.75rem;
      }
    `}
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  width: 100%;
  padding: 0 1.25rem;
  text-decoration: none;
  color: ${colors.white};
  transition: color 0.2s ease-out, opacity 0.2s ease-out;
  pointer-events: none;

  &.active {
    pointer-events: initial;
  }
  &.is-active {
    & > div {
      color: ${colors.white};
      background-color: ${darken(0.06, colors.cobalt)};
    }
  }
  &:hover,
  &:active,
  &:focus {
    & > div {
      color: ${colors.white};
      background-color: ${colors.catalinaBlue};
    }
  }
`;

export const StyledMenuItemWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 0.625rem 0.3125rem;
  border-radius: 0.1875rem;
`;

export const StyledMenuItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.625rem;
  margin-right: 0.8rem;
  font-size: 1.25rem;
`;

export const StyledMenuItemName = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  padding-right: 1.25rem;
  white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-size: 1rem;
`;

export const StyledMenuItemLock = styled.div`
  position: absolute;
  top: 0.1875rem;
  right: 0;
  font-size: 0.875rem;
`;

export const StyledUnreadOrders: any = styled.div<any>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: 52px;
  background-color: red;
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #fff;

  ${(props) =>
    props.new &&
    css`
      border-radius: 8px;
      top: -3px;
      left: 110px;
      width: 32px;
      height: 19px;
      font-size: 10px;
    `}
`;

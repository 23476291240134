import * as yup from 'yup';

import { REQUIRED, URL } from '@/v2/shared/components/Form/validationMessages';
import { OptionName, Tour360Form } from './types';

export const FORM_NAME = 'tour360';
export const FORM_GROUP_NAME = 'tours';

export const INITIAL_VALUE = { name: OptionName.Matterport, matterport: '', iguide: '', cloudPano: '', zillow: '' };

export const DEFAULT_VALUES: Tour360Form = {
  tours: [INITIAL_VALUE]
};

export const SCHEMA = yup.object().shape({
  tours: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      matterport: yup.string().nullable().when('name', {
        is: OptionName.Matterport,
        then: yup.string().required(REQUIRED).url(URL)
      }),
      iguide: yup.string().nullable().when('name', {
        is: OptionName.Iguide,
        then: yup.string().required(REQUIRED).url(URL)
      }),
      cloudPano: yup.string().nullable().when('name', {
        is: OptionName.CloudPano,
        then: yup.string().required(REQUIRED).url(URL)
      }),
      zillow: yup.string().nullable().when('name', {
        is: OptionName.Zillow,
        then: yup.string().required(REQUIRED).url(URL)
      })
    })
  )
});

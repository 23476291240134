import {
  StagingGenerateRequestData,
  StagingGenerateResponseData,
  StagingOptions,
  StagingRegenerateRequestData,
  StagingRegenerateResponseData,
} from "./types";
import { httpPost } from "@/services/api/http";
import { ApiPath } from "@/v2/shared/enums";

export const getOptions = (token: string): Promise<StagingOptions> =>
  httpPost(ApiPath.StagingOptions, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const generate = (
  data: StagingGenerateRequestData,
  token: string
): Promise<StagingGenerateResponseData> =>
  httpPost(ApiPath.StagingGenerate, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 120000,
  });

export const regenerate = (
  data: StagingRegenerateRequestData,
  token: string
): Promise<StagingRegenerateResponseData> =>
  httpPost(ApiPath.StagingRegenerate, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 120000,
  });

export const saveTour = (data: any, token: string): Promise<any> =>
  httpPost(
    ApiPath.TourSave,
    { json: data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 120000,
    }
  );

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	backdrop: {
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 20,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255,255,255,.3)',
		cursor: 'not-allowed'
	},
	toolbar: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		margin: '20px 0',
		padding: '10px 0',
		backgroundColor: '#fff',

		'& > *': {
			marginRight: 10
		},

		'& .rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label': {
			fontWeight: 700
		}
	},
	message: {
		'& kbd': {
			display: 'inline-block',
			border: '1px solid #ccc',
			borderRadius: 4,
			padding: '2px 7px',
			margin: '0 2px',
			boxShadow: '0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset',
			backgroundColor: '#f7f7f7'
		}
	},
	selected: {
		margin: '0 15px 0 5px',

		'& strong': {
			color: '#7d52a8'
		}
	},
	label: {
		padding: '8px 2px 8px 10px',
    display: 'inline-block',
    verticalAlign: 'middle'
	},
	grid: {
		position: 'relative',
		margin: '20px -5px'
	}
});

import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledRadioButtonsGroup = styled.div`
`;

export const StyledRadioButtonsTitle = styled.p`
	margin-bottom: 0.625rem;
	color: ${colors.mineShaft};
`;

export const StyledRadioButtonsInput = styled.input`
	position: absolute;
  height: 0;
	width: 0;
	opacity: 0;
  cursor: pointer;
`;

export const StyledRadioButtonsCheckmark = styled.span`
	position: absolute;
  top: -0.125rem;
  left: 0;
  height: 1.25rem;
	width: 1.25rem;
	border-radius: 50%;
	background-color: ${colors.gallery};
	
	&:after {
		content: "";
  	position: absolute;
  	display: none;
	}
`;

export const StyledRadioButtonsLabel = styled.label`
	display: block;
  position: relative;
	margin-bottom: 0.875rem;
  padding-left: 1.875rem;
	font-size: 0.75rem;
	color: ${colors.mineShaft};
	cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
	user-select: none;
	
	&:hover {
		${StyledRadioButtonsInput} {
			& ~ ${StyledRadioButtonsCheckmark} {
				background-color: ${colors.silver};
			}
		}
	}

	${StyledRadioButtonsInput} {
		&:checked {
			& ~ ${StyledRadioButtonsCheckmark} {
				background-color: ${colors.dodgerBlue};

				&:after {
					display: block;
				}
			}	
		}
	}

	${StyledRadioButtonsCheckmark} {
		&:after {
			left: 0.5rem;
			top: 0.25rem;
			width: 0.3125rem;
			height: 0.625rem;
			border: solid white;
			border-width: 0 0.125rem 0.125rem 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
`;

import SignIn from '@/containers/Auth/SignIn';
import SignUp from '@/containers/Auth/SignUp';
import ResetPassword from '@/containers/Auth/ResetPassword';
import ChangePassword from '@/containers/Auth/ChangePassword';
import UserActivate from '@/containers/Auth/UserActivate';
import ResendActivation from '@/containers/Auth/ResendActivation';

const routes = [
	{
    path: '/',
		component: SignIn,
		meta: {
			name: 'Sign In'
		}
	},
	{
    path: '/sign-in',
		component: SignIn,
		meta: {
			name: 'Sign In'
		}
	},
	{
    path: '/sign-up',
		component: SignUp,
		meta: {
			name: 'Sign Up'
		}
	},
	{
    path: '/reset-password',
		component: ResetPassword,
		meta: {
			name: 'Reset password'
		}
	},
	{
    path: '/change-password',
		component: ChangePassword,
		meta: {
			name: 'Change password'
		}
	},
	{
    path: '/user-activate',
		component: UserActivate,
		meta: {
			name: 'User activate'
		}
	},
	{
    path: '/resend-activation',
		component: ResendActivation,
		meta: {
			name: 'Resend activation'
		}
	}
];

export { routes };

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router'
import {
  Route,
  Switch
} from 'react-router-dom';

import config from '@/config';
import { getAccountInfoRequest } from '@/actions/account';
import {
  initCustomDomain, 
  getCustomDomainRequest
} from '@/actions/customDomain';

import {
  authRoutes, 
  secretRoutes,
  history,
  AuthRoute,
  SecretRoute
} from '@/routes/index';

import { 
  StyledApp, 
  StyledAppWrapper 
} from '@/components/Styled/App/index';

import NotFound from '@/containers/NotFound';
import WrappedAdmin from '@/components/WrappedAdmin/index';
import Sidebar from '@/components/Sidebar/index';
import HeaderAdmin from '@/components/Headers/HeaderAdmin/index';
import Footer from '@/components/Footer/index';
import Feedback from '@/components/Feedback/index';
import Toasts from '@/components/Toasts/index';
import ScrollToTop from '@/components/ScrollToTop/index';
import ErrorBoundary from '@/v2/shared/components/ErrorBoundary/ErrorBoundary';
import ApplicationEnvMessage from '@/v2/shared/components/ApplicationEnvMessage/ApplicationEnvMessage';

class App extends Component<any> {
  componentDidMount () {
    const { token, getAccountInfoRequest, initCustomDomain, getCustomDomainRequest } = this.props;

    if (token) {
      getAccountInfoRequest();
    }

    if (config.CUSTOM_DOMAIN) {
      initCustomDomain();
      getCustomDomainRequest();
    }
  }

  componentDidUpdate (prevProps) {
    const { token, getAccountInfoRequest } = this.props;

    if (!prevProps.token && token && prevProps.token !== token) {
      getAccountInfoRequest();
    }
  }

  render() {
    const { token } = this.props;

    return (
      <>
        <ApplicationEnvMessage />
        <StyledApp>
          <Router history={history}>
            <ScrollToTop>
              <Fragment>
                {token &&
                  <Fragment>
                    <Sidebar />
                    <Feedback />
                  </Fragment>
                }
                <StyledAppWrapper>
                  {token &&
                    <HeaderAdmin />
                  }
                  <ErrorBoundary>
                    <Switch>
                      {authRoutes.map((route, key) => (
                        <AuthRoute
                          key={route.meta.name}
                          exact={key === 0}
                          path={route.path}
                          component={route.component}
                          authenticated={token}
                        />
                      ))};
                      {secretRoutes.map(route => (
                        <SecretRoute
                          key={route.meta.name}
                          path={route.path}
                          component={WrappedAdmin(route.component, route.request)}
                          authenticated={token}
                        />
                      ))};
                      
                      <Route component={NotFound} />
                    </Switch>
                  </ErrorBoundary>
                  {token &&
                    <Footer />
                  }
                </StyledAppWrapper>
              </Fragment>
            </ScrollToTop>
          </Router>
          <Toasts />
        </StyledApp>
      </>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token
});

const mapDispatchToProps = {
  getAccountInfoRequest,
  initCustomDomain,
  getCustomDomainRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	root: {
		position: 'relative'
	},
	loader: {
		position: 'fixed',
		top: 60,
		left: 280,
		right: 0,
		zIndex: 50,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 20,
		boxShadow: '0 1px 10px 1px rgb(0 0 0 / 10%)',
		backgroundColor: '#fff',

		'& .rs-progress-line': {
			width: 350
		},
		'& .rs-loader .rs-loader-content': {
			fontSize: 13
		},
		'& .rs-loader .rs-loader-spin': {
			position: 'relative',
			top: 2
		},
		'& .rs-progress-info-status': {
			fontSize: 14
		}
	}
});

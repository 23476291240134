import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Toggle } from 'rsuite';

import { LeadCaptureProps } from './types';
import { selectLeadCapture } from '../../selectors';

const LeadCapture = ({ id, onChange }: LeadCaptureProps): ReactElement => {
  const leadCapture = useSelector(selectLeadCapture(id));

  const handleChange = (value: string, selected: boolean) => {
    onChange({ ...leadCapture, [value]: selected });
  };

  return (
    <>
      <label style={{ marginRight: 10 }}>Enabled</label>
      <Toggle 
        size="lg"
        checkedChildren="On" 
        unCheckedChildren="Off"
        defaultChecked={leadCapture?.enabled}
        style={{ width: 80 }}
        onChange={(selected) => handleChange('enabled', selected)}
      />
      <label style={{ marginLeft: 20, marginRight: 10 }}>Mandatory</label>
      <Toggle 
        size="lg"
        checkedChildren="On" 
        unCheckedChildren="Off"
        defaultChecked={leadCapture?.mandatory}
        style={{ width: 80 }}
        onChange={(selected) => handleChange('mandatory', selected)}
      />
    </>
	);
};

export default LeadCapture;

import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledHeader = styled.div`
	position: sticky;
  top: 0;
  z-index: 505;
  display: flex;
  align-items: center;
	height: 3.75rem;
  width: 100%;
  padding: 0 1.25rem;
  border-bottom: 0.0325rem solid ${colors.gallery};
  background-color: ${colors.athensGray};
`;

export const StyledHeaderLogo = styled.a`
  display: block;
  font-size: 0.9375rem;
	font-weight: 500;
  color: ${colors.catalinaBlue};
`;

export const StyledHeaderLogoImage = styled.img`
  display: block;
  width: 6.875rem;
`;

export const StyledHeaderTour = styled.div`
  margin-left: auto;
  text-align: right;
`;

export const StyledHeaderTourLabel = styled.div`
  margin-bottom: 0.125rem;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.catalinaBlue};
`;

export const StyledHeaderTourName = styled.div`
  padding: 0.125rem 0.3125rem;
  border-radius: 0.125rem;
  background-color: ${colors.amaranth};
  font-size: 0.75rem;
  font-weight: 500;
  color: ${colors.white};
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Badge, Button, ButtonToolbar } from 'rsuite';

import { useStyles } from './styles';
import layoutData from './data.json';
import TemplateDetailsModal from '../TemplateDetails/TemplateDetails';
import { LayoutCardName, LayoutCardsProps } from './types';
import { ThemeModeName } from '../ThemeMode/types';
import { selectLayout } from '../../selectors';

// @ts-ignore
import classicLightImg from './images/rock-light.png';
// @ts-ignore
import classicDarkImg from './images/rock-dark.png';
// @ts-ignore
import goldLightImg from './images/paper-light.png';
// @ts-ignore
import goldDarkImg from './images/paper-dark.png';
// @ts-ignore
import modernLightImg from './images/modern-light.png';
// @ts-ignore
import modernDarkImg from './images/modern-dark.png';
// @ts-ignore
import oceanLightImg from './images/template-new.png';
// @ts-ignore
import oceanDarkImg from './images/template-new.png';

const imageList = {
  'rock-light': classicLightImg,
  'rock-dark': classicDarkImg,
  'paper-light': goldLightImg,
  'paper-dark': goldDarkImg,
  'modern-light': modernLightImg,
  'modern-dark': modernDarkImg,
  'ocean-light': oceanLightImg,
  'ocean-dark': oceanDarkImg
};

const LayoutCards = ({ id, mode, onChange }: LayoutCardsProps): ReactElement => {
  const classes = useStyles();

  const layout = useSelector(selectLayout(id));

  const [ activeLayout, setActiveLayout ] = useState<LayoutCardName>(layout);
  const [ activeLayoutDetails, setActiveLayoutDetails ] = useState<LayoutCardName>(null);

  useEffect(() => {
    onChange(activeLayout, layoutData.find(item => item.value === activeLayout)?.availableThemeModes);
  }, []);

  const handleClick = (value: LayoutCardName, availableThemeModes: string[]) => {
    setActiveLayout(value);
    onChange(value, availableThemeModes);
  };

  return (
    <div className={classes.container}>
      {layoutData.map(({ value, label, info, active,  availableThemeModes, disabledDemo, lightUrl, darkUrl }) => {
        const isLightMode = mode === ThemeModeName.Light;
        const image = imageList[`${value}-${isLightMode ? ThemeModeName.Light : ThemeModeName.Dark}`];

        return (
          <div 
            key={value} 
            className={
              classnames(
                classes.card,
                {[classes.activeCard]: activeLayout === value},
                {[classes.disabledCard]: !active}
              )
            }
          >
            {info &&
              <Badge content={info} />
            }
            <div onClick={() => active && handleClick(value as LayoutCardName, availableThemeModes)}>
              <div className={classes.name}>{label}</div>
              <div className={classes.imageWrapper}>
                <img src={image} />
              </div>
            </div>
            {/* <ButtonToolbar>
              <Button 
                appearance={activeLayout === value ? 'primary' : 'default'}
                size="sm"
                disabled={!active}
                onClick={() => setActiveLayoutDetails(value as LayoutCardName)}
              >
                View details
              </Button>
              <Button 
                appearance={activeLayout === value ? 'primary' : 'default'}
                size="sm" 
                href={isLightMode ? lightUrl : darkUrl}
                target="_blank"
                disabled={!active || disabledDemo}
              >
                View demo
              </Button>
            </ButtonToolbar> */}
          </div>
        )})}

      <TemplateDetailsModal
        show={!!activeLayoutDetails}
        detailsKey={activeLayoutDetails}
        onClose={() => setActiveLayoutDetails(null)}
      />
    </div>
	);
};

export default LayoutCards;

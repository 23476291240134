import React, { PureComponent } from 'react';
import { MdDeleteForever } from 'react-icons/md';

import { 
  StyledList,
  StyledItem,
  StyledItemName,
  StyledItemBtnRemove
} from './styles';

type Props = {
	items: Array<Object>
};

class PermissionList extends PureComponent<Props & any> {
	handleRemoveItem = username => event => {
		this.props.onRemoveItem(username);
	}
	
  render() {
    const { items, creatorId, userId, userName, loading } = this.props;

    return (
			<StyledList>
				{items.map(item => (
					<StyledItem key={item.username}>
						<StyledItemName>{item.username}</StyledItemName>
						{creatorId === userId &&
							<StyledItemBtnRemove 
								disabled={loading || userName === item.username}
								onClick={this.handleRemoveItem(item.username)}
							>
								<MdDeleteForever />
							</StyledItemBtnRemove>
						}
					</StyledItem>
				))}
			</StyledList>
    )
  }
}

export default PermissionList;

// APP types
export const APP_RESET = 'APP_RESET';

// Auth types
export const AUTH_REQUEST_SIGNIN = 'AUTH_REQUEST_SIGNIN';
export const AUTH_REQUEST_SIGNUP = 'AUTH_REQUEST_SIGNUP';
export const AUTH_REQUEST_SIGNOUT = 'AUTH_REQUEST_SIGNOUT';
export const AUTH_REQUEST_USER_ACTIVATE = 'AUTH_REQUEST_USER_ACTIVATE';
export const AUTH_REQUEST_RESEND_ACTIVATION = 'AUTH_REQUEST_RESEND_ACTIVATION';
export const AUTH_REQUEST_RESET_PASSWORD = 'AUTH_REQUEST_RESET_PASSWORD';
export const AUTH_REQUEST_CHANGE_PASSWORD = 'AUTH_REQUEST_CHANGE_PASSWORD';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

// Tours types
export const TOURS_REQUEST_FETCH = 'TOURS_REQUEST_FETCH';
export const TOURS_REQUEST_GET = 'TOURS_REQUEST_GET';
export const TOURS_SUCCESS_GET = 'TOURS_SUCCESS_GET';
export const TOURS_FAILURE_GET = 'TOURS_FAILURE_GET';

export const TOURS_REQUEST_ADD = 'TOURS_REQUEST_ADD';
export const TOURS_SUCCESS_ADD = 'TOURS_SUCCESS_ADD';
export const TOURS_FAILURE_ADD = 'TOURS_FAILURE_ADD';

export const TOURS_REQUEST_REMOVE = 'TOURS_REQUEST_REMOVE';
export const TOURS_SUCCESS_REMOVE = 'TOURS_SUCCESS_REMOVE';
export const TOURS_FAILURE_REMOVE = 'TOURS_FAILURE_REMOVE';

// Tour types
export const TOUR_REQUEST_GET = 'TOUR_REQUEST_GET';
export const TOUR_SUCCESS_GET = 'TOUR_SUCCESS_GET';
export const TOUR_FAILURE_GET = 'TOUR_FAILURE_GET';

export const TOUR_REQUEST_SAVE = 'TOUR_REQUEST_SAVE';
export const TOUR_SUCCESS_SAVE = 'TOUR_SUCCESS_SAVE';
export const TOUR_FAILURE_SAVE = 'TOUR_FAILURE_SAVE';

export const TOUR_REQUEST_CHANGE_ADDRESS = 'TOUR_REQUEST_CHANGE_ADDRESS';
export const TOUR_SUCCESS_CHANGE_ADDRESS = 'TOUR_SUCCESS_CHANGE_ADDRESS';
export const TOUR_FAILURE_CHANGE_ADDRESS = 'TOUR_FAILURE_CHANGE_ADDRESS';

// Account types
export const ACCOUNT_REQUEST_GET_INFO = 'ACCOUNT_REQUEST_GET_INFO';
export const ACCOUNT_SUCCESS_GET_INFO = 'ACCOUNT_SUCCESS_GET_INFO';
export const ACCOUNT_REQUEST_SAVE_INFO = 'ACCOUNT_REQUEST_SAVE_INFO';
export const ACCOUNT_SUCCESS_SAVE_INFO = 'ACCOUNT_SUCCESS_SAVE_INFO';
export const ACCOUNT_REQUEST_CHANGE_PASSWORD = 'ACCOUNT_REQUEST_CHANGE_PASSWORD';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'ACCOUNT_FAILURE';

// Permissions types
export const PERMISSIONS_REQUEST_GET = 'PERMISSIONS_REQUEST_GET';
export const PERMISSIONS_SUCCESS_GET = 'PERMISSIONS_SUCCESS_GET';
export const PERMISSIONS_FAILURE_GET = 'PERMISSIONS_FAILURE_GET';

export const PERMISSIONS_REQUEST_ADD = 'PERMISSIONS_REQUEST_ADD';
export const PERMISSIONS_SUCCESS_ADD = 'PERMISSIONS_SUCCESS_ADD';
export const PERMISSIONS_FAILURE_ADD = 'PERMISSIONS_FAILURE_ADD';

export const PERMISSIONS_REQUEST_REMOVE = 'PERMISSIONS_REQUEST_REMOVE';
export const PERMISSIONS_SUCCESS_REMOVE = 'PERMISSIONS_SUCCESS_REMOVE';
export const PERMISSIONS_FAILURE_REMOVE = 'PERMISSIONS_FAILURE_REMOVE';

// Agents types
export const AGENTS_REQUEST_FETCH = 'AGENTS_REQUEST_FETCH';

export const AGENTS_REQUEST_GET = 'AGENTS_REQUEST_GET';
export const AGENTS_SUCCESS_GET = 'AGENTS_SUCCESS_GET';
export const AGENTS_FAILURE_GET = 'AGENTS_FAILURE_GET';

export const AGENTS_REQUEST_SAVE = 'AGENTS_REQUEST_SAVE';
export const AGENTS_SUCCESS_SAVE = 'AGENTS_SUCCESS_SAVE';
export const AGENTS_FAILURE_SAVE = 'AGENTS_FAILURE_SAVE';

export const AGENTS_REQUEST_REMOVE = 'AGENTS_REQUEST_REMOVE';
export const AGENTS_SUCCESS_REMOVE = 'AGENTS_SUCCESS_REMOVE';
export const AGENTS_FAILURE_REMOVE = 'AGENTS_FAILURE_REMOVE';

// Feedback types
export const FEEDBACK_REQUEST_SEND = 'FEEDBACK_REQUEST_SEND';
export const FEEDBACK_SUCCESS_SEND = 'FEEDBACK_SUCCESS_SEND';
export const FEEDBACK_FAILURE_SEND = 'FEEDBACK_FAILURE_SEND';

// Subscriptions types
export const SUBSCRIPTION_REQUEST_CHECK = 'SUBSCRIPTION_REQUEST_CHECK';
export const SUBSCRIPTION_SUCCESS_CHECK = 'SUBSCRIPTION_SUCCESS_CHECK';
export const SUBSCRIPTION_FAILURE_CHECK = 'SUBSCRIPTION_FAILURE_CHECK';

// Layout
export const LAYOUT_PULL_SIDEBAR = 'LAYOUT_PULL_SIDEBAR';
export const LAYOUT_LOADING_CONTENT_ON = 'LAYOUT_LOADING_CONTENT_ON';
export const LAYOUT_LOADING_CONTENT_OFF = 'LAYOUT_LOADING_CONTENT_OFF';
export const LAYOUT_REQUEST_FAKE = 'LAYOUT_REQUEST_FAKE';
export const LAYOUT_SUCCESS_FAKE = 'LAYOUT_SUCCESS_FAKE';

// Toasts types
export const TOAST_ADD = 'TOAST_ADD';
export const TOAST_REMOVE = 'TOAST_REMOVE';

// UI types
export const CUSTOM_DOMAIN_INIT = 'CUSTOM_DOMAIN_INIT';
export const CUSTOM_DOMAIN_REQUEST_GET = 'CUSTOM_DOMAIN_REQUEST_GET';
export const CUSTOM_DOMAIN_SUCCESS_GET = 'CUSTOM_DOMAIN_SUCCESS_GET';

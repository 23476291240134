import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toggle } from 'rsuite';

import { useStyles } from './styles';
import pagesData from './data.json';
import { PagesProps } from './types';
import { selectPages } from '../../selectors';

const Pages = ({ id, onChange }: PagesProps): ReactElement => {
  const classes = useStyles();

  const pages = useSelector(selectPages(id));
  const { videoSlideshow } = useSelector((state: any) => state?.account?.info);

  // @ts-ignore
  const [ list, setList ] = useState<{key: string, value: boolean}>(pages);

  useEffect(() => {
    onChange(list);
  }, []);

  const handleChange = (value: string, selected: boolean) => {
    const updatedList = { ...list, [value]: selected };
    
    setList(updatedList);
    onChange(updatedList);
  };

  return (
    <div className={classes.container}>
      {pagesData.map(({ label, value }) => {

        if (videoSlideshow !== true && value === 'videoSlideshow') {
          return;
        }

        return (
          <div
            key={value} 
            className={classes.item}
          >
            <label>{label}</label>
            <Toggle 
              size="lg"
              checkedChildren="On" 
              unCheckedChildren="Off"
              defaultChecked={list[value]} 
              style={{ width: 80 }}
              onChange={(selected) => handleChange(value, selected)}
            />
          </div>
        )
      })}
    </div>
	);
};

export default Pages;

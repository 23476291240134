import PanelForm from "@/components/PanelForm";
import Title from "@/components/Title";
import { redirectTo } from "@/helpers/redirect";
import { history } from "@/routes/index";
import { Photo } from "@/v2/shared/types/Photo";
import { getOptions, generate, regenerate, saveTour } from "./http";
import { PickPhotoModal } from "./components/atoms/PickPhotoModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Icon,
  IconButton,
  Message,
  Notification,
  Panel,
  Progress,
  SelectPicker,
  Toggle,
} from "rsuite";
import uuid from "uuid";
import { mapStagingOptionToValueLabel } from "./utils";
import { useStyles } from "./styles";
import { FullSizePhotoModal } from "@/v2/views/Tour/VirtualStaging/components/atoms/FullSizePhoto";
import clsx from "clsx";
import { saveTourSuccess } from "@/actions/tour";
import { getUserId } from "@/selectors";
import ConfirmModal from "@/v2/shared/components/ConfirmModal/ConfirmModal";

const { Line } = Progress;

const VirtualStaging = ({ id }: { id: string }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.auth.token);
  const currentTour = useSelector((state: any) => state?.tour[id]);
  const creatorId = useSelector((state: any) => state.tour[id]?.creatorId);
  const tourOwner = getUserId() === creatorId;
  const pricing = useSelector(
    (state: any) => state?.account?.info?.pricing?.staging
  );

  const [confirmModal, setConfirmModal] = useState(false);
  const [showPickPhotoModal, setShowPickPhotoModal] = useState(false);
  const [showFullSizePhotoModal, setShowFullSizePhotoModal] = useState(false);
  const [fullSizePhoto, setFullSizePhoto] = useState("");

  const [roomTypes, setRoomTypes] = useState([]);
  const [furnitureTypes, setFurnitureTypes] = useState([]);

  const [removeExistingFurniture, setRemoveExistingFurniture] = useState(false);
  const [addFurniture, setAddFurniture] = useState(false);
  const [watermark, setWatermark] = useState(false);
  const [selectedRoomType, setSelectedRoomType] = useState("");
  const [selectedFurnitureType, setSelectedFurnitureType] = useState("");

  const [savingTour, setSavingTour] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [activeProgressBar, setActiveProgressBar] = useState(false);
  const [percent, setPercent] = useState(0);

  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState("");
  const [generatedPhoto, setGeneratedPhoto] = useState("");
  const [generatedPhotoList, setGeneratedPhotoList] = useState([]);
  const [renderId, setRenderId] = useState("");

  let interval: ReturnType<typeof setInterval>;

  useEffect(() => {
    getOptions(token).then((res) => {
      setRoomTypes(res.data.roomTypes.map(mapStagingOptionToValueLabel));
      setFurnitureTypes(res.data.styles.map(mapStagingOptionToValueLabel));
    });
  }, []);

  useEffect(() => {
    if (!removeExistingFurniture) {
      setAddFurniture(false);
    }
  }, [removeExistingFurniture]);

  useEffect(() => {
    if (!addFurniture) {
      setSelectedRoomType("");
      setSelectedFurnitureType("");
    }
  }, [addFurniture]);

  const skipUrl = `/${redirectTo({
    currentPage: "virtualStaging",
    activePages: currentTour?.settings,
  })}/${id}`;

  const goToOrderPhotos = () => {
    history.push(`/photos/${id}`);
  };

  const isNotValidated = (): boolean =>
    !selectedPhotoUrl ||
    (addFurniture && (!selectedRoomType || !selectedFurnitureType));

  const handleSelected = (photo: Photo) => {
    setShowPickPhotoModal(false);
    setSelectedPhotoUrl(photo.urls.at(0).url);
  };

  const handleShowFullSizePhoto = (photo: string) => {
    setFullSizePhoto(photo);
    setShowFullSizePhotoModal(true);
  };

  const handleStagingGenerate = async () => {
    setGenerating(true);
    startProgress();

    try {
      const res = await generate(
        {
          tourId: id,
          originalName: `${uuid()}_${selectedPhotoUrl}`,
          photo: selectedPhotoUrl,
          roomType: selectedRoomType,
          style: selectedFurnitureType,
          watermark,
          declutter: removeExistingFurniture,
          furniture: addFurniture,
        },
        token
      );

      const photoUrl = res.data.files.at(0).urls.at(0).url;

      setGenerating(false);
      setGeneratedPhoto(photoUrl);
      setGeneratedPhotoList([res.data.files.at(0)]);
      setRenderId(res.data.render_id);
      notifyGeneratedSuccess();
      endProgress();
    } catch (e) {
      setGenerating(false);
      clearProgress();
      notifyGeneratedError();
    }
  };

  const handleStagingRegenerate = async () => {
    setGenerating(true);
    startProgress();

    try {
      const res = await regenerate(
        {
          tourId: id,
          originalName: `${uuid()}_${generatedPhoto}`,
          renderId,
          roomType: selectedRoomType,
          style: selectedFurnitureType,
          watermark,
        },
        token
      );

      const photoUrl = res.data.files.at(0).urls.at(0).url;

      setGenerating(false);
      setGeneratedPhoto(photoUrl);
      setGeneratedPhotoList([res.data.files.at(0), ...generatedPhotoList]);
      setRenderId(res.data.render_id);
      notifyGeneratedSuccess();
      endProgress();
    } catch (e) {
      setGenerating(false);
      clearProgress();
      notifyGeneratedError();
    }
  };

  const clearFilters = () => {
    setRemoveExistingFurniture(false);
    setAddFurniture(false);
    setWatermark(false);
    setSelectedRoomType("");
    setSelectedFurnitureType("");
  };

  const handleClear = () => {
    setSelectedPhotoUrl("");
    setGeneratedPhoto("");
    setGeneratedPhotoList([]);

    clearFilters();
  };

  const notifySaveTourSuccess = () => {
    Notification.success({
      title: "Success",
      description:
        "Great! Your new generated photo has been added to your photo list!",
    });
  };

  const notifyGeneratedSuccess = () => {
    Notification.success({
      title: "Success",
      description: "Awesome! New photo generated!",
    });
  };

  const notifyGeneratedError = () => {
    Notification.error({
      title: "Error",
      description:
        "An error has occurred. Please come back later while are busy fixing it.",
    });
  };

  const startProgress = () => {
    setActiveProgressBar(true);
    setPercent(0);

    let i = 0;
    interval = setInterval(() => {
      i += 2;
      setPercent(i);
      if (i >= 100) {
        clearInterval(interval);
      }
    }, 1000);
  };

  const endProgress = () => {
    setPercent(100);
    clearInterval(interval);

    setTimeout(() => {
      setActiveProgressBar(false);
      setPercent(0);
    }, 1000);
  };

  const clearProgress = () => {
    clearInterval(interval);
    setActiveProgressBar(false);
    setPercent(0);
  };

  const getFilteredPhotos = (): Photo[] => {
    const photos = currentTour?.photos || [];

    return photos.filter((p) => !p.originalName?.includes("staging"));
  };

  const getUpdatedTourPhotos = (): Photo[] => {
    const matchedPhoto = generatedPhotoList.find(
      (p) => p.urls.at(0).url === generatedPhoto
    );

    return [matchedPhoto, ...(currentTour?.photos || [])];
  };

  const handleConfirmSave = () => {
    setConfirmModal(false);
    saveToPhotos();
  };

  const handleSave = () => {
    if (tourOwner && pricing?.price > 0) {
      setConfirmModal(true);
    } else {
      saveToPhotos();
    }
  };

  const saveToPhotos = async () => {
    setSavingTour(true);

    try {
      const res = await saveTour(
        { ...currentTour, photos: getUpdatedTourPhotos() },
        token
      );

      notifySaveTourSuccess();
      setSavingTour(false);
      dispatch(saveTourSuccess(res.data.tour));
    } catch (e) {
      notifyGeneratedError();
      setSavingTour(false);
    }
  };

  return (
    <Panel>
      <Title name="Virtual Staging" />

      <div className={classes.main}>
        {tourOwner && pricing?.price > 0 && (
          <Message
            style={{ marginTop: 30 }}
            type="warning"
            showIcon
            description={
              <p>
                There is <strong>{pricing?.priceAsString}</strong> charge for
                each staged photo that is saved using "Saved to Photos" button.
                <br />
                There is no charge for regenerations and there is no charge if
                you decide not to save the photo.
                <br />
                Charge will happen at the end of month as a part of the
                subscription renewal invoice.
              </p>
            }
          />
        )}

        <div className={classes.photos}>
          {selectedPhotoUrl && (
            <Panel bordered>
              <div className={classes.photoContainer}>
                <ControlLabel>Original</ControlLabel>
                <img
                  src={selectedPhotoUrl}
                  alt="Original"
                  onClick={() => {
                    handleShowFullSizePhoto(selectedPhotoUrl);
                  }}
                />
                <Button
                  appearance="default"
                  size="xs"
                  onClick={() => {
                    handleShowFullSizePhoto(selectedPhotoUrl);
                  }}
                >
                  View full size
                </Button>
              </div>
            </Panel>
          )}

          {generatedPhoto && (
            <Panel bordered>
              <div className={classes.photoContainer}>
                <ControlLabel>Staged</ControlLabel>
                <img
                  src={generatedPhoto}
                  alt="Staged"
                  onClick={() => {
                    handleShowFullSizePhoto(generatedPhoto);
                  }}
                />
                <Button
                  appearance="default"
                  size="xs"
                  onClick={() => {
                    handleShowFullSizePhoto(generatedPhoto);
                  }}
                >
                  View full size
                </Button>
                <Button
                  appearance="primary"
                  size="xs"
                  onClick={handleSave}
                  loading={savingTour}
                >
                  Save to Photos
                </Button>
              </div>

              <div className={classes.carousel}>
                {generatedPhotoList.map((photo) => (
                  <div
                    key={photo.originalName}
                    className={clsx(
                      photo.urls.at(0).url === generatedPhoto &&
                        classes.selectedCarouselItem
                    )}
                    onClick={() => setGeneratedPhoto(photo.urls.at(0).url)}
                  >
                    <img
                      src={photo.urls.at(2).url}
                      alt={`Staged ${photo.originalName}`}
                    />
                  </div>
                ))}
              </div>

              {generatedPhotoList.length === 20 && (
                <div className={classes.photoLimit}>
                  You have generated a limited number (20) of photos.
                </div>
              )}
            </Panel>
          )}
        </div>

        {tourOwner && (
          <Panel bordered>
            <IconButton
              disabled={generating}
              icon={<Icon icon="image" />}
              appearance="primary"
              placement="right"
              onClick={() => {
                setShowPickPhotoModal(true);
                handleClear();
              }}
            >
              Pick Photo
            </IconButton>
          </Panel>
        )}

        <Panel bordered>
          Remove existing furniture{" "}
          <Toggle
            disabled={generating || !!generatedPhoto}
            onChange={setRemoveExistingFurniture}
            checked={removeExistingFurniture}
          />
        </Panel>

        <Panel bordered>
          <div className={classes.addFurniture}>
            Add furniture{" "}
            <Toggle
              onChange={setAddFurniture}
              disabled={generating}
              checked={addFurniture}
            />
          </div>

          {addFurniture && (
            <div className={classes.types}>
              <div>
                <ControlLabel>Room type</ControlLabel>
                <div>
                  <SelectPicker
                    data={roomTypes}
                    disabled={generating}
                    cleanable={false}
                    searchable={false}
                    onChange={(value) => setSelectedRoomType(value)}
                  />
                </div>
              </div>

              <div>
                <ControlLabel>Furniture type</ControlLabel>
                <div>
                  <SelectPicker
                    data={furnitureTypes}
                    disabled={generating}
                    cleanable={false}
                    searchable={false}
                    onChange={(value) => setSelectedFurnitureType(value)}
                  />
                </div>
              </div>
            </div>
          )}
        </Panel>

        <Panel bordered>
          Add Virtual Staging Watermark{" "}
          <Toggle
            disabled={generating}
            onChange={setWatermark}
            checked={watermark}
          />
        </Panel>

        <ButtonToolbar>
          {generatedPhoto && (
            <Button
              appearance="primary"
              size="md"
              loading={generating}
              disabled={isNotValidated() && generatedPhotoList.length === 20}
              onClick={handleStagingRegenerate}
            >
              Regenerate
            </Button>
          )}

          {!generatedPhoto && tourOwner && (
            <Button
              appearance="primary"
              size="md"
              loading={generating}
              disabled={isNotValidated()}
              onClick={handleStagingGenerate}
            >
              Generate
            </Button>
          )}

          <Button
            appearance="default"
            size="md"
            disabled={generating || !generatedPhoto}
            onClick={handleClear}
          >
            Reset
          </Button>
        </ButtonToolbar>

        {!tourOwner && (
          <Message
            type="warning"
            description="Virtual Staging is only available to photographers. Please contact your photographer to enquiry about virtual staging"
          />
        )}

        {activeProgressBar && <Line percent={percent} strokeColor="#ffc107" />}

        {generating && (
          <div className={classes.flashAnimation}>
            <Message
              type="warning"
              description="Virtual staging generation may take a few minutes. Please be patient."
            />
          </div>
        )}
      </div>

      <PanelForm
        primaryButtonText="Order Photos"
        primaryButtonOff={!generatedPhoto}
        onSave={generatedPhoto ? () => goToOrderPhotos() : undefined}
        skipUrl={skipUrl}
        disabled={!generatedPhoto}
        loading={savingTour}
        formProps={{ valid: true }}
      />

      <PickPhotoModal
        show={showPickPhotoModal}
        photos={getFilteredPhotos()}
        onSelected={handleSelected}
        onCancel={() => setShowPickPhotoModal(false)}
      ></PickPhotoModal>

      <FullSizePhotoModal
        show={showFullSizePhotoModal}
        photo={fullSizePhoto}
        onClose={() => setShowFullSizePhotoModal(false)}
      ></FullSizePhotoModal>

      <ConfirmModal
        show={confirmModal}
        message={
          <>
            Please confirm the <strong>{pricing?.priceAsString}</strong> charge
            on your next invoice for each virtually staged photo.
          </>
        }
        onCancel={() => setConfirmModal(false)}
        onConfirm={handleConfirmSave}
      />
    </Panel>
  );
};

export default VirtualStaging;

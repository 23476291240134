import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledSwitchMediaGroup = styled.div`
`;

export const StyledSwitchMediaTitle = styled.p`
	margin-bottom: 0.625rem;
	color: ${colors.mineShaft};
`;

export const StyledSwitchMediaContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;

export const StyledSwitchMediaName = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0.4375rem;
	background-color: ${colors.dodgerBlue};
	font-size: 0.8125rem;
	font-weight: 500;
	text-align: center;
	color: ${colors.white};
`;

export const StyledSwitchMediaInput = styled.input`
	position: absolute;
  height: 0;
	width: 0;
	opacity: 0;
  cursor: pointer;
`;

export const StyledSwitchMediaCheckmark = styled.span`
	position: absolute;
  top: 0;
  left: 0;
	z-index: 10;
  height: 100%;
	width: 100%;
	transition: border-color .2s ease-out;
	border: 0.1875rem solid ${colors.white};
	background-color: rgba(0,0,0,.02);
`;

export const StyledSwitchMediaLabel = styled.label`
	position: relative;
	display: block;
  width: calc(33.3% - 0.625rem);
	height: 21.875rem;
	margin: 0 0.3125rem 0.3125rem;
	background-position: 50% 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: transparent;
	box-shadow: 2px 4px 8px 1px rgba(0,0,0,0.2);
	color: ${colors.mineShaft};
	cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
	user-select: none;
	
	&:hover {
		${StyledSwitchMediaInput} {
			& ~ ${StyledSwitchMediaCheckmark} {
				border-color: ${colors.concrete};
			}
		}
	}

	${StyledSwitchMediaInput} {
		&:checked {
			& ~ ${StyledSwitchMediaName} {
				background-color: ${colors.carnation};
			}	

			& ~ ${StyledSwitchMediaCheckmark} {
				border-color: ${colors.carnation};
			}	
		}
	}
`;

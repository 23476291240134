import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  list: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 30px'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  
    '&:last-child': {
      flexDirection: 'row-reverse'
    }
  },
  name: {
    margin: '0 10px',
    fontSize: 18,
    fontWeight: 700
  },
  circle: {
    width: 34,
    height: 34,
    margin: '0 5px',
    borderRadius: '50%',
    border: '4px solid',
    cursor: 'pointer'
  }  
});


import React, { ReactElement, useEffect, useState } from 'react';
import { Nav, Panel } from 'rsuite';

import Tab from '@/v2/shared/components/Tab/Tab';
import Title from '@/components/Title';
import AccountData from './AccountData';
import Subscription from './Subscription';
import Integrations from './Integrations';
import { useStyles } from './styles';
import { getParams } from '@/v2/shared/utils/queryStringParser';

const TABS = ['account-data', 'subscription', 'integrations'];

const Account: React.FC = (): ReactElement => {
  const classes = useStyles();

  const [ tab, setTab ] = useState(TABS[0]);

  useEffect(() => {
    // ?tab=integrations&status=success&source=googleCalendar
    const tabParam = getParams()?.tab;

    if (tabParam && TABS.includes(tabParam)) {
      setTab(tabParam);
    }
  }, []);

  return (
    <Panel>
      <Title name="Account" />

      <Nav
        className={classes.nav}
        appearance="subtle"
        activeKey={tab}
        onSelect={activeKey => setTab(activeKey)}
      >
        <Nav.Item eventKey={TABS[0]}>Personal Info</Nav.Item>
        <Nav.Item eventKey={TABS[1]}>Subscription</Nav.Item>
        {/* <Nav.Item eventKey={TABS[2]}>Integrations</Nav.Item> */}
      </Nav>

      <Tab activeKey={tab}>
        <Tab.Item eventKey={TABS[0]}>
          <AccountData />
        </Tab.Item>
        <Tab.Item eventKey={TABS[1]}>
          <Subscription />
        </Tab.Item>
        <Tab.Item eventKey={TABS[2]}>
          <Integrations />
        </Tab.Item>
      </Tab>
    </Panel>
  );
};

export default Account;

import React, { Fragment, useEffect, useRef } from 'react';

import config from '@/config';
import { Button } from '@/components/Styled/Buttons/index';

export const WalkScore = (props) => {
  const {
    address,
    wsId,
    wsFormat = 'wide',
    wsWidth = '690',
    wsHeight = '525'
  } = props;

  const scriptUrl = config.WALK_SCORE_URL;
  const wsRef = useRef(null);

  const init = () => {
    (window as any).ws_wsid = wsId;
    (window as any).ws_address = address;
    (window as any).ws_format = wsFormat;
    (window as any).ws_width = wsWidth;
    (window as any).ws_height = wsHeight;

    const script = document.createElement('script');
    script.async = true;
    script.src = scriptUrl;
    wsRef.current.appendChild(script);
  };

  const clear = () => {
    wsRef.current.querySelectorAll('*').forEach(el => el.remove());
  };

  const handleRefresh = (evt) => {
    evt.preventDefault();
    clear();
    init();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Fragment>
      <Button onClick={handleRefresh}>Refresh</Button>
      <div style={{marginBottom: '20px'}} id="ws-walkscore-tile" ref={wsRef}></div>
    </Fragment>
  );
};

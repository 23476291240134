import React from 'react';
import { reduxForm, FieldArray } from 'redux-form'
import { findIndex } from 'lodash';

import PanelForm from '@/components/PanelForm/index';
import { Input } from '@/components/FormUI/index';

import {
  StyledFormList,
  StyledFormListItem
} from '@/components/FormUI/styles';

const validate = (values, props) => {
  const { items } = props;
  const errors = {};
  const arrayErrors = [];

  if (!values.userNames || !values.userNames.length) {
    return false;
  }

  const findUserNameIndex = (list, match) => {
    return findIndex(list, match);
  };

  values.userNames.forEach((user, index) => {
    const userNameErrors = {};

    const isExcludedUserNameOfActiveFormRow =
      findUserNameIndex(values.userNames, {userName: user.userName}) !== index;

    const isUserNameOfFormRow =
      findUserNameIndex(values.userNames, {userName: user.userName}) > -1;

    const isUserNameOfItems = findUserNameIndex(items, {username: user.userName}) > -1;

    const emailExists =
      (isExcludedUserNameOfActiveFormRow && isUserNameOfFormRow) || isUserNameOfItems;

    if (!user || !user.userName) {
      // @ts-ignore
      userNameErrors.userName = 'Required';
      arrayErrors[index] = userNameErrors;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(user.userName)) {
      // @ts-ignore
      userNameErrors.userName = 'Invalid email address';
      arrayErrors[index] = userNameErrors;
    } else if (emailExists) {
      // @ts-ignore
      userNameErrors.userName = 'Email exists';
      arrayErrors[index] = userNameErrors;
    }
  });

  // @ts-ignore
  errors.userNames = arrayErrors;

  return errors;
};

const renderUserNames = ({ fields }) => (
  <StyledFormList>
    {fields.map((user, index) => (
      <StyledFormListItem  key={index}>
        <Input
          name={`${user}.userName`}
          type="email"
          label="Email:"
          btnAdd={fields.length === (index + 1)}
          btnRemove={fields.length > 1}
          onAdd={() => fields.push({})}
          onRemove={() => fields.remove(index)}
        />
      </StyledFormListItem >
    ))}
  </StyledFormList>
);

let FormStatistics = (props) => {
  const { handleSubmit, submitSucceeded, loading } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray name="userNames" component={renderUserNames} />
      <PanelForm loading={loading && submitSucceeded} />
    </form>
  )
};

FormStatistics = reduxForm({
  form: 'statistics',
  validate,
  initialValues: {
    userNames: [{}]
  },
})(FormStatistics)

export { FormStatistics };

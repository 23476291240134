import { 
  PERMISSIONS_REQUEST_GET,
  PERMISSIONS_SUCCESS_GET,
  PERMISSIONS_FAILURE_GET,
  PERMISSIONS_REQUEST_ADD,
  PERMISSIONS_SUCCESS_ADD,
  PERMISSIONS_FAILURE_ADD,
  PERMISSIONS_REQUEST_REMOVE,
  PERMISSIONS_SUCCESS_REMOVE,
  PERMISSIONS_FAILURE_REMOVE
} from '@/actions/types';

export const getPermissionsRequest = id => {
  return {
    type: PERMISSIONS_REQUEST_GET,
    id
  }
};

export const getPermissionsSuccess = data => {
  return {
    type: PERMISSIONS_SUCCESS_GET,
    data
  }
};

export const getPermissionsFailure = () => {
  return {
    type: PERMISSIONS_FAILURE_GET
  }
};

export const addPermissionsRequest = (data, msg) => {
  return {
    type: PERMISSIONS_REQUEST_ADD,
    payload: {
      data,
      msg
    }
  }
};

export const addPermissionsSuccess = () => {
  return {
    type: PERMISSIONS_SUCCESS_ADD
  }
};

export const addPermissionsFailure = () => {
  return {
    type: PERMISSIONS_FAILURE_ADD
  }
};

export const removePermissionsRequest = (data, callback) => {
  return {
		type: PERMISSIONS_REQUEST_REMOVE,
		payload: {
      data,
      callback
    }
	}
};

export const removePermissionsSuccess = () => {
  return {
		type: PERMISSIONS_SUCCESS_REMOVE
  }
};

export const removePermissionsFailure = () => {
  return {
		type: PERMISSIONS_FAILURE_REMOVE
	}
};

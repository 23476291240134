import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ButtonToolbar, ControlLabel, DatePicker, FlexboxGrid, Form, FormGroup, Icon, IconButton, Toggle } from 'rsuite';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isBefore, startOfToday } from 'date-fns';

import { selectOpenHouse } from '../../selectors';
import { OpenHouseForm } from './types';
import { DAY_ITEM, SCHEMA } from './constants';

const OpenHouse = ({
  id,
  onChange
}: {
  id: string;
  onChange: (value: OpenHouseForm) => void;
}): ReactElement => {
  const openHouse = useSelector(selectOpenHouse(id));

  const {
		control,
		watch
	} = useForm<OpenHouseForm>({
		mode: 'onChange',
		shouldFocusError: false,
		resolver: yupResolver(SCHEMA),
		defaultValues: openHouse
	});

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'days'
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      value.days = value.days.filter(day => day.day && day.start && day.end);

      onChange({ ...openHouse, ...value });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div>
      <Form style={{ marginTop: 30 }}>
        <FormGroup>
          <label style={{ marginRight: 10 }}>Enabled</label>
          <Controller
            name={'enabled'}
            control={control}
            render={({ field: { ref, ...field } }) =>
              <Toggle
                size="lg"
                checkedChildren="On"
                unCheckedChildren="Off"
                defaultChecked={openHouse?.enabled}
                style={{ width: 80 }}
                onChange={value => field.onChange(value)}
              />
            }
          />
        </FormGroup>
        <FormGroup>
					{fields?.map((item, index) => (
						<FlexboxGrid align="bottom" key={item.id} style={{ marginBottom: 20 }}>
							<FlexboxGrid.Item colspan={8}>
								<FormGroup style={{ paddingRight: 5 }}>
									<ControlLabel>Day:</ControlLabel>
									<Controller
										name={`days.${index}.day`}
										control={control}
										render={({ field: { ref, ...field } }) =>
											<DatePicker
                        style={{ width: '100%' }}
                        {...(field.value && {value: new Date(field.value)})}
                        format="DD/MM/YYYY"
                        placement='topStart'
                        ranges={[]}
                        disabledDate={date => isBefore(date, startOfToday())}
                        onChange={value => field.onChange(value)}
                      />
										}
									/>
								</FormGroup>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={4}>
								<FormGroup style={{ paddingRight: 5 }}>
									<ControlLabel>Start time:</ControlLabel>
									<Controller
										name={`days.${index}.start`}
										control={control}
										render={({ field: { ref, ...field } }) =>
                      <DatePicker
                        style={{ width: '100%' }}
                        {...(field.value && {value: new Date(field.value)})}
                        format="HH:mm"
                        placement='topStart'
                        ranges={[]}
                        hideMinutes={(minutes) => minutes % 5 !== 0}
                        onChange={value => field.onChange(value)}
                      />
										}
									/>
								</FormGroup>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={4}>
								<FormGroup style={{ paddingRight: 5 }}>
									<ControlLabel>End time:</ControlLabel>
									<Controller
										name={`days.${index}.end`}
										control={control}
										render={({ field: { ref, ...field } }) =>
											<DatePicker
                        style={{ width: '100%' }}
                        {...(field.value && {value: new Date(field.value)})}
                        format="HH:mm"
                        placement='topStart'
                        ranges={[]}
                        hideMinutes={(minutes) => minutes % 5 !== 0}
                        onChange={value => field.onChange(value)}
                      />
										}
									/>
								</FormGroup>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={8}>
								<ButtonToolbar>
									<IconButton
										appearance="primary"
										type="button"
										icon={<Icon icon="plus" />}
										onClick={() => append(DAY_ITEM, {shouldFocus: true})}
									/>
									{fields?.length > 1 &&
										<IconButton
											color="red"
											type="button"
											icon={<Icon icon="minus" />}
											onClick={() => remove(index)}
										/>
									}
								</ButtonToolbar>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					))}
				</FormGroup>
      </Form>
    </div>
	);
};

export default OpenHouse;

import React, { ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FileType } from 'rsuite/lib/Uploader';
import { FlexboxGrid } from 'rsuite';
import { 
	closestCenter,
	DndContext,
	DragOverlay,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { Flipper, Flipped } from 'react-flip-toolkit';

import FileUploader from './FileUploader/FileUploader';
import FileGrid from './FileGrid/FileGrid';
import FileCard from './FileCard/FileCard';
import DragOverlayCard from './DragOverlay/DragOverlayCard';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { FileStatus, File, FileManagerProps } from './types';
import { Photo } from '../../types/Photo';
import ImageCard from './ImageCard/ImageCard';
import ModalEdit from './ModalEdit/ModalEdit';
import { FILE_MANAGER_REMOVE_WARNING } from './constants';

// TODO: turn off card animations upon click on save button
// TODO: fix weird bug during dragover because sometime sorting doesn't work such as we expected
// TODO: small refactor (validation and files functions)

const FileManager = ({
	info,
	url,
	headers,
	timeout,
	fileLimit,
	maxFileSize,
	acceptExtensions,
	files,
	prefix = 'files',
	enableFileName,
	enableEditingFile,
	enableRemovingFile,
	hideToolbar,
	onChangeFiles,
	onStart,
	onEnd,
	onUploadDone,
	onCloseSummary
}: FileManagerProps): ReactElement => {
	const MAX_COLS = 24;
	const COLS_NUMBER_KEY = `hmot_${prefix}_cols`;

	const defaultColsNumber = parseInt(localStorage.getItem(COLS_NUMBER_KEY)) || 4;
	const activationConstraint = { distance: 4 };

	const mouseSensor = useSensor(MouseSensor, {
    activationConstraint
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

	const [ isUploading, setIsUploading ] = useState(false);
	const [ isSelectedAll, setIsSelectedAll ] = useState(false);
	const [ isDragging, setIsDragging ] = useState(false);
	const [ dropIds, setDropIds ] = useState([]);
	const [ draggingCard, setDraggingCard ] = useState(null);
	const [ fileProgress, setFileProgress ] = useState(0);
	const [ fileList, setFileList ] = useState<File[]>([]);
	const [ uploadedFileList, setUploadedFileList ] = useState<File[]>([]);
	const [ selectedFiles, setSelectedFiles ] = useState<File[]>([]);
	const [ lastSelectedIndex, setLastSelectedIndex ] = useState(-1);
	const [ colsNumber, setColsNumber ] = useState(MAX_COLS / defaultColsNumber);
	const [ modalEdition, setModalEdition ] = useState(false);
	const [ modalRemoval, setModalRemoval ] = useState(false);
	const [ activeFileValues, setActiveFileValues ] = useState({ id: null, fileName: null });

	const flipKey = fileList.map(({ id }) => id).join('');

	useEffect(() => {
		if (files) {
			setFileList(files);
			setDropIds(files.map(item => item.id));
		}
	}, [files]);

	useEffect(() => {
		if (fileList.length > 0 && selectedFiles.length === fileList.length) {
			setIsSelectedAll(true);
		} else {
			setIsSelectedAll(false);
		}
	}, [selectedFiles]);

	useEffect(() => {
		if (uploadedFileList.length > 0) {
			onUploadDone && onUploadDone();
		}
	}, [uploadedFileList]);

	const mapFileListToFileGrid = (file: FileType): File => ({
		id: file.fileKey as string,
		name: file.name.split('.').slice(0, -1).join('.'),
		url: URL.createObjectURL(file.blobFile),
		uploaded: false,
		saved: false,
		status: 'inited',
		type: file.blobFile.type,
		size: file.blobFile.size,
		fileData: undefined
	});

	const removeFiles = (files: File[]) => {
		setFileList(files);
		onChangeFiles(files);
		setDropIds(files.map(item => item.id));
	};

	const setUploadDone = () => {
		const updatedFileList = fileList
			.map(file => {
				if (file.status === 'success') {
					file.uploaded = true;
				}
				return file;
			})
			.filter(file => file.status === 'success');

		setFileList(updatedFileList);
		onChangeFiles(updatedFileList);

		if (updatedFileList.length > 0 && onUploadDone) {
			setUploadedFileList(updatedFileList);
		}
	};

	const handleUploaderStart = () => {
		setIsUploading(true);
		setSelectedFiles([]);
		onStart();
	};

	const handleUploaderEnd = () => {
		setIsUploading(false);
		onEnd();
	};

	const handleUpdateFile = (file: FileType, status: FileStatus, responseFile?: Photo) => {
		const currentFile = fileList.find(item => item.id === file.fileKey);

		currentFile.status = status;

		if (responseFile) {
			currentFile.url = typeof responseFile === 'string' ? responseFile : responseFile.urls[2]?.url;
			currentFile.fileData = responseFile;
		}
	};

	const handleUpdateFiles = (files: FileType[]) => {
		setFileList([...fileList, ...files.map(mapFileListToFileGrid)]);
	};

	const handleFileProgress = (percent: number) => {
		setFileProgress(percent);
	};

	const handleUploadDone = () => {
		setTimeout(setUploadDone, 1000);
	};

	const handleAbort = () => {
		setUploadDone();
	};

	const handleChangeCols = (value: number) => {
		setColsNumber(24 / value);

		localStorage.setItem(COLS_NUMBER_KEY, value.toString());
	};

	const handleChangeSelectAll = (value: boolean) => {
		if (value) {
			setSelectedFiles(fileList);
		} else {
			setSelectedFiles([]);
		}
	};

	const handleEditFile = (fileId: string, event) => {
		const fileName = fileList.find(file => file.id === fileId)?.name;

		setActiveFileValues({ id: fileId, fileName });
		setModalEdition(true);
		event.stopPropagation();
	};

	const handleSaveFileName = (name: string) => {
		const updatedFileList = fileList.map(file => {
			if (file.id === activeFileValues.id) {
				file.name = name;
			}
			return file;
		});

		onChangeFiles(updatedFileList);
		setModalEdition(false);
	};

	const handleRemoveFile = (fileId: string, event) => {
		event.stopPropagation();
		removeFiles(fileList.filter(file => file.id !== fileId));
		
		if (selectedFiles.length > 0) {
			setSelectedFiles(selectedFiles.filter(file => file.id !== fileId));
		}
	};

	const handleRemoveSelectedFiles = () => {
		setModalRemoval(true);
	};

	const handleConfirmRemoveFile = () => {
		setModalRemoval(false);
		removeFiles(fileList.filter(f => !selectedFiles.find(sf => sf.id === f.id)));
	};

	const handleSelectionChange = (index: number, cmdKey: boolean, shiftKey: boolean, ctrlKey: boolean) => {
		let newSelectedFiles;
    const fileCards = fileList;
    const fileCard = index < 0 ? '' : fileCards[index];
    const newLastSelectedIndex = index;

		if (!cmdKey && !shiftKey && !ctrlKey) {
			newSelectedFiles = [fileCard];
		} else if (shiftKey) {
			if (lastSelectedIndex >= index) {
        newSelectedFiles = [].concat.apply(
          selectedFiles,
          fileCards.slice(index, lastSelectedIndex)
        );
      } else {
        newSelectedFiles = [].concat.apply(
          selectedFiles,
          fileCards.slice(lastSelectedIndex + 1, index + 1)
        );
      }
		} else if (cmdKey || ctrlKey) {
			const foundIndex = selectedFiles.findIndex(item => item === fileCard);

      if (foundIndex >= 0) {
        newSelectedFiles = [
          ...selectedFiles.slice(0, foundIndex),
          ...selectedFiles.slice(foundIndex + 1)
        ];
      } else {
        newSelectedFiles = [...selectedFiles, fileCard];
      }
		}

		const finalList = fileCards
			? fileCards.filter(fc => newSelectedFiles.find(sf => sf === fc))
			: [];

		setSelectedFiles(finalList);
		setLastSelectedIndex(newLastSelectedIndex);
	};

	const handleDragStart = (event) => {
		const activeId = event?.active?.id;

		setDraggingCard(fileList.find(item => item.id === activeId));
		setIsDragging(true);

		if(!selectedFiles.find(item => item.id === activeId)) {
			setSelectedFiles([]);
		}
	};

	// During dragover event droppable ids have original order. Droppable order is changed after dragend event.
	// Draggables have [2,1,3]
	// Droppables still have [1,2,3]
	// That's why we should save droppable ids after each dragend event (setDropIds) 
	// Then we should search overIndex by event.over.id in dropIds
	const handleDragOver = (event) => {
		const fileCards = fileList.slice(); // new array object

		const { active, over } = event;

		const activeIndex = active?.data?.current?.index;
		const overIndex = dropIds.indexOf(over?.id);

		const activeId = active?.id;
		const overId = fileCards[overIndex]?.id;

		const accepts = active?.data?.current?.accepts;
		const overType = over?.data?.current?.type;

		if (
			overIndex < 0
			|| fileList.length === 0
			|| !accepts?.includes(overType)
			|| !activeId
			|| !overId
		) {
			return;
		}

		let prevRemainingFiles = [];
		let draggedFile = fileCards.filter(item => item.id === activeId);
		let nextRemainingFiles = [];

		if (activeIndex > overIndex) {
			prevRemainingFiles = fileCards.slice(0, overIndex);
			nextRemainingFiles = fileCards.slice(overIndex).filter(item => item.id !== activeId);
		} else if (activeIndex < overIndex) {
			prevRemainingFiles = fileCards.slice(0, overIndex + 1).filter(item => item.id !== activeId);
			nextRemainingFiles = fileCards.slice(overIndex).filter(item => item.id !== overId);
		}

		const mergedList = [...prevRemainingFiles, ...draggedFile, ...nextRemainingFiles];
		const updatedFileList = mergedList.length > 1 ? mergedList : fileCards;

		setFileList(updatedFileList);
		onChangeFiles(updatedFileList);
	};

	const handleDragEnd = (event) => {
		const fileCards = fileList.slice(); // new array object

		const { active, over } = event;

		const overId = over?.id;
		const overIndex = dropIds.indexOf(overId);

		const accepts = active?.data?.current?.accepts;
		const overType = over?.data?.current?.type;

		setIsDragging(false);

		// Selected one item
		if (selectedFiles.length <= 1 || !over || !accepts.includes(overType)) {
			setDropIds(fileCards.map(item => item.id));
			setSelectedFiles([]);
      return false;
    }

		const draggedFiles = selectedFiles;
		const prevRemainingFiles = fileCards
			.slice(0, overIndex)
			.filter(item => !selectedFiles.find(fc => fc.id === item.id));
		const nextRemainingFiles = fileCards
			.slice(overIndex)
			.filter(item => !selectedFiles.find(fc => fc.id === item.id));

		const mergedList = [...prevRemainingFiles, ...draggedFiles, ...nextRemainingFiles];
		const updatedFileList = mergedList.length > 0 ? mergedList : fileCards;

		setDropIds(updatedFileList.map(item => item.id));
		setFileList(updatedFileList);
		setSelectedFiles([]);
		onChangeFiles(updatedFileList);
	};

	return (
		<>
			<FileUploader
				url={url}
				headers={headers}
				info={info}
				timeout={timeout}
				fileLimit={fileLimit}
				maxFileSize={maxFileSize}
				acceptExtensions={acceptExtensions}
				onStart={handleUploaderStart}
				onEnd={handleUploaderEnd}
				onUpdateFile={handleUpdateFile}
				onUpdateFiles={handleUpdateFiles}
				onUpdateFileProgress={handleFileProgress}
				onUploadDone={handleUploadDone}
				onAbort={handleAbort}
				onCloseSummary={onCloseSummary}
			/>
			<FileGrid 
				defaultColsNumber={defaultColsNumber}
				disabled={isUploading}
				disabledRemove={selectedFiles.length === 0}
				numberOfSelectedFiles={selectedFiles.length}
				totalFiles={fileList.length}
				selectedAll={isSelectedAll}
				hideToolbar={hideToolbar}
				onChangeCols={handleChangeCols}
				onChangeSelectAll={handleChangeSelectAll}
				onRemove={handleRemoveSelectedFiles}
			>
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragOver={handleDragOver}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
				>
					<Flipper flipKey={flipKey}>
						<FlexboxGrid>
							{fileList.map((item, index) => (
								<Flipped key={item.id} flipId={item.id}>
									<FlexboxGrid.Item colspan={colsNumber}>
										<FileCard
											index={index}
											id={item.id}
											status={item.status}
											saved={item.saved}
											uploaded={item.uploaded}
											colSize={MAX_COLS / colsNumber}
											fileProgress={fileProgress}
											isSelected={selectedFiles.includes(item)}
											selectedFiles={selectedFiles}
											editable={enableEditingFile}
											removable={enableRemovingFile}
											imageComp={<ImageCard 
																		url={item.url} 
																		name={enableFileName && item.name}
																		type={item.type}
																		uploaded={item.uploaded} 
																		status={item.status} />
											}
											onSelectionChange={handleSelectionChange}
											onEdit={handleEditFile}
											onRemove={handleRemoveFile}
										/>
									</FlexboxGrid.Item>
								</Flipped>
							))}
						</FlexboxGrid>
					</Flipper>

					{createPortal(
						<DragOverlay
							modifiers={[restrictToWindowEdges]}
							dropAnimation={null}
						>
							{isDragging ? (
								<DragOverlayCard
									colSize={MAX_COLS / colsNumber}
									quantity={selectedFiles.length}
									imageComp={<ImageCard 
															url={draggingCard?.url} 
															name={enableFileName && draggingCard?.name} 
															type={draggingCard?.type}
															uploaded={draggingCard?.uploaded}
															status="success" />
									}
								/>
							) : null}
						</DragOverlay>,
						document.body
					)}
				</DndContext>
			</FileGrid>
			<ConfirmModal
				show={modalRemoval}
				message={FILE_MANAGER_REMOVE_WARNING}
				loading={false}
				centerBody
				onCancel={() => setModalRemoval(false)}
				onConfirm={handleConfirmRemoveFile}
			/>
			{enableEditingFile &&
				<ModalEdit
					show={modalEdition}
					fileName={activeFileValues.fileName}
					onClose={() => setModalEdition(false)}
					onSave={handleSaveFileName}
				/>
			}
		</>
	);
};

export default FileManager;

import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import qs from 'qs';

import { subscriptions } from '@/helpers/subscriptions';
import { isMobile, alertMessage } from './SignIn';

import { authRequestSignUp } from '@/actions/auth';

import { FormSignUp } from '@/components/Forms/index';
import { ModalAlert } from '@/components/Modal/index';
import WrappedAuth from '@/components/WrappedAuth/index';
import PromoCode from '@/v2/shared/components/PromoCode/PromoCode';

class SignUp extends Component<any> {
	state = {
    modal: true.valueOf,
		promoId: null
	}

	componentDidMount() {
		ReactGA.event({
			category: 'Page',
			label: 'Sign Up',
			action: 'Clicked'
		});
	}

	closeModal = () => {
		this.setState({
			modal: false
		});
  }

	handlePromoCodeCheckSuccess = (promoCode: string) => {
		this.setState({
			promoId: promoCode
		});
	}

	handleSubmit = values => {
		const { username, password, name, phone, subscription, newsletter = false, country, state } = values;

		this.props.authRequestSignUp(username, password, name, phone, subscription, newsletter, country?.value, state?.value, this.state.promoId);

		ReactGA.event({
			category: 'Sign Up',
			label: 'Send Form',
			action: 'Sent'
		});
	}

	render() {
		const { initialValues, loading } = this.props;
		
		return (
			<Fragment>
				<div style={{ margin: '30px 0 15px' }}>
					<PromoCode onCheckSuccess={this.handlePromoCodeCheckSuccess} />
				</div>
				<FormSignUp 
					onSubmit={this.handleSubmit}
					loading={loading}
					initialValues={initialValues}
				/>
				{isMobile() &&
					<ModalAlert
						message={alertMessage}
						type="error"
						show={this.state.modal}
						onClose={this.closeModal}
					/>
				}
			</Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	// const params = ownProps.location.search;
	// const packageFromUrl = qs.parse(params, {ignoreQueryPrefix: true}).package;
	// const matchedPackage = subscriptions[packageFromUrl];
	// const subscription = matchedPackage ? matchedPackage.value : 'package39';
	const subscription = 'packagePhotographerFree';

	const initialValues = {
		subscription
	};
	
	return {
		loading: state.auth.loading,
		initialValues
	}
};

const mapDispatchToProps = {
  authRequestSignUp
};

export default WrappedAuth(SignUp, mapStateToProps, mapDispatchToProps);

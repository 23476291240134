import styled from 'styled-components';
import { darken } from 'polished'
import { NavLink } from 'react-router-dom';
import { colors } from '@/defaults/index';

import { StyledFormInput } from '@/components/FormUI/styles'

export const StyledFormWrapper = styled.div`
	position: relative;
	z-index: 10;
	width: 100%;
	max-width: 27.5rem;
	margin: 0.625rem auto;
	padding: 1.875rem 2.5rem;
	border-radius: 4px;
	/* border: 1px solid #f2f2f2; */
	background-color: ${colors.white};
	color: ${colors.black};
`;

export const StyledFormTitle = styled.h2`
	margin-bottom: 10px;
	font-size: 1.125rem;
	font-weight: 500;
	text-align: center;
`;

export const StyledFormSubTitle = styled.p`
	margin-bottom: 20px;
	font-size: 0.875rem;
	text-align: center;
`;

export const StyledFormRedirect = styled(NavLink)`
	color: ${colors.blueMarguerite};

	&:hover {
		text-decoration: underline !important;
	}

	&.disabled {
		pointer-events: none;
		cursor: default;
	}
`;

export const StyledFormLink = styled.a`
	display: inline-block;
	margin: 0 0 10px 0;
	font-size: 0.875rem;
	color: ${props => props.color ? props.color : colors.dustyGray};

	&:hover {
		text-decoration: underline !important;
	}

	&:last-child {
		margin: 0;
	}
`;

export const StyledForm = styled.form`
	${StyledFormInput} {
		border-color: ${colors.black};

		&:focus {
			border-color: ${colors.blueMarguerite};
		}
	}
`;

export const StyledFormTabs = styled.div`
	margin-bottom: 0.9375rem;
`;

export const StyledFormTabsBtn = styled.button<any>`
	display: inline-block;
	margin-right: 8px;
	padding: 8px 14px;
	border: none;
	// @ts-ignore
	background-color: ${props => props.active ? darken(0.12, colors.blueMarguerite) : 'transparent'};
	border-radius: 2px;
	// @ts-ignore
	color: ${props => props.dark || props.active ? colors.white : colors.black};
	font-size: 14px;
	outline: none;
	transition: background-color .2s ease-out;
	
	&:hover {
		background-color: ${darken(0.1, colors.blueMarguerite)};
	}
`;

export const StyledFormTabsItem = styled.div<any>`
	// @ts-ignore
	display: ${props => props.active ? 'block' : 'none'};
`;

import React from "react";
import { Button, Modal } from "rsuite";
import { createUseStyles } from "react-jss";

export const FullSizePhotoModal = ({
  show,
  photo,
  onClose,
}: {
  show: boolean;
  photo: string;
  onClose: () => void;
}) => {
  const classes = useStyles();

  return (
    <Modal full backdrop={true} show={show} onHide={onClose} size="lg">
      <Modal.Body>
        <div className={classes.container}>
          <img src={photo} alt="Full size" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="default">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useStyles = createUseStyles({
  container: {
    textAlign: "center",

    "& img": {
      display: "inline-block",
      maxWidth: "100%",
    },
  },
});

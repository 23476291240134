import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  list: {
    padding: '10px 30px 0 50px',

    '& li': {
      marginBottom: 10,
      fontSize: '16px',
      fontWeight: 500,
      color: '#40B7AD'
    },

    '& .rs-icon': {
      position: 'relative',
      top: 1,
      marginRight: 10,
      fontSize: '18px',
      color: '#FF4560'
    }
  }
});


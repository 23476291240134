import * as yup from 'yup';

import { REQUIRED, URL } from '@/v2/shared/components/Form/validationMessages';
import { OptionName, VideoForm } from './types';

export const FORM_NAME = 'video';
export const FORM_GROUP_NAME = 'videos';

export const INITIAL_VALUE = {
  name: OptionName.Youtube,
  youtube: {
    branded: '',
    unbranded: ''
  },
  vimeo: {
    branded: '',
    unbranded: ''
  },
  iframe: {
    branded: '',
    unbranded: ''
  }
};

export const DEFAULT_VALUES: VideoForm = {
  videos: [INITIAL_VALUE]
};

export const SCHEMA = yup.object().shape({
  videos: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      youtube: yup.object().when('name', {
        is: OptionName.Youtube,
        then: yup.object({
          branded: yup.string().nullable().required(REQUIRED).url(URL),
          unbranded: yup.string().nullable().required(REQUIRED).url(URL)
        })
      }),
      vimeo: yup.object().when('name', {
        is: OptionName.Vimeo,
        then: yup.object({
          branded: yup.string().nullable().required(REQUIRED).url(URL),
          unbranded: yup.string().nullable().required(REQUIRED).url(URL)
        })
      }),
      iframe: yup.object().when('name', {
        is: OptionName.Iframe,
        then: yup.object({
          branded: yup.string().nullable().required(REQUIRED).url(URL),
          unbranded: yup.string().nullable().required(REQUIRED).url(URL)
        })
      })
    })
  )
});

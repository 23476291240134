import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import noScroll from 'no-scroll';

import { colors } from '@/defaults/index';

import {
		StyledModal,
		StyledModalTransparent,
		StyledModalDialog,
		StyledModalContent,
		StyledModalBackdrop,
		StyledModalBtnClose,
		transitionOptionsCSS
} from './styles';

import ModalPortal from '../../ModalPortal'

class Modal extends PureComponent<any> {
  componentDidUpdate(prevProps) {
    // @ts-ignore
    if (!prevProps.show && this.props.show) {
      window.addEventListener('keyup', this.handleKeyUp, false);
    } else {
      window.removeEventListener('keyup', this.handleKeyUp, false);
    }
  }

  handleKeyUp = (event) => {
    if (event.key === 'Escape') {
      // @ts-ignore
      this.props.onClose();
    }
  }

	handleEnter = () => {
		noScroll.on();
	}

	handleExited = () => {
		noScroll.off();
  }
  
  render() {
    const { show, onClose, children, color } = this.props;
    const { 
      timeout, 
      transitionNameModal, 
      transitionNameBackdrop 
    } = transitionOptionsCSS;

    return (
      <ModalPortal>
        <CSSTransition
          in={show}
          onEnter={this.handleEnter}
          onExited={this.handleExited}
          unmountOnExit
          timeout={timeout}
          classNames={transitionNameModal}
        >
          <StyledModal>
            <StyledModalTransparent onClick={onClose} />
            <StyledModalDialog>
              <StyledModalContent color={color}>
                <StyledModalBtnClose onClick={onClose}>
                  <FontAwesomeIcon 
                    icon={faTimes}
                    size="lg"
                    color={colors.mineShaft} />
                </StyledModalBtnClose>
                {children}
              </StyledModalContent>
            </StyledModalDialog>
          </StyledModal>
        </CSSTransition>
        <CSSTransition
          in={show}
          unmountOnExit
          timeout={timeout}
          classNames={transitionNameBackdrop}
        >
          <StyledModalBackdrop />
        </CSSTransition>
      </ModalPortal>
    )
  }
}

export { Modal };

import React, { ReactElement, useEffect, useState } from 'react';
import qs from 'qs';
import { Message } from 'rsuite';

import { httpPost } from '@/services/api/http';
import { getBearerAuth } from '@/services/api';
import { ApiPath } from '../../enums';

interface PromoCodeProps {
  token?: string;
  isApply?: boolean;
  codeValue?: string;
  onCheckSuccess?: (promoId: string) => void;
  onCheckError?: () => void;
}

const STORAGE_KEY = 'promoCodeApplied';
const STORAGE_VALUE = '1';

const PromoCode = ({
  token = null,
  isApply,
  codeValue = null,
  onCheckSuccess,
  onCheckError
}: PromoCodeProps): ReactElement => {
  const [ valid, setValid ] = useState(false);
  const [ invalid, setInvalid ] = useState(false);

  useEffect((): void => { 
    checkPromoCode();
    checkPromoCodeApplied();
  }, []);

  useEffect((): void => { codeValue && isApply && initPromosApply(codeValue, token); }, [ codeValue ]);

  const checkPromoCode = async () => {
    const promoId = getPromoId();

    if (!promoId) {
      return;
    }

    const response = await promosCheck(promoId);

    if (response.status === 'success') {
      onCheckSuccess && onCheckSuccess(promoId);
      isApply && initPromosApply(promoId, token);
    } else {
      setInvalid(true);
    }
  };

  const checkPromoCodeApplied = () => {
    if (localStorage.getItem(STORAGE_KEY) === STORAGE_VALUE) {
      setValid(true);
      localStorage.removeItem(STORAGE_KEY);
    }
  };

  const getPromoId = (): string => {
    const params = window.location.search;
	  return qs.parse(params, { ignoreQueryPrefix: true }).promoCode;
  }

  const promosCheck = (promoId: string): Promise<any> => httpPost(ApiPath.PromosCheck, { promoId });

  const promosApply = (promoId: string, token: string): Promise<any> => 
    httpPost(ApiPath.PromosApply, { promoId }, getBearerAuth(token));

  const initPromosApply = (promoId: string, token: string): Promise<any> => 
    promosApply(promoId, token)
      .then(() => {
        localStorage.setItem(STORAGE_KEY, STORAGE_VALUE);
        window.location.replace(location.pathname);
      })
      .catch(() => {
        onCheckError && onCheckError();
        setInvalid(true);
      });

  return (
    <>
      {valid &&
        <Message
          type="success"
          showIcon
          description={
            <p>
              Promo code applied.
            </p>
          }
        />
      }
      {invalid &&
        <Message
          type="warning"
          showIcon
          description={
            <p>
              We are sorry but your promo code has expired.
            </p>
          }
        />
      }
    </>
  );
};

export default PromoCode;

import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { reset } from 'redux-form';

import Api from '@/services/api';

import { history } from '@/routes/index';

import { catchSuccess } from './catchSuccess';
import { catchError } from './catchError';

import { getToken } from '@/selectors/index';

import { 
  PERMISSIONS_REQUEST_GET,
  PERMISSIONS_REQUEST_ADD,
  PERMISSIONS_REQUEST_REMOVE
} from '@/actions/types';

import {
  getPermissionsSuccess,
  getPermissionsFailure,
  addPermissionsSuccess,
  addPermissionsFailure,
  removePermissionsSuccess,
  removePermissionsFailure
} from '@/actions/permissions';

import { getTourSuccess } from '@/actions/tour';

function* getPermissionsRequest({ id }) {
  try {
    const token = yield select(getToken);
    
    const [permissions, tour] = 
      yield all([
        call(Api.getPermissions, id, token), 
        call(Api.getTourById, id, token)
      ]);

    if (tour.status === 'failure') {
      history.push('/access-denied');
    } else {
      yield put(getPermissionsSuccess(permissions.data.perms));
      yield put(getTourSuccess(tour.data.tour));
    }

  } catch (error) {
		yield put(getPermissionsFailure());
    yield catchError(error);
  }
}

function* addPermissionsRequest({ payload: {data, msg} }) {
  try {
    const token = yield select(getToken);
    const {tourId, usernames} = data;
    
    const { message } = yield call(Api.addPermissions, tourId, usernames, token);
    const { data: { perms } } = yield call(Api.getPermissions, tourId, token);

    yield put(getPermissionsSuccess(perms));
    yield put(addPermissionsSuccess());
    yield put(reset('permissions'));
    yield put(reset('permissionList'));
    yield catchSuccess(message);
  } catch (error) {
    yield put(addPermissionsFailure());
    yield catchError(error);
  }
}

function* removePermissionsRequest({ payload: {data, callback} }) {
  try {
    const token = yield select(getToken);
    const {tourId, username} = data;

    const { message } = yield call(Api.removePermissions, tourId, username, token);
    const { data: { perms } } = yield call(Api.getPermissions, tourId, token);

    callback();

    yield put(getPermissionsSuccess(perms));
    yield put(removePermissionsSuccess());
    yield put(reset('permissions'));
    yield put(reset('permissionList'));
    yield catchSuccess(message);
  } catch (error) {
    yield put(removePermissionsFailure());
    yield catchError(error);
  }
}

export function* getPermissionsRequestSaga() {
  // @ts-ignore
  yield takeLatest(PERMISSIONS_REQUEST_GET, getPermissionsRequest);
}

export function* addPermissionsRequestSaga() {
  // @ts-ignore
  yield takeLatest(PERMISSIONS_REQUEST_ADD, addPermissionsRequest);
}

export function* removePermissionsRequestSaga() {
  // @ts-ignore
  yield takeLatest(PERMISSIONS_REQUEST_REMOVE, removePermissionsRequest);
}

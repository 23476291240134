import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const HistoryContainer = styled.div`
	margin: 25px 0;
`;

export const HistoryTable = styled.div`
	display: table;
	table-layout: fixed;
	width: 100%;
	margin-top: 20px;
`;

export const HistoryRow = styled.div`
	&:nth-child(odd) {
		background-color: ${colors.alabaster};
	}
`;

export const HistoryCol = styled.div`
	display: table-cell;
	vertical-align: middle;
	padding: 10px;
	font-size: 14px;
	color: ${colors.boulder};

	&:first-child {
		width: 150px;
	}
`;

import { LayoutCardName } from '../LayoutCards/types';

export interface TopSectionProps {
  id: string;
  activeLayout: LayoutCardName;
  onSectionChange: (value: TopSectionItem) => void;
  onNumberChange: (value: string | number) => void;
}

export interface TopSectionItem {
  value: string;
  label: string;
}

export enum TopSectionValue {
  FirstPhoto = 'none',
  Slideshow = 'slideshow',
  Video = 'video',
  Tour360 = 'tour360',
  VimeoBackground = 'vimeo-background'
}

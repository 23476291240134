import {
  TOURS_REQUEST_FETCH,
  TOURS_SUCCESS_GET,
	TOURS_FAILURE_GET,
	TOURS_REQUEST_ADD,
	TOURS_SUCCESS_ADD,
	TOURS_FAILURE_ADD,
	TOURS_REQUEST_REMOVE,
	TOURS_SUCCESS_REMOVE,
	TOURS_FAILURE_REMOVE,
	TOUR_REQUEST_CHANGE_ADDRESS,
	TOUR_SUCCESS_CHANGE_ADDRESS,
	TOUR_FAILURE_CHANGE_ADDRESS
} from '@/actions/types';

const defaultState = {
	loading: false,
	fetchLoading: false,
	items: [],
	currentPage: 1,
	perPage: 16,
	total: 1,
	totalPages: 1,
	fetchError: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
		case TOURS_REQUEST_FETCH:
			return {
				...state,
				fetchLoading: true
			};
		case TOURS_REQUEST_ADD:
		case TOURS_REQUEST_REMOVE:
		case TOUR_REQUEST_CHANGE_ADDRESS:
			return {
				...state,
				loading: true
			};
		case TOURS_SUCCESS_ADD:
		case TOURS_SUCCESS_REMOVE:
		case TOURS_FAILURE_ADD:
		case TOURS_FAILURE_REMOVE:
		case TOUR_SUCCESS_CHANGE_ADDRESS:
		case TOUR_FAILURE_CHANGE_ADDRESS:
      return {
				...state,
				loading: false
			};
		case TOURS_SUCCESS_GET:
			const { currentPage, perPage, total, totalPages } = action.items;
			const newItems = action.items.data.filter(item => !item.deleted);
      
      return {
				...state,
				items: newItems,
				currentPage,
				perPage,
				total,
				totalPages,
				fetchLoading: false,
				fetchError: false
			};
		case TOURS_FAILURE_GET:
      return {
				...state,
				fetchLoading: false,
				fetchError: true
			};
    default:
      return state;
  }
};

import { httpPost } from '@/services/api/http';
import { ApiPath } from '@/v2/shared/enums';

export const generate = (tourId: string, template: string, branded: boolean, token: string): Promise<any> =>
	httpPost(
    ApiPath.VideoSlideshowGenerate,
    { 
      tourId, 
      template,
      branded,
      preview: false
    }, 
    { 
      headers: { 
        'Authorization': `Bearer ${token}`
      } 
    }
  );

export const getAgentList = (token: string): Promise<any> =>
	httpPost(
    ApiPath.AgentList,
    null,
    { 
      headers: { 
        'Authorization': `Bearer ${token}`
      } 
    }
  );

export const sendContact = (email: string, name: string, phone: string, message: string): Promise<any> =>
	httpPost(ApiPath.ContactSend, {email, message, name, phone});

import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { 
  StyledHeaderAuth,
  StyledHeaderAuthLogo,
  StyledHeaderAuthImage,
	StyledHeaderName
} from './styles';

// @ts-ignore
import logoImg from '@/assets/logo-rwd.svg';

const HeaderAuth = (): ReactElement => {
	const defaultWWW = 'http://homeontour.com';

	const { customDomain, www, logo, name } = useSelector((state: any) => state.customDomain);

	return (
		<StyledHeaderAuth>
			{customDomain ? renderLogo(logo, www) : renderLogo(logoImg, defaultWWW)}
			{customDomain &&
				<StyledHeaderName>{name}</StyledHeaderName>
			}
		</StyledHeaderAuth>
	);
};

export default HeaderAuth;

const renderLogo = (logo: string, www: string): ReactElement => (
	isLogo(logo) &&
		<StyledHeaderAuthLogo 
			href={www}
			target="_blank"
		>
			<StyledHeaderAuthImage 
				src={logo} 
			/>
		</StyledHeaderAuthLogo>
);

const isLogo = (value: string): boolean => !value.includes('/1x1.png');

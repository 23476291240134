import AgentAccess from '@/containers/Tour/AgentAccess';
import Details from '@/pages/Tour/Details/Details';
import WalkScore from '@/pages/Tour/WalkScore/WalkScore';
import Agent from '@/containers/Tour/Agent';
import Publish from '@/containers/Tour/Publish';
import AccessDenied from '@/containers/AccessDenied';

import Account from '@/v2/views/Account/Account';
// import Orders from '@/v2/views/Orders/Orders';
import Agents from '@/v2/views/Agents/Agents';
import Tours from '@/v2/views/Tours/Tours';
import Portfolio from '@/v2/views/Portfolio/Portfolio';
import Branding from '@/v2/views/Branding/Branding';

import Settings from '@/v2/views/Tour/Settings/Settings';
import Photos from '@/v2/views/Tour/Photos/Photos';
import Floorplan from '@/v2/views/Tour/Floorplan/Floorplan';
import Documents from '@/v2/views/Tour/Documents/Documents';
import Map from '@/v2/views/Tour/Map/Map';
import VideoSlideshow from '@/v2/views/Tour/VideoSlideshow/VideoSlideshow';
import Tour360 from '@/v2/views/Tour/Tour360/Tour360';
import Video from '@/v2/views/Tour/Video/Video';
import VirtualStaging from '@/v2/views/Tour/VirtualStaging/VirtualStaging';
// import Publish from '@/v2/views/Tour/Publish/Publish';

const routes = [
	{
    path: '/account',
		component: Account,
		request: 'fake',
		meta: {
			name: 'Account'
		}
	},
	// {
    // path: '/orders',
	// 	component: Orders,
	// 	request: 'orders',
	// 	meta: {
	// 		name: 'Orders'
	// 	}
	// },
	{
    path: '/agents',
		component: Agents,
		request: 'agents',
		meta: {
			name: 'Agents'
		}
	},
	{
		path: '/branding',
		component: Branding,
		request: 'fake',
		meta: {
			name: 'Branding'
		}
	},
	{
		path: '/portfolio',
		component: Portfolio,
		request: 'fake',
		meta: {
			name: 'Portfolio'
		}
	},
	{
		path: '/tours',
		component: Tours,
		request: 'fake',
		meta: {
			name: 'Tours'
		}
	},
	{
		path: '/settings/:id',
		component: Settings,
		request: 'tour',
		meta: {
			name: 'Settings'
		}
	},
	{
    path: '/agent-access/:id',
		component: AgentAccess,
		request: 'permissions',
		meta: {
			name: 'Agent Access'
		}
	},
	{
    path: '/360-tour/:id',
		component: Tour360,
		request: 'tour',
		meta: {
			name: '360 tour'
		}
	},
  {
    path: '/video/:id',
		component: Video,
		request: 'tour',
		meta: {
			name: 'Video'
		}
	},
	{
    path: '/photos/:id',
		component: Photos,
		request: 'tour',
		meta: {
			name: 'Photos'
		}
	},
	{
    path: '/virtual-staging/:id',
		component: VirtualStaging,
		request: 'tour',
		meta: {
			name: 'Virtual Staging'
		}
	},
	{
		path: '/details/:id',
		component: Details,
		request: 'tour',
		meta: {
			name: 'Details'
		}
	},
	{
    path: '/floorplan/:id',
		component: Floorplan,
		request: 'tour',
		meta: {
			name: 'Floorplan'
		}
	},
	{
    path: '/documents/:id',
		component: Documents,
		request: 'tour',
		meta: {
			name: 'Documents'
		}
	},
	{
		path: '/walk-score/:id',
		component: WalkScore,
		request: 'tour',
		meta: {
			name: 'Walk Score'
		}
	},
	{
    path: '/map/:id',
		component: Map,
		request: 'tour',
		meta: {
			name: 'Map'
		}
	},
	{
    path: '/agent/:id',
		component: Agent,
		request: 'tour',
		meta: {
			name: 'Agent'
		}
	},
	{
    path: '/video-slideshow/:id',
		component: VideoSlideshow,
		request: 'tour',
		meta: {
			name: 'Video Slideshow'
		}
	},
	{
    path: '/publish/:id',
		component: Publish,
		request: 'tour',
		meta: {
			name: 'Publish'
		}
	},
	{
    path: '/access-denied',
		component: AccessDenied,
		request: 'fake',
		meta: {
			name: 'Access denied'
		}
  }
];

export { routes };

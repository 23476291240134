import React, { ReactElement, useState } from 'react';
import useCopy from '@react-hook/copy'
import {
  Button, Checkbox, FlexboxGrid, Input, InputGroup, InputNumber, Notification, Panel
} from 'rsuite';

import { useStyles } from './styles';
import { CustomizeIframeProps } from './types';

const CustomizeIframe = ({ url }: CustomizeIframeProps): ReactElement => {
  const classes = useStyles();

  const [ heroFooter, setHeroFooter ] = useState<boolean>(false);
  const [ transparent, setTransparent ] = useState<boolean>(true);
  const [ fit, setFit ] = useState<boolean>(true);
  const [ width, setWidth ] = useState<number>(700);
  const [ height, setHeight ] = useState<number>(400);

  const disabledHeroFooter = !heroFooter ? '&hero=0&footer=0' : '';
  const enabledTransparent = transparent ? '&transparent=1' : '';
  const iframeUrl = url + disabledHeroFooter + enabledTransparent;
  const iframeFit = fit ? '100%' : width;

  const code =
    `<iframe src="${iframeUrl}" height="${height}" width="${iframeFit}" frameBorder="0"></iframe>`;

  const { copy } = useCopy(code);

  const handleClipboardClick = () => {
    copy();

    Notification.open({
      title: 'Copied to Clipboard',
      description: `${code}`,
      placement: 'bottomEnd',
      duration: 2000,
      style: {
        wordBreak: 'break-word'
      }
    });
  };

  return (
    <>
      <Panel bordered>
        <FlexboxGrid justify="start" className={classes.formRow}>
          <FlexboxGrid.Item>
            <Checkbox
              checked={heroFooter}
              onChange={(value, checked) => setHeroFooter(checked)}
            >
              Display Top and Footer
            </Checkbox>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item>
            <Checkbox
              checked={transparent}
              onChange={(value, checked) => setTransparent(checked)}
            >
              Transparent
            </Checkbox>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item>
            <Checkbox
              checked={fit}
              onChange={(value, checked) => setFit(checked)}
            >
              Fit to window width
            </Checkbox>
          </FlexboxGrid.Item>
          {!fit &&
          <FlexboxGrid.Item>
            <InputGroup>
              <InputGroup.Addon>Width</InputGroup.Addon>
              <InputNumber
                value={width}
                min={0}
                onChange={value => setWidth(Number(value))}
              />
            </InputGroup>
          </FlexboxGrid.Item>
          }
          <FlexboxGrid.Item>
            <InputGroup>
              <InputGroup.Addon>Height</InputGroup.Addon>
              <InputNumber
                value={height}
                min={0}
                onChange={value => setHeight(Number(value))}
              />
            </InputGroup>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Panel>

      <FlexboxGrid justify="end" className={classes.code}>
        <Input
          componentClass="textarea"
          rows={2}
          readOnly
          value={code}
        />
        <Button
          color="cyan"
          size="xs"
          onClick={handleClipboardClick}
        >
          Copy
        </Button>
      </FlexboxGrid>

      <Panel bordered>
        <iframe src={iframeUrl} height={height} width={iframeFit} frameBorder={0}></iframe>
      </Panel>
    </>
  )
};

export default CustomizeIframe;

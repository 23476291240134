import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { darken } from 'polished';
import { colors } from '@/defaults/index';

export const Button = styled.button<any>`
	display: inline-block;
	position: relative;
	margin: 5px 0;
	// @ts-ignore
	padding: ${props => props.small ? '5px 8px' : '9px 12px'};
	border: none;
	border-radius: 2px;
	background-color: ${props => props.color ? props.color : colors.blueMarguerite};
	outline: none;
	cursor: pointer;
	font-size: 15px;
	color: ${colors.white};
	transition: background-color .2s ease-out;
	
	&:hover {
		background-color: ${props => props.color ? darken(0.1, props.color) : darken(0.1, colors.blueMarguerite)};
	}

	& > svg {
		display: inline-block;
		margin-left: 5px;
		font-size: 1.2rem;
		color: ${colors.white};
	}
`;

export const ButtonIcon = styled.button`
	display: inline-block;
	position: relative;
	width: 1.75rem;
	height: 1.75rem;
	border: none;
	border-radius: 50%;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: background-color .2s ease-out;

	& > svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 1.2rem;
		color: ${colors.white};
	}
`;

export const ButtonRedirect = styled(NavLink)<any>`
	display: inline-block;
	padding: 0.5rem 1.125rem;
	border-radius: 0.125rem;
	background-color: ${colors.punch};
	color: ${colors.white};
	line-height: 1.15;
	outline: none;
	transition: background-color .2s ease-out;

	&:hover:not(:disabled) {
		background-color: ${darken(0.1, colors.punch)};
	}

	// @ts-ignore
	${props => props.skippable && css`
		background-color: ${colors.corn};

		&:hover:not(:disabled) {
			background-color: ${darken(0.1, colors.corn)};
		}
	`}
`;

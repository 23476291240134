import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	picker: {
		position: 'relative'
	},
	swatchWrapper: {
		display: 'flex',

		'& > input': {
			border: '1px solid #cdcdcd',
			borderRadius: 8,
			paddingLeft: 8,
			color: '#5d5d5d !important'
		}
	},
	swatch: {
		width: 28,
		height: 28,
		marginRight: 10,
		borderRadius: 8,
		border: '3px solid #fff',
		boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
		cursor: 'pointer'
	},
	popover: {
		position: 'absolute',
		bottom: 'calc(100% + 5px)',
		left: 0,
		borderRadius: 9,
		background: '#fff',
		boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)'
	}
});

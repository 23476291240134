import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ControlLabel, Form, FormGroup, Panel } from 'rsuite';
import { geocodeByAddress } from 'react-google-places-autocomplete';

import { saveTour } from '@/helpers/forms';
import { redirectTo } from '@/helpers/redirect';
import { saveTourRequest } from '@/actions/tour';

import GoogleMaps from '@/v2/shared/components/GoogleMaps/GoogleMaps';
import PanelForm from '@/components/PanelForm';
import Title from '@/components/Title';
import useGoogleMaps from '@/v2/shared/hooks/useGoogleMaps';
import GoogleAutocomplete from '@/v2/shared/components/GoogleAutocomplete/GoogleAutocomplete';

const Map: React.FC = (props: any): ReactElement => {
  const FORM_NAME = 'map';
  
  const { map, id, currentTour, fullAddress, loading } = props;
  const skipUrl = `/${redirectTo({currentPage: FORM_NAME, activePages: currentTour?.settings})}/${id}`;

  const { isLoaded } = useGoogleMaps();
  const [ latLng, setLatLng ] = useState({ lat: 56.130366000000016, lng: -106.39071631249999 });
  const [ zoom, setZoom ] = useState(18);

  useEffect(() => {
    if (map) {
      setLatLng({
        lat: map?.lat,
        lng: map?.lng
      });
      setZoom(map?.zoom);
    }
  }, []);

  useEffect(() => {
    if (!map && isLoaded) {
      setLocationFromAddress(fullAddress);
    }
  }, [isLoaded]);

  const setLocationFromAddress = (address: string) => {
    geocodeByAddress(address)
      .then(results => {
        const geometry = results?.find(item => item?.geometry)?.geometry;

        if (geometry) {
          setLatLng({
            lat: geometry.location.lat(),
            lng: geometry.location.lng()
          });
        }
      });
  };

  const handleAutocompleteChange = (response) => {
    setLocationFromAddress(response?.label);
	};

  const handlePositionChange = (lat: number, lng: number) => {
    setLatLng({ lat, lng });
  };

  const handleZoomChange = (zoom: number) => {
    zoom && setZoom(zoom);
  };

  const handleSave = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, { ...latLng, zoom});
  }

  const handleSaveAndNext = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, { ...latLng, zoom}, true);
  }

  return (
    <Panel>
      <Title name="Map" />
      <Form autoComplete="off">
        <FormGroup style={{ marginBottom: 15 }}>
          <ControlLabel>Address Search</ControlLabel>
          <GoogleAutocomplete
            geocodeByAddressIsOff
            defaultValue={fullAddress}
            onChange={handleAutocompleteChange}
          />
        </FormGroup>
      </Form>
      <GoogleMaps
        lat={latLng?.lat}
        lng={latLng?.lng}
        zoom={zoom}
        height="450px"
        onPositionChange={handlePositionChange}
        onZoomChange={handleZoomChange}
      />
      <div style={{ marginTop: 15 }}></div>
      <PanelForm 
        loading={loading}
        onSave={handleSave}
        onSaveAndNext={handleSaveAndNext}
        skipUrl={skipUrl}
        formProps={{valid: true}}
      />
    </Panel>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const currentTour = state.tour[id];
  const fullAddress = currentTour?.fullAddress;
  const map = currentTour?.map;

  return {
    id,
    currentTour,
    fullAddress,
    map,
    loading: state.tour.loading
  };
};

const mapDispatchToProps = {
  saveTourRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

import React, { Children, cloneElement, Component, isValidElement, ReactElement } from 'react';

import TabItem from '@/v2/shared/components/Tab/TabItem';
import { TabProps } from './types';

export default class Tab extends Component<TabProps> {

  static Item = TabItem;

  render(): ReactElement {
    // @ts-ignore
    const { children, activeKey } = this.props;

    const childrenWithProps = Children.map(children, child => {
      if (isValidElement(child)) {
        // @ts-ignore
        return cloneElement(child, { activeKey })
      }

      return child;
    });

    return (<div className="hmt-tab-content">{childrenWithProps}</div>);
  }
};

import styled, { css } from 'styled-components';
import { darken } from 'polished'
import { colors } from '@/defaults/index';

export const StyledSwitchBoxGroup = styled.div`
`;

export const StyledSwitchBox = styled.div<any>`
	position: relative;
	margin-bottom: 1.25rem;
	background-color: ${colors.white};
	overflow: hidden;

	${props => props.theme && props.theme === 'dark' && css`
		background-color: transparent;

		${StyledSwitchBoxChecked} {
			border-width: 0.0325rem;
			border-color: ${colors.alabaster};
		}

		${StyledSwitchBoxLabel} {
			&:hover {
				${StyledSwitchBoxInput} {
					&:not(:disabled):not(:checked) {
						& ~ ${StyledSwitchBoxChecked} {
							border-color: ${darken(0.2, colors.concrete)};

							&:after {
								background-color: ${darken(0.2, colors.concrete)};
							}
						}
					}
				}
			}
		}

		${StyledSwitchBoxInput} {
			&:checked {
				& ~ ${StyledSwitchBoxChecked} {
					border-color: ${colors.lima};

					&:after {
						background-color: ${colors.lima};
					}
				}
			}
		}

		${StyledSwitchBoxTitle} {
			color: ${colors.white};
		}

		${StyledSwitchBoxContent1} {
			color: ${colors.white};
		}

		${StyledSwitchBoxContent2} {
			color: ${colors.white};
		}
	`}

	// @ts-ignore
	${props => props.disabled && css`
		opacity: .6;

		${StyledSwitchBoxLabel} {
			cursor: not-allowed;
		}
	`}
`;

export const StyledSwitchBoxContainer = styled.div`
	position: relative;
	padding: 1.25rem 1.25rem 1.25rem 2.5rem;
`;

export const StyledSwitchBoxChecked = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0.0625rem solid ${colors.concrete};
	transition: border-color .2s ease-out;
	
	&:after {
		content: '';
		position: absolute;
		top: 0.3125rem;
		left: 0.3125rem;
		height: calc(100% - 0.625rem);
		width: 0.625rem;
		background-color: ${colors.concrete};
		transition: background-color .2s ease-out;
	}
`;

export const StyledSwitchBoxInput = styled.input`
	position: absolute;
  height: 0;
	width: 0;
	opacity: 0;
	
	&:checked {
		& ~ ${StyledSwitchBoxChecked} {
			border-color: ${darken(0.1, colors.blueMarguerite)};

			&:after {
				background-color: ${darken(0.1, colors.blueMarguerite)};
			}
		}
	}
`;

export const StyledSwitchBoxLabel = styled.label`
	display: block;
	cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
	user-select: none;

	&:hover {
		${StyledSwitchBoxInput} {
			&:not(:disabled):not(:checked) {
				& ~ ${StyledSwitchBoxChecked} {
					border-color: ${darken(0.1, colors.concrete)};

					&:after {
						background-color: ${darken(0.1, colors.concrete)};
					}
				}
			}
		}
	}
`;

export const StyledSwitchBoxTitle = styled.div`
	margin: 0.3125rem 0;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1;
	color: ${colors.mineShaft};
`;

export const StyledSwitchBoxContent1 = styled.div`
	margin: 0.3125rem 0;
	font-size: 1.0625rem;
	color: ${colors.mineShaft};
`;

export const StyledSwitchBoxContent2 = styled.div`
	margin: 0.3125rem 0;
	font-size: 0.875rem;
	color: ${colors.dustyGray};
`;

export const StyledSwitchBoxList = styled.ul`
	display: block;
	margin: 0.3125rem 0;
`;

export const StyledSwitchBoxLi = styled.li`
	position: relative;
	margin-left: 0.125rem;
	padding-left: 0.625rem;
	font-size: 0.75rem;

	&:before {
		content: '';
		position: absolute;
		top: 0.375rem;
		left: 0;
		width: 0.25rem;
		height: 0.25rem;
		border-radius: 50%;
		background-color: ${colors.silver};
	}
`;

export const StyledSwitchBoxPrevent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	cursor: pointer;
`;

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Button, Loader, Message, Notification, Progress, Uploader } from 'rsuite';
import { FileType } from 'rsuite/lib/Uploader';
import { isEmpty } from 'lodash';

import { useStyles } from './file-uploader.styles';
import { FileUploaderProps } from '../types';
import UploadSummaryModal from '../UploadSummaryModal/UploadSummaryModal';
import {
	FILE_UPLOADER_VALIDATION_WRONG_EXTENSION, 
	getFileLimitValidationMsg, 
	getMaxFileSizeValidationMsg
} from '../constants';

const { Line } = Progress;

const FileUploader = ({
	info,
	url,
	headers,
	timeout = 240000, // 1000 = 1s -> 240 s = 4 min
	fileLimit = 200,
	maxFileSize = 30, // MB
	acceptExtensions = ['image/jpg', 'image/jpeg', 'image/png'],
	onUpdateFile,
	onUpdateFiles,
	onUpdateFileProgress,
	onUploadDone,
	onAbort,
	onStart,
	onEnd,
	onCloseSummary,
}: FileUploaderProps): ReactElement => {
	const uploaderRef: any = useRef();
	const [ uploadingFiles, setUploadingFiles ] = useState(false);
	const [ uploadDone, setUploadDone ] = useState(false);
	const [ fileCache, setFileCache ] = useState<FileType[]>([]);
	const [ activeFileIndex, setActiveFileIndex ] = useState<number | null>(null);
	const [ fileProgress, setFileProgress ] = useState(0);
	const [ uploadedFilesNumber, setUploadedFilesNumber ] = useState(0);
	const [ rejectedFilesNumber, setRejectedFilesNumber ] = useState(0);
	const [ numberOfWrongFilesSize, setNumberOfWrongFilesSize ] = useState(0);
	const [ numberOfWrongFilesExtension, setNumberOfWrongFilesExtension ] = useState(0);
	const [ numberOfServerErrors, setNumberOfServerErrors ] = useState(0);
	const [ summary, setSummary ] = useState(false);

	const classes = useStyles();

	useEffect(() => {
		if (activeFileIndex !== null) {
			startUpload(fileCache[activeFileIndex]);
		}
	}, [activeFileIndex]);

	useEffect(() => {
		if (uploadDone) {
			setSummary(true);
			onUploadDone();
			setUploadDone(false);
		}
	}, [uploadDone]);

	const showErrorNotify = (title: string, description?: string, order: number = 0) => {
		setTimeout(() => {
			Notification.error({ title, description });
		}, order ? order * 100 : order);
	};

	const isValidFileSize = (file: FileType): boolean => file.blobFile.size <= maxFileSize * 1000000;

	const isValidFileExtension = (file: FileType): boolean => acceptExtensions.length === 0
		? true
		: acceptExtensions.includes(file.blobFile.type);

	const validateFile = (file: FileType): FileType => {
		if (isValidFileSize(file) && isValidFileExtension(file)) {
			return file;
		}
	};

	const getValidatedFileList = (fileList: FileType[]): FileType[] => {
		if (fileList.length > fileLimit) {
			showErrorNotify(getFileLimitValidationMsg(fileLimit));
			return [];
		}

		return fileList.filter(validateFile);
	};

	const catchInvalidFiles = (fileList: FileType[]) => {
		const listOfWrongFileSize = fileList.filter(file => !isValidFileSize(file));
		const listOfWrongFileExtension = fileList.filter(file => !isValidFileExtension(file));

		if (!isEmpty(listOfWrongFileSize)) {
			setNumberOfWrongFilesSize(listOfWrongFileSize.length);

			listOfWrongFileSize.forEach((file, index) => {
				showErrorNotify(file.name, getMaxFileSizeValidationMsg(maxFileSize), index + 1);
			});
		}

		if (!isEmpty(listOfWrongFileExtension)) {
			setNumberOfWrongFilesExtension(listOfWrongFileExtension.length);

			listOfWrongFileExtension.forEach((file, index) => {
				showErrorNotify(file.name, FILE_UPLOADER_VALIDATION_WRONG_EXTENSION, index + 1);
			});
		}
	};

	const updateFileProgress = (percent: number) => {
		const percentNumber = parseInt(percent.toString());

		setFileProgress(percentNumber);
		onUpdateFileProgress(percentNumber);
	};

	const startUpload = (file: FileType) => {
		uploaderRef.current.start(file);
	};

	const reset = () => {
		onEnd();
		setUploadingFiles(false);
		updateFileProgress(0);
		setActiveFileIndex(null);

		uploaderRef.current.cleanInputValue();
	};

	const resetSummary = () => {
		setSummary(false);
		setUploadedFilesNumber(0);
		setRejectedFilesNumber(0);
		setNumberOfWrongFilesSize(0);
		setNumberOfWrongFilesExtension(0);
		setNumberOfServerErrors(0);
	};

	const catchResponse = () => {
		// Upload process is done
		if (fileCache.length === activeFileIndex + 1) {
			reset();
			setUploadDone(true);
		} else {
			setActiveFileIndex(activeFileIndex + 1);
		}
	};

	const handleChange = (fileList: FileType[]) => {
		const validatedFileList = getValidatedFileList(fileList)
			.sort((a: any, b: any) => {
				const x = a.name.toUpperCase();
        const y = b.name.toUpperCase();

				return x - y;
			});

		const invalidFiles = fileList.filter(fl => !validatedFileList.find(vf => vf.fileKey === fl.fileKey));

		if (!isEmpty(invalidFiles)) {
			catchInvalidFiles(invalidFiles);
		}

		if (isEmpty(validatedFileList)) {
			uploaderRef.current.cleanInputValue();
			return;
		}

		onUpdateFiles(validatedFileList);
		setFileCache(validatedFileList);
		setActiveFileIndex(0);
		setUploadingFiles(true);
		onStart();
		setRejectedFilesNumber(rejectedFilesNumber + invalidFiles.length);
	};

	const handleUpload = (file: FileType) => {
		updateFileProgress(0);
		onUpdateFile(file, 'uploading');
	};

	const handleProgress = (percent: number) => {
		updateFileProgress(parseInt(percent.toString()));
	};

	const handleSuccess = (response: any, file: FileType) => {
		onUpdateFile(file, 'success', response?.data?.files[0]);
		setUploadedFilesNumber(uploadedFilesNumber + 1);
		catchResponse();
	};

	const handleError = (reason: any, file: FileType) => {
		setTimeout(() => {
			onUpdateFile(file, 'failure');
			setRejectedFilesNumber(rejectedFilesNumber + 1);
			setNumberOfServerErrors(numberOfServerErrors + 1);
			catchResponse();
		}, 2000);
	};

	const handleCancel = () => {
		const file = fileCache[activeFileIndex];

		if (uploaderRef.current.xhrs[file.fileKey].readyState !== 4) {
			uploaderRef.current.xhrs[file.fileKey].abort();
		}

		resetSummary();
		reset();
		onAbort();
	};

	const handleCloseSummary = () => {
		resetSummary();
		onCloseSummary && onCloseSummary();
	};

	return (
		<>
			{info &&
				<Message 
					style={{ marginBottom: 20 }} 
					showIcon 
					type="info"
					description={info}
				/>
			}
			<Uploader
				ref={uploaderRef}
				action={url}
				headers={headers}
				timeout={timeout}
				autoUpload={false}
				name="files"
				draggable
				multiple
				fileList={[]}
				fileListVisible={false}
				style={{ marginBottom: 10 }}
				onChange={handleChange}
				onUpload={handleUpload}
				onProgress={handleProgress}
				onSuccess={handleSuccess}
				disabled={uploadingFiles}
				onError={handleError}
			>
				<div style={{ lineHeight: '200px' }}>Click or Drag files to this area to upload</div>
			</Uploader>

			{uploadingFiles &&
				<div className={classes.loader}>
					<Loader content={`Uploading ${activeFileIndex + 1} ${fileCache.length > 1 ? 'of ' + fileCache.length + ' files' : 'file'}`} />
					<Line percent={fileProgress} strokeColor="#ffc107" />
					<Button color="red" size="xs" onClick={handleCancel}>Cancel</Button>
				</div>
			}
			<UploadSummaryModal
				show={summary}
				totalUploaded={uploadedFilesNumber}
				totalRejected={rejectedFilesNumber}
				numberOfWrongFilesSize={numberOfWrongFilesSize}
				numberOfWrongFilesExtension={numberOfWrongFilesExtension}
				numberOfServerErrors={numberOfServerErrors}
				onClose={handleCloseSummary}
			/>
		</>
	);
};

export default FileUploader;

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { merge } from 'immutable';
import { get, omit } from 'lodash';

import { history } from '@/routes/index';

import Api from '@/services/api';

import { redirectTo } from '@/helpers/redirect';

import { catchSuccess } from './catchSuccess';
import { catchError } from './catchError';

import { 
  getActiveTour,
  getToken,
  getVideoSlideshow
} from '@/selectors/index';

import { 
  TOUR_REQUEST_GET,
  TOUR_REQUEST_SAVE,
  TOUR_REQUEST_CHANGE_ADDRESS
} from '@/actions/types';

import {
  getTourSuccess,
  saveTourSuccess,
  saveTourFailure,
  changeTourAddressSuccess,
  changeTourAddressFailure
} from '@/actions/tour';

function* getTourRequest({ id }) {
  try {
    const token = yield select(getToken);
    const { status, data } = yield call(Api.getTourById, id, token);

    if (status === 'failure') {
      history.push('/access-denied');
    } else {
      yield put(getTourSuccess(data.tour));
    }
  } catch (error) {
    yield catchError(error);
  }
}

function* saveTourRequest({ payload: { json, options } }) {
  try {
    const token = yield select(getToken);
    const isVideoSlideshow = yield select(getVideoSlideshow);
    const { id } = json;
    const dataFromState = yield select(getActiveTour, id);
    const requestData = merge(dataFromState, json);

    const { message, data } = yield call(Api.saveTour, requestData, token);

    if (options.currentPage === 'notify') {
      const isNotify = get(json, 'settings.enabled') && get(json, 'settings.notifications.agent');

      if (isNotify) {
        yield call(Api.tourNotify, id, token);
      }
    }

    yield put(saveTourSuccess(data.tour));
    yield catchSuccess(message);

    const { redirect, redirectPath, currentPage } = options;

    if (redirect) {
      const nextPage = redirectTo({currentPage, activePages: data.tour.settings, redirectPath, isVideoSlideshow});
      const url = redirectPath
        ? `/${nextPage}`
        : `/${nextPage}/${id}`;

      history.push(url);
    }
  } catch (error) {
    yield put(saveTourFailure());
    yield catchError(error);
  }
}

function* changeTourAddressRequest({ payload: { json, options, callback } }) {
  try {
    const token = yield select(getToken);
    const { id, address, idType } = json;
    const { currentIdType } = options;
    const dataFromState = yield select(getActiveTour, id);
    const jsonData = merge(dataFromState, json);

    const { address: _address, city, zipcode, state, country } = address;
    const fullAddress = `${_address}, ${city}, ${state ? state + ' ' : ''}${zipcode}, ${country.label}`;
    const walkScore = { address: fullAddress };

    let requestData = {
      ...jsonData,
      fullAddress,
      walkScore
    };

    if (currentIdType === 'long' || (currentIdType === 'short' && idType === 'long')) {
      requestData = omit(requestData, 'id')
    }

    const { message, data } = yield call(Api.saveTour, requestData, token);

    callback();

    yield put(changeTourAddressSuccess());
    yield put(saveTourSuccess(data.tour));
    yield catchSuccess(message);

    history.push(`/settings/${data.tour.id}`);
  } catch (error) {
    yield put(changeTourAddressFailure());
    yield put(saveTourFailure());
    yield catchError(error);
  }
}

export function* getTourRequestSaga() {
  // @ts-ignore
  yield takeLatest(TOUR_REQUEST_GET, getTourRequest);
}

export function* saveTourRequestSaga() {
  // @ts-ignore
  yield takeLatest(TOUR_REQUEST_SAVE, saveTourRequest);
}

export function* changeTourAddressRequestSaga() {
  // @ts-ignore
  yield takeLatest(TOUR_REQUEST_CHANGE_ADDRESS, changeTourAddressRequest);
}

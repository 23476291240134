import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledHeaderAuth = styled.div`
	margin-bottom: 15px;

	&:after {
		content: '';
		display: block;
		width: 100%;
		max-width: 5rem;
		height: 1px;
		margin: 1.6875rem auto 0;
		background-color: #e5e5e5;
	}
`;

export const StyledHeaderName = styled.div`
	margin-top: 25px;
	text-align: center;
	font-size: 1.25rem;
`;

export const StyledHeaderAuthLogo = styled.a`
	display: block;
	width: 100%;
	max-width: 15rem;
	margin: 0 auto;
`;

export const StyledHeaderAuthImage = styled.img`
	display: block;
	width: 100%;
`;

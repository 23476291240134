import { DOMHelper } from 'rsuite';
import Shepherd from 'shepherd.js';

const { scrollTop } = DOMHelper;

export const setTutorial = (): any =>
  new Shepherd.Tour({
    useModalOverlay: true,
    keyboardNavigation: false,
    defaultStepOptions: {
      canClickTarget: false
    },
    steps: [
      {
        title: 'Account',
        text: `Edit your basic information including changing your password and phone number.<br>
              Change your subscription package and check how many tours you have left.`,
        attachTo: {
          element: '.shepherd-step-account',
          on: 'right'
        },
        buttons: [getSkipButton(), getNextButton()]
      },
      {
        title: 'Orders',
        text: `Order functionality allows you to create a custom order form on your website and enable 
              your customers to schedule and pay for photoshoots in a few easy steps.`,
        attachTo: {
          element: '.shepherd-step-orders',
          on: 'right'
        },
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Agents',
        text: 'Add/Remove or Edit all the agents your work with.',
        attachTo: {
          element: '.shepherd-step-agents',
          on: 'right'
        },
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Branding',
        text: 'Ensure your branding is properly visible to drive more business.',
        attachTo: {
          element: '.shepherd-step-branding',
          on: 'right'
        },
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Portfolio',
        text: 'Create a portfolio of all your work and share it with your customers.',
        attachTo: {
          element: '.shepherd-step-portfolio',
          on: 'right'
        },
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Tours',
        text: 'This will be your home, all your work will be available here',
        attachTo: {
          element: '.shepherd-step-tours',
          on: 'right'
        },
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Settings',
        text: 'This menu is enabled when editing a tour to allow you to jump between sections for more efficient editing.',
        attachTo: {
          element: '.shepherd-step-settings',
          on: 'right'
        },
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Tour List',
        text: 'Here you will see all your tours. Remember, we never delete or achieve tours so they are always available.',
        attachTo: {
          element: '.shepherd-step-tour-list',
          on: 'top'
        },
        modalOverlayOpeningPadding: 15,
        modalOverlayOpeningRadius: 2,
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Search',
        text: 'You can find your tours by address, agent name or date.',
        attachTo: {
          element: '.shepherd-step-search',
          on: 'bottom'
        },
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 6,
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Create a New Tour',
        text: 'Click here to create a new tour.',
        attachTo: {
          element: '.shepherd-step-create-tour',
          on: 'right'
        },
        modalOverlayOpeningRadius: 6,
        buttons: [getSkipButton(), getBackButton(), getNextButton()]
      },
      {
        title: 'Contact Us',
        text: 'If you have any question don\'t hesitate to reach out.',
        attachTo: {
          element: '.shepherd-step-contact-us',
          on: 'left'
        },
        modalOverlayOpeningRadius: 4,
        buttons: [getBackButton(), getEndButton()]
      }
    ]
  });

const getBackButton = (): any => ({
  text: 'Back',
  action: function() {
    if (
      this.steps.length === 12
      && this.getCurrentStep().options.attachTo.element === '.shepherd-step-introduction-list'
    ) {
      scrollTop(window, 0);
    }

    return this.back();
  }
});

const getNextButton = (): any => ({
  text: 'Next',
  action: function() {
    if (
      this.steps.length === 12
      && this.getCurrentStep().options.attachTo.element === '.shepherd-step-create-tour'
    ) {
      scrollTop(window, (document.querySelector('.shepherd-step-introduction-list') as any).offsetTop);
    }

    return this.next();
  }
});

const getSkipButton = (): any => ({
  classes: 'shepherd-button-secondary',
  text: 'Skip',
  action: function() {
    return this.cancel();
  }
});

const getEndButton = (): any => ({
  text: 'Done',
  action: function() {
    return this.complete();
  }
});

export const tutorialsVideosStep = {
  title: 'Tutorials Videos',
  text: 'Please watch the tutorials videos that provide step-by-step instructions on how to create your first tour.',
  attachTo: {
    element: '.shepherd-step-introduction-list',
    on: 'top'
  },
  buttons: [getSkipButton(), getBackButton(), getNextButton()]
};

import React, { ReactElement, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  ControlLabel,
  Form,
  FormGroup,
  Icon,
  Input,
  List,
  Panel,
  Radio,
  RadioGroup,
} from "rsuite";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Title from "@/components/Title";
import { useStyles } from "./styles";
import { select360Tour } from "./selectors";
import PanelForm from "@/components/PanelForm";
import { redirectTo } from "@/helpers/redirect";
import { saveTour } from "@/helpers/forms";
import { saveTourRequest } from "@/actions/tour";
import ErrorBlock from "@/v2/shared/components/Form/ErrorBlock/ErrorBlock";
import Options from "./data.json";
import { getMappedValueFromObject } from "./utils";
import {
  DEFAULT_VALUES,
  FORM_GROUP_NAME,
  FORM_NAME,
  INITIAL_VALUE,
  SCHEMA,
} from "./constants";
import { TourItem } from "./types";

const Tour360: React.FC = ({ id, saveTourRequest }: any): ReactElement => {
  const classes = useStyles();

  const saving = useSelector((state: any) => state.tour.loading);
  const currentTour = useSelector((state: any) => state.tour[id]);
  const tour360 = useSelector(select360Tour(id));

  // @ts-ignore
  const skipUrl = `/${redirectTo({
    currentPage: FORM_NAME,
    activePages: currentTour.settings,
  })}/${id}`;

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({
    mode: "onChange",
    shouldFocusError: false,
    resolver: yupResolver(SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { fields, append, remove, move } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: FORM_GROUP_NAME,
  });

  useEffect(() => {
    if (tour360?.tours) {
      setValue(FORM_GROUP_NAME, tour360.tours);
    } else if (tour360?.matterport || tour360?.iguide || tour360?.cloudPano) {
      // deprecated
      setValue(FORM_GROUP_NAME, getMappedValueFromObject(tour360));
    }
  }, [tour360]);

  const watchTours = useWatch({
    name: FORM_GROUP_NAME,
    control,
    defaultValue: [INITIAL_VALUE],
  });

  const handleSort = ({ oldIndex, newIndex }) => {
    move(oldIndex, newIndex);
  };

  const handleSave = (data: TourItem[]) => {
    // @ts-ignore
    saveTour({ id, saveTourRequest }, FORM_NAME, data);
  };

  const handleSaveAndNext = (data: TourItem[]) => {
    // @ts-ignore
    saveTour({ id, saveTourRequest }, FORM_NAME, data, true);
  };

  return (
    <Panel>
      <Title name="360 Tour" />

      <Form>
        <List sortable bordered pressDelay={300} onSortEnd={handleSort}>
          {fields.map((item, index) => (
            <List.Item key={index} index={index}>
              <div key={item.id}>
                <FormGroup>
                  <Controller
                    name={`tours[${index}].name`}
                    control={control}
                    render={({ field }) => (
                      <div className={classes.wrapIconAndRadioGroup}>
                        <Icon icon="arrows-alt" size="2x" />
                        <RadioGroup
                          {...field}
                          inline
                          appearance="picker"
                          value={field.value}
                          onChange={(value) => field.onChange(value)}
                        >
                          <Radio value={Options.matterport.id}>
                            {Options.matterport.label}
                          </Radio>
                          <Radio value={Options.iguide.id}>
                            {Options.iguide.label}
                          </Radio>
                          <Radio value={Options.cloudPano.id}>
                            {Options.cloudPano.label}
                          </Radio>
                          <Radio value={Options.zillow.id}>
                            {Options.zillow.label}
                          </Radio>
                        </RadioGroup>
                      </div>
                    )}
                  />
                </FormGroup>
                {Object.values(Options).map(
                  (opt) =>
                    watchTours[index]?.name === opt.id && (
                      <FormGroup key={opt.id}>
                        <ControlLabel>
                          {Options[opt.id].exampleLabel}
                        </ControlLabel>
                        <ControlLabel style={{ marginBottom: 15 }}>
                          <a
                            href={Options[opt.id].instructionUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {Options[opt.id].instructionLabel} &raquo;
                          </a>
                        </ControlLabel>
                        <Controller
                          name={`tours[${index}][${opt.id}]`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <div className={classes.inputGroup}>
                                <Input
                                  placeholder={Options[opt.id].placeholder}
                                  {...field}
                                  value={field.value || ""}
                                />
                                {fields?.length > 1 && (
                                  <Button
                                    color="red"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </div>
                              {opt.id === "matterport" && (
                                <div>
                                  <Checkbox
                                    checked={isAutoplayChecked(field.value)}
                                    onChange={(_value, checked) => {
                                      field.onChange(
                                        setAutoplayParam(field.value, checked)
                                      );
                                    }}
                                  >
                                    Autoplay
                                  </Checkbox>
                                </div>
                              )}
                            </div>
                          )}
                        />
                        <ErrorBlock error={errors?.tours?.[index]?.[opt.id]} />
                      </FormGroup>
                    )
                )}
              </div>
            </List.Item>
          ))}
        </List>
        <FormGroup style={{ marginTop: 30 }}>
          <Button
            appearance="primary"
            type="button"
            onClick={() => append(INITIAL_VALUE)}
          >
            Add new
          </Button>
        </FormGroup>
      </Form>

      <Panel className={classes.panel}>
        <PanelForm
          loading={saving}
          onSave={handleSubmit(handleSave)}
          onSaveAndNext={handleSubmit(handleSaveAndNext)}
          skipUrl={skipUrl}
          formProps={{ valid: true }}
        />
      </Panel>
    </Panel>
  );
};

const mapDispatchToProps = {
  saveTourRequest,
};

export default connect(null, mapDispatchToProps)(Tour360);

function isAutoplayChecked(value: string): boolean {
  return (
    value?.includes("play=1") &&
    value?.includes("ts=0") &&
    value?.includes("lp=1")
  );
}

function setAutoplayParam(value: string, checked: boolean): string {
  if (!value) {
    return checked ? "?play=1&ts=0&lp=1" : "?play=0";
  }

  let newValue = value
    .replace("play=0", "")
    .replace("play=1", "")
    .replace("ts=0", "")
    .replace("ts=1", "")
    .replace("lp=0", "")
    .replace("lp=1", "")
    .replace(/&+$/, "");

  if (checked) {
    return newValue.includes("?")
      ? newValue.concat("&play=1&ts=0&lp=1")
      : newValue.concat("?play=1&ts=0&lp=1");
  } else {
    return newValue;
  }
}

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  nav: {
    margin: '30px 0',

    '& .rs-nav-item > .rs-nav-item-content': {
      padding: '20px 40px'
    }
  },
  tab: {
    '& .rs-panel-heading': {
      padding: '0 0 20px'
    }
  },
  formRow: {
    margin: '0 -5px',

    '& .rs-flex-box-grid-item': {
      marginBottom: 5,
      padding: '0 5px'
    }
  },
  code: {
    position: 'relative',
    marginBottom: 30,
    marginTop: 30,

    '& textarea.rs-input': {
      paddingRight: 65
    },
    '& .rs-btn': {
      position: 'absolute',
      top: 7,
      right: 7,
      zIndex: 5
    }
  }
});

import React, { Component } from 'react';

import { ButtonRedirect } from '@/components/Styled/Buttons/index';

import Title from '@/components/Title/index';

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

class AccessDenied extends Component {
  render() {
    return (
			<GridRow>
				<GridCol>
					<Title name="Access denied" />
					<ButtonRedirect to="/tours">Back to tours</ButtonRedirect>
				</GridCol>
			</GridRow>
    )
  }
}

export default AccessDenied;

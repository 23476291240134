import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  grid: {
		marginTop: 20
  },
	field: {
		width: 300,
		marginLeft: 10
	},
	agentList: {
		margin: '40px 0 50px'
	},
	agentListItem: {
		display: 'flex',
		alignItems: 'center',
		height: 40,

		'&:first-child': {
			paddingLeft: 10
		},

		'&:last-child': {
			paddingRight: 10
		}
	},
	agentListAvatar: {
		'& > .rs-avatar-image': {
			objectFit: 'cover'
		}
	},
	agentListDetails: {
		'& div:nth-child(1)': {
			fontSize: 14,
			fontWeight: 600
		},

		'& div:nth-child(2)': {
			fontSize: 12
		}
	}
});

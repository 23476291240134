import { call, put, takeLatest, select } from 'redux-saga/effects';

import Api from '@/services/api';

import { catchSuccess } from './catchSuccess';
import { catchError } from './catchError';

import { getToken } from '@/selectors/index';

import {
  ACCOUNT_REQUEST_GET_INFO,
  ACCOUNT_REQUEST_SAVE_INFO,
  ACCOUNT_REQUEST_CHANGE_PASSWORD
} from '@/actions/types';

import {
  getAccountInfoSuccess,
  saveAccountInfoSuccess,
  accountSuccess,
  accountFailure
} from '@/actions/account';

function* getAccountInfoRequest() {
  try {
    const token = yield select(getToken);
    
    const { data } = yield call(Api.getAccountInfo, token);
		
    yield put(getAccountInfoSuccess(data.info));
  } catch (error) {
		yield put(accountFailure());
    yield catchError(error);
  }
}

export function* saveAccountInfoRequest({payload: { data }}) {
  try {
    const token = yield select(getToken);

    const { message } = yield call(Api.saveAccountInfo, data, token);

    yield getAccountInfoRequest();

    yield put(saveAccountInfoSuccess());
    yield catchSuccess(message);
  } catch (error) {
    yield put(accountFailure());
    yield catchError(error);
  }
}

export function* requestChangePassword({payload: { username, password }}) {
  try {
    const token = yield select(getToken);

    const { message } = yield call(Api.accountChangePassword, username, password, token);

    // @ts-ignore
    yield put(accountSuccess());
    yield catchSuccess(message);
  } catch (error) {
    yield put(accountFailure());
    yield catchError(error);
  }
}

export function* getAccountInfoRequestSaga() {
  yield takeLatest(ACCOUNT_REQUEST_GET_INFO, getAccountInfoRequest);
}

export function* saveAccountInfoRequestSaga() {
  // @ts-ignore
  yield takeLatest(ACCOUNT_REQUEST_SAVE_INFO, saveAccountInfoRequest);
}

export function* accountRequestChangePasswordSaga() {
  // @ts-ignore
  yield takeLatest(ACCOUNT_REQUEST_CHANGE_PASSWORD, requestChangePassword);
}

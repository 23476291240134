// @flow
import { httpPost, CancelToken } from '@/services/api/http';
import {
  FileUploadEvents,
  FileUploadResponse
} from '../models/ServiceModel';

const FileUploadService = {
  cancel: undefined,
  catchCancel: false,
  destroy: false,

  // @ts-ignore
  startUpload(url: string, files: any, token: string, callback: (res: FileUploadEvents) => FileUploadResponse) {
    this.upload(url, files, token, callback);
  },

  // @ts-ignore
  upload(url: string, files: any, token: string, callback: (res: FileUploadEvents) => FileUploadResponse, index?: number) {
    let startIndexFile = index ? index : 0;
    const data = this.createFormData(files[startIndexFile]);

    httpPost(url, data, {
      cancelToken: new CancelToken(function executor(c) {
        this.cancel = c;
      }.bind(this)),
      headers: {
        'Authorization': `Bearer ${token}`
      },
      timeout: 10 * 60000,
      onUploadProgress: progressEvent => {
        const {loaded, total} = progressEvent;
        const percentage = Math.floor((loaded / total) * 100);

        callback({name: FileUploadEvents.PROGRESS, value: percentage});
      }
    })
      .then(res => {
        callback({name: FileUploadEvents.SUCCESS, value: res.data.files});
      })
      .catch(error => {
        if (error === FileUploadEvents.CANCEL_UPLOADING) {
          this.cancel = undefined;
          this.catchCancel = true;
        } else {
          callback({name: FileUploadEvents.ERROR, value: error});
        }
      })
      .finally(() => {
        if (this.destroy) {
          this.destroy = false;
          this.catchCancel = false;
          return;
        }

        if (this.catchCancel) {
          callback({name: FileUploadEvents.FINALLY, value: null});

          this.catchCancel = false;
          return;
        }

        if (startIndexFile !== files.length - 1) {
          const nextFileIndex = startIndexFile + 1;

          callback({name: FileUploadEvents.NEXT_FILE, value: nextFileIndex});
          this.upload(url, files, token, callback, nextFileIndex);
        } else {
          callback({name: FileUploadEvents.FINALLY, value: null});

          this.cancel = undefined;
        }
      });
  },

  cancelUpload() {
    this.cancel && this.cancel(FileUploadEvents.CANCEL_UPLOADING);
  },

  destroyUpload() {
    if (this.cancel) {
      this.destroy = true;
      this.cancelUpload();
    }
  },

  createFormData(file: any): any {
    let data = new FormData();

    const {name, type} = file;
    const blobFile = new Blob([file], {type});

    data.append('files', blobFile, name);

    return data;
  }
};

export default FileUploadService;

import React from 'react';
import { Field } from 'redux-form';

import withFormGroup from '../withFormGroup';

import {
	StyledSwitchBoxGroup,
	StyledSwitchBox,
	StyledSwitchBoxLabel,
	StyledSwitchBoxContainer,
	StyledSwitchBoxInput,
	StyledSwitchBoxChecked,
	StyledSwitchBoxTitle,
	StyledSwitchBoxContent1,
	StyledSwitchBoxContent2,
	StyledSwitchBoxList,
	StyledSwitchBoxLi,
	StyledSwitchBoxPrevent
} from './styles';

const renderField = ({
	input,
	theme,
	options
}) => (
	<StyledSwitchBoxGroup>
		{options.map(({disabled, value, title, content1, content2, contentList, onPrevent}) => 
			<StyledSwitchBox theme={theme} disabled={disabled} key={value}>
				{onPrevent && value !== input.value &&
					<StyledSwitchBoxPrevent onClick={onPrevent} />
				}
				<StyledSwitchBoxLabel>
					<StyledSwitchBoxContainer>
						{title && <StyledSwitchBoxTitle>{title}</StyledSwitchBoxTitle>}
						{content1 && <StyledSwitchBoxContent1>{content1}</StyledSwitchBoxContent1>}
						{content2 && <StyledSwitchBoxContent2>{content2}</StyledSwitchBoxContent2>}
						{contentList &&
							<StyledSwitchBoxList>
								{contentList.map((item, index) =>
									<StyledSwitchBoxLi key={index}>{item}</StyledSwitchBoxLi>
								)}
							</StyledSwitchBoxList>
						}
						<StyledSwitchBoxInput 
							{...input} 
							type="radio" 
							disabled={disabled} 
							value={value} 
							checked={value === input.value} 
						/>
						<StyledSwitchBoxChecked />
					</StyledSwitchBoxContainer>
				</StyledSwitchBoxLabel>
			</StyledSwitchBox>
		)}
	</StyledSwitchBoxGroup>
);

const FieldComponent = withFormGroup(renderField);

const SwitchBox = ({...rest}) => {
		return (
			<Field
				component={FieldComponent}
				{...rest}
			/>
		)
};

export { SwitchBox };

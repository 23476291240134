import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { first, isEmpty, get } from 'lodash';

import Title from '@/components/Title/index';
import { FormAgents } from '@/components/Forms/index';
import ImageCard from '@/components/ImageCard/index';
import FileUpload from '@/components/FileUpload/FileUpload';
import { Modal } from '@/components/Modal/index';
import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';
import { Paragraph } from '@/components/Styled/Text/index';
import { StyledFormGroup } from '@/components/FormUI/styles';
import { colors } from '@/defaults/index';
import { saveAgentsRequest } from '@/actions/agents';
import { statesUSA } from '@/helpers/statesUSA';
import { provincesCanada } from '@/helpers/provincesCanada';
import {
	AgentProps,
	AgentOwnProps,
	AgentState
} from './models/AgentModel';

class Agent extends Component<AgentProps & any, AgentState & any> {
	apiUrl = '/auth/photos/upload';

  state = {
		logo: null,
		avatar: null,
		banner: null,
  }

  componentDidUpdate(prevProps: any) {
		const { show, initialValues } = this.props;

		if (!prevProps.show && show && initialValues && initialValues.logo) {
			this.updateLogo(initialValues.logo);
		}

		if (prevProps.show && !show) {
			this.updateLogo(null);
		}

		if (!prevProps.show && show && initialValues && initialValues.avatar) {
			this.updateAvatar(initialValues.avatar);
		}

		if (prevProps.show && !show) {
			this.updateAvatar(null);
		}

		if (!prevProps.show && show && initialValues && initialValues.banner) {
			this.updateBanner(initialValues.banner);
		}

		if (prevProps.show && !show) {
			this.updateBanner(null);
		}

  }

	updateLogo = (data: object) => {
    this.setState({logo: data});
	}
	
	updateAvatar = (data: object) => {
    this.setState({avatar: data});
  }

	updateBanner = (data: any) => {
		this.setState({banner: data});
	}

  handleRemoveLogo = () => {
    this.updateLogo(null);
	}
	
	handleRemoveAvatar = () => {
    this.updateAvatar(null);
	}

	handleRemoveBanner = () => {
		this.updateBanner(null);
	}
	
	handleUploadLogo = (files: object[]) => {
		this.updateLogo(files);
	}

	handleUploadAvatar = (files: object[]) => {
		this.updateAvatar(files);
	}

	handleUploadBanner = (files: object[]) => {
		this.updateBanner(files);
	}

  handleSubmit = (values: any) => {
		// @ts-ignore
		const { state } = values;
		const { saveAgentsRequest, onClose, onSaved } = this.props;
		const { logo, avatar, banner } = this.state;

		const ccList = values.ccList.filter(({email}) => email).map(({email}) => email);

		const newValues = {...values, state: state ? state.value : undefined};
		const agent = { ...newValues, logo, avatar, banner, ccList };

		saveAgentsRequest({
			json: agent
		}, null, () => {
			onClose();
			onSaved && onSaved();
    });
  }

	mapInitialValues = (values) => {
		let newValues = {
			...values,
			ccList: values?.ccList?.length > 0
				? values.ccList.map((email: string) => ({email}))
				: [{}]
		};

		return newValues;
	};
  
  render() {
    const { show, onClose, initialValues, selectedCountry, loadingAgents, agentId } = this.props;
    const { logo, avatar, banner } = this.state;

    return (
			<Fragment>
				<Modal
					color={colors.whiteApprox}
					show={show}
					onClose={onClose}
				>
					<GridRow>
						<GridCol>
							<Title name="Agent" />
						</GridCol>
						<GridCol>
							<FormAgents
								onSubmit={this.handleSubmit}
								onClose={onClose}
								initialValues={this.mapInitialValues(initialValues)}
								selectedCountry={selectedCountry}
								loading={loadingAgents}
								id={agentId}
							>
								<StyledFormGroup>
									<GridRow>
										<GridCol>
											<Title 
												subtitle="true" 
												name="Brokerage Logo:" 
											/>
										</GridCol>
										<GridCol col="60">
											<FileUpload
												multiple={false}
												url={this.apiUrl}
												onUpload={this.handleUploadLogo} />
										</GridCol>
										<GridCol col="40">
											{logo &&
												<ImageCard
													image={logo[0].urls[2].url}
													onRemove={this.handleRemoveLogo}
													alt="Logo" />
											}
										</GridCol>
									</GridRow>
									<GridRow style={{marginTop: '0.625rem'}}>
										<GridCol>
											<Title 
												subtitle="true" 
												name="Agent Avatar:" 
											/>
										</GridCol>
										<GridCol col="60">
											<FileUpload 
												multiple={false}
												url={this.apiUrl}
												onUpload={this.handleUploadAvatar} />
										</GridCol>
										<GridCol col="40">
											{avatar &&
												<ImageCard
													image={avatar[0].urls[2].url}
													onRemove={this.handleRemoveAvatar}
													alt="Avatar" />
											}
										</GridCol>
										<GridCol>
											<Paragraph style={{marginTop: '0.9375rem'}}>
												* For optimal results upload square photos<br />
												* Accepted extensions: jpg, jpeg, png<br />
											</Paragraph>
										</GridCol>
									</GridRow>
									{/* <GridRow style={{marginTop: '0.625rem'}}>
										<GridCol>
											<Title
												subtitle="true"
												name="Banner:"
											/>
										</GridCol>
										<GridCol col="60">
											<FileUpload
												multiple={false}
												url={this.apiUrl}
												onUpload={this.handleUploadBanner} />
										</GridCol>
										<GridCol col="40">
											{banner &&
												<ImageCard
													auto
													image={banner[0].urls[2].url}
													onRemove={this.handleRemoveBanner}
													alt="Banner"
												/>
											}
										</GridCol>
									</GridRow> */}
								</StyledFormGroup>
							</FormAgents>
						</GridCol>
					</GridRow>
				</Modal>
			</Fragment>
    )
  }
}

const getState = (selectedCountry: string, state: string): any => {
	const list = selectedCountry === 'usa'
		? statesUSA
		: provincesCanada;

	return first(list.filter(({code}) => code === state));
}

const mapStateToProps = (state: any, ownProps: any): AgentOwnProps => {
	const { agentId, startValues } = ownProps;
	const currentAgent = state.agents.items.filter(({id}) => id === agentId);
	const selectedCountry = get(state.form, 'agents.values.country.value');
	let initialValues = !isEmpty(currentAgent) ? first(currentAgent) : null;
	const ccList = get(first(currentAgent) , 'ccList') || [];

	if (initialValues) {
		const { state } = initialValues as any;
		const newState = getState(selectedCountry, state);

		if (newState) {
			const { code, name } = newState;

			(initialValues as any).state = {value: code, label: name};
		}
	}

	// Use only if initialValues is null
	if (startValues) {
		initialValues = startValues;
	}

	// if (initialValues) {
	// 	(initialValues as any).ccList = ccList.length > 0
	// 		? ccList.map((email: string) => ({email}))
	// 		: [{}];
	// } else {
	// 	initialValues = { ccList: [{}] };
	// }

  return {
		agentId,
		initialValues: initialValues as any,
		selectedCountry,
    loadingAgents: state.agents.loading
  };
};

const mapDispatchToProps = {
	saveAgentsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Agent);

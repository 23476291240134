// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone'
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

import { LoaderTail } from '@/components/Loaders/index';
import  {
	StyledFileUpload,
	StyledFileUploadBoxOverlay,
	StyledFileUploadBoxOverlayContainer,
	StyledFileUploadText,
	StyledFileUploadProgressWrapper,
	StyledFileUploadDropzone,
	StyledFileUploadInDropzone,
	StyledFileUploadProgressContainer,
	StyledFileUploadProgressBar,
	StyledFileUploadProgressPercentage,
	DropZoneStyle,
	DropZoneActiveStyle
} from './styles';
import { Button } from '@/components/Styled/Buttons/index';
import { fireToast } from '@/actions/toasts';
import { colors } from '@/defaults/index';
import FileUploadService from './services/index';
import {
	FileUploadEvents,
	FileUploadResponse
} from './models/ServiceModel';
import {
	FileUploadProps,
	FileUploadState
} from './models/FileUploadModel';

class FileUpload extends Component<FileUploadProps & any, FileUploadState> {
	fileLimit = 200;
	acceptFiles = 'image/jpg, image/jpeg, image/png';
	multiple = false;
	maxSize = 30000000;

	state = {
		isLoading: false,
		isSummary: false,
		progress: 0,
		currentFile: 1,
		totalFiles: 0,
		uploadedFilesTotal: 0,
		rejectedFilesTotal: 0
	}

	componentWillUnmount() {
		FileUploadService.destroyUpload();
	}

	handleUpload = (files: object[], rejectedFiles: object[]) => {
		const { url, fileLimit = this.fileLimit, token, fireToast } = this.props;

		this.setState({rejectedFilesTotal: 0});

		if (!isEmpty(rejectedFiles)) {
			// @ts-ignore
			const rejectedFileList = rejectedFiles.map(({name}) => name);

			this.calculateRejectedFilesTotal(rejectedFileList.length);

			fireToast({
				type: 'error',
				message: `Rejected files: ${rejectedFileList.join(', ')}`
			});
		}

		if (files.length > fileLimit) {
			fireToast({
				type: 'error',
				message: `You can upload up to ${fileLimit} files at a time`
			});

			return false;
		}

		if (isEmpty(files)) {
			return false;
		}

		this.setState({
			totalFiles: files.length,
			isLoading: true
		});

		// @ts-ignore
		FileUploadService.startUpload(url, files, token, (response: FileUploadResponse) => {
			this.selectAction(response);
		});
	}

	handleDisableSummary = () => {
		this.setState({
			isLoading: false,
			isSummary: false,
			progress: 0,
			currentFile: 1,
			totalFiles: 0,
			uploadedFilesTotal: 0,
			rejectedFilesTotal: 0
		});
	}

	handleCancel = () => {
		FileUploadService.cancelUpload();
	}

	selectAction = ({name, value}: FileUploadResponse) => {
		const { onUpload, fireToast } = this.props;

		switch(name) {
			case FileUploadEvents.PROGRESS:
				this.setState({progress: value});
				break;
			case FileUploadEvents.SUCCESS:
				onUpload(value);
				this.calculateUploadedFilesTotal(1);
				break;
			case FileUploadEvents.ERROR:
				fireToast({
					type: 'error',
					message: `We can not upload this file. Something is wrong.`
				});
				this.calculateRejectedFilesTotal(1);
				break;
			case FileUploadEvents.NEXT_FILE:
				this.setState({currentFile: value + 1});
				break;
			case FileUploadEvents.FINALLY:
				this.setState({
					isLoading: false,
					isSummary: true
				});
				break;
			default:
				break;
		}
	}

	calculateRejectedFilesTotal = (amount: number) => {
		this.setState({rejectedFilesTotal: this.state.rejectedFilesTotal + amount});
	}

	calculateUploadedFilesTotal = (amount: number) => {
		this.setState({uploadedFilesTotal: this.state.uploadedFilesTotal + amount});
	}

	renderUploading = () => {
		const { totalFiles, currentFile, progress } = this.state;

		return (
			<StyledFileUploadBoxOverlay>
				<StyledFileUploadBoxOverlayContainer>
					<StyledFileUploadText size={15} strong>Uploading {currentFile} of {totalFiles} files</StyledFileUploadText>
					<StyledFileUploadProgressWrapper>
						<StyledFileUploadProgressContainer>
							<StyledFileUploadProgressBar style={{width: `${progress}%`}}/>
							<StyledFileUploadProgressPercentage>{progress}%</StyledFileUploadProgressPercentage>
						</StyledFileUploadProgressContainer>
						<LoaderTail size={24} color={'black'} />
					</StyledFileUploadProgressWrapper>
					<StyledFileUploadText size={14} strong block>Please do not press Back or Refresh!</StyledFileUploadText>
					<Button onClick={this.handleCancel} color={colors.crimson} small>Cancel</Button>
				</StyledFileUploadBoxOverlayContainer>
			</StyledFileUploadBoxOverlay>
		);
	}

	renderSummary = () => {
		const { uploadedFilesTotal, rejectedFilesTotal } = this.state;

		return (
			<StyledFileUploadBoxOverlay>
				<StyledFileUploadBoxOverlayContainer>
					<StyledFileUploadText size={16} strong color={colors.mountainMeadow} block>Uploaded files: {uploadedFilesTotal}</StyledFileUploadText>
					<StyledFileUploadText size={16} strong color={colors.carnation} block>Rejected files: {rejectedFilesTotal}</StyledFileUploadText>
					<Button onClick={this.handleDisableSummary}>Upload files again <FontAwesomeIcon icon={faUpload} /></Button>
				</StyledFileUploadBoxOverlayContainer>
			</StyledFileUploadBoxOverlay>
		);
	}

	renderUpload = () => {
		const { isLoading, isSummary } = this.state;
		const {
			multiple = this.multiple,
			acceptFiles = this.acceptFiles,
			maxSize = this.maxSize
		} = this.props;

		return (
			<StyledFileUploadDropzone inactive={isLoading || isSummary}>
				<Dropzone
					style={DropZoneStyle}
					activeStyle={DropZoneActiveStyle}
					multiple={multiple}
					accept={acceptFiles}
					maxSize={maxSize}
					onDrop={this.handleUpload}
				>
					<StyledFileUploadInDropzone>
						<FontAwesomeIcon
							icon={faUpload}
							size="2x"/>
						<StyledFileUploadText block>Choose a file or drag it here.</StyledFileUploadText>
					</StyledFileUploadInDropzone>
				</Dropzone>
			</StyledFileUploadDropzone>
		);
	}

	render() {
		const { isLoading, isSummary } = this.state;

		return (
			<StyledFileUpload>

				{isLoading && this.renderUploading()}

				{isSummary && this.renderSummary()}

				{this.renderUpload()}

			</StyledFileUpload>
		)
	}
}

const mapStateToProps = (state: any) => ({
	token: state.auth.token
});

const mapDispatchToProps = {
	fireToast
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  section: {
    position: 'relative'
  },
	disabledSection: {
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 5,
		width: '100%',
		height: '100%',
		cursor: 'not-allowed'
	}
});

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    '& .rs-panel-heading': {
      fontSize: '20px',

      '& .rs-panel-title .rs-btn': {
        position: 'relative',
        bottom: 2,
        margin: {
          left: 10
        }
      }
    },
    '& .rs-loader-wrapper': {
      zIndex: 10,

      '& .rs-loader': {
        justifyContent: 'flex-start'
      },
      '& .rs-loader-spin': {
        top: 240
      },
      '& .rs-loader-content': {
        marginTop: 240
      }
    }
  }
});

import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import Select from 'react-select';
import { MdAddCircle, MdRemoveCircle } from 'react-icons/md';

import withFormGroup from '../withFormGroup';

import { SelectStyles } from './styles';
import  { StyledFormInputBtn, StyledFormInputWrapper, StyledFormLabel } from '../styles';

const renderField = ({
	input,
	disabled,
	label,
	options,
	styles,
	isClearable,
	btnAdd,
	btnRemove,
	onAdd,
	onRemove,
}) => (
	<Fragment>
		<StyledFormLabel>{label}</StyledFormLabel>
		<StyledFormInputWrapper>{disabled}
			<Select
				{...input}
				type="select"
				options={options}
				value={input.value}
				isDisabled={disabled}
				onChange={(value) => input.onChange(value)}
				onBlur={() => input.onBlur(input.value)}
				styles={styles ? {...SelectStyles, ...styles} : SelectStyles}
				className="react-select"
				isClearable={isClearable}
			/>
			{btnRemove &&
				<StyledFormInputBtn 
					type="button"
					remove
					onClick={onRemove}
					disabled={disabled}
				>
					<MdRemoveCircle />
				</StyledFormInputBtn>
			}
			{btnAdd &&
				<StyledFormInputBtn 
					type="button" 
					add
					onClick={onAdd}
					disabled={disabled}
				>
					<MdAddCircle />
				</StyledFormInputBtn>
			}
		</StyledFormInputWrapper>
	</Fragment>
);

const FieldComponent = withFormGroup(renderField);

const HSelect = ({...rest}) => {
	return (
		<Field
			component={FieldComponent}
			{...rest}
		/>
	)
};

export { HSelect };

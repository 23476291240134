import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Icon, Loader, Panel, SelectPicker } from 'rsuite';
import useFetch, { CachePolicies } from 'use-http';

import config from '@/config';

import { SelectPickerItem } from '@/v2/shared/types/SelectPicker';
import { ApiPath } from '@/v2/shared/enums';
import CustomizeIframe from './CustomizeIframe';
import { mapAgentEmailsToSelectPicker } from './utils';
import { Urls } from './enums';
import { useStyles } from './styles';

const MyAgentsPortfolio = (): ReactElement => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.auth.token);
  const options = {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: { 'Authorization': `Bearer ${token}` }
  };

  const [ agentList, setAgentList ] = useState<SelectPickerItem[]>([]);
  const [ username, setUsername ] = useState<string>('');
  const { post, response, loading } = useFetch(config.API_URL + ApiPath.AgentList, options);

  const url = `${Urls.Portfolio}?username=${username}&agent=true`;

  useEffect((): void => { getAgents(); }, []);

  const getAgents = async (): Promise<any> => {
    const res = await post();

    if (response.ok) {
      setAgentList(mapAgentEmailsToSelectPicker(res?.data?.agents));
    }
  };

  if (loading) {
    return (<Loader content="Loading..." />);
  }

  return (
    <div className={classes.tab}>
      <div style={{ marginBottom: 30 }}>
        <SelectPicker
          placeholder="Select agent"
          data={agentList}
          style={{ width: 300 }}
          onSelect={value => setUsername(value)}
          cleanable={false}
          disabled={loading}
        />
      </div>

      {username &&
        <>
          <Panel bodyFill header="Direct link">
            <Button appearance="link" href={url} target="_blank">
              <Icon icon='link' /> View My Agents Portfolio
            </Button>
          </Panel>

          <Divider>OR</Divider>

          <Panel bodyFill header="Embed">
            <CustomizeIframe url={url} />
          </Panel>
        </>
      }
    </div>
  )
};

export default MyAgentsPortfolio;

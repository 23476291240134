import Storage from '@/services/storage';

export const getToken = state => state.auth.token;

export const getVideoSlideshow = state => state.account?.info?.videoSlideshow;

export const getUserId = () => Storage.getUserIdFromToken();

export const getUserName = state => state.account.info.email;

export const getTourById = (state, id) => state.tours.items.filter(item => item.id === id)[0];

export const getActiveTour = (state, id) => state.tour[id];

import React, { useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  wrapper: {
    position: 'absolute',
    zIndex: 99999
  },
  tooltip: {
    display: (props: any) => props.active ? 'block' : 'none',
    position: 'absolute',
    top: '-28px',
    left: 0,
    padding: '5px',
    borderRadius: '2px',
    backgroundColor: '#44c144',
    fontSize: '12px',
    color: '#fff'
  },
  input: {
    position: 'absolute',
    left: '-9999px'
  }
});

export type CopyToClipboardProps = {
  text: string,
  children: any
};

const CopyToClipboard = (props: CopyToClipboardProps) => {
  const { children, text } = props;

  const [ activeTooltip, setActiveTooltip ] = useState(false);
  const textInputRef = useRef();
  const rootRef = useRef();
  const classes = useStyles({active: activeTooltip});

  const [ posX, setPosX ] = useState(0);
  const [ posY, setPosY ] = useState(0);

  const copy = (event) => {
    event.preventDefault();

    const { current } = textInputRef;

    setPosX(event.pageX);
    setPosY(event.pageY);

    document.body.appendChild(rootRef.current);

    // @ts-ignore
    current.select();
    // @ts-ignore
    current.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setActiveTooltip(true);

    setTimeout(() => {
      setActiveTooltip(false);
    }, 1000);
  };

  return (
    <span onClick={copy}>
      {children}
      <span 
        ref={rootRef}
        className={classes.wrapper}
        style={{ left: posX, top: posY }}
      >
        <span className={classes.tooltip}>Copied</span>
        <input type="text" readOnly value={text} ref={textInputRef} className={classes.input} />
      </span>
    </span>
  );
};

export { CopyToClipboard };

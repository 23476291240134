import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { Avatar, Badge, Button, ButtonToolbar, Icon, IconButton, IconStack, Panel, Tag, Tooltip, Whisper } from 'rsuite';
import { isEmpty } from 'lodash';

import 'react-lazy-load-image-component/src/effects/blur.css';

import config from '@/config';
import { getUserId } from '@/selectors/index';

import { dateFormat } from '@/helpers/format/date';
import { TourListProps } from '@/v2/views/Tours/types';
import { mapToInitials } from '../../utils';
import { useStyles } from './styles';
import { CopyToClipboard } from '@/v2/shared/components/CopyToClipboard/CopyToClipboard';

const TourList = ({ items, onRemoveTour, paymentsEnabled }: TourListProps): ReactElement => {
  const userId = getUserId();
  const classes = useStyles();

  return (
    <div className={`shepherd-step-tour-list ${classes.row}`}>
      {items.map(item => {
        const { id, creatorId, createTime, hashId, settings, photos, fullAddress, agent, 
          customDomain, brandedCustomDomain, unbrandedCustomDomain, mediaDomain, payments, delivered, downloaded } = item;

        const enabled = settings?.enabled;
        const sold = settings?.sold;
        const paid = payments?.paid;
        const domain = process.env.NODE_ENV !== 'development' && customDomain ? customDomain : config.TOUR_DOMAIN;
        const editLink = `/settings/${id}`;
        const tourOff = !enabled && !sold;

        // For a while, then it will be removed
        const isOldMultiPage = settings?.layout && settings.layout.split('-')[0] === 'scissors';
        const pageName = (settings?.video ? 'video' : '') 
          || (settings?.tour360 ? '360-tour' : '')
          || (settings?.slideshow ? 'slideshow' : '')
          || (settings?.gallery ? 'gallery' : '')
          || (settings?.details ? 'details' : '');
        const pageNamePath = isOldMultiPage ? `${pageName}/` : '';

        const brandedUrl = !tourOff ? `${brandedCustomDomain ? brandedCustomDomain : domain}/${pageNamePath}${id}${!brandedCustomDomain ? '?branded=1' : ''}` : '';
        const unbrandedUrl = !tourOff ? `${unbrandedCustomDomain ? unbrandedCustomDomain : domain}/${pageNamePath}${id}${!unbrandedCustomDomain ? '?branded=0' : ''}` : '';
        const mediaCenterUrl = `${mediaDomain}/${hashId}`; 

        return (
          <div className={classes.col} key={item.id}>
            <Panel shaded bordered bodyFill className={classes.card}>
              <div className={classes.cardThumbnail}>
                {!isEmpty(photos) &&
                  <NavLink to={editLink}>
                    <LazyLoadImage
                      alt={fullAddress}
                      src={photos[0]?.urls[2]?.url}
                      effect="blur"
                      width="100%"
                      height="100%"
                    />
                  </NavLink>
                }
                {enabled &&
                  <Tag color="green">Published</Tag>
                }
                {sold &&
                  <Tag color="red">Sold</Tag>
                }
                {paymentsEnabled &&
                  <>
                    {!sold && payments && !paid && <Tag color="red">Unpaid</Tag>}
                    {!sold && (paid || !payments) && <Tag color="cyan">Paid</Tag>}
                  </>
                }
                {userId === creatorId &&
                  <IconButton
                    icon={<Icon icon="close"/>}
                    color="red"
                    size="sm"
                    circle
                    className={classes.removeButton}
                    onClick={() => onRemoveTour(id)}
                  />
                }
              </div>
              <Panel className={classes.cardBody}>
                <NavLink to={editLink} className={classes.editLink}>
                  <Icon icon='link' /> {fullAddress}
                </NavLink>
                <div>
                  <ButtonToolbar>
                    <Button
                      href={brandedUrl}
                      target="_blank"
                      appearance="primary"
                      size="sm"
                      block
                      disabled={tourOff}
                    >
                      View Branded 
                      {!tourOff &&
                        <CopyToClipboard text={brandedUrl}>
                          <span className={classes.viewButtonCopyIcon}><Icon icon='copy' /></span>
                        </CopyToClipboard>
                      }
                    </Button>
                    <Button
                      href={unbrandedUrl}
                      target="_blank"
                      appearance="primary"
                      size="sm"
                      block
                      disabled={tourOff}
                    >
                      View Unbranded
                      {!tourOff &&
                        <CopyToClipboard text={unbrandedUrl}>
                          <span className={classes.viewButtonCopyIcon}><Icon icon='copy' /></span>
                        </CopyToClipboard>
                      }
                    </Button>
                    <Button
                      href={mediaCenterUrl}
                      target="_blank"
                      appearance="primary"
                      size="sm"
                      block
                      disabled={tourOff}
                    >
                      {/* <Badge content="NEW" className={classes.badge}></Badge> */}
                      View Media Center
                      {!tourOff &&
                        <CopyToClipboard text={mediaCenterUrl}>
                          <span className={classes.viewButtonCopyIcon}><Icon icon='copy' /></span>
                        </CopyToClipboard>
                      }
                    </Button>
                  </ButtonToolbar>
                </div>
              </Panel>
              <Panel className={classes.footer}>
                <LazyLoadComponent>
                  <Avatar
                    className={classes.agentAvatar}
                    circle
                    src={!isEmpty(agent?.avatar) ? agent?.avatar[0]?.urls[3]?.url : '-'}
                    alt={mapToInitials(agent?.agentName)}
                  />
                </LazyLoadComponent>
                <div className={classes.agentContent}>
                  <div>
                    <strong>{agent?.agentName}</strong> 
                    <span className={classes.date}>{dateFormat(createTime, 'D MMM YYYY')}</span>
                  </div>
                  <div>
                    <small>{agent?.brokerageName}</small>
                    {(delivered || downloaded) &&
                      <div className={classes.agentInfoStatus}>
                        {delivered &&
                          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Delivered to Agent</Tooltip>}>
                            <Icon icon="envelope" size="lg" />
                          </Whisper>
                        }
                        {downloaded &&
                          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Downloaded by Agent</Tooltip>}>
                            <Icon icon="download" size="lg" />
                          </Whisper>
                        }
                      </div>
                    }
                  </div>
                </div>
              </Panel>
            </Panel>
          </div>
        )
      })}
    </div>
  )
}

export default TourList;

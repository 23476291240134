import { has, isString } from 'lodash';

import { SelectPickerItem } from '../types/SelectPicker';

export const mapStringToSelectPickerItem =
  (value: string, data: SelectPickerItem[]): SelectPickerItem =>
    data.find(item => item.value === value);

export const mapSelectPickerItemToString =
  (
    item: SelectPickerItem | string,
    data?: SelectPickerItem[]
  ): string => {

  if (has(item, 'value')) {
    return item['value'];
  }

  if (!isString(item)) {
    return '';
  }

  // In case if item === label instead of value property
  if (data) {
    const label = data.find(({label}) => label === item);
    return label ? label['value'] : item as string;
  }

  return item as string;
};

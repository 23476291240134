import React from 'react';
import { reduxForm } from 'redux-form';
import { findIndex } from 'lodash';

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import { HSelect } from '@/components/FormUI/index';
import PanelForm from '@/components/PanelForm/index';

const validate = (values, props) => {
	const { email } = values;
	const { items } = props;
	const errors = {}

	const findEmailIndex = (list, match) => {
		return findIndex(list, match);
	};
	
  if (!email) {
		// @ts-ignore
    errors.email = 'Required';
	} else if (findEmailIndex(items, {username: email.value}) > -1) {
		// @ts-ignore
		errors.email = 'Email exists';
	}
	
  return errors;
};
				
let FormPermissionList = (props) => {
	const { 
		handleSubmit,
		submitSucceeded,
		loading,
		options
	} = props;

	return (
		<form onSubmit={handleSubmit}>
			<HSelect
				name="email"
				label="Agent email"
				options={options} 
			/>
			<GridRow>
				<GridCol>
					<PanelForm 
						loading={loading && submitSucceeded}
					/>
				</GridCol>
			</GridRow>
		</form>
	)
};

FormPermissionList = reduxForm({
	form: 'permissionList',
	validate
})(FormPermissionList)

export { FormPermissionList };

import React, { ReactElement, useState } from 'react';
import { Nav, Panel } from 'rsuite';

import Tab from '@/v2/shared/components/Tab/Tab';
import MyPortfolio from './MyPortfolio';
import MyAgentsPortfolio from './MyAgentsPortfolio';
import { useStyles } from './styles';
import Title from '@/components/Title';

const Portfolio: React.FC = (): ReactElement => {
  const classes = useStyles();
  const [ tab, setTab ] = useState('my-portfolio');

  return (
    <Panel>
      <Title name="Portfolio" />
      <p style={{ marginTop: 25 }}>
        Portfolio pages are a great way to showcase your work.
        You can either share all your tours or tours of a specific agent.
        Your agents can use those pages to share all their listings with their prospective clients.
        <br />
        You can use a direct link in your social media posts or client emails.
        If you prefer to add portfolio to your own homepage you can use the embed option.
        We can setup a special domain <i>https://portfolio.yourcompanyname.com</i> as well.
        Please send us an email to <strong>contact@homeontour.com</strong> to have it enabled.
      </p>

      <Nav
        className={classes.nav}
        appearance="subtle"
        activeKey={tab}
        onSelect={activeKey => setTab(activeKey)}
      >
        <Nav.Item eventKey="my-portfolio">My Portfolio</Nav.Item>
        <Nav.Item eventKey="my-agents-portfolio">My Agents Portfolio</Nav.Item>
      </Nav>

      <Tab activeKey={tab}>
        <Tab.Item eventKey="my-portfolio">
          <MyPortfolio />
        </Tab.Item>
        <Tab.Item eventKey="my-agents-portfolio">
          <MyAgentsPortfolio />
        </Tab.Item>
      </Tab>
    </Panel>
  );
};

export default Portfolio;

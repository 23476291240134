import { all } from 'redux-saga/effects'

import { 
  authRequestSignInSaga,
  authRequestSignUpSaga,
  authRequestSignOutSaga,
  authRequestResendActivationSaga,
  authRequestUserActivateSaga,
  authRequestResetPasswordSaga,
  authRequestChangePasswordSaga
} from './auth';

import {
  getAccountInfoRequestSaga,
  saveAccountInfoRequestSaga,
  accountRequestChangePasswordSaga
} from './account';

import { 
  addTourRequestSaga,
  removeTourRequestSaga,
  getToursRequestSaga,
  fetchToursRequestSaga
} from './tours';

import {
  getTourRequestSaga,
  saveTourRequestSaga,
  changeTourAddressRequestSaga
} from './tour';

import { 
  getPermissionsRequestSaga,
  addPermissionsRequestSaga,
  removePermissionsRequestSaga
} from './permissions';

import { 
  getAgentsRequestSaga,
  saveAgentsRequestSaga,
  removeAgentsRequestSaga
} from './agents';

import { sendFeedbackRequestSaga } from './feedback';

import { checkSubscriptionRequestSaga } from './subscriptions';

import { getCustomDomainRequestSaga } from './customDomain';

import { watchFetchSaga } from './watch';

export default function* rootSaga() {
  yield all([
    authRequestSignInSaga(),
    authRequestSignUpSaga(),
    authRequestSignOutSaga(),
    authRequestResendActivationSaga(),
    authRequestUserActivateSaga(),
    authRequestResetPasswordSaga(),
    authRequestChangePasswordSaga(),
    getAccountInfoRequestSaga(),
    saveAccountInfoRequestSaga(),
    accountRequestChangePasswordSaga(),
    addTourRequestSaga(),
    removeTourRequestSaga(),
    fetchToursRequestSaga(),
    getToursRequestSaga(),
    getTourRequestSaga(),
    saveTourRequestSaga(),
    changeTourAddressRequestSaga(),
    getPermissionsRequestSaga(),
    addPermissionsRequestSaga(),
    removePermissionsRequestSaga(),
    getAgentsRequestSaga(),
    saveAgentsRequestSaga(),
    removeAgentsRequestSaga(),
    sendFeedbackRequestSaga(),
    checkSubscriptionRequestSaga(),
    getCustomDomainRequestSaga(),
    watchFetchSaga()
  ]);
}

import jwt from 'jsonwebtoken';

const prefix = 'hmot_';

export default {
  saveToken (token) {
    localStorage.setItem(`${prefix}token`, token);
  },
  isVerifyToken (token) {
    return !!jwt.decode(token);
  },
  getToken () {
    const token = localStorage.getItem(`${prefix}token`);

    if (!this.isVerifyToken(token)) {
      this.removeToken();

      return undefined;
    }

    return token;
  },
  removeToken () {
    localStorage.removeItem(`${prefix}token`);
  },
  getUserIdFromToken () {
    const token = this.getToken();

    return token ? jwt.decode(token).userId : null;
  },
  setPullState (pull) {
    localStorage.setItem(`${prefix}pull`, pull);
  },
  getPullState () {
    return localStorage.getItem(`${prefix}pull`); 
  }
}

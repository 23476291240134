import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Panel, Loader, Message, Pagination, Divider, Alert, DOMHelper, Button } from 'rsuite';
import { isEmpty, isEqual } from 'lodash';
import useFetch, { CachePolicies } from 'use-http';

import config from '@/config';

import PromoCode from '@/v2/shared/components/PromoCode/PromoCode';
import IntroductionList from '@/v2/views/Tours/components/IntroductionList/IntroductionList';
import ModifyTour from '@/v2/shared/components/ModifyTour/ModifyTour';
import ConfirmModal from '@/v2/shared/components/ConfirmModal/ConfirmModal';
import useDebounce from '@/v2/shared/hooks/useDebounce';
import { SelectPickerItem } from '@/v2/shared/types/SelectPicker';
import { ApiPath } from '@/v2/shared/enums';

import SearchForm from './components/SearchForm/SearchForm';
import TourList from './components/TourList/TourList';
import { mapAgentsToSelectPicker } from './utils';
import { SearchValueState, ToursDataState } from './types';
import { limit, initialSearchValuesState, initialToursDataState } from './constants';
import { useStyles } from './styles';
import { setTutorial, tutorialsVideosStep } from './tutorial';

let tutorial;

const LOCALSTORAGE_TUTORIAL_KEY = 'hmt_tutorial';
const LOCALSTORAGE_TUTORIAL_VAL = '1';

const { scrollTop } = DOMHelper;
const alertDuration = 3500; // TODO: Alert.config (Global config)

const Tours: React.FC = (): ReactElement => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.auth.token);
  const suspended = useSelector((state: any) => state.account?.info?.suspended);
  const paymentsEnabled = useSelector((state: any) => state?.account?.info?.payments?.enabled);
  const options = {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: { 'Authorization': `Bearer ${token}` }
  };

  const firstRunDebouncedSearch = useRef<boolean>(true);
  const [ validSubscription, setValidSubscription ] = useState<boolean | null>(null);
  const [ showConfirmModal, setShowConfirmModal ] = useState<boolean>(false);
  const [ activeTourId, setActiveTourId ] = useState<string>(null);
  const [ agentsData, setAgentsData ] = useState<SelectPickerItem[]>([]);
  const [ addressValue, setAddressValue ] = useState<string>('');
  const [ searchValues, setSearchValues ] = useState<SearchValueState>(initialSearchValuesState);
  const [ toursData, setToursData ] = useState<ToursDataState>(initialToursDataState);
  const [ clearTouched, setClearTouched ] = useState<boolean>(false);
  const debouncedSearch = useDebounce(addressValue, 500);
  const searchingIsActive = !isEqual(initialSearchValuesState, searchValues);
  const [ isLoadedMore, setIsLoadedMore ] = useState(false);

  const {
    post: reqFetchAgents, response: resAgents, loading: loadingAgents
  } = useFetch(config.API_URL + ApiPath.AgentList, options);

  const {
    post: reqFetchTours, response: resTours, loading: loadingTours
  } = useFetch(config.API_URL + ApiPath.TourUser, options);

  const {
    post: reqFetchSearchTours, response: resSearchTours, loading: searching
  } = useFetch(config.API_URL + ApiPath.TourSearch, options);

  const {
    post: reqRemoveTour, response: resRemoveTour, loading: removing
  } = useFetch(config.API_URL + ApiPath.TourSave, options);

  const {
    post: reqCheckSubscription, response: resCheckSubscription
  } = useFetch(config.API_URL + ApiPath.CheckSubscription, options);

  const { tourList, activePage, totalPages } = toursData;
  const loading = loadingTours || searching;

  /*
  /* check subscription
   */
  useEffect((): void => { checkSubscription(); }, []);

  /*
  /* get all agents to select field
   */
  useEffect((): void => { getAgents(); }, []);

  /*
  /* search tours or get all tours if search fields are empty
   */
  useEffect((): void => {
    if (searchingIsActive) {
      searchTours(1);
    } else {
      getTours(1);
    }
  }, [searchValues]);

  /*
  /* search tours with delay by address field
   */
  useEffect((): void => {
    if (firstRunDebouncedSearch.current) {
      firstRunDebouncedSearch.current = false;
      return;
    }

    if (clearTouched) {
      setClearTouched(false);
      return;
    }

    handleChange({address: addressValue});
  }, [debouncedSearch]);

  const getAgents = async (): Promise<any> => {
    const response = await reqFetchAgents();

    if (resAgents.ok) {
      setAgentsData(mapAgentsToSelectPicker(response?.data?.agents));
    }
  }

  const getTours = async (page: number): Promise<any> => {
    const response = await reqFetchTours({ limit, page });

    if (resTours.ok) {
      const { currentPage, totalPages, data: tourList } = response?.data?.tours;

      initTutorial(tourList);

      setToursData({
        tourList,
        activePage: currentPage,
        totalPages
      });
      scrollTop(window, 0);
    }
  }

  const searchTours = async (page: number): Promise<any> => {
    const response = await reqFetchSearchTours({...searchValues, page, limit});

    if (resSearchTours.ok) {
      const { currentPage, totalPages, data: tourList } = response?.data?.tours;

      setToursData({
        tourList,
        activePage: currentPage,
        totalPages
      });
      scrollTop(window, 0);
    }
  }

  const initTutorial = (list: any) => {
    if (localStorage.getItem(LOCALSTORAGE_TUTORIAL_KEY) !== LOCALSTORAGE_TUTORIAL_VAL) {
      tutorial = setTutorial();
      if (list?.length < 2) {
        tutorial.addStep(tutorialsVideosStep, 10);
      }
      tutorial.start();
      localStorage.setItem(LOCALSTORAGE_TUTORIAL_KEY, LOCALSTORAGE_TUTORIAL_VAL);
    }
  };

  const checkSubscription = async (): Promise<any> => {
    await reqCheckSubscription();

    if (resCheckSubscription.ok) {
      setValidSubscription(true);
    } else {
      setValidSubscription(false);
    }
  }

  const handleChange = (value: SearchValueState): void => {
    setSearchValues(searchState => ({
      ...searchState,
      ...value
    }));
  };

  const handleClear = (): void => {
    handleChange(initialSearchValuesState);
    setClearTouched(true);
    setAddressValue('');
  };

  const handleRemoveTour = (tourId: string): void => {
    setActiveTourId(tourId);
    setShowConfirmModal(true);
  };

  const handleRemoveTourConfirm = async (): Promise<any> => {
    const tour = tourList.find(({id}) => id === activeTourId);
    const json = { ...tour, deleted: true };

    await reqRemoveTour({ json });

    if (resRemoveTour.ok) {
      handleClear();
      setShowConfirmModal(false);
      Alert.success('Tour has been removed.', alertDuration);
    } else {
      Alert.error('Tour could not be removed. Please try again.', alertDuration);
    }
  };

  const handleLoadMore = () => {
    setIsLoadedMore(true);
    searchTours(2);
  };

  const renderPanelHeader = () =>
    <div>Tours
      {validSubscription === true && !suspended && <ModifyTour />}
      {validSubscription === false &&
        <Message
          style={{ marginTop: 20 }}
          showIcon 
          type="info"
          description="Please change your subscription package"
        />
      }
      {suspended === true &&
        <Message
          style={{ marginTop: 20 }}
          type="error"
          description={
            <>
              Account suspended due to non-payment. 
              Please pay your last invoice and e-mail <a href="mailto:contact@homeontour.com">contact@homeontour.com</a>
            </>
          }
        />
      }
      <div style={{ marginTop: 30 }}>
        <PromoCode
          isApply
          token={token}
        />
      </div>
    </div>;

  return (
    <div className={classes.root}>
      <Panel header={renderPanelHeader()}>
        <SearchForm
          clearDisabled={!searchingIsActive}
          searchValues={searchValues}
          debouncedSearchValue={addressValue}
          onChangeDebouncedSearchValue={setAddressValue}
          selectPickerData={agentsData}
          selectPickerLoading={loadingAgents}
          onChange={handleChange}
          onClear={handleClear}
        />

        {loading &&
          <Loader backdrop content="loading..." size="md" vertical />
        }

        {(isEmpty(tourList)) && !loading ? (
          <>
            <Message description="No results found." />
            <IntroductionList />
          </>
        ) : (
          <>
            <TourList
              items={tourList}
              paymentsEnabled={paymentsEnabled}
              onRemoveTour={handleRemoveTour}
            />
            {tourList?.length === 1 &&
              <IntroductionList />
            }
            {totalPages > 1 &&
              <div style={{ textAlign: 'center' }}>
                <Divider />
                {!isLoadedMore &&
                  <Button
                    appearance="primary"
                    onClick={handleLoadMore}
                  >
                    Load More
                </Button>
                }
                {isLoadedMore &&
                  <Pagination
                    prev
                    next
                    ellipsis
                    boundaryLinks
                    size="lg"
                    pages={totalPages}
                    maxButtons={5}
                    activePage={activePage}
                    // onSelect={page => searchingIsActive ? searchTours(page): getTours(page)}
                    onSelect={page => searchTours(page)}
                  />
                }
              </div>
            }
            <ConfirmModal
              show={showConfirmModal}
              message="Are you sure you want to delete this tour?"
              loading={removing}
              onCancel={() => setShowConfirmModal(false)}
              onConfirm={() => handleRemoveTourConfirm()}
            />
          </>
        )}
      </Panel>
    </div>
  );
};

export default Tours;

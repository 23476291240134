import pagesData from "./components/Pages/data.json";
import topSectionData from "./components/TopSection/data.json";
import { LayoutCardName } from "./components/LayoutCards/types";
import { ThemeModeName } from "./components/ThemeMode/types";

export const SETTINGS_INITIAL = {
  enabled: false,
  sold: false,
  video: false,
  slideshow: false,
  tour360: false,
  gallery: false,
  details: false,
  floorplan: false,
  documents: false,
  map: false,
  walkScore: false,
  videoSlideshow: false,
  layout: `${LayoutCardName.Classic}-${ThemeModeName.Light}`,
  topSection: topSectionData[0],
  numberOfPhotosOnIntro: 3,
  slideshowConfig: {
    autoplay: false,
    thumbnails: false,
    animations: false,
    fitToScreen: false,
  },
  music: {
    id: "",
    name: "",
    url: "",
  },
  notifications: {
    agent: false,
    notifyAt: null,
    sendCopy: false,
  },
  payments: {
    enabled: false,
    paid: false,
    paymentLink: null,
  },
  exitPopup: false,
  leadCapture: {
    enabled: false,
    mandatory: true,
  },
  openHouse: {
    enabled: false,
    days: [
      {
        day: "",
        start: "",
        end: "",
      },
    ],
  },
  access: {
    enabled: false,
    isPublishDate: true,
    publishDate: "",
    timeZone: "",
    isTourPassword: false,
    tourPassword: "",
    tourPasswordOneTime: false,
    isMediaPassword: false,
    mediaPassword: "",
    mediaPasswordOneTime: false,
  },
};

export const selectSettings = (id: string) => (state: any) =>
  state.tour[id]?.settings || SETTINGS_INITIAL;

export const selectEnabled = (id: string) => (state: any) =>
  Boolean(state.tour[id]?.settings?.enabled);

export const selectSold = (id: string) => (state: any) =>
  Boolean(state.tour[id]?.settings?.sold);

export const selectPages = (id: string) => (state: any) =>
  mapPages(state.tour[id]?.settings || SETTINGS_INITIAL);

export const selectLayout = (id: string) => (state: any) =>
  getPartOfNameFromString(state.tour[id]?.settings?.layout, 0) ||
  LayoutCardName.Classic;

export const selectMode = (id: string) => (state: any) =>
  getPartOfNameFromString(state.tour[id]?.settings?.layout, 1) ||
  ThemeModeName.Light;

export const selectTopSection = (id: string) => (state: any) =>
  state.tour[id]?.settings?.topSection || SETTINGS_INITIAL.topSection;

export const selectNumberOfPhotosOnIntro = (id: string) => (state: any) =>
  state.tour[id]?.settings?.numberOfPhotosOnIntro ||
  SETTINGS_INITIAL.numberOfPhotosOnIntro;

export const selectSlideshowConfig = (id: string) => (state: any) =>
  state.tour[id]?.settings?.slideshowConfig || SETTINGS_INITIAL.slideshowConfig;

export const selectMusic = (id: string) => (state: any) =>
  state.tour[id]?.settings?.music || SETTINGS_INITIAL.music;

export const selectNotifications = (id: string) => (state: any) =>
  state.tour[id]?.settings?.notifications || SETTINGS_INITIAL.notifications;

export const selectPayments = (id: string) => (state: any) =>
  state.tour[id]?.settings?.payments || SETTINGS_INITIAL.payments;

export const selectExitPopup = (id: string) => (state: any) =>
  Boolean(state.tour[id]?.settings?.exitPopup);

export const selectLeadCapture = (id: string) => (state: any) =>
  state.tour[id]?.settings?.leadCapture || SETTINGS_INITIAL.leadCapture;

export const selectOpenHouse = (id: string) => (state: any) =>
  state.tour[id]?.settings?.openHouse || SETTINGS_INITIAL.openHouse;

export const selectAccess = (id: string) => (state: any) =>
  state.tour[id]?.settings?.access || SETTINGS_INITIAL.access;

const mapPages = (settings) => {
  return pagesData.reduce((list, { value }) => {
    list[value] = Boolean(settings && settings[value]);
    return list;
  }, {});
};

const getPartOfNameFromString = (name, index, separator = "-") =>
  name && name.split(separator)[index];

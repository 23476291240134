import { Schema } from 'rsuite';
import { isRequiredMsg } from './constants';

const { StringType } = Schema.Types;

export const model = Schema.Model({
  domain: StringType()
    .isRequired(isRequiredMsg)
    .pattern(
      /^(?!:\/\/)[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
      'Invalid domain format. Correct example: example.com'
    )
});

import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputNumber, SelectPicker } from 'rsuite';

import data from './data.json';
import { TopSectionItem, TopSectionProps, TopSectionValue } from './types';
import { LayoutCardName } from '../LayoutCards/types';
import { selectNumberOfPhotosOnIntro, selectTopSection } from '../../selectors';

const TopSection = ({ id, activeLayout, onSectionChange, onNumberChange }: TopSectionProps): ReactElement => {
  const isTemplateV2 = (activeLayout === LayoutCardName.Modern || activeLayout === LayoutCardName.Ocean);

  const topSection = useSelector(selectTopSection(id));
  const numberOfPhotosOnIntro = useSelector(selectNumberOfPhotosOnIntro(id));

  const [ activeTopSection, setActiveTopSection ] = useState<string>(topSection?.value);

  useEffect(() => {
    onSectionChange(topSection);
    onNumberChange(numberOfPhotosOnIntro);
  }, []);

  const filterData = (): TopSectionItem[] => {
    if (isTemplateV2) {
      return data;
    } else {
      return data.filter(item => item.value !== TopSectionValue.VimeoBackground);
    }
  };

  const handleChange = (item: TopSectionItem) => {
    setActiveTopSection(item.value);
    onSectionChange(item);
  };

  return (
    <>
      <SelectPicker
        size="md"
        placeholder="Select..."
        searchable={false}
        cleanable={false}
        defaultValue={topSection?.value}
        onChange={val => handleChange(data.find(({ value }) => value === val))}
        data={filterData()}
        style={{
          marginBottom: 25,
          width: 400
        }}
      />

      {isTemplateV2 && activeTopSection !== TopSectionValue.VimeoBackground &&
        <div>
          <div style={{ marginBottom: 3 }}>Number of photos on Intro</div>
          <InputNumber 
            defaultValue={numberOfPhotosOnIntro} 
            max={5} 
            min={1}
            onChange={(val) => onNumberChange(val)}
            style={{ width: 100 }} 
          />
        </div>
      }
    </>
	);
};

export default TopSection;

export enum ApiPath {
  TourUser = "/auth/tour/user",
  TourSearch = "/auth/tour/search",
  TourSave = "/auth/tour/save",
  DomainLink = "/auth/domain/link",
  DomainByTourId = "/auth/domain/getbytour",
  AgentList = "/auth/agent/list",
  PhotosDownload = "/auth/photos/download",
  PhotosDownloadNotification = "/auth/photos/download/notification",
  CheckSubscription = "/auth/subscription/check",
  uploadPhotos = "/auth/tour/upload?tourId=",
  uploadFiles = "/auth/tour/uploadFiles?tourId=",
  uploadAttachments = "/auth/tour/uploadAttachments?tourId=",
  GetFooter = "/auth/user/getfooter",
  ChangeFooter = "/auth/user/changefooter",
  VideoSlideshowGenerate = "/auth/video/generate",
  GetMusic = "/auth/music/get",
  GetUserMusic = "/auth/music/getuser",
  PromosCheck = "/promos/check",
  PromosApply = "/auth/promos/apply",
  OrdersCheck = "/auth/orders/check",
  OrdersSearch = "/auth/orders/search",
  OrdersId = "/auth/orders/id",
  OrdersSave = "/auth/orders/save",
  OrdersSetup = "/auth/orders/setup",
  IntegrationsGet = "/auth/integrations/get",
  IntegrationsCalendar = "/auth/integrations/calendar",
  IntegrationsQuickBooks = "/auth/integrations/quickbooks",
  IntegrationsStripe = "/auth/integrations/stripe",
  UserCustomPortal = "/auth/user/customerportal",
  ContactSend = "/contactus/send",
  BrandingDomain = "/auth/user/branding/domain",
  BrandingEmail = "/auth/user/branding/email",
  BrandingExample = "/auth/user/branding/example",
  BrandingSave = "/auth/user/branding/save",
  NotifyTest = "auth/tour/notify/test",
  StagingOptions = "auth/staging/options",
  StagingGenerate = "auth/staging/generate",
  StagingRegenerate = "auth/staging/regenerate",
  PasswordCreate = "/auth/password/create",
}

export enum Countries {
  USA = "usa",
  Canada = "canada",
}

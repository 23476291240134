export enum CheckTrigger {
  Change = 'change',
  Blur = 'blur',
  None = 'none'
}

export enum Layout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  Inline = 'inline'
}

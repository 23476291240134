import { put } from 'redux-saga/effects';

import Messages from '@/services/messages';

import { authRequestSignOut } from '@/actions/auth';
import { fireToast } from '@/actions/toasts';

export function* catchError(error) {
  const { status, data } = error;

  let message = 'DEFAULT';

  if (status === 401) {
    yield put(authRequestSignOut());

    message = 'SESSION_EXPIRED';
  } else if (status === 408) {
    message = 'TIMEOUT';
  } else if (status === 422) {
    message = data.message || 'DEFAULT';
  }

  yield put(
    // @ts-ignore
    fireToast({
      type: 'error',
      message: Messages[message] || Messages['DEFAULT']
    })
  );
}

import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form'

import { subscriptions } from '@/helpers/subscriptions';

import Title from '@/components/Title/index';
import PanelForm from '@/components/PanelForm/index';
import { SwitchBox } from '@/components/FormUI/index';

const validate = values => {
	const { subscription } = values;
	const errors = {};

	if (!subscription) {
		// @ts-ignore
    errors.subscription = 'Required';
	}
	
  return errors;
};

const mapPackagesToOptions = (_package: any) => ({
	value: _package?.name,
	title: _package?.label,
	content1: _package?.price === 0 ? 'Free' : `${_package?.priceAsString} (${_package?.currency}) per month`,
	content2: _package?.description?.[0],
	contentList: _package?.description?.slice(1)
});
				
let FormSubscription = (props) => {
	const { handleSubmit, onCancel, submitSucceeded, packages = [], loading } = props;
	const {
		packagePhotographerFree,
		package39,
		package79,
		package139
	} = subscriptions;

	return (
		<Fragment>
			<Title 
				subtitle="true"
				name="Change subscription:"
			/>
			<form onSubmit={handleSubmit}>
				<SwitchBox
					name="subscription"
					options={[
						...packages.map(mapPackagesToOptions),
						// {
						// 	value: packagePhotographerFree.value,
						// 	title: packagePhotographerFree.title,
						// 	content1: packagePhotographerFree.price,
						// 	content2: packagePhotographerFree.info,
						// 	contentList: packagePhotographerFree.options
						// },
						// {
						// 	value: package39.value,
						// 	title: package39.title,
						// 	content1: package39.price,
						// 	content2: package39.info,
						// 	contentList: package39.options
						// },
						// {
						// 	value: package79.value,
						// 	title: package79.title,
						// 	content1: package79.price,
						// 	content2: package79.info,
						// 	contentList: package79.options
						// },
						// {
						// 	value: package139.value,
						// 	title: package139.title,
						// 	content1: package139.price,
						// 	content2: package139.info,
						// 	contentList: package139.options
						// },
						{
							value: 'cancel',
							title: 'Cancel Your subscription',
							onPrevent: onCancel
						}
					]}
				/>
				<div style={{'height': '0.9375rem'}}></div>
				<PanelForm loading={loading && submitSucceeded} />
			</form>
		</Fragment>
	)
};

FormSubscription = reduxForm({
	form: 'subscription',
	validate
})(FormSubscription)

export { FormSubscription };

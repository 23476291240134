// @ts-ignore
import rockLightThumbnail from '@/assets/templates/rock-light_thumbnail.png';
// @ts-ignore
import rockDarkThumbnail from '@/assets/templates/rock-dark_thumbnail.png';
// @ts-ignore
import paperLightThumbnail from '@/assets/templates/paper-light_thumbnail.png';
// @ts-ignore
import paperDarkThumbnail from '@/assets/templates/paper-dark_thumbnail.png';
// @ts-ignore
import scissorsLightThumbnail from '@/assets/templates/scissors-light_thumbnail.png';
// @ts-ignore
import scissorsDarkThumbnail from '@/assets/templates/scissors-dark_thumbnail.png';

export const getLayouts = () => [
	{
		value: 'rock-light',
		label: 'Rock (One-page)',
		thumbnail: rockLightThumbnail
	},
	{
		value: 'paper-light',
		label: 'Paper (One-page)',
		thumbnail: paperLightThumbnail
	},
	{
		value: 'scissors-light',
		label: 'Scissors (Multi-page)',
		thumbnail: scissorsLightThumbnail
	},
	{
		value: 'rock-dark',
		label: 'Rock (One-page)',
		thumbnail: rockDarkThumbnail
	},
	{
		value: 'paper-dark',
		label: 'Paper (One-page)',
		thumbnail: paperDarkThumbnail
	},
	{
		value: 'scissors-dark',
		label: 'Scissors (Multi-page)',
		thumbnail: scissorsDarkThumbnail
	}
];

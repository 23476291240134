import {
	TOUR_SUCCESS_GET,
	TOUR_REQUEST_SAVE,
	TOUR_SUCCESS_SAVE,
	TOUR_FAILURE_SAVE
} from '@/actions/types';

const defaultState = {
	loading: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOUR_SUCCESS_GET:
      return {
        ...state,
        [action.json.id]: action.json
			};
		case TOUR_REQUEST_SAVE:
			return {
				...state,
				loading: true
			};
		case TOUR_SUCCESS_SAVE:
      return {
				...state,
        [action.json.id]: action.json,
				loading: false
			};
		case TOUR_FAILURE_SAVE:
      return {
				...state,
				loading: false
			};
    default:
      return state;
  }
};

import React, { Fragment, useState } from 'react';
import { reduxForm, change } from 'redux-form'

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import { Input } from '@/components/FormUI/index';
import PanelForm from '@/components/PanelForm/index';
import { StyledFormGroup } from '@/components/FormUI/styles';
import { StyledFormLink } from '../styles';

const validate = values => {
	const { matterport, iguide, cloudPano, matterportUrl, iguideUrl, cloudPanoUrl } = values;
	const errors = {}
	
  if (matterport && !matterportUrl) {
		// @ts-ignore
    errors.matterportUrl = 'Required.';
	}

	if (iguide && !iguideUrl) {
		// @ts-ignore
    errors.iguideUrl = 'Required.';
	}

	if (cloudPano && !cloudPanoUrl) {
		// @ts-ignore
		errors.cloudPanoUrl = 'Required.';
	}
	
  return errors;
};
				
let FormTour360 = (props) => {
	const {
		handleSubmit,
		onSaveAndNext,
		skipUrl,
		loading,
		initialValues: {
			matterport,
			iguide,
			cloudPano
		}
	} = props;

	const initialTab = (matterport && 'matterport') || (iguide && 'iguide') || (cloudPano && 'cloudPano');
	const [tab, setTab] = useState(initialTab);

	const resetSwitchers = () => {
		['matterport', 'iguide', 'cloudPano'].forEach(key => {
			props.dispatch(change('tour360', key, false));
		});
	}

	const handleChange = (event) => {
		const { name } = event.target;

		resetSwitchers();
		props.dispatch(change('tour360', name, true));
		setTab(name);
	}

	const renderMatterport = () => (
		<Fragment>
			<Input
				label="Matterport URL (e.g. https://my.matterport.com/show/?m=abcdef)"
				name="matterportUrl"
				type="text"
				placeholder="https://my.matterport.com/show/?m=abcdef"
			/>
			<StyledFormGroup>
				<StyledFormLink 
					href="https://support.matterport.com/hc/en-us/articles/115004549347-Embed-a-Space" 
					target="_blank"
					rel="noopener noreferrer"
				>
					Matterport Instruction &raquo;
				</StyledFormLink>
			</StyledFormGroup>
		</Fragment>
	)

	const renderIGuide = () => (
		<Fragment>
			<Input
				label="IGuide URL (e.g. https://youriguide.com/abcdef)"
				name="iguideUrl"
				type="text"
				placeholder="https://youriguide.com/abcdef"
			/>
			<StyledFormGroup>
				<StyledFormLink 
					href="https://goiguide.com/" 
					target="_blank" 
					rel="noopener noreferrer"
				>
					IGuide Instruction &raquo;
				</StyledFormLink>
			</StyledFormGroup>
		</Fragment>
	)

	const renderCloudPano = () => (
		<Fragment>
			<Input
				label="CloudPano URL (e.g. https://app.cloudpano.com/tours/abcdef)"
				name="cloudPanoUrl"
				type="text"
				placeholder="https://app.cloudpano.com/tours/abcdef"
			/>
			<StyledFormGroup>
				<StyledFormLink
					href="https://www.cloudpano.com/"
					target="_blank"
					rel="noopener noreferrer"
				>
					CloudPano Instruction &raquo;
				</StyledFormLink>
			</StyledFormGroup>
		</Fragment>
	)

	return (
		<form onSubmit={handleSubmit}>
			<GridRow>
				<GridCol wrap="true">
					<Input
						label="Matterport"
						name="matterport"
						type="checkbox"
						onChange={handleChange} />
					<Input
						label="IGuide"
						name="iguide"
						type="checkbox" 
						onChange={handleChange} />
					<Input
						label="CloudPano"
						name="cloudPano"
						type="checkbox"
						onChange={handleChange} />
				</GridCol>
			</GridRow>
			{tab === 'matterport' &&
				renderMatterport()
			}
			{tab === 'iguide' &&
				renderIGuide()
			}
			{tab === 'cloudPano' &&
				renderCloudPano()
			}
			<GridRow>
				<GridCol>
					<PanelForm 
						loading={loading}
						onSave={handleSubmit}
						onSaveAndNext={onSaveAndNext}
						skipUrl={skipUrl}
						formProps={props}
					/>
				</GridCol>
			</GridRow>
		</form>
	)
};

FormTour360 = reduxForm({
	form: 'tour360',
	initialValues: {
		matterport: true,
		matterportUrl: null,
		iguide: false,
		iguideUrl: null,
		cloudPano: false,
		cloudPanoUrl: null
	},
	validate
})(FormTour360)

export { FormTour360 };

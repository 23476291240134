import styled from 'styled-components';
import { colors } from '@/defaults/index';

const toastColors = {
	success: colors.lima,
	error: colors.carnation
};

const transitionOptions = {
	timeout: 500,
	transitionName: 'fade'
}

export const transitionOptionsCSS = transitionOptions;

export const StyledToastsContainer = styled.div`
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 2000;
	max-width: 320px;
	width: 100%;
	padding: 0 0.625rem;
	transform: translateX(-50%);
`;

export const StyledToastsItem = styled.div<any>`
	margin: 0.625rem auto;
	padding: 0.75rem 0.9375rem;
	border-radius: 0.125rem;
	// @ts-ignore
	background-color: ${props => toastColors[props.type]};
	box-shadow: 0 3px 3px rgba(0,0,0,.15);
	text-align: center;
	font-size: 0.875rem;
	font-weight: 500;
	color: ${colors.white};
	word-break: break-word;

	&.${transitionOptions.transitionName}-enter {
		opacity: 0.01;
	}
	&.${transitionOptions.transitionName}-enter-active {
		opacity: 1;
		transition: opacity ${transitionOptions.timeout}ms ease-in;
	}
	&.${transitionOptions.transitionName}-exit {
		opacity: 1;
	}
	&.${transitionOptions.transitionName}-exit-active {
		opacity: 0.01;
		transition: opacity ${transitionOptions.timeout}ms ease-in;
	}
`;

import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form'

import {
	StyledFormTitle,
	StyledFormSubTitle,
	StyledForm
} from '../styles';

import { 
	Input, 
	Submit 
} from '@/components/FormUI/index';

const validate = values => {
	const { code } = values;
	const errors = {}
	
  if (!code) {
		// @ts-ignore
    errors.code = 'Required';
	}
	
  return errors;
};
				
let FormUserActivate = (props) => {
	const { handleSubmit, loading } = props;

	return (
		<Fragment>
			<StyledFormTitle>
				Just one more step...
			</StyledFormTitle>
			<StyledFormSubTitle>
				Copy activation code which we sent to your email address and click the button below.
			</StyledFormSubTitle>
			<StyledForm onSubmit={handleSubmit}>
				<Input 
					name="code"
					type="text"
					label="Activation code"/>
				<Submit 
					primary
					center 
					xl={!loading} 
					maxWidth="340"
          disabled={loading}
          loading={loading}
				>
					Send
				</Submit>
			</StyledForm>
		</Fragment>
	)
};

FormUserActivate = reduxForm({
	form: 'userActivate',
	validate
})(FormUserActivate)

export { FormUserActivate };

import React, { ReactElement, useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { Badge, Panel } from "rsuite";

import { useStyles } from "./styles";
import { saveTour } from "@/helpers/forms";
import { saveTourRequest } from "@/actions/tour";
import { getUserId } from "@/selectors";

import Title from "@/components/Title";
import PanelForm from "@/components/PanelForm";
import ModifyTour from "@/v2/shared/components/ModifyTour/ModifyTour";
import CustomDomain from "@/v2/shared/components/CustomDomain/CustomDomain";

import Pages from "./components/Pages/Pages";
import ThemeMode from "./components/ThemeMode/ThemeMode";
import LayoutCards from "./components/LayoutCards/LayoutCards";
import TopSection from "./components/TopSection/TopSection";
import SlideshowConfiguration from "./components/SlideshowConfiguration/SlideshowConfiguration";
import MusicSetup from "./components/MusicSetup/MusicSetup";
import LeadCapture from "./components/LeadCapture/LeadCapture";
import OpenHouse from "./components/OpenHouse/OpenHouse";
import {
  selectEnabled,
  selectExitPopup,
  selectNotifications,
  selectPayments,
  selectSold,
  SETTINGS_INITIAL,
} from "./selectors";
import { ThemeModeName } from "./components/ThemeMode/types";
import { LayoutCardName } from "./components/LayoutCards/types";
import { TopSectionItem } from "./components/TopSection/types";
import { SlideshowConfigurationValue } from "./components/SlideshowConfiguration/types";
import { MusicSetupValue } from "./components/MusicSetup/types";
import { LeadCaptureValue } from "./components/LeadCapture/types";
import { OpenHouseForm } from "./components/OpenHouse/types";
import { DEFAULT_AVAILABLE_THEME_MODES } from "./components/ThemeMode/constants";
import { AccessAndPassword } from "./components/AccessAndPassword/AccessAndPassword";
import { AccessAndPasswordForm } from "@/v2/views/Tour/Settings/components/AccessAndPassword/types";

const Settings: React.FC = ({ id, saveTourRequest }: any): ReactElement => {
  const FORM_NAME = "settings";

  const classes = useStyles();

  const saving = useSelector((state: any) => state.tour.loading);
  const walkScore = useSelector((state: any) => state.tour[id]?.walkScore);
  const fullAddress = useSelector((state: any) => state.tour[id]?.fullAddress);
  const creatorId = useSelector((state: any) => state.tour[id]?.creatorId);
  const tourOwner = getUserId() === creatorId;

  const enabled = useSelector(selectEnabled(id));
  const sold = useSelector(selectSold(id));
  const notifications = useSelector(selectNotifications(id));
  const payments = useSelector(selectPayments(id));
  const exitPopup = useSelector(selectExitPopup(id));

  const [settings, setSettings] = useState<any>(SETTINGS_INITIAL);
  const [pages, setPages] = useState({});
  const [mode, setMode] = useState(ThemeModeName.Light);
  const [layout, setLayout] = useState(LayoutCardName.Classic);
  const [topSection, setTopSection] = useState(SETTINGS_INITIAL.topSection);
  const [numberOfPhotosOnIntro, setNumberOfPhotosOnIntro] = useState<
    number | string
  >(SETTINGS_INITIAL.numberOfPhotosOnIntro);
  const [slideshowConfig, setSlideshowConfig] = useState(
    SETTINGS_INITIAL.slideshowConfig
  );
  const [music, setMusic] = useState(SETTINGS_INITIAL.music);
  const [leadCapture, setLeadCapture] = useState(SETTINGS_INITIAL.leadCapture);
  const [openHouse, setOpenHouse] = useState(SETTINGS_INITIAL.openHouse);
  const [access, setAccess] = useState(SETTINGS_INITIAL.access);

  const [availableThemeModes, setAvailableThemeModes] = useState<string[]>(
    DEFAULT_AVAILABLE_THEME_MODES
  );

  useEffect(() => {
    updateSettings();
  }, [
    pages,
    mode,
    layout,
    topSection,
    numberOfPhotosOnIntro,
    slideshowConfig,
    music,
    leadCapture,
    openHouse,
    access,
  ]);

  const updateSettings = () => {
    const updatedSettings = {
      enabled,
      sold,
      ...pages,
      layout: `${layout}-${mode}`,
      topSection,
      numberOfPhotosOnIntro,
      slideshowConfig,
      music,
      notifications,
      payments,
      exitPopup,
      leadCapture,
      openHouse,
      access,
    };

    setSettings({ ...settings, ...updatedSettings });
  };

  const saveWalkScore = (value: boolean) => {
    if (value && (!walkScore || (walkScore && !walkScore.address))) {
      const data = { address: fullAddress };

      // @ts-ignore
      saveTour({ id, saveTourRequest }, "walkScore", data);
    }
  };

  const handlePageChange = (value: { key: string; value: boolean }) => {
    setPages(value);
    saveWalkScore(value["walkScore"]);
  };

  const handleModeChange = (value: ThemeModeName) => {
    setMode(value);
  };

  const handleLayoutChange = (
    value: LayoutCardName,
    availableThemeModes: string[]
  ) => {
    setLayout(value);
    setAvailableThemeModes(availableThemeModes);
  };

  const handleSectionChange = (value: TopSectionItem) => {
    setTopSection(value);
  };

  const handleNumberChange = (value: string | number) => {
    setNumberOfPhotosOnIntro(value);
  };

  const handleSlideshowConfigChange = (value: SlideshowConfigurationValue) => {
    setSlideshowConfig(value);
  };

  const handleMusicSetupChange = (value: MusicSetupValue) => {
    const { id, name, url } = value;

    setMusic({ id, name, url });
  };

  const handleLeadCaptureChange = (value: LeadCaptureValue) => {
    const { enabled, mandatory } = value;

    setLeadCapture({ enabled, mandatory });
  };

  const handleOpenHouseChange = (value: OpenHouseForm) => {
    const { enabled, days } = value;

    setOpenHouse({ enabled, days });
  };

  const handleAccessChange = (value: AccessAndPasswordForm) => {
    setAccess(value);
  };

  const handleSave = () => {
    // @ts-ignore
    saveTour({ id, saveTourRequest }, FORM_NAME, settings);
  };

  const handleSaveAndNext = () => {
    // @ts-ignore
    saveTour({ id, saveTourRequest }, FORM_NAME, settings, true);
  };

  return (
    <>
      <div className={classes.titleSection}>
        <Title name="Settings" />
      </div>

      {tourOwner && (
        <div className={classes.panel}>
          <Panel header="Sensitive changes:" bordered>
            <ModifyTour tourId={id} />
            <span style={{ paddingLeft: 8 }}>
              <CustomDomain tourId={id} />
            </span>
          </Panel>
        </div>
      )}

      <div className={classes.panel}>
        <Panel header="Active pages:" bordered>
          <Pages id={id} onChange={handlePageChange} />
        </Panel>
      </div>

      <div className={classes.panel}>
        <Panel header="Layout:">
          <div className={classes.layoutContainer}>
            <ThemeMode
              id={id}
              availableThemeModes={availableThemeModes}
              onChange={handleModeChange}
            />
            <LayoutCards id={id} mode={mode} onChange={handleLayoutChange} />
          </div>
        </Panel>
      </div>

      <div className={classes.panel}>
        <Panel header="Top Section:" collapsible bordered defaultExpanded>
          <TopSection
            id={id}
            activeLayout={layout}
            onSectionChange={handleSectionChange}
            onNumberChange={handleNumberChange}
          />
        </Panel>
      </div>

      <div className={classes.panel}>
        <Panel header="Slideshow configuration:" collapsible bordered>
          <SlideshowConfiguration
            id={id}
            onChange={handleSlideshowConfigChange}
          />
        </Panel>
      </div>

      <div className={classes.panel}>
        <Panel header="Music:" collapsible bordered>
          <MusicSetup id={id} onChange={handleMusicSetupChange} />
        </Panel>
      </div>

      <div className={classes.panel}>
        <Panel header="Lead capture:" bordered collapsible>
          <LeadCapture id={id} onChange={handleLeadCaptureChange} />
        </Panel>
      </div>

      <div className={classes.panel}>
        <Panel header="Open house:" bordered collapsible>
          <OpenHouse id={id} onChange={handleOpenHouseChange} />
        </Panel>
      </div>

      {tourOwner && (
        <div className={classes.panel}>
          <Panel
            header={
              <Badge content="NEW" style={{ paddingTop: 10 }}>
                Access and password:
              </Badge>
            }
            bordered
            collapsible
          >
            <AccessAndPassword id={id} onChange={handleAccessChange} />
          </Panel>
        </div>
      )}

      <Panel>
        <PanelForm
          loading={saving}
          onSave={handleSave}
          onSaveAndNext={handleSaveAndNext}
          formProps={{ valid: true }}
        />
      </Panel>
    </>
  );
};

const mapDispatchToProps = {
  saveTourRequest,
};

export default connect(null, mapDispatchToProps)(Settings);

import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form'

import Title from '@/components/Title/index';
import PanelForm from '@/components/PanelForm/index';

import { InputPassword } from '@/components/FormUI/index';

const validate = values => {
	const { password } = values;
	const errors = {}

	if (!password) {
		// @ts-ignore
    errors.password = 'Required';
	} else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z_\W\d]{8,}$/.test(password)) {
		// @ts-ignore
		errors.password = 'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number';
	}
	
  return errors;
};
				
let FormAccountChangePassword = (props) => {
	const { handleSubmit, submitSucceeded, loading } = props;

	return (
		<Fragment>
			<Title 
				subtitle="true"
				name="Change password:"
			/>
			<form onSubmit={handleSubmit}>
				<InputPassword
					name="password"
					type="password"
					label="Password" />
				<PanelForm loading={loading && submitSucceeded} />
			</form>
		</Fragment>
	)
};

FormAccountChangePassword = reduxForm({
	form: 'accountChangePassword',
	validate
})(FormAccountChangePassword)

export { FormAccountChangePassword };

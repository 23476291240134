import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';

import rootSaga from '@/sagas/root';

import { APP_RESET } from '@/actions/types';

import authReducer from '@/reducers/auth';
import accountReducer from '@/reducers/account';
import toursReducer from '@/reducers/tours';
import tourReducer from '@/reducers/tour';
import permissionsReducer from '@/reducers/permissions';
import agentsReducer from '@/reducers/agents';
import feedbackReducer from '@/reducers/feedback';
import subscriptionsReducer from '@/reducers/subscriptions';
import toastsReducer from '@/reducers/toasts';
import layoutReducer from '@/reducers/layout';
import customDomainReducer from '@/reducers/customDomain';

const allReducers: any = combineReducers({
	auth: authReducer,
	account: accountReducer,
	tours: toursReducer,
	tour: tourReducer,
	permissions: permissionsReducer,
	agents: agentsReducer,
	feedback: feedbackReducer,
	subscriptions: subscriptionsReducer,
	toasts: toastsReducer,
	layout: layoutReducer,
	customDomain: customDomainReducer,
	form: formReducer
});

const rootReducer: any = (state, action): any => {
  if (action.type === APP_RESET) {
		const { auth, customDomain } = state;

    state = { auth, customDomain };
  }

  return allReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const middleware = [ thunk, sagaMiddleware ];
if (process.env.NODE_ENV !== 'production') {
    // middleware.push(createLogger());
}

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);
const store = createStoreWithMiddleware(rootReducer);

sagaMiddleware.run(rootSaga);

export default (): any => store;

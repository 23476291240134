import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledLoaderContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: ${colors.athensGray};
`;

import {
	PERMISSIONS_SUCCESS_GET,
	PERMISSIONS_REQUEST_ADD,
	PERMISSIONS_SUCCESS_ADD,
	PERMISSIONS_FAILURE_ADD,
	PERMISSIONS_REQUEST_REMOVE,
	PERMISSIONS_SUCCESS_REMOVE,
	PERMISSIONS_FAILURE_REMOVE
} from '@/actions/types';

const defaultState = {
  userNames: [],
  loading: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PERMISSIONS_SUCCESS_GET:
      return {
        ...state,
        userNames: action.data
      };
    case PERMISSIONS_REQUEST_ADD:
    case PERMISSIONS_REQUEST_REMOVE:
      return {
        ...state,
        loading: true
      };
    case PERMISSIONS_SUCCESS_ADD:
		case PERMISSIONS_SUCCESS_REMOVE:
    case PERMISSIONS_FAILURE_ADD:
    case PERMISSIONS_FAILURE_REMOVE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

import * as yup from 'yup';

import { BrandingForm } from './types';
import { BE_NUMBER, MAX, MIN, REQUIRED, URL } from '../../shared/components/Form/validationMessages';

export const PRESET_COLORS = ['#E94256', '#0747a6', '#7D52A8', '#ffd800', '#f2f2f2'];

export const PHOTO_SIZE_ITEM = {
	name: '',
	width: 100,
	height: 100,
	default: false
};

export const DEFAULT_VALUES: BrandingForm = {
	companyName: '',
	companyAddress: '',
	companyUrl: '',
	copyrightInfo: '',
	privacyUrl: '',
	termsUrl: '',
	customColorScheme: false,
	supportEnabled: false,
	supportUrl: '',
	color: {
		button: '#eee',
		background: '#fff'
	},
	logo: '',
	favIcon: '',
	logoEmail: '',
	mediaSections: [],
	photoSizes: [PHOTO_SIZE_ITEM],
	photoOriginalName: false
};

export const SCHEMA = yup.object().shape({
  companyName: yup.string(),
  companyAddress: yup.string(),
  companyUrl: yup.string().url(URL),
  copyrightInfo: yup.string(),
  privacyUrl: yup.string().url(URL),
  termsUrl: yup.string().url(URL),
  supportEnabled: yup.boolean(),
	supportUrl: yup.string().nullable().when('supportEnabled', {
		is: true,
		then: yup.string().required(REQUIRED).url(URL)
	}),
  customColorScheme: yup.boolean(),
	color: yup.object().shape({
		button: yup.string(),
		background: yup.string()
	}),
  logo: yup.string(),
  favIcon: yup.string(),
  logoEmail: yup.string(),
	mediaSections: yup.array(),
	photoSizes: yup.array().of(
		yup.object().shape({
			name: yup.string().required(REQUIRED),
			width: yup.number().min(100, `Width ${MIN}`).max(4000, `Width ${MAX}`).typeError(`Width ${BE_NUMBER}`),
			height: yup.number().min(100, `Height ${MIN}`).max(4000, `Height ${MAX}`).typeError(`Height ${BE_NUMBER}`),
		})
	),
	photoOriginalName: yup.boolean()
});

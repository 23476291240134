import React from 'react';

import {
	StyledModalConfirmContent,
	StyledModalConfirmFooter,
	StyledModalConfirmBtn
} from './styles';

import { colors } from '@/defaults/index';

import { Modal } from '../Modal/index'
import { LoaderTail } from '@/components/Loaders/index';

const ModalConfirm = props => {
	const { 
		message,
		cancelBtnText,
		cancelBtnColor,
		confirmBtnText,
		confirmBtnColor, 
		show, 
		loading,
		onClose, 
		onConfirm 
	} = props;

	return (
		<Modal 
			show={show}
			onClose={onClose}
		>
			<StyledModalConfirmContent>{message}</StyledModalConfirmContent>
			<StyledModalConfirmFooter>
				<StyledModalConfirmBtn 
					color={cancelBtnColor || colors.boulder}
					onClick={onClose}
				>
					{cancelBtnText}
				</StyledModalConfirmBtn>
				<StyledModalConfirmBtn 
					color={confirmBtnColor || colors.lima}
					onClick={onConfirm}
				>
          {loading ? (
            <LoaderTail />
          ) : (
            confirmBtnText
          )}
				</StyledModalConfirmBtn>
			</StyledModalConfirmFooter>
		</Modal>
	)
}

export { ModalConfirm };

import {
  TOAST_ADD,
  TOAST_REMOVE
} from '@/actions/types';

const defaultState = {
  items: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
		case TOAST_ADD:
      return {
        items: [...state.items, action.toast]
			};
		case TOAST_REMOVE:
      return {
				items: state.items.filter(item => item.id !== action.id)
			};
    default:
      return state;
  }
};

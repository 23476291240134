import React from "react";
import { Button, Icon, Modal } from "rsuite";

import { ConfirmModalProps } from "./types";

const ConfirmModal = ({
  show,
  message,
  loading,
  centerBody,
  onCancel,
  onConfirm,
}: ConfirmModalProps) => (
  <Modal backdrop={true} show={show} onHide={onCancel} size="xs">
    <Modal.Body style={{ textAlign: centerBody ? "center" : "left" }}>
      <Icon
        icon="remind"
        style={{
          color: "#ffb300",
          fontSize: 24,
        }}
      />
      &nbsp;&nbsp;&nbsp;
      {message}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onConfirm} appearance="primary" loading={loading}>
        Ok
      </Button>
      <Button onClick={onCancel} appearance="subtle" disabled={loading}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ConfirmModal;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { saveTour } from '@/helpers/forms';
import { redirectTo } from '@/helpers/redirect';

import { saveTourRequest } from '@/actions/tour';

import Title from '@/components/Title/index';
import { FormDetails } from '@/components/Forms/index';

import  {
  GridRow,
  GridCol
} from '@/components/Styled/Grid/index';

class Details extends Component<any> {
  formName: string = 'details';

  handleSubmit = values => {
    // @ts-ignore
    saveTour(this.props, this.formName, values);
  }

  handleSaveAndNext = () => {
    // @ts-ignore
    saveTour(this.props, this.formName, this.props.formValues, true);
  }

  render() {
    const {id, currentTour, initialValues, loading} = this.props;
    // @ts-ignore
    const skipUrl = `/${redirectTo({currentPage: this.formName, activePages: currentTour.settings})}/${id}`;

    return (
      <GridRow>
        <GridCol>
          <Title name="Details" />
          <FormDetails
            onSubmit={this.handleSubmit}
            onSaveAndNext={this.handleSaveAndNext}
            skipUrl={skipUrl}
            initialValues={initialValues}
            loading={loading}
          />
        </GridCol>
      </GridRow>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const form = state.form.details;
  const id = ownProps.match.params.id;
  const currentTour = state.tour[id];
  const formValues = form && form.values;
  const initialValues = currentTour ? currentTour.details : null;

  return {
    id,
    initialValues,
    formValues,
    currentTour,
    loading: state.tour.loading
  };
};

const mapDispatchToProps = {
  saveTourRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);

import { 
	TiUserOutline,
	TiCogOutline,
	TiFilm,
	TiMap,
	TiThListOutline,
	TiThLargeOutline,
	TiDocument,
	TiFlashOutline,
	TiTabsOutline,
	TiKeyOutline,
	TiPowerOutline,
	TiGroupOutline,
	TiTicket,
	TiHeartOutline,
	TiBook,
	TiDocumentText,
	// TiShoppingCart
} from 'react-icons/ti';

import { MdPayment } from 'react-icons/md';

import { 
	MdFilterTiltShift
} from 'react-icons/md';

export const mainMenu = [
	{
    path: '/account',
		url: '/account',
		name: 'Account',
		icon: TiUserOutline
	},
	// {
    // path: '/orders',
	// 	url: '/orders',
	// 	name: 'Orders',
	// 	icon: TiShoppingCart
	// },
	{
    path: '/agents',
		url: '/agents',
		name: 'Agents',
		icon: TiGroupOutline
	},
	{
		path: '/branding',
		url: '/branding',
		name: 'Branding',
		icon: TiTicket
	},
	{
		path: '/portfolio',
		url: '/portfolio',
		name: 'Portfolio',
		icon: TiBook
	},
	{
    path: '/tours',
		url: '/tours',
		name: 'Tours',
		icon: TiThListOutline
	}
];

export const tourContent = [
	{
		path: '/settings/:id',
		url: '/settings/',
		name: 'Settings',
		icon: TiCogOutline
	},
	{
    path: '/360-tour/:id',
		url: '/360-tour/',
		name: '360 tour',
		icon: MdFilterTiltShift
	},
  {
    path: '/video/:id',
		url: '/video/',
		name: 'Video',
		icon: TiFilm
	},
	{
    path: '/photos/:id',
		url: '/photos/',
		name: 'Photos',
		icon: TiThLargeOutline
	},
	{
    path: '/virtual-staging/:id',
		url: '/virtual-staging/',
		name: 'Virtual Staging',
		icon: TiThLargeOutline
	},
	{
		path: '/details/:id',
		url: '/details/',
		name: 'Details',
		icon: TiDocument
	},
	{
    path: '/floorplan/:id',
		url: '/floorplan/',
		name: 'Floorplan',
		icon: TiTabsOutline
	},
	{
    path: '/documents/:id',
		url: '/documents/',
		name: 'Documents',
		icon: TiDocumentText
	},
	{
		path: '/walk-score/:id',
		url: '/walk-score/',
		name: 'Walk Score',
		icon: TiHeartOutline
	},
	{
    path: '/map/:id',
		url: '/map/',
		name: 'Map',
		icon: TiMap
	},
	{
    path: '/agent/:id',
		url: '/agent/',
		name: 'Agent',
		icon: TiFlashOutline
	},
	{
    path: '/video-slideshow/:id',
		url: '/video-slideshow/',
		name: 'Video Slideshow',
		icon: TiFilm
	},
	{
    path: '/publish/:id',
		url: '/publish/',
		name: 'Publish',
		icon: TiPowerOutline
	}
];

export const tourSettings = [
	{
    path: '/agent-access/:id',
		url: '/agent-access/',
		name: 'Agent Access',
		icon: TiKeyOutline
	}
];

import React, { Component } from 'react';
import qs from 'qs';

import { authRequestUserActivate } from '@/actions/auth';

import { FormUserActivate } from '@/components/Forms/index';

import WrappedAuth from '@/components/WrappedAuth/index';

class UserActivate extends Component<any> {
	handleSubmit = values => {
		const { code } = values;

		this.props.authRequestUserActivate(code);
	}

	render() {
		const { initialValues } = this.props;

    return (
			<FormUserActivate 
				onSubmit={this.handleSubmit}
				loading={this.props.loading}
				initialValues={initialValues}
			/>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const params = ownProps.location.search;
	const codeFromUrl = qs.parse(params, {ignoreQueryPrefix: true}).code;

	return {
		loading: state.auth.loading,
		initialValues: { code: codeFromUrl ? codeFromUrl : undefined }
	}
};

const mapDispatchToProps = {
  authRequestUserActivate
};

export default WrappedAuth(UserActivate, mapStateToProps, mapDispatchToProps);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, omit } from 'lodash';

import { saveTour } from '@/helpers/forms';
import { validateSettings } from '@/helpers/validations/settings';

import { saveTourRequest } from '@/actions/tour';

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import { ModalAlert } from '@/components/Modal/index';
import Title from '@/components/Title/index';
import { FormPublish } from '@/components/Forms/index';
import Attachments from '@/v2/views/Tour/Publish/Attachments';

class Publish extends Component<any> {
  formName: string = 'publish';

  state = {
    modal: false,
    modalMessage: null,
    clickedSubmitName: null // save/send
  }

  toggleModal = () => {
		this.setState({
			modal: !this.state.modal
		});
  };

  isValidData = values => {
    const { currentTour } = this.props;
    const validation = validateSettings(values, currentTour);

    if (!validation.isValidate) {

      this.setState({
        modalMessage: validation.message
      });

      this.toggleModal();

      return false;
    }

    return true;
  }

  mapFormValues = formValues => {
    const values = JSON.parse(JSON.stringify(formValues));
    const labels = ['additionalNotesState', 'sendNow', 'sendLater', 'addCC', 'notifyAtValidation'];

    if (!values.additionalNotesState) {
      labels.push('notifications.additionalNotes');
    }

    if (values?.notifications?.cc) {
      const emails = values.notifications.cc.split(',');
      values.notifications.cc = [...emails].map(s => s.trim());
    }

    return omit(values, labels);
  }

  handleSubmit = values => {
    this.setState({ clickedSubmitName: 'save' });

    const newValues = this.mapFormValues(values);

    // @ts-ignore
    this.isValidData(newValues) && saveTour(this.props, this.formName, newValues);
  }

  handleSaveAndNext = () => {
    this.setState({ clickedSubmitName: 'save' });

    const { formValues } = this.props;
    const newValues = this.mapFormValues(formValues);

    this.isValidData(newValues) && saveTour(this.props, this.formName, newValues, true, 'tours');
  }

  handleSend = () => {
    this.setState({ clickedSubmitName: 'send' });

    const { formValues } = this.props;
    const newValues = this.mapFormValues(formValues);

    // @ts-ignore
    this.isValidData(newValues) && saveTour(this.props, 'notify', newValues);
  }

  handleSendAndNext = () => {
    this.setState({ clickedSubmitName: 'send' });

    const { formValues } = this.props;
    const newValues = this.mapFormValues(formValues);

    this.isValidData(newValues) && saveTour(this.props, 'notify', newValues, true, 'tours');
  }

  render() {
    return (
      <GridRow>
				<GridCol>
					<Title name="Publish" />
          <FormPublish
            onSubmit={this.handleSubmit}
            onSaveAndNext={this.handleSaveAndNext}
            onSend={this.handleSend}
            onSendAndNext={this.handleSendAndNext}
            initialValues={this.props.initialValues}
            paymentsEnabled={this.props.paymentsEnabled}
            loading={this.props.loading}
            clickedSubmitName={this.state.clickedSubmitName}
          />
          <ModalAlert
            message={this.state.modalMessage}
            type="error"
            show={this.state.modal}
            onClose={this.toggleModal} 
          />
				</GridCol>
        <GridCol style={{ marginTop: 20 }}>
          <Attachments id={this.props.id} />
        </GridCol>
			</GridRow>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const form = state.form.publish;
  const id = ownProps.match.params.id;
  const formValues = form && form.values;
  const currentTour = state.tour[id];
  const initialValues = currentTour ? currentTour.settings : null;
  const paymentsEnabled = state?.account?.info?.payments?.enabled;
  const paymentsDefaultPaid = state?.account?.info?.payments?.defaultPaid;

  if (initialValues && initialValues.notifications) {
    initialValues.additionalNotesState = !!initialValues.notifications.additionalNotes;
  }

  const isAgent = get(initialValues, 'notifications.agent');
  const isNotifyAt = get(initialValues, 'notifications.notifyAt');

  initialValues.sendNow = isAgent && !isNotifyAt;
  initialValues.sendLater = isAgent && isNotifyAt;
  initialValues.notifyAtValidation = isNotifyAt;
  initialValues.addCC = get(initialValues, 'notifications.cc');
  initialValues.payments = Object.assign(
    {}, 
    { 
      enabled: paymentsEnabled,
      paid: !!paymentsDefaultPaid,
      paymentLink: ''
    }, 
    currentTour?.payments
  );

  if (initialValues?.notifications?.cc && typeof initialValues?.notifications?.cc === 'object') {
    initialValues.notifications.cc = initialValues.notifications.cc.join(', ');
  }

  return {
    id,
    initialValues,
    currentTour,
    formValues,
    paymentsEnabled,
    loading: state.tour.loading
  };
};

const mapDispatchToProps = {
  saveTourRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Publish);

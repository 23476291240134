import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Toggle } from "rsuite";

import data from "./data.json";
import {
  SlideshowConfigurationProps,
  SlideshowConfigurationValue,
} from "./types";
import { selectSlideshowConfig } from "../../selectors";

const SlideshowConfiguration = ({
  id,
  onChange,
}: SlideshowConfigurationProps): ReactElement => {
  const slideshowConfig = useSelector(selectSlideshowConfig(id));

  const [config, setConfig] =
    useState<SlideshowConfigurationValue>(slideshowConfig);

  useEffect(() => {
    onChange(config);
  }, []);

  const handleChange = (value: string, selected: boolean) => {
    const updatedConfig = { ...config, [value]: selected };

    setConfig(updatedConfig);
    onChange(updatedConfig);
  };

  return (
    <ul style={{ display: "flex" }}>
      {data.map(({ label, value }) => (
        <li key={value} style={{ marginRight: 20 }}>
          <label style={{ marginRight: 10 }}>{label}</label>
          <Toggle
            checkedChildren="On"
            unCheckedChildren="Off"
            defaultChecked={config[value]}
            onChange={(selected) => handleChange(value, selected)}
          />
        </li>
      ))}
    </ul>
  );
};

export default SlideshowConfiguration;

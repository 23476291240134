import styled from 'styled-components';
import { colors } from '@/defaults/index';

const transitionOptions = {
	timeout: 300,
	transitionNameModal: 'fade-slide',
	transitionNameBackdrop: 'fade'
}

export const transitionOptionsCSS = transitionOptions;

export const StyledModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1005;
	width: 100%;
	height: 100%;
	padding: 0 0.625rem;
	overflow-y: auto;

	&.${transitionOptions.transitionNameModal}-enter {
		opacity: 0.01;
		transform: translateY(-30%);
	}
	&.${transitionOptions.transitionNameModal}-enter-active {
		opacity: 1;
		transform: translateY(0);
		transition: opacity ${transitionOptions.timeout}ms ease-out, 
								transform ${transitionOptions.timeout}ms ease-out;
	}
	&.${transitionOptions.transitionNameModal}-exit {
		opacity: 1;
		transform: translateY(0);
	}
	&.${transitionOptions.transitionNameModal}-exit-active {
		opacity: 0.01;
		transform: translateY(-30%);
		transition: opacity ${transitionOptions.timeout}ms ease-out, 
								transform ${transitionOptions.timeout}ms ease-out;
	}
`;

export const StyledModalTransparent = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const StyledModalDialog = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 36rem;
	margin: 1.875rem auto;
`;

export const StyledModalHeader = styled.div`
  margin-bottom: 1.25rem;
  padding-bottom: 0.9375rem;
  border-bottom: 0.0525rem solid ${colors.wildSand};
  font-size: 18px;
  font-weight: 500;
`;

export const StyledModalContent = styled.div`
	position: relative;
	padding: 2.1875rem 1.25rem 1.25rem;
	border: 0.0625rem solid ${colors.mineShaft};
	border-radius: 0.1875rem;
	background-color: ${props => props.color ? props.color : colors.white};
	box-shadow: 0 3px 3px rgba(0,0,0,.2);
`;

export const StyledModalFooter = styled.div<any>`
	display: flex;
	// @ts-ignore
	justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};
	width: 100%;
	border-top: 0.0525rem solid ${colors.wildSand};
	// @ts-ignore
	margin: ${props => props.margin ? props.margin : 0};
	padding-top: 0.9375rem;

	& > * {
	// @ts-ignore
		margin-left: ${props => props.justifyContent ? 0 : '0.375rem'};
		margin-bottom: 0;
	}
`;

export const StyledModalBtnClose = styled.button`
	position: absolute;
	top: 0.3125rem;
	right: 0.3125rem;
	border: none;
	background-color: transparent;
	outline: none;
	transition: opacity .2s ease-out;

	&:hover {
		opacity: .8;
	}
`;

export const StyledModalBackdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.5);

	&.${transitionOptions.transitionNameBackdrop}-enter {
		opacity: 0.01;
	}
	&.${transitionOptions.transitionNameBackdrop}-enter-active {
		opacity: 1;
		transition: opacity ${transitionOptions.timeout}ms ease-out;
	}
	&.${transitionOptions.transitionNameBackdrop}-exit {
		opacity: 1;
	}
	&.${transitionOptions.transitionNameBackdrop}-exit-active {
		opacity: 0.01;
		transition: opacity ${transitionOptions.timeout}ms ease-out;
	}
`;

export const ModalHeader: any = StyledModalHeader;
export const ModalFooter: any = StyledModalFooter;

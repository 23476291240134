import React, { ReactElement, useEffect } from 'react';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ModalEditProps } from '../types';
import { REQUIRED } from '../../Form/validationMessages';

const schema = yup.object().shape({
  fileName: yup.string().required(REQUIRED)
});

const ModalEdit = ({ show, fileName, onClose, onSave }: ModalEditProps): ReactElement => {
  const { 
		control,
		formState: { errors },
		handleSubmit,
    setValue
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: { fileName: '' }
	});

  useEffect(() => {
    setValue('fileName', fileName);
	}, [fileName]);

  const handleSave = ({ fileName }) => {
    onSave(fileName);
  };

  return (
    <Modal backdrop={true} show={show} onHide={onClose} size="xs">
      <Modal.Header>
        <Modal.Title>Edit file name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <ControlLabel>File name:</ControlLabel>
            <Controller
              name="fileName"
              control={control}
              render={({ field }) => <FormControl {...field} errorMessage={errors?.fileName?.message} />}
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit(handleSave)} appearance="primary">
          Save
        </Button>
        <Button onClick={onClose} appearance="subtle">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEdit;

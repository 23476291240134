import {
	AGENTS_SUCCESS_GET,
	AGENTS_REQUEST_SAVE,
	AGENTS_SUCCESS_SAVE,
	AGENTS_FAILURE_SAVE,
	AGENTS_REQUEST_REMOVE,
	AGENTS_SUCCESS_REMOVE,
	AGENTS_FAILURE_REMOVE
} from '@/actions/types';

const defaultState = {
  items: [],
  loading: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AGENTS_SUCCESS_GET:
      return {
        ...state,
        items: action.data
      };
    case AGENTS_REQUEST_SAVE:
    case AGENTS_REQUEST_REMOVE:
      return {
        ...state,
        loading: true
      };
    case AGENTS_SUCCESS_SAVE:
		case AGENTS_SUCCESS_REMOVE:
    case AGENTS_FAILURE_SAVE:
    case AGENTS_FAILURE_REMOVE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

import { Component } from 'react';
import ReactDOM from 'react-dom';

class ModalPortal extends Component<any> {
	private root: any;
	private modalRoot: any;
	private modalRootClassName: string;
	private el: HTMLDivElement;

	constructor(props) {
		super(props);

		this.root = document.getElementById('root');
		this.modalRoot = null;
		this.modalRootClassName = 'modal-root';
		this.el = document.createElement('div');
	}

	componentDidMount() {
		this.modalRoot = document.createElement('div');
		this.modalRoot.className = this.modalRootClassName;

		this.root.parentNode.insertBefore(this.modalRoot, this.root.nextSibling);
		
		this.modalRoot.appendChild(this.el);
	}
	
	componentWillUnmount() {
		this.modalRoot.remove();
  }

	render () {
		return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
	}
}

export default ModalPortal;

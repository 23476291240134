import styled from 'styled-components';
import { colors } from '@/defaults/index';
import { rgba } from 'polished';

// @ts-ignore
import bgImg from '@/assets/bg-auth.jpg';

export const transitionOptionsCSS = {
	timeout: 300,
	transitionName: 'component-fade'
}

export const StyledApp = styled.div`
	display: flex;
	flex-wrap: wrap;
	min-height: 100vh;
	background-color: ${colors.whiteApprox};
`;

export const StyledAppWrapper = styled.div`
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
`;

export const StyledAppContainer = styled.div<any>`
	position: relative;
	width: 100%;
	min-height: calc(100vh - 3.75rem - 4.0625rem);
	padding: 1.25rem 1.25rem 3.75rem;

	// @ts-ignore
	${props => props.loading && `
		height: calc(100vh - 3.75rem - 4.0625rem);
		min-height: 0;
		overflow: hidden;
	`}
`;

export const StyledAppSign = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	min-height: 100vh;
	padding: 0 0.625rem;
	background-image: url(${bgImg});
	background-repeat: no-repeat;
	background-color: transparent;
	background-size: cover;
	background-position: 50%;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: ${rgba(colors.whiteApprox, .9)};
	}
`;

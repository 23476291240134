import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '@/services/api';

import { catchSuccess } from './catchSuccess';
import { catchError } from './catchError';

import { FEEDBACK_REQUEST_SEND } from '@/actions/types';

import {
	sendFeedbackSuccess,
	sendFeedbackFailure
} from '@/actions/feedback';

export function* sendFeedbackRequest({payload: { requestData, callback }}) {
  try {
    const { message } = yield call(Api.sendFeedback, requestData);

		callback();

    yield put(sendFeedbackSuccess());
		yield catchSuccess(message);
  } catch (error) {
    yield put(sendFeedbackFailure());
    yield catchError(error);
  }
}

export function* sendFeedbackRequestSaga() {
  // @ts-ignore
  yield takeLatest(FEEDBACK_REQUEST_SEND, sendFeedbackRequest);
}

import axios from 'axios';
import config from '@/config';

export const CancelToken = axios.CancelToken;
export const http = axios.create({
  baseURL: config.API_URL,
  timeout: 15000
});

const responseHandler = response => response.data;
const catchHandler = error => {
  if (error.message === 'CANCEL_UPLOADING') {
    throw error.message;
  }

  if (error.code === 'ECONNABORTED') {
    const timeout = {
      status: 408
    };

    return Promise.reject(timeout);
  }

  throw error.response;
};

export const httpPost = (url, payload, config?: any) => {
  return http.post(url, payload, config || {})
    .then(responseHandler)
    .catch(catchHandler);
};

export const httpGet = (url, config?: any) => {
  return http.get(url, config || {})
    .then(responseHandler)
    .catch(catchHandler);
};

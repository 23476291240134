import React, { PureComponent } from 'react';

import {
	StyledLoaderContainer
} from './styles';

class LoaderContent extends PureComponent {
	render() {
    return (
			<StyledLoaderContainer>
				<svg 
					version="1.1" 
					xmlns="http://www.w3.org/2000/svg" 
					width="80px" 
					height="80px" 
					x="0px" 
					y="0px"
					viewBox="0 0 34.085 37.633" 
					enableBackground="new 0 0 26.085 29.657" 
					preserveAspectRatio="xMidYMid meet"
				>
					<g transform="translate(4,3.976)">
						<polyline 
							fill="#E94357" 
							points="6.02,29.657 6.02,22.346 0,26.14 0,29.657"
						>
							<animateTransform
								attributeName="transform"
								type="translate"
								from="0 4"
								to="0 4"
								values="0 4; 0 0; 0 4"
								keyTimes="0; 0.5; 1"
								keySplines=".42 0 1 1;
														.42 0 1 1;
														.42 0 1 1"
								dur="0.9s" 
								begin="0s" 
								repeatCount="indefinite">
							</animateTransform>
						</polyline>
					</g>
					<g transform="translate(4,3.976)">
						<polyline 
							fill="#E94357" 
							points="0,0.024 0,9.34 6.02,5.547 6.02,0.024"
						>
							<animateTransform
								attributeName="transform"
								type="translate"
								from="0 -4"
								to="0 -4"
								values="0 -4; 0 0; 0 -4"
								keyTimes="0; 0.5; 1"
								keySplines=".42 0 1 1;
														.42 0 1 1;
														.42 0 1 1"
								dur="0.9" 
								begin="0s" 
								repeatCount="indefinite">
							</animateTransform>
						</polyline>
					</g>
					<g transform="translate(4,3.976)">
						<polyline 
							fill="#E94357" 
							points="26.085,0.126 26.085,9.443 20.065,5.649 20.065,0.126"
						>
							<animateTransform
								attributeName="transform"
								type="translate"
								from="0 -4"
								to="0 -4"
								values="0 -4; 0 0; 0 -4"
								keyTimes="0; 0.5; 1"
								keySplines=".42 0 1 1;
														.42 0 1 1;
														.42 0 1 1"
								dur="0.9" 
								begin="0s" 
								repeatCount="indefinite">
							</animateTransform>
						</polyline>
					</g>
					<g transform="translate(4,3.976)">
						<polyline 
							fill="#E94357" 
							points="0,21.186 13.028,12.894 20.065,17.3 20.065,20.936 20.065,22.132 20.065,29.657 26.085,29.657 
											26.085,22.132 26.085,17.3 26.085,13.967 23.259,12.198 16.222,7.792 13.021,5.788 9.823,7.798 2.815,12.205 0,13.974 0,17.3 "/>
					</g>
				</svg>

				{/* <svg 
					width="100px" 
					height="100px" 
					xmlns="http://www.w3.org/2000/svg" 
					viewBox="0 0 100 100" 
					preserveAspectRatio="xMidYMid"
					style={{background: 'none'}}
				>
					<circle 
						cx="50" 
						cy="50" 
						fill="none" 
						stroke="#063a89" 
						strokeWidth="6" 
						r="35" 
						strokeDasharray="164.93361431346415 56.97787143782138" 
						transform="rotate(119.822 50 50)"
					>
						<animateTransform 
							attributeName="transform" 
							type="rotate" 
							calcMode="linear" 
							values="0 50 50;360 50 50" 
							keyTimes="0;1" 
							dur="1s" 
							begin="0s" 
							repeatCount="indefinite">
						</animateTransform>
					</circle>
				</svg> */}
			</StyledLoaderContainer>
		)
	}
}

export { LoaderContent };

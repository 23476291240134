import React, { ReactElement } from 'react';
import { Button, Message, Modal } from 'rsuite';

import { UploadSummaryModalProps } from '../types';

const UploadSummaryModal = ({
	show,
	totalUploaded = 0,
	totalRejected = 0,
	numberOfWrongFilesSize = 0,
	numberOfWrongFilesExtension = 0,
	numberOfServerErrors = 0,
	onClose 
}: UploadSummaryModalProps): ReactElement => {

	return (
		<div className="modal-container">
			<Modal backdrop="static" show={show} onHide={onClose} size="xs">
				<Modal.Header>
					<Modal.Title>Upload summary</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Message
						type="success" 
						description={
							<div>
								Total uploaded files: &nbsp;<strong>{totalUploaded}</strong>
							</div>
						} 
					/>
					{totalRejected > 0 &&
						<Message
							type="error" 
							description={
								<div>
									Total rejected files: &nbsp;<strong>{totalRejected}</strong>
									<ul style={{ marginTop: 8 }}>
										<li>Too large file size: &nbsp;<strong>{numberOfWrongFilesSize}</strong></li>
										<li>Wrong file extension: &nbsp;<strong>{numberOfWrongFilesExtension}</strong></li>
										<li>Server errors: &nbsp;<strong>{numberOfServerErrors}</strong></li>
									</ul>
								</div>
							} 
						/>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={onClose}>Close</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default UploadSummaryModal;

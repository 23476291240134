import { 
	CUSTOM_DOMAIN_INIT,
	CUSTOM_DOMAIN_SUCCESS_GET
} from '@/actions/types';

const defaultState = {
	customDomain: false,
	name: '',
	www: '',
	logo: 'https://homeontour.com/images/1x1.png'
};

export default (state = defaultState, action) => {
  switch (action.type) {
		case CUSTOM_DOMAIN_INIT:
			return {
				...state,
				customDomain: true
			};
		case CUSTOM_DOMAIN_SUCCESS_GET:
			return {
				...state,
				...action.payload
			};
    default:
      return state;
  }
};

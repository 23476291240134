import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import { isEmpty } from "lodash";
import { Button, Divider, Form, FormGroup, Icon, Input } from "rsuite";

import Api from "@/services/api";

import { saveAccountInfoRequest } from "@/actions/account";

import History from "@/components/History/index";
import { ModalConfirm } from "@/components/Modal/index";
import { FormSubscription } from "@/components/Forms/index";
import PromoCode from "@/v2/shared/components/PromoCode/PromoCode";

import { GridRow, GridCol } from "@/components/Styled/Grid/index";

import { TextTop, TextStrong } from "@/components/Styled/Text/index";
import Title from "@/components/Title";
import { updateCreditCard } from "@/v2/views/Account/http";

class Subscription extends Component<any> {
  state = {
    publishedTours: 0,
    modalCancel: false,
    promoCodeValue: null,
    promoCode: null,
    stripeUrl: "",
    loadingStripeUrl: false,
  };

  async componentDidMount() {
    const { data } = await Api.getAccountStats(this.props.token);

    this.setState({ publishedTours: data.stats.enabled });
  }

  toggleModalCancel = () => {
    this.setState({
      modalCancel: !this.state.modalCancel,
    });
  };

  handleCancel = () => {
    this.toggleModalCancel();
  };

  handleUpdateCreditCard = async () => {
    try {
      this.setState({ loadingStripeUrl: true });

      const res = await updateCreditCard();

      this.setState({ stripeUrl: res.data.cusPortal, loadingStripeUrl: false });
    } catch (error) {
      this.setState({ loadingStripeUrl: false });
    }
  };

  handleCancelConfirm = () => {
    this.props.cancelSubscription();

    this.toggleModalCancel();
  };

  handleSubmit = (values) => {
    const { name, phone, saveAccountInfoRequest } = this.props;
    const { subscription } = values;

    saveAccountInfoRequest({
      name,
      phone,
      package: subscription,
    });
  };

  render() {
    const { initialValues, history, pricing, loading, token } = this.props;

    return (
      <GridRow>
        <GridCol>
          <TextTop primary>
            Tours published this month:{" "}
            <TextStrong>{this.state.publishedTours}</TextStrong>
          </TextTop>
          <FormSubscription
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
            initialValues={initialValues}
            packages={pricing?.packages}
            loading={loading}
          />
          <div style={{ marginTop: 30 }}>
            {!this.state.stripeUrl && (
              <Button
                appearance="primary"
                onClick={this.handleUpdateCreditCard}
                loading={this.state.loadingStripeUrl}
              >
                Update Your Credit Card
              </Button>
            )}
            {this.state.stripeUrl && (
              <Button
                appearance="link"
                href={this.state.stripeUrl}
                target="_blank"
              >
                <Icon icon="external-link" /> Click here to change credit card
                information
              </Button>
            )}
          </div>
          {!isEmpty(history) && (
            <Fragment>
              <div style={{ height: "2.1875rem" }}></div>
              <Title subtitle="true" name="Subscription history:" />
              <History items={history} />
            </Fragment>
          )}
          <Divider />
          <Form layout="inline" style={{ marginTop: 50 }}>
            <FormGroup>
              <Input
                placeholder="Promo code"
                style={{ width: 260 }}
                onChange={(value) => this.setState({ promoCodeValue: value })}
              />
            </FormGroup>
            <Button
              type="button"
              appearance="primary"
              style={{ marginLeft: -10 }}
              onClick={() =>
                this.setState({ promoCode: this.state.promoCodeValue })
              }
            >
              Apply promo code
            </Button>
          </Form>
          <PromoCode codeValue={this.state.promoCode} token={token} isApply />
          <ModalConfirm
            message="Cancelling your subscription will cause all your tours to become unavailable at the end of your monthly billing period."
            cancelBtnText="No"
            confirmBtnText="Yes"
            show={this.state.modalCancel}
            onConfirm={this.handleCancelConfirm}
            onClose={this.toggleModalCancel}
          />
        </GridCol>
      </GridRow>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { email, name, phone, subscription, pricing } = state.account.info;

  const initialValues = {
    subscription: subscription ? subscription.currentPackage : "",
  };

  return {
    token: state.auth.token,
    email,
    name,
    phone,
    pricing,
    history: subscription ? subscription.history : [],
    initialValues,
    loading: state.account.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAccountInfoRequest: (request) =>
      dispatch(saveAccountInfoRequest(request)),
    cancelSubscription: () =>
      dispatch(change("subscription", "subscription", "cancel")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);

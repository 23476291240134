import React, { Component } from 'react';

import { authRequestResetPassword } from '@/actions/auth';

import { FormResetPassword } from '@/components/Forms/index';

import WrappedAuth from '@/components/WrappedAuth/index';

class ResetPassword extends Component<any> {
	handleSubmit = values => {
		const { username } = values;

		this.props.authRequestResetPassword(username);
	}

	render() {
    return (
			<FormResetPassword 
				onSubmit={this.handleSubmit}
				loading={this.props.loading}
			/>
		)
	}
}

const mapStateToProps = state => ({
  loading: state.auth.loading
});

const mapDispatchToProps = {
  authRequestResetPassword
};

export default WrappedAuth(ResetPassword, mapStateToProps, mapDispatchToProps);

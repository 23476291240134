import {
  AUTH_REQUEST_SIGNIN,
  AUTH_REQUEST_SIGNUP,
	AUTH_REQUEST_SIGNOUT,
	AUTH_REQUEST_RESEND_ACTIVATION,
	AUTH_REQUEST_USER_ACTIVATE,
	AUTH_REQUEST_RESET_PASSWORD,
	AUTH_REQUEST_CHANGE_PASSWORD,
	AUTH_SUCCESS,
	AUTH_FAILURE
} from '@/actions/types';

export const authRequestSignIn = (username, password) => {
	return {
		type: AUTH_REQUEST_SIGNIN,
		payload: {
			username,
			password
		}
  }
};

export const authRequestSignUp = (username, password, name, phone, subscription, newsletter, country, state, promoId) => {
	return {
		type: AUTH_REQUEST_SIGNUP,
		payload: {
			username,
			password,
			name,
			phone,
			subscription,
			newsletter,
			country,
			state,
			promoId
		}
  }
};

export const authRequestSignOut = () => {
  return {
		type: AUTH_REQUEST_SIGNOUT
	}
};

export const authRequestResendActivation = username => {
  return {
		type: AUTH_REQUEST_RESEND_ACTIVATION,
		username
	}
};

export const authRequestUserActivate = (code) => {
  return {
		type: AUTH_REQUEST_USER_ACTIVATE,
		code
	}
};

export const authRequestResetPassword = username => {
  return {
		type: AUTH_REQUEST_RESET_PASSWORD,
		username
	}
};

export const authRequestChangePassword = (username, password, code) => {
  return {
		type: AUTH_REQUEST_CHANGE_PASSWORD,
		payload: {
			username,
			password,
			code
		}
	}
};

export const authSuccess = token => {
  return {
		type: AUTH_SUCCESS,
		token
  }
};

export const authFailure = message => {
  return {
		type: AUTH_FAILURE,
		message
  }
};

import { StatusName } from './types';

export const SETTINGS_INITIAL = {
  brandedStatus: 'none',
  unbrandedStatus: 'none',
  urlBranded: '',
  urlUnbranded: '',
  template: '1'
};

export const selectGenerating = (id: string) => (state: any) => 
  state.tour[id]?.videoSlideshow?.brandedStatus === StatusName.Generating
  || state.tour[id]?.videoSlideshow?.unbrandedStatus === StatusName.Generating;

export const selectUrlBranded = (id: string) => (state: any) => state.tour[id]?.videoSlideshow?.urlBranded;

export const selectUrlUnbranded = (id: string) => (state: any) => state.tour[id]?.videoSlideshow?.urlUnbranded;

export const selectTemplate = (id: string) => (state: any) => 
  state.tour[id]?.videoSlideshow?.template || SETTINGS_INITIAL.template;

export const selectAgent = (id: string) => (state: any) => state.tour[id]?.agent;

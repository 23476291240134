import React from 'react';
import { Field } from 'redux-form'

import { renderField } from '../renderField'

const Input = (props) => {
	const { 
		name,
		value,
		type,
		min,
		label,
		placeholder, 
		disabled,
		kind,
		color,
		autoFocus,
		onChange,
		btnAdd,
		btnRemove,
		onAdd,
		onRemove
	} = props;

	return (
		<Field
			autoFocus={autoFocus}
			disabled={disabled}
			name={name}
			value={value}
			type={type}
			min={min}
			kind={kind}
			color={color}
			placeholder={placeholder}
			onChange={onChange}
			btnAdd={btnAdd}
			btnRemove={btnRemove}
			onAdd={onAdd}
			onRemove={onRemove}
			component={renderField}
			label={label} />
	)
};

export { Input };

import React from 'react';
import { reduxForm } from 'redux-form';

import  {
  GridRow,
  GridCol
} from '@/components/Styled/Grid/index';
import PanelForm from '@/components/PanelForm/index';
import { RadioButtons } from '@/components/FormUI/index';

let FormStatsFrequency = (props) => {
  const {
    handleSubmit,
    submitSucceeded,
    loading
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <GridRow>
        <GridCol col="50">
          <RadioButtons
            name="statsFrequency"
            title="Report frequency:"
            options={[
              {
                value: 'weekly',
                label: 'Weekly',
              },
              {
                value: 'monthly',
                label: 'Monthly',
              },
            ]}
          />
        </GridCol>
        <GridCol>
          <PanelForm loading={loading && submitSucceeded} />
        </GridCol>
      </GridRow>
    </form>
  )
};

FormStatsFrequency = reduxForm({
  form: 'statsFrequency'
})(FormStatsFrequency)

export { FormStatsFrequency };

import React from 'react';
import { Field } from 'redux-form';

import withFormGroup from '../withFormGroup';

import {
	StyledSwitchMediaGroup,
	StyledSwitchMediaTitle,
	StyledSwitchMediaContainer,
	StyledSwitchMediaLabel,
	StyledSwitchMediaName,
	StyledSwitchMediaInput,
	StyledSwitchMediaCheckmark
} from './styles';

const renderField = ({
	input,
	options,
	title
}) => (
	<StyledSwitchMediaGroup>
		<StyledSwitchMediaTitle>{title}</StyledSwitchMediaTitle>
		<StyledSwitchMediaContainer>
			{options.map(({value, label, thumbnail}) => 
				<StyledSwitchMediaLabel key={value} 
					style={{backgroundImage: `url(${thumbnail}`}}>
					<StyledSwitchMediaInput
						{...input} 
						type="radio" 
						value={value}
						checked={value === input.value}
					/>
					<StyledSwitchMediaName>{label}</StyledSwitchMediaName>
					<StyledSwitchMediaCheckmark />
				</StyledSwitchMediaLabel>
			)}
		</StyledSwitchMediaContainer>
	</StyledSwitchMediaGroup>
);

const FieldComponent = withFormGroup(renderField);

const SwitchMedia = ({...rest}) => {
		return (
			<Field
				component={FieldComponent}
				{...rest}
			/>
		)
};

export { SwitchMedia };

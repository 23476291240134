import React from 'react';
import { reduxForm, change } from 'redux-form'

import { mapStates } from '@/mappers/mapStates';

import {
	countries,
	isCountryState
} from '@/helpers/countries';
import { statesUSA } from '@/helpers/statesUSA';
import { provincesCanada } from '@/helpers/provincesCanada';
import { tourUrls } from '@/helpers/tourUrls';

import { 
	Input,
	HSelect,
	RadioButtons,
	Submit 
} from '@/components/FormUI/index';
import { StyledFormGroup } from '@/components/FormUI/styles';

import { 
  ModalHeader, 
  ModalFooter 
} from '@/components/Modal/index';

const validate = values => {
  const { address, city, zipcode, state, country, idType } = values;
	const errors = {};
	
  if (!address) {
		// @ts-ignore
    errors.address = 'Required';
  }
  
  if (!city) {
		// @ts-ignore
    errors.city = 'Required';
  }
  
  if (!zipcode) {
		// @ts-ignore
    errors.zipcode = 'Required';
  }
  
  if (!state) {
		// @ts-ignore
    errors.state = 'Required';
  }
  
  if (!country) {
		// @ts-ignore
    errors.country = 'Required';
	}

	if (!idType) {
		// @ts-ignore
    errors.idType = 'Required';
	}
	
  return errors;
};
				
let FormNewTour = (props) => {
	const { loading, handleSubmit, onClose, selectedCountry, isEdit } = props;

	const handleChangeCountry = values => {
		props.dispatch(change('newTour', 'state', null));
	}

	return (
		<form onSubmit={handleSubmit}>
      <ModalHeader>
				{isEdit ? (
					'Change Tour Address'
				) : (
					'Add new tour'
				)}
      </ModalHeader>
			<Input 
				name="address"
				type="text"
				label="Address"
				autoFocus={true}
			/>
      <Input 
				name="city"
				type="text"
				label="City"
			/>
      <Input 
				name="zipcode"
				type="text"
				label="ZIP Code"
			/>
      <HSelect
				name="country"
				label="Country"
				options={countries}
				onChange={handleChangeCountry}
			/>
			{selectedCountry && isCountryState(selectedCountry) &&
				<HSelect
					name="state"
					label="State"
					options={mapStates(selectedCountry === 'usa' ? statesUSA : provincesCanada)}
				/>
			}
			<RadioButtons
				name="idType"
				title="Tour URL*"
				options={tourUrls}
			/>
			{isEdit &&
				<StyledFormGroup>
					<p style={{color: '#ff0000', fontSize: '13px'}}>
						* Changing the address of the tour can change the web address that you shared with your agent,
						please resend the new web address
					</p>
				</StyledFormGroup>
			}
			<ModalFooter>
				<Submit 
					type="button"
					boulder
					onClick={onClose}
				>
					Cancel
				</Submit>
				<Submit 
          primary
          loading={loading}
				>
					Save
				</Submit>
			</ModalFooter>
		</form>
	)
};

FormNewTour = reduxForm({
	form: 'newTour',
	validate
})(FormNewTour)

export { FormNewTour };

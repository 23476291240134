import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  item: {
    margin: '0 10px 10px 0',

    '& label': {
      display: 'block',
      marginBottom: 3
    }
  }
});

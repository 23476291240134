import { DeprecatedResponseData, OptionName, TourItem } from './types';

export const getMappedValueFromObject = (data: DeprecatedResponseData): TourItem[] => {
  const name = (data.matterport && OptionName.Matterport) 
    || (data.iguide && OptionName.Iguide) 
    || (data.cloudPano && OptionName.CloudPano);

  return [{
    name,
    matterport: name === OptionName.Matterport ? data.matterportUrl : '',
    iguide: name === OptionName.Iguide ? data.iguideUrl : '',
    cloudPano: name === OptionName.CloudPano ? data.cloudPanoUrl : '',
    zillow: ''
  }];
};

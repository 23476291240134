import {
	TOURS_REQUEST_FETCH,
	TOURS_REQUEST_GET,
	TOURS_SUCCESS_GET,
	TOURS_FAILURE_GET,
	TOURS_REQUEST_ADD,
	TOURS_SUCCESS_ADD,
	TOURS_FAILURE_ADD,
	TOURS_REQUEST_REMOVE,
	TOURS_SUCCESS_REMOVE,
	TOURS_FAILURE_REMOVE
} from '@/actions/types';

export const getToursRequest = (page, limit) => {
  return {
		type: TOURS_REQUEST_GET,
		payload: {
			page,
			limit
		}
	}
};

export const fetchToursRequest = (page, limit) => {
  return {
		type: TOURS_REQUEST_FETCH,
		payload: {
			page,
			limit
		}
	}
};

export const getToursSuccess = (items) => {
  return {
		type: TOURS_SUCCESS_GET,
		items
	}
};

export const getToursFailure = fetchError => {
  return {
		type: TOURS_FAILURE_GET,
		fetchError
	}
};

export const addTourRequest = (json, callback) => {
  return {
		type: TOURS_REQUEST_ADD,
		payload: {
			json,
			callback
		}
	}
};

export const addTourSuccess = () => {
  return {
		type: TOURS_SUCCESS_ADD
  }
};

export const addTourFailure = () => {
  return {
		type: TOURS_FAILURE_ADD
	}
};

export const removeTourRequest = (id, callback) => {
  return {
		type: TOURS_REQUEST_REMOVE,
		payload: {
			id,
			callback
		}
	}
};

export const removeTourSuccess = () => {
  return {
		type: TOURS_SUCCESS_REMOVE
  }
};

export const removeTourFailure = () => {
  return {
		type: TOURS_FAILURE_REMOVE
	}
};

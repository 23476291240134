import styled from 'styled-components';
import { rgba } from 'polished'
import { colors } from '@/defaults/index';

export const StyledFeedback = styled.div` 
	position: fixed;
	top: 50%;
	right: 0;
	z-index: 30;
	width: 1.875rem;
	height: 5.5rem;
	border-top-left-radius: 0.1875rem;
	border-bottom-left-radius: 0.1875rem;
	transform: translateY(-50%);
	background-color: ${colors.studio};
	box-shadow: -2px 2px 8px 1px ${rgba(colors.black, .2)};
	cursor: pointer;
`;

export const StyledFeedbackText = styled.p`
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 13px;
	font-weight: 300;
	white-space: nowrap;
	color: ${colors.white};
	transform: translateX(-50%) translateY(-50%) rotate(-90deg);
`;

import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import config from '@/config';

import { ApiPath } from '@/v2/shared/enums';
import { saveTourRequest } from '@/actions/tour';
import { saveTour } from '@/helpers/forms';
import { redirectTo } from '@/helpers/redirect';
import Title from '@/components/Title';
import PanelForm from '@/components/PanelForm';
import FileManager from '@/v2/shared/components/FileManager/FileManager';
import { File } from '@/v2/shared/components/FileManager/types';
import { useStyles } from './styles';

const Floorplan: React.FC = (props: any): ReactElement => {
	const FORM_NAME = 'floorplan';
	const { id, currentTour, loadingTour, token, initialPhotos, initialFiles } = props;
	const classes = useStyles();
	// @ts-ignore
  const skipUrl = `/${redirectTo({currentPage: FORM_NAME, activePages: currentTour.settings})}/${id}`;

	const [ photoList, setPhotoList ] = useState<File[]>([]);
	const [ fileList, setFileList ] = useState<File[]>([]);
	const [ photos, setPhotos ] = useState([]);
	const [ files, setFiles ] = useState([]);
	const [ uploading, setUploading ] = useState(false);

	useEffect(() => {
		setPhotos(initialPhotos || []);
		setPhotoList(initialPhotos?.map(mapFilesToFileGrid));

		setFiles(initialFiles || []);
		setFileList(initialFiles?.map(mapFilesToFileGrid));
	}, [initialPhotos]);

	const mapFilesToFileGrid = (file, index): File => {
		const url = typeof file === 'string' ? file : file.urls[2]?.url;
		const type = url?.split('.').pop();

		return {
			id: (index + 1).toString(),
			name: url,
			url,
			uploaded: true,
			saved: true,
			status: 'success',
			type,
			size: 0,
			fileData: file
		};
	};

	const getData = () => ({
		photos,
		files
	});

	const handleChangePhotos = (files: File[]) => {
		setPhotos(files.map(file => file.fileData));
	};

	const handleChangeFiles = (files: File[]) => {
		setFiles(files.map(file => file.fileData));
	};

	const handleSave = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, getData());
  };

  const handleSaveAndNext = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, getData(), true);
  };

  return (
    <>
			<Title name="Photos" />
			<div className={classes.section}>
				{uploading && <div className={classes.disabledSection}></div>}
				<FileManager
					prefix="floorplan_photos"
					url={config.API_URL + ApiPath.uploadPhotos + id}
					headers={{ 'Authorization': `Bearer ${token}` }}
					info="Accepted extensions: jpg, jpeg, png"
					files={photoList}
					enableRemovingFile={true}
					onChangeFiles={handleChangePhotos}
					onStart={() => setUploading(true)}
					onEnd={() => setUploading(false)}
				/>
			</div>
			<Title name="Files" />
			<div className={classes.section}>
				{uploading && <div className={classes.disabledSection}></div>}
				<FileManager
					prefix="floorplan_files"
					url={config.API_URL + ApiPath.uploadFiles + id}
					headers={{ 'Authorization': `Bearer ${token}` }}
					info="Accepted extensions: pdf"
					acceptExtensions={['application/pdf']}
					files={fileList}
					enableRemovingFile={true}
					onChangeFiles={handleChangeFiles}
					onStart={() => setUploading(true)}
					onEnd={() => setUploading(false)}
				/>
			</div>
			<PanelForm
				loading={loadingTour}
				disabled={uploading}
				onSave={handleSave}
				onSaveAndNext={handleSaveAndNext}
				skipUrl={skipUrl}
				formProps={{valid: true}}
			/>
		</>
	);
};

const mapStateToProps = (state: any, ownProps: any): any => {
  const id = ownProps.match.params.id;
  const currentTour = state.tour[id];
  const initialPhotos = currentTour?.floorplan?.photos;
  const initialFiles = currentTour?.floorplan?.files;

  return {
		token: state.auth.token,
    id,
    currentTour,
    initialPhotos,
		initialFiles,
    loadingTour: state.tour.loading
  };
};

const mapDispatchToProps = {
  saveTourRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Floorplan);

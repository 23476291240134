import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Button, ButtonToolbar, Checkbox, CheckboxGroup, ControlLabel, FlexboxGrid, Form, FormGroup, Icon, IconButton, Input, Message, Panel, Toggle } from 'rsuite';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from 'lodash';

import { save, connectDomain, connectEmail, getBrandingExample, sendTestEmail } from './http';
import { BrandingData, BrandingForm } from './types';
import ColorPicker from '../../shared/components/ColorPicker/ColorPicker';
import ErrorBlock from '../../shared/components/Form/ErrorBlock/ErrorBlock';
import SimpleUploader from '../../shared/components/SimpleUploader/SimpleUploader';
import Title from '@/components/Title';
import { selectBranding } from './selectors';
import { DEFAULT_VALUES, PHOTO_SIZE_ITEM, PRESET_COLORS, SCHEMA } from './constants';

const Branding: React.FC = (): ReactElement => {
	const branding: BrandingData = useSelector(selectBranding);

	const [ saving, setSaving ] = useState(false);
	const [ connectingDomain, setConnectingDomain ] = useState(false);
	const [ connectingEmail, setConnectingEmail ] = useState(false);
	const [ sendingTestEmail, setSendingTestEmail ] = useState(false);
	const [ myBrandingExampleUrl, setMyBrandingExampleUrl ] = useState('');
	const [ mediaSections, setMediaSections ] = useState<string[]>([]);

	const { 
		control,
		formState: { errors },
		handleSubmit,
		setValue,
		watch
	} = useForm<BrandingForm>({
		mode: 'onChange',
		shouldFocusError: false,
		resolver: yupResolver(SCHEMA),
		defaultValues: DEFAULT_VALUES
	});

	const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'photoSizes'
  });

	const watchCustomColorScheme = watch('customColorScheme');
	const watchSupportEnabled = watch('supportEnabled');

	useEffect(() => {
		getBrandingExampleUrl();
	}, []);

	useEffect(() => {
		if (branding) {
			setFormData(branding);
		}
	}, [ branding ]);

	const getBrandingExampleUrl = () => {
		getBrandingExample().then(res => {
			const { mediaDomain, hashId } = res?.data?.tours;

			setMyBrandingExampleUrl(`${mediaDomain}/${hashId}`);
		});
	};

	const setFormData = (data: BrandingData) => {
		Object.keys(DEFAULT_VALUES).forEach((key: any) => {
			setValue(key, data[key] || DEFAULT_VALUES[key]);
		});

		if (branding.mediaSections) {
			const sections = branding.mediaSections?.filter(item => item.name !== 'attachments');

			setMediaSections(sections.map(item => item.name));
			setValue('mediaSections', sections.filter(item => item.enabled).map(item => item.name));
		}
	};

	const handleConnectDomain = () => {
		setConnectingDomain(true);

		connectDomain().then(() => {
			Alert.success('Domain setup requested.');
		})
		.catch(() => {
			Alert.error('Something is wrong :( Try again!');
		})
		.finally(() => {
			setConnectingDomain(false);
		});
	}

	const handleConnectEmail = () => {
		setConnectingEmail(true);

		connectEmail().then(() => {
			Alert.success('Email setup requested.');
		})
		.catch(() => {
			Alert.error('Something is wrong :( Try again!');
		})
		.finally(() => {
			setConnectingEmail(false);
		});
	}

	const handleSendTestEmail = () => {
		setSendingTestEmail(true);

		sendTestEmail().then(() => {
			setSendingTestEmail(false);
			Alert.success('Test email has been sent!');
		});
	}

	const handleSave = (data: BrandingForm) => {
		branding['mediaSections'] = branding.mediaSections.map(item => {
			item.enabled = !!data.mediaSections.includes(item.name);
			return item;
		});

		const requestData: BrandingData = {...branding, ...omit(data, 'mediaSections')};

		setSaving(true);

		save(requestData)
			.then(() => {
				Alert.success('Branding settings have been saved!');
			})
			.catch(() => {
				Alert.error('Something is wrong :( Try again!');
			})
			.finally(() => {
				setSaving(false);
			});
	};

  return (
    <Panel>
			<Title name="Branding" />
			<Message 
				style={{ marginBottom: 30 }}
				type="info" 
				description={
					<>
						HomeOnTour allows for full white-labelling of our service. 
						Starting with connecting your domain and email address, to customs terms, copyright and privacy statements. 
						You can even use your own logo and colour scheme. Enjoy making our platform match your branding!
					</>
				} 
			/>
			<ButtonToolbar>
				<Button 
					color="cyan" 
					href="https://media.bestlensintown.com/a0a1cd708fa4033ab6d205f7f01828a9"
					target="_blank"
				>
					View Example Branding
				</Button>
				{myBrandingExampleUrl &&
					<Button 
						color="cyan" 
						href={myBrandingExampleUrl}
						target="_blank"
					>
						View My Branding
					</Button>
				}
				<Button 
					color="cyan"
					loading={sendingTestEmail}
					onClick={() => handleSendTestEmail()}
				>
					Send Test Email
				</Button>
			</ButtonToolbar>

			<Form layout="inline" style={{ marginTop: 30, marginBottom: -5 }}>
				{branding?.tourUrl === 'https://tour.homeontour.com' ? (
					<FormGroup>
						<Button 
							color="green" 
							style={{ width: 160 }}
							loading={connectingDomain}
							onClick={() => handleConnectDomain()}
						>
							Connect your domain
						</Button>
					</FormGroup>
				) : (
					<FormGroup>
						<ControlLabel style={{ width: 140 }}>Domain Connected:</ControlLabel> {' '}
						<Input style={{ width: 440 }} disabled readOnly value={branding?.tourUrl} />
						{' '}&nbsp;
						<Icon icon="check" style={{ color: 'green' }} />
					</FormGroup>
				)}
			</Form>
			<Form layout="inline">
				{branding?.customEmail === 'no-reply@homeontour.com' ? (
					<FormGroup>
						<Button 
							color="green" 
							style={{ width: 160 }}
							loading={connectingEmail}
							onClick={() => handleConnectEmail()}
						>
							Connect your email
						</Button>
					</FormGroup>
				) : (
					<FormGroup>
						<ControlLabel style={{ width: 140 }}>Email Connected:</ControlLabel> {' '}
						<Input style={{ width: 440 }} disabled readOnly value={branding?.customEmail} />
						{' '}&nbsp;
						<Icon icon="check" style={{ color: 'green' }} />
					</FormGroup>
				)}
			</Form>

			{(connectingEmail || connectingDomain) &&
				<Message
					type="info"
					description="Our staff has been informed about your request and will be in touch shortly."
				/>
			}
			<Form style={{ marginTop: 30 }}>
				<FormGroup>
					<ControlLabel>Company Name:</ControlLabel>
					<Controller
						name="companyName"
						control={control}
						render={({ field }) => <Input {...field} />}
					/>
				</FormGroup>
				<FormGroup>
					<ControlLabel>Company Address:</ControlLabel>
					<Controller
						name="companyAddress"
						control={control}
						render={({ field }) => <Input {...field} />}
					/>
				</FormGroup>
				<FormGroup>
					<ControlLabel>Company Website:</ControlLabel>
					<Controller
						name="companyUrl"
						control={control}
						render={({ field }) => <Input {...field} />}
					/>
					<ErrorBlock error={errors.companyUrl} />
				</FormGroup>
				<FormGroup>
					<ControlLabel>Copyright Info: (e.g. Company Name, All Rights Reserved):</ControlLabel>
					<Controller
						name="copyrightInfo"
						control={control}
						render={({ field }) => <Input {...field} />}
					/>
				</FormGroup>
				<FormGroup>
					<ControlLabel>Privacy Policy:</ControlLabel>
					<Controller
						name="privacyUrl"
						control={control}
						render={({ field }) => <Input {...field} />}
					/>
					<ErrorBlock error={errors.privacyUrl} />
				</FormGroup>
				<FormGroup>
					<ControlLabel>Terms and Conditions:</ControlLabel>
					<Controller
						name="termsUrl"
						control={control}
						render={({ field }) => <Input {...field} />}
					/>
					<ErrorBlock error={errors.termsUrl} />
				</FormGroup>
				<FormGroup>
					<ControlLabel>Support Enabled:</ControlLabel>
					<Controller
						name="supportEnabled"
						control={control}
						render={({ field }) => <Toggle size="md" checked={field.value} onChange={value => field.onChange(value)} />}
					/>
				</FormGroup>
				{watchSupportEnabled &&
					<FormGroup>
						<ControlLabel>Support Website:</ControlLabel>
						<Controller
							name="supportUrl"
							control={control}
							render={({ field }) => <Input {...field} />}
						/>
						<ErrorBlock error={errors.supportUrl} />
					</FormGroup>
				}
				<FormGroup>
					<ControlLabel>Custom Color Scheme:</ControlLabel>
					<Controller
						name="customColorScheme"
						control={control}
						render={({ field }) => <Toggle size="md" checked={field.value} onChange={value => field.onChange(value)} />}
					/>
				</FormGroup>
				{watchCustomColorScheme &&
					<FormGroup>
						<div style={{ display: 'flex' }}>
							<div style={{ marginRight: 40 }}>
								<ControlLabel>Button Color:</ControlLabel>
								<Controller
									name="color.button"
									control={control}
									render={({ field }) =>
										<ColorPicker 
											presetColors={PRESET_COLORS}
											value={field.value}
											onChange={value => field.onChange(value)}
										/>
									}
								/>
							</div>
							<div>
								<ControlLabel>Background Color:</ControlLabel>
								<Controller
									name="color.background"
									control={control}
									render={({ field }) =>
										<ColorPicker 
											presetColors={PRESET_COLORS}
											value={field.value}
											onChange={value => field.onChange(value)}
										/>
									}
								/>
							</div>
						</div>
					</FormGroup>
				}
				<FormGroup>
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: 60 }}>
							<ControlLabel>Favicon:</ControlLabel>
							<Controller
									name="favIcon"
									control={control}
									render={({ field }) => (
										<>
											<SimpleUploader
												accept="image/png"
												dimensions={[64, 64]}
												pictureUrl={field.value}
												onSuccess={value => field.onChange(value)}
												onClear={() => field.onChange('')}
											/>
											<Input type="hidden" {...field} />
										</>
									)}
								/>
						</div>
						<div style={{ marginRight: 60 }}>
							<ControlLabel>Logo (Media):</ControlLabel>
							<Controller
								name="logo"
								control={control}
								render={({ field }) => (
									<>
										<SimpleUploader
											pictureUrl={field.value}
											size="original"
											onSuccess={value => field.onChange(value)}
											onClear={() => field.onChange('')}
										/>
										<Input type="hidden" {...field} />
									</>
								)}
							/>
						</div>
						<div>
							<ControlLabel>Logo (Email):</ControlLabel>
							<Controller
								name="logoEmail"
								control={control}
								render={({ field }) => (
									<>
										<SimpleUploader
											pictureUrl={field.value}
											size="original"
											onSuccess={value => field.onChange(value)}
											onClear={() => field.onChange('')}
										/>
										<Input type="hidden" {...field} />
									</>
								)}
							/>
						</div>
					</div>
				</FormGroup>
				<FormGroup>
					<ControlLabel style={{ marginBottom: 15 }}>Photo download resolutions:</ControlLabel>
					{fields?.map((item, index) => (
						<FlexboxGrid align="bottom" key={item.id}>
							<FlexboxGrid.Item colspan={8}>
								<FormGroup style={{ paddingRight: 5 }}>
									<ControlLabel>Name:</ControlLabel>
									<Controller
										name={`photoSizes.${index}.name`}
										control={control}
										render={({ field: { ref, ...field } }) =>
											<Input
												{...field}
												value={field.value}
											/>
										}
									/>
								</FormGroup>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={4}>
								<FormGroup style={{ paddingRight: 5 }}>
									<ControlLabel>Width (px):</ControlLabel>
									<Controller
										name={`photoSizes.${index}.width`}
										control={control}
										render={({ field: { ref, ...field } }) =>
											<Input
												{...field}
												type="number"
												step="1"
												max="4000"
												value={field.value + ''}
											/>
										}
									/>
								</FormGroup>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={4}>
								<FormGroup style={{ paddingRight: 5 }}>
									<ControlLabel>Height (px):</ControlLabel>
									<Controller
										name={`photoSizes.${index}.height`}
										control={control}
										render={({ field: { ref, ...field } }) =>
											<Input
												{...field}
												type="number"
												step="1"
												max="4000"
												value={field.value + ''}
											/>
										}
									/>
								</FormGroup>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={8}>
								<ButtonToolbar>
									<IconButton 
										appearance="primary" 
										type="button" 
										icon={<Icon icon="plus" />}
										onClick={() => append(PHOTO_SIZE_ITEM, {shouldFocus: true})}
									/>
									{fields?.length > 1 &&
										<IconButton 
											color="red" 
											type="button" 
											icon={<Icon icon="minus" />}
											onClick={() => remove(index)}
										/>
									}
								</ButtonToolbar>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={24} style={{ marginBottom: 15 }}>
								<ErrorBlock error={errors?.photoSizes?.[index]?.name} />
								<ErrorBlock error={errors?.photoSizes?.[index]?.width} />
								<ErrorBlock error={errors?.photoSizes?.[index]?.height} />
							</FlexboxGrid.Item>
						</FlexboxGrid>
					))}
				</FormGroup>
				<FormGroup>
					<ControlLabel>Preserve original file names during download:</ControlLabel>
					<Controller
						name="photoOriginalName"
						control={control}
						render={({ field }) => <Toggle size="md" checked={field.value} onChange={value => field.onChange(value)} />}
					/>
				</FormGroup>
				<FormGroup>
					<ControlLabel>Media sections:</ControlLabel>
					<Controller
						name="mediaSections"
						control={control}
						render={({ field }) => (
							<CheckboxGroup
								inline
								value={field.value}
								onChange={value => field.onChange(value)}
								{...field}
							>
								{mediaSections.map(name => (
									<Checkbox key={name} value={name}>{name.toLocaleUpperCase()}</Checkbox>
								))}
							</CheckboxGroup>
						)}
					/>
				</FormGroup>
				<FormGroup>
					<Panel bordered>
						<Button 
							appearance="primary"
							onClick={handleSubmit(handleSave)}
							loading={saving}
						>
							Save
						</Button>
					</Panel>
				</FormGroup>
			</Form>
    </Panel>
  );
};

export default Branding;

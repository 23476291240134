import {
	ACCOUNT_SUCCESS_GET_INFO,
	ACCOUNT_REQUEST_SAVE_INFO,
	ACCOUNT_SUCCESS_SAVE_INFO,
	ACCOUNT_REQUEST_CHANGE_PASSWORD,
  ACCOUNT_SUCCESS,
  ACCOUNT_FAILURE
} from '@/actions/types';

const defaultState = {
	info: {},
	loading: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
		case ACCOUNT_SUCCESS_GET_INFO:
      return {
        ...state,
        info: action.data
			};
		case ACCOUNT_REQUEST_SAVE_INFO:
		case ACCOUNT_REQUEST_CHANGE_PASSWORD:
			return {
				...state,
				loading: true
			};
		case ACCOUNT_SUCCESS_SAVE_INFO:
			return {
				...state,
				loading: false
			};
		case ACCOUNT_SUCCESS:
      return {
				...state,
				token: action.token,
				loading: false
			};
		case ACCOUNT_FAILURE:
      return {
				...state,
				loading: false
			};
    default:
      return state;
  }
};

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Button, Col, ControlLabel, Form, FormControl, FormGroup, Grid, Loader, 
  Message, Modal, Radio, RadioGroup, Row
} from 'rsuite';

import { RSFormState, useStyles } from '@/v2/shared/components/RSForm';
import { initialFormState } from './constants';
import { model } from './schema';
import { CustomDomainFormProps } from './types';

const CustomDomainForm = ({ value, pricing, onSubmit, getLoading, postLoading, footer }: CustomDomainFormProps): ReactElement => {
  const classes = useStyles();
  const form = useRef<HTMLFormElement>(null);
  const [ formState, setFormState ] = useState<RSFormState>(initialFormState);
  const { formValue } = formState;

  useEffect((): void => {
    setFormState((state) => ({
      ...state,
      formValue: {
        ...state.formValue,
        ...value
      }
    }));
  }, [value]);

  const handleSubmit = (): void => {
    if (!form.current.check()) {
      return;
    }
    onSubmit(formState.formValue);
  };

  const renderLoading = (): ReactElement =>
    <Loader content="Loading..." />;

  return (
    <Modal.Body>
      <Form
        className={classes.root}
        fluid
        ref={form}
        onChange={formValue => {
          setFormState((state) => ({
            ...state,
            ...{formValue}
          }));
        }}
        onCheck={formError => {
          setFormState(state => ({
            ...state,
            ...{formError}
          }));
        }}
        formValue={formValue}
        model={model}
      >
        <Grid fluid style={{ overflow: 'hidden' }}>
          <Row gutter={16}>
            <Col xs={24}>

              <Message
                style={{ marginBottom: 10 }}
                type="warning"
                showIcon
                description={
                  <p>
                    Custom domains must be purchased separately before linking.
                    Please use <a href="https://www.godaddy.com" target="_blank">GoDaddy</a> to purchase your domain.
                  </p>
                }
              />

              <Message
                style={{ marginBottom: 25 }}
                type="warning"
                showIcon
                description={
                  <p>
                    There is {pricing?.priceAsString} charge for each added domain.
                  </p>
                }
              />

              <FormGroup>
                <ControlLabel>Domain</ControlLabel>
                {getLoading ? (
                  renderLoading()
                ) : (
                  <FormControl name="domain" />
                )}
              </FormGroup>
              <FormGroup>
                {getLoading ? (
                  renderLoading()
                ) : (
                  <FormControl
                    name="branded"
                    accepter={RadioGroup}
                    inline
                  >
                    <Radio key={1} value={false}>Unbranded</Radio>
                    <Radio key={2} value={true}>Branded</Radio>
                  </FormControl>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Grid>
      </Form>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleSubmit} loading={postLoading}>
          Save
        </Button>
        {footer}
      </Modal.Footer>
    </Modal.Body>
  )
};

export default CustomDomainForm;

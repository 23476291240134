import styled from 'styled-components';
import { darken } from 'polished'
import { colors } from '@/defaults/index';

export const StyledModalConfirmContent = styled.div`
	margin-top: 0.9375rem;
	font-size: 1rem;
	text-align: center;
`;

export const StyledModalConfirmFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 1.875rem;
`;

export const StyledModalConfirmBtn = styled.button`
	margin-left: 0.375rem;
	padding: 0.5rem 1.125rem;
	border: none;
	border-radius: 0.125rem;
	background-color: ${props => props.color};
	color: ${colors.white};
	outline: none;
	transition: background-color .2s ease-out;

	&:hover {
		background-color: ${props => darken(0.1, props.color)};
	}
`;

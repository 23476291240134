import React, { ReactElement } from 'react';
import { HelpBlock } from 'rsuite';

import { ErrorBlockProps } from './types';

const ErrorBlock = ({ error }: ErrorBlockProps): ReactElement =>
	<>
		{error &&
			<HelpBlock style={{ color: '#f16161' }}>{error.message}</HelpBlock>
		}
	</>;

export default ErrorBlock;

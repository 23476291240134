import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  nav: {
    margin: '30px 0',

    '& .rs-nav-item > .rs-nav-item-content': {
      padding: '20px 40px'
    }
  }
});

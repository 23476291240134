import styled from 'styled-components';
import { darken } from 'polished'
import { colors } from '@/defaults/index';

export const StyledList = styled.ul`
	width: 100%;
	max-width: 28.125rem;
	margin-bottom: 30px;
`;

export const StyledItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.875rem 0.25rem;
	border-bottom: 0.0625rem solid ${colors.wildSand}; 
`;

export const StyledItemName = styled.div`
	margin-right: 0.9375rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: ${colors.doveGray};
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledItemBtnRemove = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
	flex: 0 0 1.825rem;
  width: 1.825rem;
  height: 1.825rem;
  border: none;
  border-radius: 0.1875rem;
  background-color: ${colors.carnation};
	font-size: 1.25rem;
	color: ${colors.white};
  overflow: hidden;
	box-shadow: 0 1px 1px 1px rgba(0,0,0,0.05);
  transition: background-color .2s ease-out;
  outline: none;

  &:hover:not(:disabled) {
    background-color: ${darken(0.1, colors.carnation)};
  }

	&:disabled {
		background-color: ${darken(0.2, colors.silver)};
	}
`;

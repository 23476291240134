import { put, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import {
	TOURS_REQUEST_GET,
	TOURS_SUCCESS_GET,
	TOUR_REQUEST_GET,
	TOUR_SUCCESS_GET,
	PERMISSIONS_REQUEST_GET,
	PERMISSIONS_SUCCESS_GET,
	AGENTS_REQUEST_GET,
	AGENTS_SUCCESS_GET,
	LAYOUT_REQUEST_FAKE,
  LAYOUT_SUCCESS_FAKE
 } from '@/actions/types';

 import { 
  loadingContentOn,
  loadingContentOff
} from '@/actions/layout';

export function* request() {
  yield put(loadingContentOn());
}

export function* responseSuccess() {
	yield delay(250);
  yield put(loadingContentOff());
}

export function* watchFetchSaga() {
  yield takeLatest(TOURS_REQUEST_GET, request);
	yield takeLatest(TOURS_SUCCESS_GET, responseSuccess);
	yield takeLatest(TOUR_REQUEST_GET, request);
	yield takeLatest(TOUR_SUCCESS_GET, responseSuccess);
	yield takeLatest(PERMISSIONS_REQUEST_GET, request);
	yield takeLatest(PERMISSIONS_SUCCESS_GET, responseSuccess);
	yield takeLatest(AGENTS_REQUEST_GET, request);
	yield takeLatest(AGENTS_SUCCESS_GET, responseSuccess);
	yield takeLatest(LAYOUT_REQUEST_FAKE, request);
  yield takeLatest(LAYOUT_SUCCESS_FAKE, responseSuccess);
}

import React, { Component } from 'react';

import { authRequestChangePassword } from '@/actions/auth';

import { FormChangePassword } from '@/components/Forms/index';

import WrappedAuth from '@/components/WrappedAuth/index';

class ChangePassword extends Component<any> {
	handleSubmit = values => {
		const { username, password, code } = values;

		this.props.authRequestChangePassword(username, password, code);
	}

	render() {
    return (
			<FormChangePassword 
				onSubmit={this.handleSubmit}
				loading={this.props.loading}
			/>
		)
	}
}

const mapStateToProps = state => ({
  loading: state.auth.loading
});

const mapDispatchToProps = {
  authRequestChangePassword
};

export default WrappedAuth(ChangePassword, mapStateToProps, mapDispatchToProps);

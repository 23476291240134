import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Icon, IconButton, Loader, Uploader } from 'rsuite';
import { FileType } from 'rsuite/lib/Uploader';
import { useSelector } from 'react-redux';
import { first, get } from 'lodash';

import config from '@/config';
import { SimpleUploaderProps } from './types';
import { imageButton, useStyles } from './styles';

const SimpleUploader = (props: SimpleUploaderProps): ReactElement => {
	const classes = useStyles();

	const {
		pictureUrl,
		accept = 'image/jpg,image/jpeg,image/png',
		size = 'xs',
		dimensions = [],
		onSuccess,
		onClear
	} = props;

	const token = useSelector((state: any) => state.auth.token);

	const [ uploading, setUploading ] = useState(false);
	const [ fileInfo, setFileInfo ] = useState<string | null>(null);

	const isUploadWithSize = dimensions.length === 2;
	const actionUrl = isUploadWithSize ? 'auth/user/uploadWithSize' : 'auth/user/upload';

	useEffect(() => {
		if (pictureUrl) {
				setFileInfo(pictureUrl);
		}
	}, [ pictureUrl ]);

	const handleClear = () => {
		setFileInfo('');
		onClear();
	};

	return (
		<div className={classes.root}>
			{fileInfo &&
				<IconButton 
					icon={<Icon icon="window-close" />} 
					color="red" 
					size="xs"
					className={classes.clearButton}
					onClick={handleClear}
				/>
			}
			<Uploader 
				listType="picture"
				fileListVisible={false}
				name="files"
				data={isUploadWithSize ? { 'size[0]': dimensions[0], 'size[1]': dimensions[1] } : {}}
				action={`${config.API_URL}/${actionUrl}`}
				headers={{
					'Authorization': `Bearer ${token}`
				}}
				accept={accept}
				onUpload={file => {
					setUploading(true);
					
					previewFile(file.blobFile, value => {
						setFileInfo(value);
					});
				}}
				onSuccess={(response: any, file: FileType) => {
					setUploading(false);

					if (isUploadWithSize) {
						onSuccess(response?.data?.files[0]?.urls[1]?.url);
					} else {
						onSuccess(getPhotoSize(response?.data?.files)?.[size]);
					}
				}}
				onError={() => {
					setFileInfo(null);
					setUploading(false);
					
					Alert.error('Upload failed');
				}}
			>
				<button style={imageButton}>
					{uploading && <Loader backdrop center />}
					{fileInfo ? (
						<img src={fileInfo} width="100%" height="100%" alt=""/>
					) : (
						<Icon icon='camera-retro' size="2x" />
					)}
				</button>
			</Uploader>
		</div>
	);
};

export default SimpleUploader;

function previewFile(file, callback) {
	const reader = new FileReader();
	
	reader.onloadend = () => {
		callback(reader.result);
	};

	reader.readAsDataURL(file);
}

const getPhotoSize = (photos = []) =>
	first(photos.map(item => ({
    original: get(item.urls.find(el => el.size === 'orig'), 'url'),
    xs: get(item.urls.find(el => el.size === '500x380'), 'url'),
    md: get(item.urls.find(el => el.size === '1024x1024'), 'url'),
    xl: get(item.urls.find(el => el.size === '2560x1440'), 'url')
      || get(item.urls.find(el => el.size === '1920x1080'), 'url')
      || get(item.urls.find(el => el.size === '1600x900'), 'url')
  })));

import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledUser = styled.div<any>`
	position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 3.75rem;
  width: 100%;
  padding: 0 1.25rem;
  background-color: ${colors.athensGray};
  box-shadow: 0 7px 4px -2px rgba(0,0,0,.1);

  // @ts-ignore
  ${props => props.pull && `
    justify-content: center;
    padding: 0;
  `};
`;

export const StyledUserAvatar = styled.div<any>`
  flex: 0 0 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.375rem;
  background-color: ${colors.white};
  border-radius: 0.25rem;
  overflow: hidden;
`;

export const StyledUserImage = styled.img`
	width: 100%;
  height: 100%;
`;

export const StyledUserName = styled.p`
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${colors.catalinaBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledUserSignOut = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: ${colors.catalinaBlue};
  cursor: pointer;
`;

import { SelectPickerItem } from '@/v2/shared/types/SelectPicker';
import { Countries } from '@/v2/shared/enums';

const usStatesData = require('./data/usStates.json');
const caProvincesData = require('./data/caProvinces.json');

export const getStateFieldData = (selected: string): SelectPickerItem[] => {
  switch (selected) {
    case Countries.USA:
      return usStatesData;
      break;
    case Countries.Canada:
      return caProvincesData;
      break;
    default:
      return [];
  }
};

export const isStateField = (selected: string): boolean =>
  selected === Countries.USA || selected === Countries.Canada;

export const subscriptions = {
	packagePhotographerFree: {
		value: 'packagePhotographerFree',
		title: 'Free',
		price: 'Free',
		info: 'Let\'s grow your business together',
		options: [
			'Up to 5 tours'
		]
	},
	package39: {
		value: 'package39',
		title: 'Side Hustle',
		price: '$39 (USD) per month',
		info: 'Photography is your side job',
		options: [
			'10 new tours per month',
			'Cancel any time*'
		]
	},
	package79: {
		value: 'package79',
		title: 'Local Legend',
		price: '$79 (USD) per month',
		info: 'Full time photographer',
		options: [
			'30 new tours per month',
			'Cancel any time*'
		]
	},
	package139: {
		value: 'package139',
		title: 'Agency',
		price: '$139 (USD) per month',
		info: 'Boutique Agency',
		options: [
			'60 new tours per month',
			'$2.50 per tour above 60',
			'Cancel any time*'
		]
	}
};

export const subscriptionAsteriskList = [
	'*All published tours will become immediately unavailable',
	'*During 3 months grace period you can reenable your tours anytime by restarting your subscription',
  '*After 3 monts all inactive subscriptions will be deleted'
];

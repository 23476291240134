import { call, put, takeLatest, select } from 'redux-saga/effects';

import Api from '@/services/api';

import { catchSuccess } from './catchSuccess';
import { catchError } from './catchError';

import { getToken } from '@/selectors/index';

import { 
  AGENTS_REQUEST_FETCH,
  AGENTS_REQUEST_GET,
  AGENTS_REQUEST_SAVE,
  AGENTS_REQUEST_REMOVE
} from '@/actions/types';

import {
  getAgentsSuccess,
  getAgentsFailure,
  saveAgentsSuccess,
  saveAgentsFailure,
  removeAgentsSuccess,
  removeAgentsFailure
} from '@/actions/agents';

function* getAgentsRequest() {
  try {
    const token = yield select(getToken);
    
		const { data } = yield call(Api.getAgents, token);
		
		yield put(getAgentsSuccess(data.agents));
  } catch (error) {
		yield put(getAgentsFailure());
    yield catchError(error);
  }
}

function* saveAgentsRequest({ payload: {data, msg, callback} }) {
  try {
    const token = yield select(getToken);
    
    const { message } = yield call(Api.saveAgent, data, token);
    const { data: { agents } } = yield call(Api.getAgents, token);

    yield put(getAgentsSuccess(agents));
    yield put(saveAgentsSuccess());
    yield catchSuccess(message);
		
		callback();
  } catch (error) {
    yield put(saveAgentsFailure());
    yield catchError(error);
  }
}

function* removeAgentsRequest({ payload: {data, callback} }) {
  try {
    const token = yield select(getToken);
    const { agentId } = data;

    const { message } = yield call(Api.removeAgent, agentId, token);
    const { data: { agents } } = yield call(Api.getAgents, token);

    yield put(getAgentsSuccess(agents));
    yield put(removeAgentsSuccess());
    yield catchSuccess(message);

    callback();
  } catch (error) {
    yield put(removeAgentsFailure());
    yield catchError(error);
  }
}

export function* getAgentsRequestSaga() {
  yield takeLatest(AGENTS_REQUEST_FETCH, getAgentsRequest);
  yield takeLatest(AGENTS_REQUEST_GET, getAgentsRequest);
}

export function* saveAgentsRequestSaga() {
  // @ts-ignore
  yield takeLatest(AGENTS_REQUEST_SAVE, saveAgentsRequest);
}

export function* removeAgentsRequestSaga() {
  // @ts-ignore
  yield takeLatest(AGENTS_REQUEST_REMOVE, removeAgentsRequest);
}

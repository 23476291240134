import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -15px'
  },
  card: {
    position: 'relative',
    width: 220,
    margin: '0 15px 70px',
    border: '2px solid #f1f1f1',
    borderRadius: 3,
    cursor: 'pointer',

    '& .rs-badge': {
      position: 'absolute',
      top: -10,
      right: -10,
      fontSize: 14,
      lineHeight: '20px'
    },
    
    '& .rs-btn-toolbar': {
      position: 'absolute',
      left: 0,
      bottom: -40,
      width: '100%'
    },

    '& .rs-btn-sm': {
      width: 'calc(50% - 3px)'
    }
  },
  activeCard: {
    borderColor: '#3498ff',

    '& $name': {
      backgroundColor: '#3498ff',
      color: '#fff'
    }
  },
  disabledCard: {
    cursor: 'not-allowed',

    '& img': {
      filter: 'grayscale(1)',
      opacity: '.55'
    }
  },
  imageWrapper: {
    position: 'relative',
    height: 235,
    overflow: 'hidden',

    '& img': {
      height: 'auto',
      width: '100%'
    },
  },
  name: {
    padding: '5px 0 7px',
    textAlign: 'center',
    color: '#5c5c5c',
    fontWeight: 600,
    letterSpacing: '0.05rem',
    backgroundColor: '#fff'
  } 
});


import { httpPost } from "@/services/api/http";
import { ApiPath } from "@/v2/shared/enums";
import store from "@/store/index";

export const getMusic = (): Promise<any> =>
  httpPost(ApiPath.GetMusic, null, getHeaders());

export const getUserMusic = (userId: string): Promise<any> =>
  httpPost(ApiPath.GetUserMusic, { userId }, getHeaders());

const getHeaders = (): any => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

function getToken() {
  return store().getState().auth.token;
}

import { SelectPickerItem } from '@/v2/shared/types/SelectPicker';

export const mapAgentsToSelectPicker = (data: any[]): SelectPickerItem[] =>
  data.map(({id, agentName}) => ({
    value: id,
    label: agentName
  }));

export const mapToInitials = (name: string): string =>
  name?.split(' ').map((n)=>n[0]).join(' ');

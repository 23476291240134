import {
	SUBSCRIPTION_SUCCESS_CHECK,
	SUBSCRIPTION_FAILURE_CHECK
} from '@/actions/types';

const defaultState = {
	enabled: true
};

export default (state = defaultState, action) => {
  switch (action.type) {
		case SUBSCRIPTION_SUCCESS_CHECK:
			return {
				...state,
				enabled: true
			};
		case SUBSCRIPTION_FAILURE_CHECK:
			return {
				...state,
				enabled: false
			};
    default:
      return state;
  }
};

import {
	SUBSCRIPTION_REQUEST_CHECK,
	SUBSCRIPTION_SUCCESS_CHECK,
	SUBSCRIPTION_FAILURE_CHECK
} from '@/actions/types';

export const checkSubscriptionRequest = () => {
  return {
		type: SUBSCRIPTION_REQUEST_CHECK
	}
};

export const checkSubscriptionSuccess = () => {
  return {
		type: SUBSCRIPTION_SUCCESS_CHECK
	}
};

export const checkSubscriptionFailure = () => {
  return {
		type: SUBSCRIPTION_FAILURE_CHECK
	}
};

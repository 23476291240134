import React, { ReactElement, useEffect } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';

import config from '@/config';
import useGoogleMaps from '../../hooks/useGoogleMaps';
import { GoogleAutocompleteProps } from './types';

const GoogleAutocomplete = ({ 
	placeholder = 'Enter the address...',
	defaultValue,
	geocodeByAddressIsOff,
	onChange
}: GoogleAutocompleteProps): ReactElement => {
	const { isLoaded } = useGoogleMaps();

	useEffect(() => {
		!geocodeByAddressIsOff && isLoaded && defaultValue && geocodeByAddress(defaultValue)
			.then(res => {
				onChange({ value: { place_id: res?.[0]?.place_id } });
			});
	}, [isLoaded]);
	
	return (
		<>
			{isLoaded &&
				<GooglePlacesAutocomplete
					apiKey={config.GOOGLE_MAPS_API_KEY}
					autocompletionRequest={{
						componentRestrictions: { country: ['us', 'ca', 'au', 'nz', 'vi'] },
						types: ['address']
					}}
					selectProps={{
						placeholder,
						...(defaultValue && { defaultValue: { label: defaultValue, value: defaultValue } }),
						onChange,
						styles: {
							menu: (provided) => ({
								...provided,
								zIndex: 100
							})
						}
					}}
				/>
			}
		</>
	);
};

export default GoogleAutocomplete;

import React, { memo, ReactElement } from 'react';
import { Icon, IconButton, Panel, Progress, Tooltip, Whisper } from 'rsuite';
import classnames from 'classnames';
import { useDraggable, useDroppable } from '@dnd-kit/core';

import { useStyles } from './file-card.styles';
import { FileCardProps, FileCardType } from '../types';

const { Line } = Progress;

const FileCard = ({
	id,
	index,
  status,
	saved,
	uploaded,
  colSize,
	fileProgress,
	isSelected,
	imageComp,
	editable,
	removable,
	onSelectionChange,
	onEdit,
	onRemove
}: FileCardProps): ReactElement => {
	const classes = useStyles();

  const { setNodeRef: dragRef, attributes, listeners, isDragging } = useDraggable({
    id,
		data: {
      index,
			accepts: [FileCardType.FILE_CARD]
    },
  });

  const { setNodeRef: dropRef } = useDroppable({
    id,
    data: {
			type: FileCardType.FILE_CARD
    }
  });

	return (
		<div
      ref={dropRef} 
			className={
				classnames(
					classes.item,
          { [classes.itemSelected]: isSelected },
          { [`itemSize${colSize}`]: true }
				)
			}
			style={{'opacity': isDragging ? 0 : 1}}
			onClick={(evt) => onSelectionChange(index, evt.metaKey, evt.shiftKey, evt.ctrlKey)}
		>
			<Panel bordered bodyFill>
				{editable &&
					<IconButton
						icon={<Icon icon="edit"/>}
						color="cyan"
						size="sm"
						circle
						className={classes.editButton}
						onClick={(evt) => onEdit(id, evt)}
					/>
				}
				{removable &&
					<IconButton
						icon={<Icon icon="close"/>}
						color="red"
						size="sm"
						circle
						className={classes.removeButton}
						onClick={(evt) => onRemove(id, evt)}
					/>
				}
				<div
					className={classes.itemBody}
					ref={dragRef} 
					{...listeners} 
					{...attributes}
				>
					<div className={classes.image}>
						<div className={classes.imageOverlay}></div>
						{imageComp}
					</div>
					{saved &&
						<Whisper placement="top" trigger="hover" speaker={<Tooltip>Published</Tooltip>}>
							<Icon icon="data-authorize" className={classes.uploadedStatus} />
						</Whisper>
					}
					{!uploaded &&
						<div className={classes.progressBar}>
							<Line
								percent={status === 'inited' ? 0 : status === 'uploading' ? fileProgress : 100}
								strokeWidth={4}
								status={status === 'success' ? 'success' : status === 'failure' ? 'fail' : 'active'}
							/>
						</div>
					}
				</div>
			</Panel>
		</div>
	);
};

export default memo(FileCard);

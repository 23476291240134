import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { 
	Button,
	ControlLabel,
	Divider,
	FlexboxGrid,
	Input,
	Pagination,
	Panel,
	SelectPicker
} from 'rsuite';

import { useStyles } from './styles';
import { getUserId } from '@/selectors/index';
import { removeAgentsRequest } from '@/actions/agents';
import Title from '@/components/Title';
import Agent from '@/components/Agent/Agent';
import AgentList from './AgentList';
import ConfirmModal from '@/v2/shared/components/ConfirmModal/ConfirmModal';
import { 
	getAgentAvatar, 
	sortAgentListASC,
	sortAgentListDESC,
	sortAgentListByLastAdded
} from './helper';
import { AgentListItem } from './types';
import data from './data.json';

const PAGE_SIZE = 20;
const DEFAULT_SORT_TYPE = 'asc';

const Agents: React.FC = (props: any): ReactElement => {
	const classes = useStyles();

	const sortFunctions = {
		'asc': sortAgentListASC,
		'desc': sortAgentListDESC,
		'lastAdded': sortAgentListByLastAdded
	};

	const { items, userId, loading, removeAgentsRequest } = props;
	const [ agentList, setAgentList ] = useState<AgentListItem[]>([]);
  const [ agentModal, setAgentModal ] = useState(false);
  const [ confirmModal, setConfirmModal ] = useState(false);
	const [ activeAgentId, setActiveAgentId ] = useState(null);
	const [ activePage, setActivePage ] = useState(1);
	const [ searchedText, setSearchedText ] = useState('');
	const [ sortBy, setSortBy ] = useState(DEFAULT_SORT_TYPE);

	useEffect(() => {
		setAgentList(items);
	}, [items]);

	useEffect(() => {
		if (searchedText.length > 0 && activePage !== 1) {
			setActivePage(1);
		}
	}, [searchedText]);

	const getAgentList = () => {
		const pattern = new RegExp(searchedText, 'i');

		let items = agentList
			.map(mapItemsToAgentList)
			.filter(s => pattern.test(s.name) || pattern.test(s.subname));

		const numberOfPages = items.length;

		items = items.sort(sortFunctions[sortBy])
			.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

		return { numberOfPages, items };
	};

	const mapItemsToAgentList = (item: any) => ({
		id: item.id,
		name: item.agentName,
		subname: item.brokerageName,
		avatar: getAgentAvatar(item.avatar),
		edit: userId === item.userId,
		createTime: item.createTime
	});

	const getNumberOfPages = (pages: number): number => Math.ceil(pages / PAGE_SIZE);

	const toggleAgentModal = () => {
		if (!agentModal) {
			setActiveAgentId(null);
		}

		setAgentModal(!agentModal);
	};

	const handleChangePage = (page: number) => {
		setActivePage(page);
	};

	const handleEditItem = (id: string) => {
		setActiveAgentId(id);
		setAgentModal(true);
	};

	const handleRemoveItem = (id: string) => {
		setActiveAgentId(id);
		setConfirmModal(true);
	};

	const handleConfirmRemoveItem = useCallback(() => {
		removeAgentsRequest({
			agentId: activeAgentId
		}, () => {
			setConfirmModal(false);
			setActivePage(1);
		});
	}, [agentList, activeAgentId]);

	const list = getAgentList();
	const numberOfPages = getNumberOfPages(list.numberOfPages);

  return (
    <Panel>
      <Title name="Agents" />

			<FlexboxGrid
				align="middle"
				className={classes.grid}
    	>
				<FlexboxGrid.Item>
					<Button
						appearance='primary'
						size="sm"
						onClick={toggleAgentModal}
					>
						Add new agent
					</Button>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item>
					<Input 
						className={classes.field}
						placeholder="Enter the name of the agent or brokerage"
						onChange={value => setSearchedText(value)}
					/>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item>
					<ControlLabel style={{ marginLeft: 10 }}>Sort by:</ControlLabel>
					<SelectPicker 
						data={data} 
						className={classes.field} 
						searchable={false}
						cleanable={false}
						defaultValue={DEFAULT_SORT_TYPE}
						onChange={value => setSortBy(value)}
					/>
				</FlexboxGrid.Item>
			</FlexboxGrid>

			<AgentList
				items={list.items}
				onEdit={handleEditItem}
				onRemove={handleRemoveItem}
			/>

			{numberOfPages > 1 &&
				<>
					<Divider />
					<div style={{ textAlign: 'center' }}>
						<Pagination
							prev
							next
							ellipsis
							boundaryLinks
							size="lg"
							pages={numberOfPages}
							maxButtons={5}
							activePage={activePage}
							onSelect={handleChangePage}
						/>
					</div>
				</>
			}

			<Agent
				show={agentModal}
				onClose={toggleAgentModal}
				agentId={activeAgentId}
				onSaved={() => setActivePage(1)}
			/>

			<ConfirmModal 
				show={confirmModal}
				message="Are you sure you want to delete this agent?"
				loading={loading}
				onCancel={() => setConfirmModal(false)}
				onConfirm={handleConfirmRemoveItem}
			/>
    </Panel>
  );
};

const mapStateToProps = state => ({
	userId: getUserId(),
	items: state.agents.items,
	loading: state.agents.loading
});

const mapDispatchToProps = {
	removeAgentsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Agents);

export interface DeprecatedResponseData {
  matterport: boolean;
  iguide: boolean;
  cloudPano: boolean;
  matterportUrl : string;
  iguideUrl : string;
  cloudPanoUrl : string;
}

export interface Tour360Form {
  tours: TourItem[];
}

export interface TourItem {
  name: OptionName;
  matterport: string;
  iguide: string;
  cloudPano: string;
  zillow: string;
}

export enum OptionName {
  Matterport = 'matterport',
  Iguide = 'iguide',
  CloudPano = 'cloudPano',
  Zillow = 'zillow'
}

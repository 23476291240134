import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '@/services/api';
import Storage from '@/services/storage';

import { history } from '@/routes/index';

import { catchSuccess } from './catchSuccess';
import { catchError } from './catchError';

import {
	AUTH_REQUEST_SIGNIN,
	AUTH_REQUEST_SIGNUP,
  AUTH_REQUEST_SIGNOUT,
  AUTH_REQUEST_RESEND_ACTIVATION,
  AUTH_REQUEST_USER_ACTIVATE,
  AUTH_REQUEST_RESET_PASSWORD,
  AUTH_REQUEST_CHANGE_PASSWORD
} from '@/actions/types';

import { resetApp } from '@/actions/app';

import {
  authSuccess,
  authFailure
} from '@/actions/auth';

function* requestSignIn({payload: { username, password }}) {
  try {
    const { data, message } = yield call(Api.authSignIn, username, password);
    const { token } = data;
    
    Storage.saveToken(token);

    yield put(authSuccess(token));
    yield catchSuccess(message);
  } catch (error) {
    Storage.removeToken();

    yield put(authFailure(error.data && error.data.message));
    yield catchError(error);
  }
}

function* requestSignUp({payload: { username, password, name, phone, subscription, newsletter, country, state, promoId }}) {
  try {
    yield call(Api.authSignUp, username, password, name, phone, subscription, newsletter, country, state, promoId);
    yield put(authSuccess(null));

    history.push('/user-activate');
  } catch (error) {
    yield put(authFailure(null));
    yield catchError(error);
  }
}

export function* requestSignOut() {
    Storage.removeToken();

    yield put(authSuccess(null));
    yield put(resetApp());
    yield catchSuccess('SIGNOUT');
}

export function* requestResendActivation({ username }) {
  try {
    const { message } = yield call(Api.authResendActivation, username);

    yield put(authSuccess(null));
    yield catchSuccess(message);

    history.push('/user-activate');
  } catch (error) {
    yield put(authFailure(null));
    yield catchError(error);
  }
}

export function* requestUserActivate({ code }) {
  try {
    const { message } = yield call(Api.authUserActivate, code);

    yield put(authSuccess(null));
    yield catchSuccess(message);

    history.push('/sign-in');
  } catch (error) {
    yield put(authFailure(null));
    yield catchError(error);
  }
}

export function* requestResetPassword({ username }) {
  try {
    const { message } = yield call(Api.authResetPassword, username);

    yield put(authSuccess(null));
    yield catchSuccess(message);

    history.push('/change-password');
  } catch (error) {
    yield put(authFailure(null));
    yield catchError(error);
  }
}

export function* requestChangePassword({payload: { username, password, code }}) {
  try {
    const { message } = yield call(Api.authChangePassword, username, password, code);

    yield put(authSuccess(null));
    yield catchSuccess(message);

    history.push('/sign-in');
  } catch (error) {
    yield put(authFailure(null));
    yield catchError(error);
  }
}

export function* authRequestSignInSaga() {
  // @ts-ignore
  yield takeLatest(AUTH_REQUEST_SIGNIN, requestSignIn);
}

export function* authRequestSignUpSaga() {
  // @ts-ignore
  yield takeLatest(AUTH_REQUEST_SIGNUP, requestSignUp);
}

export function* authRequestSignOutSaga() {
  yield takeLatest(AUTH_REQUEST_SIGNOUT, requestSignOut);
}

export function* authRequestResendActivationSaga() {
  // @ts-ignore
  yield takeLatest(AUTH_REQUEST_RESEND_ACTIVATION, requestResendActivation);
}

export function* authRequestUserActivateSaga() {
  // @ts-ignore
  yield takeLatest(AUTH_REQUEST_USER_ACTIVATE, requestUserActivate);
}

export function* authRequestResetPasswordSaga() {
  // @ts-ignore
  yield takeLatest(AUTH_REQUEST_RESET_PASSWORD, requestResetPassword);
}

export function* authRequestChangePasswordSaga() {
  // @ts-ignore
  yield takeLatest(AUTH_REQUEST_CHANGE_PASSWORD, requestChangePassword);
}

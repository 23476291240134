import styled, { css } from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledMenu: any = styled.ul<any>`
	// @ts-ignore
	${props => props.pull && css`
		${StyledMenuHeader} {
			padding: 0;
			text-align: center;
		}
	`}
`;

export const StyledMenuHeader = styled.li`
	display: block;
	margin: 0.875rem 0;
	padding: 0 20px;
	font-size: 0.6875rem;
	font-weight: 500;
	color: ${colors.white};
	text-transform: uppercase;
`;

import { httpPost, httpGet } from './api/http';

const timeoutForFiles = 10 * 60000;

export const getBearerAuth = (token) => ({
  headers: {
    'Authorization': `Bearer ${token}`
  }
});

export default {
  // Auth
  authSignIn (username, password) {
    return httpPost('/auth', {
      username,
      password
    });
  },
  authSignUp (username, password, name, phone, subscription, newsletter, country, state, promoId) {
    return httpPost('/user/create', {
      username,
      password,
      name,
      phone,
      package: subscription,
      newsletter,
      country,
      state,
      promoId
    });
  },
  authResetPassword (username) {
    return httpPost('/user/resetpassword', {
      username
    });
  },
  authChangePassword (username, password, resetCode) {
    return httpPost('/user/changepasswordreset', {
      username,
      password,
      resetCode
    });
  },
  authUserActivate (code) {
    return httpGet(`/user/activate/${code}`);
  },
  authResendActivation (username) {
    return httpPost('/user/resendactivation', {
      username
    });
  },

  // Account
  accountChangePassword (username, password, token) {
    return httpPost('/auth/user/changepassword', 
      {
        username,
        password
      },
      getBearerAuth(token)
    );
  },

  // Tour
  saveTour (json, token) {
    return httpPost('/auth/tour/save', 
      {
        json
      },
      getBearerAuth(token)
    );
  },
  getTourById(id, token) {
    return httpPost(`/auth/tour/id`, 
      {
        tourId: id
      },
      getBearerAuth(token)
    );
  },
  tourNotify (id, token) {
    return httpPost('/auth/tour/notify', {
      tourId: id
    },
    getBearerAuth(token));
  },

  // Tours
  getTours (token, page, limit) {
    return httpPost(`/auth/tour/user`,
      {
        page, 
        limit
      }, 
      getBearerAuth(token)
    );
  },

  // Photos
  uploadPhotos (data, token) {
    return httpPost('/auth/photos/upload', 
      data, 
      {...getBearerAuth(token), timeout: timeoutForFiles}
    );
  },

  // Files
  uploadFiles (data, token) {
    return httpPost('/auth/files/upload',
      data, 
      {...getBearerAuth(token), timeout: timeoutForFiles}
    );
  },

  // Stats
  getStats (tourId, username, token) {
    return httpPost('/auth/tour/stats', 
      {
        tourId,
        username
      }, 
      getBearerAuth(token)
    );
  },

  // Permissions
  getPermissions (tourId, token) {
    return httpPost('/auth/tour/listpermissions', 
      {
        tourId
      }, 
      getBearerAuth(token)
    );
  },
  addPermissions (tourId, usernames, token) {
    return httpPost('/auth/tour/addpermissions', 
      {
        tourId,
        usernames
      }, 
      getBearerAuth(token)
    );
  },
  removePermissions (tourId, username, token) {
    return httpPost('/auth/tour/removepermissions', 
      {
        tourId,
        username
      }, 
      getBearerAuth(token)
    );
  },

  // Account
  getAccountInfo (token) {
    return httpPost('/auth/user/getinfo', null, getBearerAuth(token));
  },
  saveAccountInfo (data, token) {
    return httpPost('/auth/user/changeinfo', 
      data,
      getBearerAuth(token)
    );
  },
  getAccountStats (token) {
    return httpPost('/auth/tour/accountstats', null, getBearerAuth(token));
  },

  // Agents
  getAgents (token) {
    return httpPost('/auth/agent/list', null, getBearerAuth(token));
  },
  saveAgent (data, token) {
    return httpPost('/auth/agent/save', 
      data,
      getBearerAuth(token)
    );
  },
  removeAgent (agentId, token) {
    return httpPost('/auth/agent/remove', 
      {
        agentId
      }, 
      getBearerAuth(token)
    );
  },

  // Feedback
  sendFeedback (data) {
    return httpPost('/contactus/send', data);
  },

  // Subscriptions
  checkSubscription (token) {
    return httpPost('/auth/subscription/check', null, getBearerAuth(token));
  },

  // Custom domain
  getByAdminDomain () {
    const domain = window.location.hostname; // e.g. customdomainadmin.homeontour.com
    return httpPost('/user/getbyadmindomain', { domain });
  }
}

import { isEmpty } from 'lodash';

const MESSAGE = 'Please fill out the forms:';

const invalidPages = (values, props) => {
	const { 
		enabled, 
		sold, 
		tour360: isTour360,
		video: isVideo, 
		slideshow: isSlideshow,
		gallery: isGallery,
		details: isDetails,
		floorplan: isFloorplan,
		walkScore: isWalkScore,
		map: isMap
	} = values;

	if (!enabled && !sold) {
		return [];
	}

	const { 
		tour360,
		video, 
		photos, 
		details,
		walkScore,
		map, 
		floorplan, 
		agent
	} = props;

	const pagesList = [];

	if (isTour360 && !tour360) {
		pagesList.push('360 tour');
	}
	if (isVideo && !video) {
		pagesList.push('Video');
	}
	if ((isSlideshow || isGallery) && (!photos || isEmpty(photos))) {
		pagesList.push('Photos');
	}
	if (isDetails && !details) {
		pagesList.push('Details');
	}
	if (isWalkScore && !walkScore) {
		pagesList.push('Walk Score');
	}
	if (isMap && !map) {
		pagesList.push('Map');
	}
	if (isFloorplan && (!floorplan || isEmpty(floorplan.photos))) {
		pagesList.push('Floorplan');
	}
	if (!agent) {
		pagesList.push('Agent');
	}

	return pagesList;
};

const validateObject = (isValidate, message) => ({
	isValidate, 
	message
});

const returnValidation = (invalidList) => {
	if (!isEmpty(invalidList)) {
		const message = `${MESSAGE} ${invalidList.join(', ')}.`;

		return validateObject(false, message);
	}

	return validateObject(true, null);
}

export const validateSettings = (values, tour) => {
	const invalidList = invalidPages(values, tour);

	return returnValidation(invalidList);
};

import { ReactNode } from 'react';
import { FileType } from 'rsuite/lib/Uploader';

import { Photo } from '@/v2/shared/types/Photo';

export type FileStatus = 'inited' | 'uploading' | 'success' | 'failure';

export enum FileCardType {
	FILE_CARD = 'fileCard'
}

interface UploaderFields {
	url: string;
	headers: object;
	info?: ReactNode | string;
	timeout?: number;
	files?: File[];
	fileLimit?: number;
	maxFileSize?: number;
	acceptExtensions?: string[];
}

export interface File {
	id: string;
	url: string;
	name: string;
	uploaded: boolean;
	saved: boolean;
	status: FileStatus;
	type: string;
	size: number;
	fileData?: Photo;
}

export interface FileManagerProps extends UploaderFields {
	prefix?: string;
	enableFileName?: boolean;
	enableEditingFile?: boolean;
	enableRemovingFile?: boolean;
	hideToolbar?: boolean;
	onChangeFiles?: (files: File[]) => void;
	onStart?: () => void;
	onEnd?: () => void;
	onCloseSummary?: () => void;
	onUploadDone?: () => void;
}

export interface FileUploaderProps extends UploaderFields {
	onUpdateFile: (file: FileType, status: FileStatus, responseFile?: Photo) => void;
	onUpdateFiles: (fileList: FileType[]) => void;
	onUpdateFileProgress: (percent: number) => void;
	onUploadDone: () => void;
	onAbort: () => void;
	onStart: () => void;
	onEnd: () => void;
	onCloseSummary: () => void;
}

export interface FileGridProps {
	children: ReactNode;
	defaultColsNumber?: number;
	disabled?: boolean;
	disabledRemove?: boolean;
	numberOfSelectedFiles: number;
	totalFiles: number;
	selectedAll?: boolean;
	hideToolbar?: boolean;
	onChangeCols: (value: number) => void;
	onChangeSelectAll: (value: boolean) => void;
	onRemove: () => void;
}

export interface FileCardProps {
	id: string;
	index: number;
	status: FileStatus;
	saved: boolean;
	uploaded: boolean;
	colSize: number;
	isSelected: any;
	selectedFiles: File[];
	fileProgress?: number;
	editable?: boolean;
	removable?: boolean;
	imageComp: ReactNode;
	onSelectionChange?: (index: number, cmdKey: boolean, shiftKey: boolean, ctrlKey: boolean) => void;
	onEdit?: (id: string, event: any) => void;
	onRemove?: (id: string, event: any) => void;
}

export interface ImageCardProps {
	url: string;
	name?: string;
	type: string;
	uploaded: boolean;
	status: FileStatus;
}

export interface DragOverlayCardProps {
	colSize: number;
	quantity: number;
	imageComp: ReactNode;
}

export interface UploadSummaryModalProps {
	show: boolean;
	totalUploaded: number;
	totalRejected: number;
	numberOfWrongFilesSize: number;
	numberOfWrongFilesExtension: number;
	numberOfServerErrors: number;
	onClose: () => void;
}

export interface ModalEditProps {
	show: boolean;
	fileName: string;
	onSave: (name: string) => void;
	onClose: () => void;
}

import { 
  AGENTS_REQUEST_FETCH,
  AGENTS_REQUEST_GET,
  AGENTS_SUCCESS_GET,
  AGENTS_FAILURE_GET,
  AGENTS_REQUEST_SAVE,
  AGENTS_SUCCESS_SAVE,
  AGENTS_FAILURE_SAVE,
  AGENTS_REQUEST_REMOVE,
  AGENTS_SUCCESS_REMOVE,
  AGENTS_FAILURE_REMOVE
} from '@/actions/types';

export const fetchAgentsRequest = id => {
  return {
    type: AGENTS_REQUEST_FETCH
  }
};

export const getAgentsRequest = id => {
  return {
    type: AGENTS_REQUEST_GET
  }
};

export const getAgentsSuccess = data => {
  return {
    type: AGENTS_SUCCESS_GET,
    data
  }
};

export const getAgentsFailure = () => {
  return {
    type: AGENTS_FAILURE_GET
  }
};

export const saveAgentsRequest = (data, msg, callback) => {
  return {
    type: AGENTS_REQUEST_SAVE,
    payload: {
      data,
      msg,
      callback
    }
  }
};

export const saveAgentsSuccess = () => {
  return {
    type: AGENTS_SUCCESS_SAVE
  }
};

export const saveAgentsFailure = () => {
  return {
    type: AGENTS_FAILURE_SAVE
  }
};

export const removeAgentsRequest = (data, callback) => {
  return {
		type: AGENTS_REQUEST_REMOVE,
		payload: {
      data,
      callback
    }
	}
};

export const removeAgentsSuccess = () => {
  return {
		type: AGENTS_SUCCESS_REMOVE
  }
};

export const removeAgentsFailure = () => {
  return {
		type: AGENTS_FAILURE_REMOVE
	}
};

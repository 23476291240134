import React, { Fragment, useState } from 'react';
import { reduxForm, change } from 'redux-form';
import classnames from 'classnames';

import { colors } from '@/defaults/index';

import {
	StyledFormTitle,
	StyledFormSubTitle,
	StyledFormRedirect,
	StyledFormLink,
	StyledForm
} from '../styles';

import { 
	HSelect,
	Input, 
	InputPassword,
	Submit
} from '@/components/FormUI/index';

import {
	countries,
	isCountryState
} from '@/helpers/countries';
import { statesUSA } from '@/helpers/statesUSA';
import { provincesCanada } from '@/helpers/provincesCanada';
import { mapStates } from '@/mappers/mapStates';

const validate = values => {
	const { username, name, phone, password, agree, country, state } = values;
	const errors = {}

  if (!username) {
		// @ts-ignore
    errors.username = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(username)) {
		// @ts-ignore
		errors.username = 'Invalid email address';
	}

	if (!name) {
		// @ts-ignore
    errors.name = 'Required';
	}

	if (!phone) {
		// @ts-ignore
    errors.phone = 'Required';
	}

	if (!state) {
		// @ts-ignore
    errors.state = 'Required';
  }
  
  if (!country) {
		// @ts-ignore
    errors.country = 'Required';
  }

	if (!password) {
		// @ts-ignore
    errors.password = 'Required';
	} else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z_\W\d]{8,}$/.test(password)) {
		// @ts-ignore
		errors.password = 'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number';
	}

	if (!agree) {
		// @ts-ignore
    errors.agree = 'Required';
	}
	
  return errors;
};

const mapOptions = (list: string[]): string[] => {
	return list.map(item => item.replace('*', ''));
};
				
const hSelectStyles = {
	option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
		...styles,
		color: isSelected ? '#fff' : '#333'
	})
};

let FormSignUp = (props) => {
	const { handleSubmit, loading } = props;
	const [ selectedCountry, setSelectedCountry ] = useState(null);

	const handleChangeCountry = values => {
		setSelectedCountry(values.value);
		props.dispatch(change('signUp', 'state', null));
	}

	return (
		<Fragment>
			<StyledFormTitle>
				Create Your Account
			</StyledFormTitle>
			<StyledFormSubTitle>
				Have an account?&nbsp;
				<StyledFormRedirect 
					to="/sign-in"
					className={
						classnames({
							'disabled': loading
						})
					}
				>
					Sign in.
				</StyledFormRedirect>
			</StyledFormSubTitle>
			<StyledForm onSubmit={handleSubmit}>
				<Input 
					name="username"
					type="email"
					label="Email"/>
				<Input 
					name="name"
					type="text"
					label="Name"/>
				<Input 
					name="phone"
					type="text"
					label="Phone"/>
				<HSelect
					name="country"
					label="Country"
					styles={hSelectStyles}
					options={countries}
					onChange={handleChangeCountry}
				/>
				{selectedCountry && isCountryState(selectedCountry) &&
					<HSelect
						name="state"
						label="State"
						styles={hSelectStyles}
						options={mapStates(selectedCountry === 'usa' ? statesUSA : provincesCanada)}
					/>
				}
				<InputPassword 
					name="password"
					type="password"
					label="Password"
				/>
				<Input
					label="I agree to receive information and commercial offers from HomeOnTour"
					kind="square"
					name="newsletter"
					type="checkbox"
					color={colors.dustyGray}
				/>
				<Input
					label="I understand and agree with Privacy Policy and Terms and Conditions"
					kind="square"
					name="agree"
					type="checkbox"
					color={colors.dustyGray} 
				/>
				<Submit 
					primary 
					center 
					xl={!loading} 
					maxWidth="340"
					disabled={loading}
					loading={loading}
				>
					Create Account
				</Submit>
			</StyledForm>
			<StyledFormSubTitle>
				<StyledFormLink
					href="https://homeontour.com/privacy/index.html" 
					target="_blank"
				>
					Privacy Policy
				</StyledFormLink>
				<br />
				<StyledFormLink
					href="https://homeontour.com/terms/index.html" 
					target="_blank"
				>
					Terms and Conditions
				</StyledFormLink>
			</StyledFormSubTitle>
		</Fragment>
	)
};

FormSignUp = reduxForm({
	form: 'signUp',
	validate
})(FormSignUp)

export { FormSignUp };

import React from 'react';

import { 
	StyledTitle,
	StyledTitleH2,
	StyledTitleH3,
} from './styles';

type Props = {
	name: string,
	subtitle: boolean,
	bold: boolean
};

const Title = (props: Props & any) => {
	const { name, subtitle, bold } = props;

	return (
		<StyledTitle>
			{subtitle ? (
				<StyledTitleH3 bold={bold}>{name}</StyledTitleH3>
			) : (
				<StyledTitleH2>{name}</StyledTitleH2>
			)}
			
		</StyledTitle>
	)
};

export default Title;

import Storage from '@/services/storage';

import {
  LAYOUT_PULL_SIDEBAR,
  LAYOUT_LOADING_CONTENT_ON,
  LAYOUT_LOADING_CONTENT_OFF
} from '@/actions/types';

const defaultState = {
  pull: Storage.getPullState() === 'true' ? true : false,
  loadingContent: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LAYOUT_PULL_SIDEBAR:
      const { pull } = action;

      Storage.setPullState(pull);

      return {
        ...state,
        pull
      };
    case LAYOUT_LOADING_CONTENT_ON:
      return {
        ...state,
        loadingContent: true
      }
    case LAYOUT_LOADING_CONTENT_OFF:
      return {
        ...state,
        loadingContent: false
      }
    default:
      return state;
  }
};

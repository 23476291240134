import { css } from "styled-components";
import { colors } from "@/defaults/index";

export const Style = css`
  // @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap&subset=latin-ext');

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    // font-family: 'Quicksand', Helvetica, Arial, sans-serif;
    // font-size: 1rem;
    // font-weight: 400;
    // line-height: 1.4;
    color: ${colors.mineShaft};
    background-color: ${colors.white};
  }

  button {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  p {
    margin: 0;
  }

  a {
    color: ${colors.mineShaft};
    text-decoration: none !important;

    &:focus,
    &:active,
    &:hover {
      outline: none !important;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  input {
    color: #333 !important;
  }

  /*
  /* React DatePicker */
  /***/
  .notify-at-react-datepicker-input {
    height: 2.5rem;
    min-width: 11.25rem /* 180/16 */;
    padding: 0 0.9375rem;
    border: 0.0625rem solid ${colors.gallery};
    background-color: ${colors.white};
    font-size: 0.875rem;
    font-weight: 400;
    outline: none;
    transition: border-color 0.2s ease-out;

    &:focus {
      border-color: ${colors.cobalt};
    }

    &:disabled {
      background-color: ${colors.gallery};
      cursor: not-allowed;

      & + .react-datepicker__close-icon {
        pointer-events: none;
      }
    }
  }

  .notify-at-react-datepicker-calendar {
    .react-datepicker__month-container {
      border-right: 1px solid #aeaeae;
    }

    .react-datepicker__time-container {
      border-left: none;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      outline: none;
    }
    .react-datepicker__navigation--next {
      right: 90px !important;
    }
  }

  .notify-at-react-datepicker-input-validation {
    label,
    input {
      display: none;
    }
  }

  /*
  /* Sortable Helpers */
  /***/
  .sortable-helper-dragging {
    img,
    [data-drag-effect="true"] {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.65);
    }
  }
  /***/

  .react-audio-player {
    outline: none;
  }

  input,
  textarea {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
      -webkit-text-fill-color: ${colors.mineShaft};
    }
  }

  @-webkit-keyframes autofill {
    to {
      color: ${colors.mineShaft};
      background: ${colors.white};
    }
  }

  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.dustyGray};
  }

  /*
  /* Animation preloader content */
  /***/
  .component-fade-enter {
    opacity: 0.01;
  }
  .component-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }
  .component-fade-exit {
    opacity: 1;
  }
  .component-fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-out;
  }

  /*
  /* Animation Create a new Tour button */
  /***/
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 188, 212, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 188, 212, 0);
    }
  }

  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export interface DeprecatedResponseData {
  isYoutube: boolean;
  isVimeo: boolean;
  isIframe: boolean;
  youtubeUrlBranded: string;
  youtubeUrlUnbranded: string;
  vimeoUrlBranded: string;
  vimeoUrlUnbranded: string;
  iframeUrlBranded: string;
  iframeUrlUnbranded: string;
}

export interface VideoForm {
  videos: VideoItem[];
}

export interface VideoItem {
  name: OptionName;
  youtube: TypeUrl;
  vimeo: TypeUrl;
  iframe: TypeUrl;
}

export interface TypeUrl {
  branded: string;
  unbranded: string;
}

export enum OptionName {
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  Iframe = 'iframe'
}

import React, { PureComponent } from 'react';
import { MdDeleteForever } from 'react-icons/md';

import { colors } from '@/defaults/index';

import {
	StyledImageCard,
	StyledImageCardImg,
	StyledImageCardBtn
} from './styles';

class ImageCard extends PureComponent<any> {
	handleRemove = () => {
		this.props.onRemove();
	}

	render() {
		const { image, alt, styles, auto } = this.props;

		return (
			<StyledImageCard
				style={styles}>
				<StyledImageCardImg
					auto={auto}
					src={image} 
					alt={alt} />
				<StyledImageCardBtn
					onClick={this.handleRemove}
				>
					<MdDeleteForever color={colors.white} />
				</StyledImageCardBtn>
			</StyledImageCard>
		)
	}
};

export default ImageCard;

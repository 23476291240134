import React, { ReactElement, useEffect, useState } from 'react';
import { Button, FlexboxGrid, List, Message } from 'rsuite';

import { getIntegrations, integrateQuickBooks, integrateGoogleCalendar, integrateStripe } from './http';
import integrationsData from './integrationsData.json';
import { IntegrationItem, IntegrationType, StatusType } from './types';
import { getParams } from '@/v2/shared/utils/queryStringParser';

const Integrations: React.FC = (): ReactElement => {
  const [ integrationList, setIntegrationList ] = useState([]);
  const [ connecting, setConnecting ] = useState<string>(null);
  const [ responseStatus, setResponseStatus ] = useState<StatusType.Success | StatusType.Error>(null);
  const [ responseMessage, setResponseMessage ] = useState<string>('');

  useEffect(() => {
    fetchAllIntegrations();
    readQueryStringParams();
  }, []);

  const readQueryStringParams = () => {
    const { status, source } = getParams();

    status && setResponseStatus(status);

    if (source) {
      let integrationName = '';

      switch (source) {
        case IntegrationType.GoogleCalendar:
          integrationName = 'Google Calendar';
          break;
        case IntegrationType.QuickBooks:
          integrationName = 'QuickBooks';
          break;
        case IntegrationType.Stripe:
          integrationName = 'Stripe';
          break;
        default:
          break;
      }

      integrationName && setResponseMessage(
        integrationName + (status === StatusType.Success ? ' has been' : ' has not been') + ' connected.'
      );
    }
  };

  const fetchAllIntegrations = () => {
    getIntegrations().then(res => {
      setIntegrationList(mapIntegrationList(res?.data || []));
    });
  };

  const connectGoogleCalendar = () => {
    integrateGoogleCalendar().then(res => {
      window.location = res.data.url;
    })
    .catch(() => setConnecting(null));
  };

  const connectQuickBooks = () => {
    integrateQuickBooks().then(res => {
      window.location = res.data.url;
    })
    .catch(() => setConnecting(null));
  };

  const connectStripe = () => {
    integrateStripe().then(res => {
      window.location = res.data.url;
    })
    .catch(() => setConnecting(null));
  };

  const handleConnectClick = (type: string) => {
    setConnecting(type);

    type === IntegrationType.GoogleCalendar && connectGoogleCalendar();
    type === IntegrationType.QuickBooks && connectQuickBooks();
    type === IntegrationType.Stripe && connectStripe();
  };

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        {responseStatus && [StatusType.Success, StatusType.Error].includes(responseStatus) && responseMessage &&
          <Message 
            type={responseStatus}
            description={responseMessage} 
          />
        }
      </div>

      <List>
        {integrationList.map((item, index) => (
          <List.Item key={item.id} index={index}>
            <FlexboxGrid align="middle">
              <FlexboxGrid.Item colspan={8}>
                <img style={item.style} src={item.image} alt="" />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={8}>
                {item.name}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={8}>
                {item.integrated ? (
                    <span style={{ 'color': '#208040' }}>Already Connected</span>
                ) : (
                  <Button 
                    appearance='primary'
                    disabled={!!connecting && connecting !== item.id}
                    loading={connecting === item.id}
                    onClick={() => handleConnectClick(item.id)}
                  >
                    Click here to connect
                  </Button>
                )}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default Integrations;

const mapIntegrationList = (data: any): IntegrationItem[] =>
  integrationsData.map(item => {
    if (data?.find(i => i.type === item.id)) {
      item.integrated = true;
    }
    return item;
  });
import {
	TOUR_REQUEST_GET,
	TOUR_SUCCESS_GET,
	TOUR_REQUEST_SAVE,
	TOUR_SUCCESS_SAVE,
	TOUR_FAILURE_SAVE,
	TOUR_REQUEST_CHANGE_ADDRESS,
	TOUR_SUCCESS_CHANGE_ADDRESS,
	TOUR_FAILURE_CHANGE_ADDRESS
} from '@/actions/types';

export const getTourRequest = id => {
  return {
		type: TOUR_REQUEST_GET,
		id
	}
};

export const getTourSuccess = json => {
  return {
		type: TOUR_SUCCESS_GET,
		json
	}
};

export const saveTourRequest = (json, options) => {
  return {
		type: TOUR_REQUEST_SAVE,
		payload: {
			json,
			options
		}
	}
};

export const saveTourSuccess = json => {
  return {
		type: TOUR_SUCCESS_SAVE,
		json
  }
};

export const saveTourFailure = () => {
  return {
		type: TOUR_FAILURE_SAVE
  }
};

export const changeTourAddressRequest = (json, options, callback) => {
	return {
		type: TOUR_REQUEST_CHANGE_ADDRESS,
		payload: {
			json,
			options,
			callback
		}
	}
};

export const changeTourAddressSuccess = () => {
	return {
		type: TOUR_SUCCESS_CHANGE_ADDRESS
	}
};

export const changeTourAddressFailure = () => {
	return {
		type: TOUR_FAILURE_CHANGE_ADDRESS
	}
};

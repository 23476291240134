import { useJsApiLoader } from '@react-google-maps/api';

import config from '@/config';

const LIBRARIES = ['places'];

interface UseGoogleMaps {
	isLoaded: boolean;
	loadError: any;
}

export default function useGoogleMaps(): UseGoogleMaps {
  const { isLoaded, loadError } = useJsApiLoader({ 
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES as any
  });

  return { isLoaded, loadError };
}

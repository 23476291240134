import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';

import { authRequestSignIn } from '@/actions/auth';

import { FormSignIn } from '@/components/Forms/index';
import { ModalAlert } from '@/components/Modal/index';

import WrappedAuth from '@/components/WrappedAuth/index';

export const isMobile = () => {
	const mq = window.matchMedia('(max-width: 1100px)');

	if (mq.matches) {
		return true;
	}
};

export const alertMessage = 'For an optimal experience please use Desktop computer in a full screen mode.';

class SignIn extends Component<any> {
	state = {
    modal: true
	}

	componentDidMount() {
		ReactGA.event({
			category: 'Page',
			label: 'Sign In',
			action: 'Clicked'
		});
	}
	
	closeModal = () => {
		this.setState({
			modal: false
		});
  }

	handleSubmit = values => {
		const { username, password } = values;

		this.props.authRequestSignIn(username, password);

		ReactGA.event({
			category: 'Sign In',
			label: 'Send Form',
			action: 'Sent'
		});
	}

	render() {
		const { loading, isActivation } = this.props;

    return (
			<Fragment>
				<FormSignIn 
					onSubmit={this.handleSubmit} 
					loading={loading}
					isActivation={isActivation}
				/>
				{isMobile() &&
					<ModalAlert
						message={alertMessage}
						type="error"
						show={this.state.modal}
						onClose={this.closeModal}
					/>
				}
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	loading: state.auth.loading,
	isActivation: state.auth.isActivation,
});

const mapDispatchToProps = {
  authRequestSignIn
};

export default WrappedAuth(SignIn, mapStateToProps, mapDispatchToProps);

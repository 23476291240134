export const FileUploadEvents = {
  PROGRESS: 'PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NEXT_FILE: 'NEXT_FILE',
  FINALLY: 'FINALLY',
  CANCEL_UPLOADING: 'CANCEL_UPLOADING'
};

export type FileUploadResponse = {
  // @ts-ignore
  name: FileUploadEvents,
  value: string | number | null | any
};

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { history } from '@/routes/index';

import Api from '@/services/api';

import { catchSuccess } from './catchSuccess';
import { catchError } from './catchError';

import { 
  getTourById,
  getToken
} from '@/selectors/index';

import { 
  TOURS_REQUEST_ADD,
  TOURS_REQUEST_REMOVE,
  TOURS_REQUEST_FETCH,
  TOURS_REQUEST_GET
 } from '@/actions/types';

import {
  getToursSuccess,
  getToursFailure,
  addTourSuccess,
  addTourFailure,
  removeTourSuccess,
  removeTourFailure
} from '@/actions/tours';

import { checkSubscriptionRequest } from '@/actions/subscriptions';

function* getToursRequest({ payload: {page, limit} }) {
  try {
    const token = yield select(getToken);
    const { data } = yield call(Api.getTours, token, page, limit);

    yield put(getToursSuccess(data.tours));
  } catch (error) {
    // @ts-ignore
    yield put(getToursFailure());
    yield catchError(error);
  }
}

function* addTourRequest({ payload: {json, callback} }) {
  try {
    const { address, city, zipcode, state, country } = json.address;
    const { idType } = json;

    const fullAddress = `${address}, ${city}, ${state ? state + ' ' : ''}${zipcode}, ${country.label}`;
    const walkScore = { address: fullAddress };

    const requestData = {
      ...json,
      fullAddress,
      walkScore,
      idType
    };

    const token = yield select(getToken);
    const { data } = yield call(Api.saveTour, requestData, token);

    callback();

    yield put(addTourSuccess());
    yield catchSuccess('ADD_TOUR_SUCCESS');

    yield delay(750);
    history.push(`/settings/${data.tour.id}`);
  } catch (error) {
    yield put(addTourFailure());
    yield catchError(error);
  }
}

function* removeTourRequest({ payload: {id, callback} }) {
  try {
    const tour = yield select(getTourById, id);

    const json = {
      ...tour,
      deleted: true
    }

    const token = yield select(getToken);
    yield call(Api.saveTour, json, token);

    callback();

    yield put(removeTourSuccess());
    yield put(checkSubscriptionRequest());
    yield catchSuccess('REMOVE_TOUR_SUCCESS');
  } catch (error) {
    yield put(removeTourFailure());
    yield catchError(error);
  }
}

export function* getToursRequestSaga() {
  // @ts-ignore
  yield takeLatest(TOURS_REQUEST_GET, getToursRequest);
}

export function* fetchToursRequestSaga() {
  // @ts-ignore
  yield takeLatest(TOURS_REQUEST_FETCH, getToursRequest);
}

export function* addTourRequestSaga() {
  // @ts-ignore
  yield takeLatest(TOURS_REQUEST_ADD, addTourRequest);
}

export function* removeTourRequestSaga() {
  // @ts-ignore
  yield takeLatest(TOURS_REQUEST_REMOVE, removeTourRequest);
}

import styled from 'styled-components';

export const BlockNotFoundContainer = styled.div`
  display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const BlockNotFound = styled.div`
  padding: 1.875rem 0.9375rem;
`;

export const BlockNotFoundTitle = styled.h2`
	margin-bottom: 0.9375rem;
  font-size: 3.75rem;
	line-height: 1;
	text-align: center;
`;

export const BlockNotFoundText = styled.h2`
	margin-bottom: 1.5625rem;
  font-size: 1.25rem;
	text-transform: uppercase;
	text-align: center;
`;

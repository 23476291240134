import React, { Fragment, useState } from 'react';
import { reduxForm, change } from 'redux-form'

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';

import { Input } from '@/components/FormUI/index';
import PanelForm from '@/components/PanelForm/index';

const validate = values => {
	const { 
		isYoutube, 
		isIframe,
		isVimeo,
		youtubeUrlBranded, 
		youtubeUrlUnbranded,
		iframeUrlBranded, 
		iframeUrlUnbranded,
		vimeoUrlBranded,
		vimeoUrlUnbranded
	} = values;

	const errors = {}
	
  if (isYoutube && !youtubeUrlBranded) {
		// @ts-ignore
    errors.youtubeUrlBranded = 'Required.';
	}

	if (isYoutube && !youtubeUrlUnbranded) {
		// @ts-ignore
    errors.youtubeUrlUnbranded = 'Required.';
	}

	if (isIframe && !iframeUrlBranded) {
		// @ts-ignore
    errors.iframeUrlBranded = 'Required.';
	}

	if (isIframe && !iframeUrlUnbranded) {
		// @ts-ignore
    errors.iframeUrlUnbranded = 'Required.';
	}

	if (isVimeo && !vimeoUrlBranded) {
		// @ts-ignore
		errors.vimeoUrlBranded = 'Required.';
	}

	if (isVimeo && !vimeoUrlUnbranded) {
		// @ts-ignore
		errors.vimeoUrlUnbranded = 'Required.';
	}
	
  return errors;
};
				
let FormVideo = (props) => {
	const { 
		handleSubmit,
		onSaveAndNext,
		skipUrl,
		loading,
		initialValues: {
			isYoutube,
			isIframe,
			isVimeo
		}
	} = props;

	const initialTab = (isYoutube && 'isYoutube') || (isIframe && 'isIframe') || (isVimeo && 'isVimeo');
	const [tab, setTab] = useState(initialTab);

	const resetSwitchers = () => {
		['isYoutube', 'isIframe', 'isVimeo'].forEach(key => {
			props.dispatch(change('video', key, false));
		});
	}

	const handleChange = (event) => {
		const { name } = event.target;

		resetSwitchers();
		props.dispatch(change('video', name, true));
		setTab(name);
	};

	const renderYoutube = () => (
		<Fragment>
			<Input 
				name="youtubeUrlBranded"
				type="text"
				label="Youtube URL - Branded (e.g. https://www.youtube.com/watch?v=xxxxxxxxxxx)"
			/>
			<Input 
				name="youtubeUrlUnbranded"
				type="text"
				label="Youtube URL - Unbranded (e.g. https://www.youtube.com/watch?v=xxxxxxxxxxx)"
			/>
		</Fragment>
	);

	const renderVimeo = () => (
		<Fragment>
			<Input 
				name="vimeoUrlBranded"
				type="text"
				label="URL - Branded (e.g. https://vimeo.com/xxxxxxxxxxxxxxxx)"
			/>
			<Input 
				name="vimeoUrlUnbranded"
				type="text"
				label="URL - Unbranded (e.g. https://vimeo.com/xxxxxxxxxxxxxxxx)"
			/>
		</Fragment>
	);

	const renderIframe = () => (
		<Fragment>
			<Input
				name="iframeUrlBranded"
				type="text"
				label="URL - Branded (e.g. https://xxxxxxxx.com/xxxxxxxxxxxxxxxx)"
			/>
			<Input
				name="iframeUrlUnbranded"
				type="text"
				label="URL - Unbranded (e.g. https://xxxxxxxx.com/xxxxxxxxxxxxxxxx)"
			/>
		</Fragment>
	);

	return (
		<form onSubmit={handleSubmit}>
			<GridRow>
				<GridCol wrap="true">
					<Input
						label="Youtube"
						name="isYoutube"
						type="checkbox"
						onChange={handleChange}
					/>
					<Input
						label="Vimeo"
						name="isVimeo"
						type="checkbox"
						onChange={handleChange}
					/>
					<Input
						label="Iframe"
						name="isIframe"
						type="checkbox" 
						onChange={handleChange}
					/>
				</GridCol>
			</GridRow>
			{tab === 'isYoutube' &&
				renderYoutube()
			}
			{tab === 'isVimeo' &&
				renderVimeo()
			}
			{tab === 'isIframe' &&
				renderIframe()
			}
			<GridRow>
				<GridCol>
					<PanelForm 
						loading={loading}
						onSave={handleSubmit}
						onSaveAndNext={onSaveAndNext}
						skipUrl={skipUrl}
						formProps={props}
					/>
				</GridCol>
			</GridRow>
		</form>
	)
};

FormVideo = reduxForm({
	form: 'video',
	initialValues: {
		isYoutube: true,
		isVimeo: false,
		isIframe: false,
		youtubeUrlBranded: null,
		youtubeUrlUnbranded: null,
		iframeUrlBranded: null,
		iframeUrlUnbranded: null,
		vimeoUrlBranded: null,
		vimeoUrlUnbranded: null
	},
	validate
})(FormVideo)

export { FormVideo };

import React from 'react';
import { 
	MdAddCircle,
	MdRemoveCircle 
} from 'react-icons/md';

import  {
	StyledFormGroup,
	StyledFormLabel,
	StyledFormInputWrapper,
	StyledFormInput,
	StyledFormTextarea,
	StyledFormSwitch,
	StyledFormSwitchInput,
	StyledFormSwitchSlider,
	StyledFormCheckboxRadioLabel,
	StyledFormCheckboxRadioInput,
	StyledFormCheckboxRadioCheckmark,
	StyledFormError,
	StyledFormInputBtn,
} from './styles';

const inputTypes = [
	'text',
	'email',
	'number',
	'password'
];

export const renderField = ({
	input,
	label,
	type,
	min,
	placeholder,
	disabled,
	kind,
	color,
	autoFocus,
	controls,
	children,
	btnAdd,
	btnRemove,
	onAdd,
	onRemove,
  meta: { touched, error, warning }
}) => (
  <StyledFormGroup>
		{(!kind) &&
			<StyledFormLabel>{label}</StyledFormLabel>
		}
		{type && inputTypes.indexOf(type) > -1 &&
			<StyledFormInputWrapper>
				<StyledFormInput 
					{...input} 
					placeholder={placeholder} 
					autoFocus={autoFocus} 
					type={type} 
					min={min}
					disabled={disabled}
					controls={controls}
				/>
				{children}
				{btnRemove &&
					<StyledFormInputBtn 
						type="button"
						remove
						onClick={onRemove}
					>
						<MdRemoveCircle />
					</StyledFormInputBtn>
				}
				{btnAdd &&
					<StyledFormInputBtn 
						type="button" 
						add
						onClick={onAdd}
					>
						<MdAddCircle />
					</StyledFormInputBtn>
				}
			</StyledFormInputWrapper>
		}
		{type && type === 'checkbox' && !kind &&
			<StyledFormSwitch>
				<StyledFormSwitchInput {...input} disabled={disabled} type={type} />
				<StyledFormSwitchSlider />
			</StyledFormSwitch>
		}
		{type && type === 'checkbox' && kind && kind === 'square' &&
			<StyledFormCheckboxRadioLabel color={color}>
				{label}
				<StyledFormCheckboxRadioInput {...input} disabled={disabled} type={type} />
				<StyledFormCheckboxRadioCheckmark />
			</StyledFormCheckboxRadioLabel>
		}
		{!type &&
			<StyledFormTextarea 
				{...input} 
				placeholder={placeholder} 
				onPaste={evt => {
					evt.preventDefault();

					const text = evt.clipboardData.getData('text/plain');
					document.execCommand('insertHTML', false, text);
				}}
			></StyledFormTextarea>
		}
		{touched &&
			error && <StyledFormError>{error}</StyledFormError>
		}
  </StyledFormGroup>
);

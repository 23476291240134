import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SelectPicker } from "rsuite";
import ReactAudioPlayer from "react-audio-player";

import { getUserId } from "@/selectors/index";
import { MusicSetupProps, MusicSetupValue, TrackItem } from "./types";
import { SETTINGS_INITIAL, selectMusic } from "../../selectors";
import { getMusic, getUserMusic } from "../../http";

const MusicSetup = ({ id, onChange }: MusicSetupProps): ReactElement => {
  const music = useSelector(selectMusic(id));

  const [musicData, setMusicData] = useState<MusicSetupValue[]>([]);
  const [tracks, setTracks] = useState<TrackItem[]>([]);
  const [activeTrackUrl, setActiveTrackUrl] = useState<string>(music?.url);
  const [activeTrackId] = useState<string>(music?.id);

  useEffect(() => {
    getTracks();
  }, []);

  const getTracks = async (): Promise<any> => {
    const allTracks = await getMusic();
    const userTracks = await getUserMusic(getUserId());

    const data = [...allTracks?.data, ...userTracks?.data];

    setMusicData(data);
    setTracks(data.map(mapTracks));
    onChange(music);
  };

  const mapTracks = ({ id, name, url }) => ({
    label: name,
    value: id,
    url: url,
  });

  const compare = (a, b) => {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const handleChange = (value: string) => {
    const selectedMusic = musicData.find((music) => music.id === value);
    const activeUrl = tracks.find((track) => track.value === value)?.url;

    setActiveTrackUrl(activeUrl);
    onChange(selectedMusic || SETTINGS_INITIAL.music);
  };

  return (
    <>
      <SelectPicker
        size="md"
        placeholder="Select..."
        placement="auto"
        searchable={false}
        data={tracks}
        sort={() => (a, b) => compare(a.label, b.label)}
        defaultValue={activeTrackId}
        onChange={(selected) => handleChange(selected)}
        style={{
          marginBottom: 25,
          width: 400,
        }}
      />

      {activeTrackUrl && (
        <div style={{ marginBottom: 20 }}>
          <ReactAudioPlayer
            src={activeTrackUrl}
            controls
            controlsList={"nodownload noplaybackrate"}
          />
        </div>
      )}
    </>
  );
};

export default MusicSetup;

import React, { Fragment } from 'react';
import { reduxForm, change } from 'redux-form'

import Title from '@/components/Title/index';
import PanelForm from '@/components/PanelForm/index';

import { HSelect, Input } from '@/components/FormUI/index';

import { mapStates } from '@/mappers/mapStates';

import {
	countries,
	isCountryState
} from '@/helpers/countries';
import { statesUSA } from '@/helpers/statesUSA';
import { provincesCanada } from '@/helpers/provincesCanada';

const validate = values => {
	const { email, name, phone, state, country } = values;
	const errors = {}
	
  if (!email) {
		// @ts-ignore
    errors.email = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)) {
		// @ts-ignore
		errors.email = 'Invalid email address';
	}

	if (!name) {
		// @ts-ignore
    errors.name = 'Required.';
	}

	if (!phone) {
		// @ts-ignore
    errors.phone = 'Required.';
	}

	if (!state) {
		// @ts-ignore
    errors.state = 'Required';
  }
  
  if (!country) {
		// @ts-ignore
    errors.country = 'Required';
  }
	
  return errors;
};
				
let FormAccountChangeInfo = (props) => {
	const { handleSubmit, submitSucceeded, loading, selectedCountry } = props;

	const handleChangeCountry = () => {
		props.dispatch(change('accountChangeInfo', 'state', null));
	}

	return (
		<Fragment>
			<Title 
				subtitle="true"
				name="Edit account:"
			/>
			<form onSubmit={handleSubmit}>
				<Input 
					name="email"
					type="email"
					label="Email"
					disabled={true} />
				<Input 
					name="name"
					type="text"
					label="Name" />
				<Input 
					name="phone"
					type="text"
					label="Phone" />
				<HSelect
					name="country"
					label="Country"
					options={countries}
					onChange={handleChangeCountry}
				/>
				{selectedCountry && isCountryState(selectedCountry) &&
					<HSelect
						name="state"
						label="State"
						options={mapStates(selectedCountry === 'usa' ? statesUSA : provincesCanada)}
					/>
				}
				<PanelForm loading={loading && submitSucceeded} />
			</form>
		</Fragment>
	)
};

FormAccountChangeInfo = reduxForm({
	form: 'accountChangeInfo',
	validate
})(FormAccountChangeInfo)

export { FormAccountChangeInfo };

import React, { ReactElement, useEffect} from 'react';
import { connect, useSelector } from 'react-redux';
import { Button, ControlLabel, Form, FormGroup, Icon, Input, List, Panel, Radio, RadioGroup } from 'rsuite';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Title from '@/components/Title';
import { useStyles } from './styles';
import { selectVideo } from './selectors';
import PanelForm from '@/components/PanelForm';
import { redirectTo } from '@/helpers/redirect';
import { saveTour } from '@/helpers/forms';
import { saveTourRequest } from '@/actions/tour';
import ErrorBlock from '@/v2/shared/components/Form/ErrorBlock/ErrorBlock';
import Options from './data.json';
import { getMappedValueFromObject } from './utils';
import { DEFAULT_VALUES, FORM_GROUP_NAME, FORM_NAME, INITIAL_VALUE, SCHEMA } from './constants';
import { VideoItem } from './types';

const Video: React.FC = ({ id, saveTourRequest }: any): ReactElement => {
  const classes = useStyles();

  const saving = useSelector((state: any) => state.tour.loading);
  const currentTour = useSelector((state: any) => state.tour[id]);
  const video = useSelector(selectVideo(id));

  // @ts-ignore
  const skipUrl = `/${redirectTo({currentPage: FORM_NAME, activePages: currentTour.settings})}/${id}`;

  const { 
		control,
		formState: { errors },
		handleSubmit,
    setValue,
	} = useForm<any>({
		mode: 'onChange',
    shouldFocusError: false,
		resolver: yupResolver(SCHEMA),
		defaultValues: DEFAULT_VALUES
	});

  const { fields, append, remove, move } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: FORM_GROUP_NAME
  });

  useEffect(() => {
    if (video?.videos) {
      setValue(FORM_GROUP_NAME, video.videos);
    } else if (video?.isYoutube || video?.isVimeo || video?.isIframe) { // deprecated
      setValue(FORM_GROUP_NAME, getMappedValueFromObject(video));
    }
  }, [video]);

  const watchVideos = useWatch({
    name: FORM_GROUP_NAME,
    control,
    defaultValue: [INITIAL_VALUE]
  });

  const handleSort = ({ oldIndex, newIndex }) => {
    move(oldIndex, newIndex);
  };

  const handleSave = (data: VideoItem[]) => {
    // @ts-ignore
    saveTour({ id, saveTourRequest }, FORM_NAME, data);
  };

  const handleSaveAndNext = (data: VideoItem[]) => {
    // @ts-ignore
    saveTour({ id, saveTourRequest }, FORM_NAME, data, true);
  };

  return (
    <Panel>
			<Title name="Video" />

      <Form>
        <List sortable bordered pressDelay={300} onSortEnd={handleSort}>
        {fields.map((item, index) => (
          <List.Item key={index} index={index}>
            <div key={item.id}>
            <FormGroup>
              <Controller
                name={`videos[${index}].name`}
                control={control}
                render={({ field }) =>
                  <div className={classes.wrapIconAndRadioGroup}>
                    <Icon icon='arrows-alt' size="2x" />
                    <RadioGroup 
                      {...field}
                      inline 
                      appearance="picker"
                      value={field.value}
                      onChange={value => field.onChange(value)}
                    >
                      <Radio value={Options.youtube.id}>{Options.youtube.label}</Radio>
                      <Radio value={Options.vimeo.id}>{Options.vimeo.label}</Radio>
                      <Radio value={Options.iframe.id}>{Options.iframe.label}</Radio>
                    </RadioGroup> 
                  </div>
                }
              />
            </FormGroup>
            {Object.values(Options).map(opt => (
              watchVideos[index]?.name === opt.id &&
                <div key={opt.id}>
                  <FormGroup>
                    <ControlLabel>{Options[opt.id].brandedExampleLabel}</ControlLabel>
                    <Controller
                      name={`videos[${index}][${opt.id}].branded`}
                      control={control}
                      render={({ field }) =>
                        <Input
                          {...field}
                          value={field.value || ''}
                        />
                      }
                    />
                    <ErrorBlock error={errors?.videos?.[index]?.[opt.id]?.branded} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>{Options[opt.id].unbrandedExampleLabel}</ControlLabel>
                    <Controller
                      name={`videos[${index}][${opt.id}].unbranded`}
                      control={control}
                      render={({ field }) =>
                        <Input
                          {...field}
                          value={field.value || ''}
                        />
                      }
                    />
                    <ErrorBlock error={errors?.videos?.[index]?.[opt.id]?.unbranded} />
                  </FormGroup>
                  {fields?.length > 1 &&
                    <FormGroup style={{ marginBottom: 10 }}>
                      <Button 
                        color="red" 
                        type="button"
                        onClick={() => remove(index)}
                      >
                        Delete
                      </Button>
                    </FormGroup>
                  }
                </div>
              ))}
            </div>
          </List.Item>
        ))}
        </List>
        <FormGroup style={{ marginTop: 30 }}>
          <Button 
            appearance="primary" 
            type="button"
            onClick={() => append(INITIAL_VALUE)}
          >
            Add new
          </Button>
        </FormGroup>
      </Form>

      <Panel className={classes.panel}>
				<PanelForm 
					loading={saving}
					onSave={handleSubmit(handleSave)}
					onSaveAndNext={handleSubmit(handleSaveAndNext)}
          skipUrl={skipUrl}
					formProps={{valid: true}}
				/>
			</Panel>
    </Panel>
	);
};

const mapDispatchToProps = {
  saveTourRequest
};

export default connect(null, mapDispatchToProps)(Video);

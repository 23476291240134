import { ThemeModeName } from "../ThemeMode/types";

export interface LayoutCardsProps {
  id: string;
  mode: ThemeModeName;
  onChange: (value: LayoutCardName, availableThemeModes: string[]) => void;
}

export enum LayoutCardName {
  Classic = 'rock',
  Gold = 'paper',
  Modern = 'modern',
  Ocean = 'ocean'
}

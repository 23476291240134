import React from 'react';
import { Field } from 'redux-form';

import withFormGroup from '../withFormGroup';

import {
	StyledCheckboxButtonsGroup,
	StyledCheckboxButtonsTitle,
	StyledCheckboxButtonsLabel,
	StyledCheckboxButtonsInput,
	StyledCheckboxButtonsCheckmark
} from './styles';

const renderField = ({
	input,
	options,
	title
}) => (
	<StyledCheckboxButtonsGroup>
		<StyledCheckboxButtonsTitle>{title}</StyledCheckboxButtonsTitle>
		{options.map(({value, name}, index) =>
			<StyledCheckboxButtonsLabel key={value}>
				{name}
				<StyledCheckboxButtonsInput
					type="checkbox"
					name={`${input.name}[${index}]`}
					value={value}
					checked={input.value.indexOf(value) !== -1}
					onChange={(event) => {
						const newValue = [...input.value] || [];

						if (event.target.checked) {
							newValue.push(value);
						} else {
							newValue.splice(newValue.indexOf(value), 1);
						}

						return input.onChange(newValue);
					}}
				/>
				<StyledCheckboxButtonsCheckmark />
			</StyledCheckboxButtonsLabel>
		)}
	</StyledCheckboxButtonsGroup>
);

const FieldComponent = withFormGroup(renderField);

const CheckboxButtons = ({...rest}) => {
		return (
			<Field
				component={FieldComponent}
				{...rest}
			/>
		)
};

export { CheckboxButtons };

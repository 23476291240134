import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { Button, ButtonToolbar } from 'rsuite';
import { HexColorPicker, HexColorInput } from 'react-colorful';

import { useStyles } from './styles';
import { ColorPickerProps } from './types';
import useClickOutside from '../../hooks/useOnClickOutside';

const ColorPicker = ({ value, presetColors, onChange }: ColorPickerProps): ReactElement => {
	const classes = useStyles();

  const popover = useRef();
  const [ isOpen, toggle ] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

	return (
		<div className={classes.picker}>
      <div className={classes.swatchWrapper}>
        <Button 
          className={classes.swatch}
          appearance="default" 
          size="lg" 
          style={{ background: value }}
          onClick={() => toggle(true)}
        >
        </Button>
        <HexColorInput prefixed color={value} onChange={color => onChange(color)} />
      </div>

      {isOpen &&
        <div className={classes.popover} ref={popover}>
          <HexColorPicker color={value} onChange={val => onChange(val)} />

          <div style={{ padding: 10}}>
            <ButtonToolbar>
              {presetColors.map(color =>
                <Button 
                  key={color}
                  appearance="default"
                  size="md" 
                  style={{ background: color }} 
                  onClick={() => onChange(color)}>
                </Button>
              )}
            </ButtonToolbar>
          </div>
        </div>
      }
    </div>
	);
};

export default ColorPicker;

export interface ThemeModeProps {
  id: string;
  availableThemeModes: string[];
  onChange: (value: ThemeModeName) => void;
}

export enum ThemeModeName {
  Light = 'light',
  Dark = 'dark'
}

import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '@/services/api';

import { catchError } from './catchError';

import { CUSTOM_DOMAIN_REQUEST_GET } from '@/actions/types';
import { getCustomDomainSuccess } from '@/actions/customDomain';

export function* getCustomDomainRequest() {
  try {
    const response = yield call(Api.getByAdminDomain);

    yield put(getCustomDomainSuccess({ ...response.data }));
  } catch (error) {
    yield catchError(error);
  }
}

export function* getCustomDomainRequestSaga() {
  // @ts-ignore
  yield takeLatest(CUSTOM_DOMAIN_REQUEST_GET, getCustomDomainRequest);
}

import styled, { css } from 'styled-components';
import { darken } from 'polished'
import { colors } from '@/defaults/index';

export const StyledModalAlertContent = styled.div<any>`
	margin-top: 0.9375rem;
	font-size: 1rem;
	font-weight: 500;

	// @ts-ignore
	${props => props.type === 'success' && css`
		color: ${colors.lima};
	`}

	// @ts-ignore
	${props => props.type === 'error' && css`
		color: ${colors.crimson};
	`}
`;

export const StyledModalAlertFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 1.875rem;
`;

export const StyledModalAlertBtn = styled.button`
	margin-left: 0.375rem;
	padding: 0.5rem 1.125rem;
	border: none;
	border-radius: 0.125rem;
	background-color: ${colors.boulder};
	color: ${colors.white};
	outline: none;
	transition: background-color .2s ease-out;

	&:hover {
		background-color: ${props => darken(0.1, colors.boulder)};
	}
`;

import { colors } from '@/defaults/index';

export const SelectStyles = {
	control: (provided, state) => ({
		...provided,
		borderRadius: 0,
		borderColor: state.isFocused ? colors.cobalt : colors.gallery,
		boxShadow: 'none'
	}),
	valueContainer: (provided, state) => ({
		...provided,
		paddingLeft: '0.8125rem'
  })
};

import React from 'react';

import { LoaderTail } from '@/components/Loaders/index';

import  {
  StyledFormGroup,
  StyledFormSubmitButton
} from '../styles';

const Submit = (props) => (
  <StyledFormGroup>
    <StyledFormSubmitButton 
      type={props.type ? props.type : `submit`}
      disabled={props.loading}
      {...props}
    >
      {props.loading ? (
        <LoaderTail />
      ) : (
        props.children
      )}
    </StyledFormSubmitButton>
  </StyledFormGroup>
);

export { Submit };

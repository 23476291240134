import { 
  LAYOUT_PULL_SIDEBAR,
  LAYOUT_LOADING_CONTENT_ON,
  LAYOUT_LOADING_CONTENT_OFF,
  LAYOUT_REQUEST_FAKE,
  LAYOUT_SUCCESS_FAKE
} from '@/actions/types';

export const pullSidebar = pull => {
  return {
		type: LAYOUT_PULL_SIDEBAR,
		pull
  }
};

export const loadingContentOn = () => {
  return {
		type: LAYOUT_LOADING_CONTENT_ON
  }
};

export const loadingContentOff = () => {
  return {
		type: LAYOUT_LOADING_CONTENT_OFF
  }
};

export const fireFakeRequest = () => {
  return dispatch => {
    dispatch(getFakeRequest());

    setTimeout(() => {
      dispatch(getFakeSuccess());
    }, 250);
  }
};

export const getFakeRequest = () => {
  return {
    type: LAYOUT_REQUEST_FAKE
  }
};

export const getFakeSuccess = () => {
  return {
		type: LAYOUT_SUCCESS_FAKE
  }
};

import React, { ReactElement } from 'react';
import { Panel } from 'rsuite';

const videosData = require('../../data/introductionVideos.json');

const IntroductionList = (): ReactElement => {
  return (
    <div 
      className="shepherd-step-introduction-list"
      style={{ display: 'flex', justifyContent: 'center', 'flexWrap': 'wrap', textAlign: 'center' }}
    >
      {videosData.map(({title, url}) =>
        <Panel
          key={title}
          header={title}
          bordered
          bodyFill
          style={{ width: '100%', maxWidth: 640, margin: '30px 20px 0' }}
        >
          <iframe src={url} width="100%" height="360" frameBorder="0"
                  allow="autoplay; fullscreen" allowFullScreen></iframe>
        </Panel>
      )}
    </div>
  )
}

export default IntroductionList;

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  panel: {
    margin: '30px 0',

    '& .rs-message': {
      marginTop: 20
    }
  },
  templates: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 40,
    width: '100%',
    maxWidth: 860
  },
  templateCard: {
    margin: '0 20px 20px 0',
    border: '2px solid #f1f1f1',
    borderRadius: 3,
    overflow: 'hidden',
    cursor: 'pointer',

    '& img': {
      height: 150,
      width: 260,
      objectFit: 'cover',
      objectPosition: 'bottom'
    },

    '& span': {
      display: 'block',
      padding: '10px 0',
      textAlign: 'center',
      fontWeight: 500
    },

    '& a': {
      display: 'block',
      padding: '10px 0',
      textAlign: 'center',
      fontWeight: 500
    }
  },
  templateRequestCard: {
    width: 260,
    minHeight: 194,
    display: 'flex',
    flexWrap: 'wrap',
    placeItems: 'center',
    position: 'relative',
    cursor: 'default',

    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      opacity: '.9'
    },

    '& p': {
      position: 'relative',
      padding: 5,
      textAlign: 'center',
      color: '#fff',
      fontSize: 18,
      textShadow: '1px 1px #000'
    },

    '& .rs-btn': {
      margin: '0 auto'
    }
  },
  templateActiveCard: {
    borderColor: '#3498ff',

    '& span': {
      backgroundColor: '#3498ff',
      color: '#fff'
    }
  },
  templateDisabledCard: {
    filter: 'grayscale(1)',
    cursor: 'not-allowed'
  },
  preview: {
    display: 'flex',
    margin: '0 -10px',
    textAlign: 'center',

    '& > div': {
      width: '50%',
      padding: '0 5px'
    },

    '& label': {
      display: 'block',
      marginBottom: 10
    },

    '& video': {
      width: '100%',
      marginBottom: 8,
      padding: '0 5px'
    }
  }
});

import { Photo } from "@/v2/shared/types/Photo";
import React from "react";
import { Button, FlexboxGrid, Modal, Panel } from "rsuite";
import { createUseStyles } from "react-jss";

export const PickPhotoModal = ({
  show,
  photos,
  onSelected,
  onCancel,
}: {
  show: boolean;
  photos: Photo[];
  onSelected: (photo: Photo) => void;
  onCancel: () => void;
}) => {
  const classes = useStyles();

  return (
    <Modal full backdrop={true} show={show} onHide={onCancel} size="xs">
      <Modal.Body>
        <FlexboxGrid>
          {photos.map((photo) => (
            <FlexboxGrid.Item
              colspan={6}
              key={photo.urls.at(0).url}
              className={classes.item}
            >
              <Panel
                shaded
                bordered
                bodyFill
                className={classes.panel}
                onClick={() => onSelected(photo)}
              >
                <img src={photo.urls.at(2).url} alt="" height="240" />
              </Panel>
            </FlexboxGrid.Item>
          ))}
        </FlexboxGrid>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onCancel}
          appearance="default"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useStyles = createUseStyles({
  item: {
    padding: 5,
  },
  panel: {
    width: "100%",
    cursor: "pointer",

    "& :hover": {
      opacity: 0.8,
    },

    "& img": {
      width: "100%",
      objectFit: "cover",
    },
  },
  cancelButton: {
    marginTop: 10,
  },
});

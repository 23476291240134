import styled from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledFileUpload = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	height: 9.375rem;
`;

export const StyledFileUploadBoxOverlay = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
`;

export const StyledFileUploadProgressWrapper = styled.div`
	display: flex;
	flex-wrap: no-wrap;
	justify-content: between;
	align-items: center;
	max-width: 25rem;
	width: 100%;
	
	& svg {
		margin-left: 0.9375rem;
	}
`;

export const StyledFileUploadBoxOverlayContainer = styled.div`
	min-width: 18.75rem;
`;

export const StyledFileUploadProgressContainer = styled.div`
	position: relative;
	width: 100%;
	height: 1.125rem;
	border: 0.0325rem solid #e1e1e1;
	background-color: ${colors.concrete};
`;

export const StyledFileUploadProgressBar = styled.div`
	height: 100%;
	background-color: ${colors.corn};
`;

export const StyledFileUploadProgressPercentage = styled.div`
	position: absolute;
	left: 0;
	top: 1px;
	width: 100%;
	height: 100%;
	font-size: 0.75rem;
	font-weight: 500;
	text-align: center;
	line-height: 0.875rem;
	color: ${colors.black};
`;

export const StyledFileUploadText = styled.span<any>`
	// @ts-ignore
	display: ${props => props.block ? 'block;' : 'inline'};
	margin: 0.3125rem 0;
	// @ts-ignore
	font-size: ${props => props.size ? props.size / 16 + 'rem' : '1rem'};
	// @ts-ignore
	font-weight: ${props => props.strong ? '500' : '400'};
	color: ${props => props.color ? props.color : colors.black};
`;

export const StyledFileUploadDropzone = styled.div<any>`
	width: 100%;
	// @ts-ignore
	opacity: ${props => props.inactive ? 0.05 : 1};
`;

export const StyledFileUploadInDropzone = styled.div`
	width: 100%;
	text-align: center;
	padding: 0 0.625rem;

	& > svg {
		margin-bottom: 0.5rem;
	}
`;

export const DropZoneStyle: any = {
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
	alignContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '100%',
	border: `0.0625rem dashed ${colors.boulder}`,
	cursor: 'pointer'
};

export const DropZoneActiveStyle = {
	'borderColor': `${colors.lima}`
};

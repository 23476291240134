import React, { Component } from 'react';

import { authRequestResendActivation } from '@/actions/auth';

import { FormResendActivation } from '@/components/Forms/index';

import WrappedAuth from '@/components/WrappedAuth/index';

class ResendActivation extends Component<any> {
	handleSubmit = values => {
		const { username } = values;

		this.props.authRequestResendActivation(username);
	}

	render() {
    return (
			<FormResendActivation 
				onSubmit={this.handleSubmit}
				loading={this.props.loading}
			/>
		)
	}
}

const mapStateToProps = state => ({
  loading: state.auth.loading
});

const mapDispatchToProps = {
  authRequestResendActivation
};

export default WrappedAuth(ResendActivation, mapStateToProps, mapDispatchToProps);

import styled, { css } from 'styled-components';
import { colors } from '@/defaults/index';

export const StyledFooter = styled.div` 
	width: 100%;
	height: 4.0625rem;
	padding: 0 1.25rem;
`;

export const StyledFooterContainer = styled.div` 
	display: flex;
	align-items: center;
	padding-top: 0.9375rem;
	border-top: 0.0625rem solid ${colors.gallery};
`;

export const StyledFooterMenuLink = styled.a<any>`
	position: relative;
  margin-right: 2.5rem;
  font-size: 0.875rem;
	font-weight: 500;
	color: ${colors.scorpion};

	${props => props.extra && css`
		margin-left: auto;
		font-size: 10px;
	`}
	
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: -1.25rem;
		width: 0.0625rem;
		height: 0.75rem;
		margin-top: -0.3125rem;
		background-color: ${colors.mercury};
		pointer-events: none;
	}

	&:first-child {
		padding-left: 0.0625rem;
	}

  &:last-child {
		margin-right: 0;

		&:after {
			display: none;
		}
	}

  &:hover {
    text-decoration: underline;
  }
`;

export default {
	// Messages from server 

	'DEFAULT': 'Oops... Try again!',
	'SESSION_EXPIRED': 'Your session has expired!',
	'TIMEOUT': 'Connection timed out!',

	'LOGIN_SUCCESS': 'You have been logged in!',
	'LOGIN_FAIL_WRONG_USER_OR_PASSWORD': 'Wrong user or password!',
	'AUTH_FAILURE_WRONG_PSWD_OR_USER_NOT_FOUND': 'Wrong user or user not found!',
	'AUTH_FAILURE_ACCOUNT_NOT_ACTIVE': 'Your account is not active!',

	'USER_CREATE_SUCCESS': null,
	'USER_CREATE_FAILURE_USER_EXISTS': 'User exists!',
	'USER_CREATE_FAILURE_UNKNOWN': null,

	'USER_ACTIVATE_SUCCESS': 'Your account is activated!',
	'USER_ACTIVATE_FAILURE': null,

	'ACTIVATION_RESEND_SUCCESS': 'Your account is activated!',
	'ACTIVATION_RESEND_FAILURE': null,

	'RESET_PSWD_SUCCESS': 'Your password has been reset!',
	'RESET_PSWD_FAILURE_USER_NOT_FOUND': 'User not found!',

	'CHANGE_PSWD_SUCCESS': 'Your password has been changed!',
	'CHANGE_PSWD_FAILURE_UNKNOWN_ERROR': null,
	'CHANGE_PSWD_FAILURE_USER_NOT_ACTIVE': 'User not active!',
	'CHANGE_PSWD_FAILURE_USER_NOT_FOUND': 'User not found!',

	'GET_TOUR_SUCCESS': null,
	'GET_TOUR_FAILURE': null,
	'GET_USER_TOURS_SUCCESS': null,
	'SAVE_TOUR_SUCCESS': 'The tour form has been saved!',
	'SAVE_TOUR_FAILURE': 'The tour form has not been saved!',

	'CHANGE_INFO_SUCCESS': 'Your data has been saved!',

	'PERM_LIST_SUCCESS': null,
	'PERM_LIST_FAILURE': null,
	'PERM_ADD_SUCCESS': 'New users have been added!',
	'PERM_ADD_FAILURE': null,
	'PERM_REM_SUCCESS': 'User has been deleted!',
	'PERM_REM_FAILURE': null,

	'AGENT_LIST_SUCCESS': null,
	'AGENT_LIST_FAILURE': null,
	'SAVE_AGENT_SUCCESS': 'Agent has been saved!',
	'SAVE_AGENT_FAILURE': null,
	'AGENT_REM_SUCCESS': 'Agent has been deleted!',
	'AGENT_REM_FAILURE': null,

	'PHOTO_UPLOAD_SUCCESS': 'Photos have been uploaded!',

	'FILE_UPLOAD_SUCCESS': 'Files have been uploaded!',

	'GET_STATS_SUCCESS': null,

	'SEND_CONTACTUS_SUCCESS': 'Thank You for Your feedback!',

	// Messages from front
	'SIGNOUT': 'You have been logged out!',

	'ADD_TOUR_SUCCESS': 'Added new Tour!',
	'REMOVE_TOUR_SUCCESS': 'Tour have been deleted!'
}

import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import config from '@/config';

import { ApiPath } from '@/v2/shared/enums';
import { saveTourRequest } from '@/actions/tour';
import { saveTour } from '@/helpers/forms';
import Title from '@/components/Title';
import PanelForm from '@/components/PanelForm';
import FileManager from '@/v2/shared/components/FileManager/FileManager';
import { File } from '@/v2/shared/components/FileManager/types';

const Attachments = (props: any): ReactElement => {
	const FORM_NAME = 'attachments';
	const { id, loadingTour, token, initialAttachments } = props;

	const [ attachmentList, setAttachmentList ] = useState<File[]>([]);
	const [ attachments, setAttachments ] = useState([]);
	const [ uploading, setUploading ] = useState(false);

	useEffect(() => {
		setAttachments(initialAttachments || []);
		setAttachmentList(initialAttachments?.map(mapAttachmentsToFileGrid));
	}, [initialAttachments]);

	// TODO: move to helper (is used by Fllorplan, Photos, Documents)
	const mapAttachmentsToFileGrid = (file, index): File => {
		return {
			id: (index + 1).toString(),
			name: file.name,
			url: file.url,
			uploaded: true,
			saved: true,
			status: 'success',
			type: file.url.split('.').pop(),
			size: 0,
			fileData: file
		};
	};

	const handleChangeAttachments = (files: File[]) => {
		setAttachments(files.map(file => ({
			name: file.name,
			url: file.url
		})));
	};

	const handleSave = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, attachments);
    console.log(attachments);
  };

  const handleSaveAndNext = () => {
    // @ts-ignore
    saveTour(props, FORM_NAME, attachments, true, 'tours');
  };

  return (
    <>
			<Title name="Attachments:" subtitle={true} />
			<FileManager
				prefix="attachments"
				url={config.API_URL + ApiPath.uploadAttachments + id}
				headers={{ 'Authorization': `Bearer ${token}` }}
				acceptExtensions={[]}
        maxFileSize={90000000000000}
        timeout={480000} // 8min
				files={attachmentList}
				enableFileName={true}
				enableEditingFile={true}
				enableRemovingFile={true}
				hideToolbar={true}
				onChangeFiles={handleChangeAttachments}
				onStart={() => setUploading(true)}
				onEnd={() => setUploading(false)}
        onUploadDone={handleSave}
			/>
			<PanelForm
				loading={loadingTour}
				disabled={uploading}
				onSave={handleSave}
				onSaveAndNext={handleSaveAndNext}
				formProps={{valid: true}}
			/>
		</>
	);
};

const mapStateToProps = (state: any, ownProps: any): any => {
  const id = ownProps.id;
  const currentTour = state.tour[id];
  const initialAttachments = currentTour?.attachments;

  return {
		token: state.auth.token,
    id,
    currentTour,
		initialAttachments,
    loadingTour: state.tour.loading
  };
};

const mapDispatchToProps = {
  saveTourRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, head } from 'lodash';
import { Message } from 'rsuite';

import { saveTour } from '@/helpers/forms';
import { redirectTo } from '@/helpers/redirect';

import { saveTourRequest } from '@/actions/tour';
import { fetchAgentsRequest } from '@/actions/agents';

import { StyledFormGroup } from '@/components/FormUI/styles';

import Title from '@/components/Title/index';
import { FormAgent } from '@/components/Forms/index';
import AgentComponent from '@/components/Agent/Agent';
import { Submit } from '@/components/FormUI/index';

import  {
	GridRow,
	GridCol
} from '@/components/Styled/Grid/index';
import { SegmentWrapper } from '@/components/Styled/Segments';
import { getUserId } from '@/selectors';

class Agent extends Component<any> {
  formName: string = 'agent';

  state = {
    showModal: false
  }
  
  componentDidMount() {
    this.props.fetchAgentsRequest();
  }

	toggleShowModal = () => {
		this.setState({
			showModal: !this.state.showModal
		});
  }

  getData = values => {
    const { agentId, multiAgents } = values;
    const agents = multiAgents.filter(item => item.agent);
    const agentList = this.props.agentsItems.filter(item => agents.some(i => item.id === i.agent.value));

    return { 
      agentId: agentId.value,
      multiAgents: agentList
    }
  }

  handleSubmit = values => {
    // @ts-ignore
    saveTour(this.props, this.formName, this.getData(values));
  }

  handleSaveAndNext = () => {
    // @ts-ignore
    saveTour(this.props, this.formName, this.getData(this.props.formValues), true);
  }
  
  render() {
    const {id, items, currentTour, initialValues, loading, tourOwner} = this.props;
    // @ts-ignore
    const skipUrl = `/${redirectTo(
      {currentPage: this.formName, activePages: currentTour.settings, isVideoSlideshow: this.props.isVideoSlideshow}
      )}/${id}`;

    return (
			<GridRow>
				<GridCol>
          <Title name="Agent" />
          {!tourOwner && 
            <Message 
              type="warning" 
              description="Only tour creator can update agent information."
              showIcon
              style={{ marginBottom: 20 }}
            />
          }
          <StyledFormGroup>
            <Submit 
              primary
              type="button"
              disabled={!tourOwner}
              onClick={this.toggleShowModal}>
              Add new agent
            </Submit>
					</StyledFormGroup>
          <SegmentWrapper maxWidth="600px">
            <FormAgent
              onSubmit={this.handleSubmit}
              onSaveAndNext={this.handleSaveAndNext}
              items={items}
              skipUrl={skipUrl}
              initialValues={initialValues}
              loading={loading}
              disabled={!tourOwner}
            />
          </SegmentWrapper>
					<AgentComponent
						show={this.state.showModal} 
						onClose={this.toggleShowModal}
					/>
				</GridCol>
			</GridRow>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const form = state.form.agent;
  const isVideoSlideshow = state?.account?.info?.videoSlideshow;
  const id = ownProps.match.params.id;
  const formValues = form && form.values;
  const currentTour = state.tour[id];
  const agentsItems = state.agents.items
  const currentAgentId = currentTour && currentTour.agentId;
  const currentMultiAgents = currentTour && currentTour.multiAgents || [];
  const creatorId = currentTour?.creatorId;
  const tourOwner = getUserId() === creatorId;

  const items = agentsItems.map(({id, agentName}) => ({
    value: id,
    label: agentName
  }));

  const agentName = agentsItems.filter(({id}) => id === currentAgentId);
  
  const initialValues = !isEmpty(agentName)
    ? { 
      agentId: { value: currentAgentId, label: (head(agentName) as any).agentName },
      multiAgents: !isEmpty(currentMultiAgents) 
        ? currentMultiAgents.map(({ id, agentName }) => ({ agent: { value: id, label: agentName } })) 
        : [{}]
    } : { agentId: null, multiAgents: [{}] };

  return {
    id,
    items,
    agentsItems,
    initialValues,
    formValues,
    currentTour,
    isVideoSlideshow,
    tourOwner,
    loading: state.tour.loading
  };
};

const mapDispatchToProps = {
  saveTourRequest,
  fetchAgentsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Agent);

import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  main: {
    marginTop: 30,
    marginBottom: 30,
    width: "100%",
    maxWidth: 1000,

    "& > *": {
      marginBottom: 20,
    },

    "& .rs-progress": {
      padding: 0,
    },
  },
  addFurniture: {},
  types: {
    display: "flex",
    marginTop: 20,

    "& > *": {
      marginRight: 20,
      width: 200,
    },

    "& .rs-picker-select": {
      width: "100%",
    },

    "& .rs-control-label": {
      display: "block",
      marginBottom: 3,
    },
  },
  photos: {
    display: "flex",
    gap: 15,

    "& .rs-panel": {
      width: "50%",
    },

    "& .rs-control-label": {
      display: "block",
      marginBottom: 10,
      fontSize: 15,
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  photoContainer: {
    display: "flex",
    flexDirection: "column",

    "& img": {
      display: "block",
      maxWidth: "100%",
      cursor: "pointer",
    },

    "& .rs-btn": {
      marginTop: 10,
    },
  },
  carousel: {
    display: "flex",
    gap: 5,
    marginTop: 10,
    marginBottom: -15,
    paddingBottom: 15,
    overflowX: "auto",

    "& > div": {
      height: 50,
      width: 50,
      flex: "0 0 auto",
      cursor: "pointer",

      "& :hover": {
        opacity: 0.7,
      },
    },

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  selectedCarouselItem: {
    border: "2px solid #187121",
    cursor: "default !important",

    "& :hover": {
      opacity: "1 !important",
    },
  },
  photoLimit: {
    marginTop: 10,
    color: "#a40b0b",
  },
  flashAnimation: {
    animation: "flash linear 2.5s infinite",
  },
});

import { httpPost } from "@/services/api/http";
import { ApiPath } from "@/v2/shared/enums";
import store from "@/store/index";

export const getIntegrations = (): Promise<any> =>
  httpPost(ApiPath.IntegrationsGet, null, getHeaders());

export const integrateGoogleCalendar = (): Promise<any> =>
  httpPost(ApiPath.IntegrationsCalendar, null, getHeaders());

export const integrateQuickBooks = (): Promise<any> =>
  httpPost(ApiPath.IntegrationsQuickBooks, null, getHeaders());

export const integrateStripe = (): Promise<any> =>
  httpPost(ApiPath.IntegrationsStripe, null, getHeaders());

export const updateCreditCard = (): Promise<any> =>
  httpPost(ApiPath.UserCustomPortal, null, getHeaders());

const getHeaders = (): any => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

function getToken() {
  return store().getState().auth.token;
}

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	file: {
		height: 250,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
	},
	icon: {
		display: 'block',
		textAlign: 'center',
		width: '100%'
	},
	error: {
		color: '#f44336'
	},
	view: {
		position: 'relative',
		zIndex: 3,
		marginTop: 10,
		background: '#575757',
		color: '#fff',
		textAlign: 'center'
	},
	fileName: {
		display: 'inline-block',
		position: 'relative',
		zIndex: 3,
		maxWidth: '100%',
		marginTop: 10,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		color: '#444',

		'&:hover': {
			textDecoration: 'underline'
		}
	},
	imageName: {
		position: 'absolute',
		bottom: 0,
		zIndex: 3,
		width: '100%',
		padding: '4px 5px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		backgroundColor: 'rgba(0,0,0,.4)',
		color: '#fff',
		textAlign: 'center',

		'&:hover': {
			textDecoration: 'underline'
		}
	}
});

import React from 'react';
import { reduxForm, change, FieldArray  } from 'redux-form';
import { findIndex } from 'lodash';

import { mapStates } from '@/mappers/mapStates';

import {
	countries,
	isCountryState
} from '@/helpers/countries';
import { statesUSA } from '@/helpers/statesUSA';
import { provincesCanada } from '@/helpers/provincesCanada';
import { StyledFormInputBtn, StyledFormLabel, StyledFormList } from '@/components/FormUI/styles';

import { 
	Input,
	HSelect,
	Submit
} from '@/components/FormUI/index';

import {
  ModalFooter 
} from '@/components/Modal/index';
import { MdAddCircle, MdRemoveCircle } from 'react-icons/md';
import { Message } from 'rsuite';

const validate = values => {
	const { 
		agentName,
		agentTitle,
		phone, 
		email, 
		brokerageName, 
		address, 
		city, 
		zipcode, 
		state, 
		country 
	} = values;

	const errors = {};
	const arrayErrors = [];

	if (!agentName) {
		// @ts-ignore
    errors.agentName = 'Required';
	}

	// if (!agentTitle) {
	// 	// @ts-ignore
  //   errors.agentTitle = 'Required';
	// }
	
	// if (!phone) {
	// 	// @ts-ignore
  //   errors.phone = 'Required';
	// }
	
	if (!email) {
		// @ts-ignore
    errors.email = 'Required';
  }
  
  // if (!brokerageName) {
	// 	// @ts-ignore
  //   errors.brokerageName = 'Required';
  // }
	
  // if (!address) {
	// 	// @ts-ignore
  //   errors.address = 'Required';
  // }
  
  // if (!city) {
	// 	// @ts-ignore
  //   errors.city = 'Required';
  // }
  
  // if (!zipcode) {
	// 	// @ts-ignore
  //   errors.zipcode = 'Required';
  // }
  
  // if (!state) {
	// 	// @ts-ignore
  //   errors.state = 'Required';
  // }
  
  // if (!country) {
	// 	// @ts-ignore
  //   errors.country = 'Required';
  // }

	if (!values.ccList || !values.ccList.length) {
		return false;
	}

	const findUserNameIndex = (list, match) => {
		return findIndex(list, match);
	};

	values.ccList.forEach((user, index) => {
		const ccListErrors = {};

		const isExcludedUserNameOfActiveFormRow = 
			findUserNameIndex(values.ccList, {email: user.email}) !== index;

		const isUserNameOfFormRow = 
			findUserNameIndex(values.ccList, {email: user.email}) > -1;

		const emailExists = 
			(isExcludedUserNameOfActiveFormRow && isUserNameOfFormRow);

		if (user.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(user.email)) {
			// @ts-ignore
			ccListErrors.email = 'Invalid email address';
			arrayErrors[index] = ccListErrors;
		} else if (emailExists) {
			// @ts-ignore
			ccListErrors.email = 'Email exists';
			arrayErrors[index] = ccListErrors;
		}
	});

	// @ts-ignore
	errors.ccList = arrayErrors;
	
  return errors;
};

const renderCCList = ({fields}) => (
	<StyledFormList>
		{fields.map((email, index) => (
			<div key={index}>
				<div style={{display: 'flex'}}>
					<div style={{ flex: 1 }}>
						<Input
								name={`${email}.email`}
								type="text"
								placeholder="Email"
								kind="small"
							/>
					</div>
					{fields.length > 1 &&
						<StyledFormInputBtn
							type="button"
							remove
							onClick={() => fields.remove(index)}
						>
							<MdRemoveCircle />
						</StyledFormInputBtn>
					}
					{fields.length === (index + 1) &&
						<StyledFormInputBtn
							type="button"
							add
							onClick={() => fields.push({})}
						>
							<MdAddCircle />
						</StyledFormInputBtn>
					}
				</div>
			</div>
		))}
	</StyledFormList>
);

let FormAgents = (props) => {
	const { 
		handleSubmit,
		loading,
		onClose,
		selectedCountry,
		id,
		children
	} = props;

	const handleChangeCountry = values => {
		props.dispatch(change('agents', 'state', null));
	}

	return (
		<form onSubmit={handleSubmit}>
			<Input 
				name="agentName"
				type="text"
				label="Agent Name*" />
			<Input 
				name="email"
				type="email"
				label="Email*" />
			<Input 
				name="agentTitle"
				type="text"
				label="Agent Title"/>
			<Input 
				name="phone"
				type="text"
				label="Phone"/>
			<Input 
				name="brokerageName"
				type="text"
				label="Brokerage Name"/>
			<Input 
				name="address"
				type="text"
				label="Address"/>
			<Input 
				name="city"
				type="text"
				label="City"/>
			<Input 
				name="zipcode"
				type="text"
				label="ZIP Code"/>
			<HSelect
				name="country"
				label="Country"
				options={countries}
				onChange={handleChangeCountry}
			/>
			{selectedCountry && isCountryState(selectedCountry) &&
				<HSelect
					name="state"
					label="State"
					options={mapStates(selectedCountry === 'usa' ? statesUSA : provincesCanada)}
				/>
			}
			{children}
			<div>
				<StyledFormLabel>CC Email list:</StyledFormLabel>
				<FieldArray name="ccList" component={renderCCList} />
				<Message
          showIcon 
          type="info"
          description="All emails listed as CC will receive tour access and assets notifications."
        />
			</div>
			<ModalFooter>
				<Submit 
					type="button"
					boulder
					onClick={onClose}
				>
					Cancel
				</Submit>
				<Submit 
          primary
          loading={loading}
				>
					Save
				</Submit>
			</ModalFooter>
		</form>
	)
};

FormAgents = reduxForm({
	form: 'agents',
	validate,
	// enableReinitialize: true
})(FormAgents)

export { FormAgents };

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	item: {
		position: 'relative',
		margin: '0 -8px',

		'& .rs-panel-bordered': {
			borderWidth: 3,
			backgroundColor: '#fff'
		},

		'&.itemSize2 img': {
			height: '350px', // 2 cols
		},

		'&.itemSize4 img': {
			height: '250px', // 4 cols
		},

		'&.itemSize6 img': {
			height: '220px', // 6 cols
		},
	
		'& img': {
			display: 'block',
			height: '100%',
			width: '100%',
			objectFit: 'cover',
			borderRadius: 4
		},
	
		'& .rs-panel': {
			overflow: 'visible'
		}
	},
	itemSelected: {
		'& .rs-panel': {
			borderColor: '#7d52a8',
		}
	},
	itemBody: {
		position: 'relative',
		margin: '5px',
		outline: 'none',
		cursor: 'grab'
	},
	image: {
		position: 'relative'
	},
	imageOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 5,
		cursor: 'grabbing'
	},
	quantity: {
		position: 'absolute',
		top: '-15px',
		left: '-15px',
		background: '#7D52A8'
	}
});

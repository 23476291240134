import React from "react";
import { submit as sub } from "redux-form";

import { colors } from "@/defaults/index";

import { StyledPanelForm } from "./styles";
import { Submit } from "@/components/FormUI/index";
import { LoaderTail } from "@/components/Loaders/index";
import { ButtonRedirect } from "@/components/Styled/Buttons/index";

const PanelForm = ({
  loading,
  disabled,
  onButton,
  onSave,
  onSaveAndNext,
  skipUrl,
  submit,
  formProps = {},
  primaryButtonText = "Save",
  secondaryButtonText = "Save and Next",
  primaryButtonOff = false,
}: any) => {
  const { valid, form, dispatch } = formProps;

  const handleAction = (action) => {
    if (submit) {
      submit();
      return;
    }

    if (valid) {
      action();
    } else {
      dispatch && dispatch(sub(form));
    }
  };

  const submitAction = () => {
    if (onButton) {
      return onButton();
    } else if (onSave) {
      return handleAction(onSave);
    } else {
      return null;
    }
  };

  return (
    <StyledPanelForm>
      {loading && <LoaderTail color={colors.lima} />}
      {!primaryButtonOff && (
        <Submit
          primary
          type={onSave || onButton ? "button" : "submit"}
          disabled={loading || disabled}
          onClick={submitAction}
        >
          {primaryButtonText}
        </Submit>
      )}
      {onSaveAndNext && (
        <Submit
          primary
          type="button"
          disabled={loading || disabled}
          onClick={() => handleAction(onSaveAndNext)}
        >
          {secondaryButtonText}
        </Submit>
      )}
      {skipUrl && (
        <ButtonRedirect skippable="true" to={skipUrl}>
          Skip
        </ButtonRedirect>
      )}
    </StyledPanelForm>
  );
};

export default PanelForm;

import React, { ReactElement } from 'react';
import { Button, Icon, Loader } from 'rsuite';
import { IconNames } from 'rsuite/lib/Icon';
import classnames from 'classnames';

import { useStyles } from './image-card.styles';
import { ImageCardProps } from '../types';

const ImageCard = ({ url, name, type, uploaded, status }: ImageCardProps): ReactElement => {
	const classes = useStyles();

	const extractExtensionFromType = (type: string): string => type?.split('/')?.pop();

	const isFile = (): boolean => {
		const imageTypes = ['apng', 'avif', 'gif', 'jpeg', 'jpg', 'png', 'svg+xml', 'webp'];
		return !imageTypes.includes(extractExtensionFromType(type));
	};

	const getFileIconName = (extension: string): IconNames => {
		switch (extension) {
			case 'pdf':
				return 'file-pdf-o';
			case 'zip':
				return 'file-zip-o';
			case 'mp3':
				return 'file-audio-o';
			case 'mp4':
				return 'file-movie-o'
			default:
				return 'file-o';
		}
	};

	const handleFileClick = () => {
		window.open(url, '_file');
	};

	const renderFile = (): ReactElement =>
		<div className={classes.file}>
			<Icon 
				icon={getFileIconName(extractExtensionFromType(type))}
				className={classes.icon}
				size="2x"
			/>
			{name ? (
				<div onClick={handleFileClick} title={`${name} (${extractExtensionFromType(type)})`} className={classes.fileName}>{name}</div>
			) : (
				<Button
					size="sm"
					className={classes.view} 
					onClick={handleFileClick}
				>
					View
				</Button>
			)}
		</div>;

	const renderImage = (): ReactElement => {
		if (status === 'success') {
			return (
				<div>
					<img src={url} />
					{name && 
						<div 
							title={`${name} (${extractExtensionFromType(type)})`}
							className={classes.imageName} 
							style={{ bottom: uploaded ? 0 : 36 }} 
							onClick={handleFileClick}
						>
							{name}
						</div>
					}
				</div>
			);
		}

		return (
			<div className="image-card-file-wrapper">
				{status === 'inited' && <Loader size="md" content="waiting..." vertical />}
				{status === 'uploading' && <Loader size="md" content="uploading..." vertical />}
				{status === 'failure' &&
					<>
						<Icon 
							icon="exclamation-circle"
							className={classnames(classes.icon, classes.error)}
							size="4x"
						/>
					</>
				}
				{name && 
					<div style={{ bottom: status === 'failure' ? 0 : 36 }} className={classes.imageName}>{name}</div>
				}
			</div>
		);
	};

	return (
		<>
			{isFile() ? renderFile() : renderImage()}
		</>
	);
};

export default ImageCard;

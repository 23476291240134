import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import normalize from "styled-normalize";
import { createGlobalStyle } from "styled-components";
import ReactGA from "react-ga";

import configureStore from "@/store/index";

import "rsuite/dist/styles/rsuite-default.css";
import "shepherd.js/dist/css/shepherd.css";
import "@/assets/styles/custom-shepherd.css";
import { Style } from "./style";

import App from "@/containers/App";

import * as serviceWorker from "./serviceWorker";

const isProd = process.env.NODE_ENV === "production";

ReactGA.initialize("UA-126575569-2", {
  debug: !isProd,
});
ReactGA.pageview(window.location.pathname + window.location.search);

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${Style}
`;

const store = configureStore();
const jsx = (
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>
);

ReactDOM.render(jsx, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

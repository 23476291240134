import { omit } from 'lodash';

export const saveTour = (props, formName, values, redirect, redirectPath) => {
	const { id, saveTourRequest } = props;

	// temporary solution - it will be done by refactor code

	let data: any = { [formName]: values, id };

	if (formName === 'agent') {
		data = {
			agentId: values.agentId,
			multiAgents: values.multiAgents,
			id
		};
	} else if (formName === 'publish') {
		data = { settings: omit(values, ['payments']), payments: values.payments, id };
	} else if (formName === 'notify') {
		data = { settings: values, id };
	}

	saveTourRequest(
		data,
		{
			redirect,
			redirectPath,
			currentPage: formName
		}
	);
};

import React, { ReactElement, useEffect, useState } from 'react';

import { useStyles } from './styles';

const HOST_DEV = 'dev.admin.homeontour.com.s3-website.us-east-2.amazonaws.com';
const HOST_DEVPROD = 'devprod.admin.homeontour.com.s3-website.us-east-2.amazonaws.com';

const ApplicationEnvMessage: React.FC = (): ReactElement => {
	const classes = useStyles();
  const host = window.location.host;

  return (
    <>
      {([HOST_DEV, HOST_DEVPROD].includes(host)) &&
        <div className={classes.root}>
          {host === HOST_DEV && 'Testing on DEV API'}
          {host === HOST_DEVPROD && 'Testing on PROD API'}
        </div>
      }
    </>
  );
};

export default ApplicationEnvMessage;
